import {createSelector} from "reselect";
import {deriveDesignClass} from "src/features/design/designSlice"
import {deriveSiteModelClass} from "src/features/siteModel/siteModelSlice";
import {selectIsLeafletDragging} from "src/features/moduleDrag/moduleDragSlice";
import {ModuleCollisionChecker, SiteModel} from "@sunrun/design-tools-domain-model";
import {StaticReferences} from "@sunrun/design-tools-domain-model";
import {selectIsModuleNudging} from "../moduleNudge/moduleNudgeSlice";

export const deriveOffRoofModules = createSelector(
  [deriveDesignClass, deriveSiteModelClass, selectIsLeafletDragging, selectIsModuleNudging],
  (design, siteModel, isLeafletDragging, isModuleNudging): Set<string> => {
    if (isLeafletDragging || isModuleNudging) {
      // Only derive when drag/nudge completes
      return deriveOffRoofModules.lastResult()
    }
    if (design && siteModel) {
      return ModuleCollisionChecker.checkForModulesOffRoofFace(design.modules, siteModel);
    }
    return StaticReferences.EMPTY_SET;
  }
);
