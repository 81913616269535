import React from "react";
import { useWorkspace } from "../hooks/useWorkspace";
import { deriveModulesWithIssues } from "src/features/designGuidance/deriveModulesWithIssues";
import {
  ModuleIssue,
  calculateMinStringLength,
} from "@sunrun/design-tools-domain-model";
import { deriveSolarResourceClass } from "src/features/solarResource/solarResourceSlice";
import { deriveDesignClass } from "src/features/design/designSlice";

const useModuleIssues = () => {
  const { state } = useWorkspace();
  const {
    moduleSelection: { selectedModuleIds },
  } = state;
  const [minStringLength, setMinStringLength] = React.useState<number>(0);

  const modulesWithIssues = deriveModulesWithIssues(state);
  const solarResource = deriveSolarResourceClass(state);
  const design = deriveDesignClass(state);

  React.useEffect(() => {
    if (
      design?.selectedInverterSpecifications?.length &&
      solarResource &&
      design.inverterCount
    ) {
      const inverterSpec = design.selectedInverterSpecifications[0];
      const moduleSpec = design.getModuleSpec();
      setMinStringLength(
        calculateMinStringLength(
          inverterSpec,
          moduleSpec,
          solarResource.siteTemperature
        )
      );
    }
  }, [design?.selectedInverterSpecifications, solarResource]);

  const getIssueDescription = (moduleIssue: ModuleIssue): string => {
    switch (moduleIssue) {
      case ModuleIssue.ShortString: {
        return `${moduleIssue} (${minStringLength} modules required)`;
      }
      default:
        return moduleIssue;
    }
  };

  const uniqueIssuesOfSelectedModules: Set<ModuleIssue> = [
    ...modulesWithIssues,
  ].reduce((prev, curr) => {
    if (selectedModuleIds.has(curr[0])) {
      [...curr[1]].forEach((issue) => prev.add(issue));
    }
    return prev;
  }, new Set<ModuleIssue>());

  return {
    modulesWithIssues,
    uniqueIssuesOfSelectedModules,
    minStringLength,
    getIssueDescription,
  };
};

export default useModuleIssues;
