import {G2Plane} from "./plane";
import {G2Polygon2D} from "./polygon2D";
import {G2Rectangle} from "./rectangle";
import {G2Vector3D} from "./vector3D";
import {G2Line} from "./line";
import {roundToDecimalPlaces} from "./util";
import { ArrayUtil } from "@sunrun/design-tools-functional";

export class G2Polygon3D {
  // by default, vertices is counterclockwise with last point not closing on first point
  readonly vertices: G2Vector3D[];

  constructor(vertices: G2Vector3D[]) {
    this.vertices = vertices
    if (vertices.length < 3) {
      throw new Error("please provide 3 or more vertices to construct a polygon");
    }
    if (!this.isVerticesCounterClockwise()) {
      throw new Error("please provide vertices in counter clock wise order to construct a polygon");
    }
  }

  public centroid(): G2Vector3D {
    // exclude closing of the ring
    const numVertices = this.vertices.length
    const centroid = new G2Vector3D(
      this.vertices.reduce((prev, curr) => prev + curr.x, 0) / numVertices,
      this.vertices.reduce((prev, curr) => prev + curr.y, 0) / numVertices,
      this.vertices.reduce((prev, curr) => prev + curr.z, 0) / numVertices,
    )
    return centroid
  }

  //return a new polygon with vertices rotated
  //eg: vertices before: point1, point2, point3, point4, point5
  //after morph(1), it becomes: point2, point3, point4, point5, point1
  public morph(by: number = 0): G2Polygon3D {
    if (by === 0) {
      return this
    } else {
      return new G2Polygon3D(this.vertices.slice(by).concat(this.vertices.slice(0, by)))
    }
  }

  //strict equals, starting from the same point
  public equals(polygon: G2Polygon3D): boolean {
    const sameNumVertices = this.vertices.length === polygon.vertices.length
    return sameNumVertices && this.vertices.map((point, index) => {
      return point.equals(polygon.vertices[index])
    }).every((it) => it)
  }

  public equalsIgnoreStartingPoint(polygon: G2Polygon3D): boolean {
    const len = this.vertices.length
    for (let i = 0; i < len; i++) {
      if (this.equals(polygon.morph(i))) {
        return true
      }
    }
    return false
  }

  public normalVector(): G2Vector3D {
    //3 vertices with known winding direction determine a plane, hence we can get its norm
    //here we also handle polygon with concave corners, we need to get all possibe scenarios of 3 consecutive points of the polygon,
    //then calculate the normal vector for each set of 3 points, and select the normal vector shows up the most
    const normalVectors: G2Vector3D[] = []
    let rotatedVertices: G2Vector3D[] = this.vertices
    const tryTimesArr = Array(this.vertices.length).fill(1)
    tryTimesArr.forEach(_it => {
      normalVectors.push(G2Plane.fromThreePointsCCW([rotatedVertices[0], rotatedVertices[1], rotatedVertices[2]]).normalVector)
      rotatedVertices = ArrayUtil.rotate(rotatedVertices, false)
    });
    const countMap = ArrayUtil.frequency(normalVectors, (v1, v2)=> v1.equals(v2))
    const maxCount = Math.max(...[...countMap.values()])
    return [...countMap].find(([_normalVector, count]) => count === maxCount)![0]
  }

  public edges(): G2Line[] {
    const zipped = ArrayUtil.zip(this.vertices.slice(0, this.vertices.length), [...this.vertices.slice(1), this.vertices[0]])
    return zipped.map(fromTo => G2Line.fromTwoPoints(fromTo[0], fromTo[1]!))
  }

  //inclusive of edge
  public containsPoint(point: G2Vector3D): boolean {
    const polygon2DContainsPoint2D = this.toPolygon2D().containsPoint(point.toVector2D())
    const pointOnPlane = (roundToDecimalPlaces(this.toPlane().getZAtPoint2D(point.toVector2D())) === roundToDecimalPlaces(point.z))
    return polygon2DContainsPoint2D && pointOnPlane
  }

  public contains(polygon: G2Polygon3D): boolean {
    return polygon.vertices.every(point => this.containsPoint(point))
  }

  //partial contain
  public overlaps(polygon: G2Polygon3D): boolean {
    return polygon.vertices.some(point => this.containsPoint(point)) && polygon.vertices.some(point => !this.containsPoint(point))
  }

  public percentageOverlapWith(_polygon: G2Polygon3D): number {
    //todo
    return 0.0
  }

  //area is positive for ounter clock wise vertices, negative for clock wise vertices
  //but we never use negative area for anything because we only allow ccw vertices in constructor
  public area(): number {
    const up = new G2Vector3D(0,0,1)
    const normal = this.getPlane().getEquation().normal
    let theta: number
    if (up.equals(normal)) {
      theta = 0
    } else {
      const xAxis = up.cross(normal).normalize()
      theta = normal.anglesCounterClockwiseInRadiansToVectorAroundAxis(up, xAxis)
    }
    const area2D = this.toPolygon2D().area()
    const cosTheta = Math.abs(Math.cos(theta))
    return area2D/cosTheta
  }

  public isVerticesCounterClockwise(): boolean {
    const area3D = this.area()
    return area3D > 0
  }

  public getPlane(): G2Plane {
    return new G2Plane(this.normalVector(), this.vertices[0])
  }

  //project 3D Polygon to 2D, get its bbox in 2D, then project back to plane
  //notice two differences here:
  //1: bbox is still a 3d polygon and toRect returns a 3d rectangle
  //2: bbox and toRect possibly return 2 different shape, where toRect respect its own x,y Axis
  public bbox(): G2Polygon3D {
    const plane = this.getPlane()
    const fourCorners = this.toPolygon2D().bbox().vertices.map(point2D => {
      const z = plane.getZAtPoint2D(point2D)
      return new G2Vector3D(
        point2D.x,
        point2D.y,
        z
      )
    })
    return new G2Polygon3D(
      fourCorners
    )
  }

  //minimum bounding Rectangle of the polygon aligned with supplied x axis which is local to the polygon
  //how to think:
  //first, define the problem:
  //  the coordinate of the polygon is in one coordinate system (coordinateSystem1), (in particular, utm)
  //  with the provider xAxis, we are at the local coordinate system (coordinateSystem2)
  //  the problem is to get the bbox in the local coordinate system, but with the coordinate measured in coordinateSystem1
  //second, here is how to solve the problem:
  //  coordinateSystem2 is just coordinateSystem1 with its origin (0,0,0) moved to a new origin and xAxis rotated around zAxis at the new origin
  //  get centroid of the current polygon, use the centroid as the new origin
  //  find the angle theta from xAxis of coordinateSystem1 to the supplied xAxis in counter clock wise
  //  get a new polygon with each point of the current polygon rotated by -theta around new origin and polygon normal
  //  get bbox of the new polygon
  //  rotate the vertices of the bbox polygon by theta around new origin and polygon normal
  //  finally get the rectangle
  public toRect(xAxis: G2Vector3D): G2Rectangle {
    const plane = this.getPlane()
    const fourCorners = this.toPolygon2D().toRect(xAxis.toVector2D()).vertices.map(point2D => {
      const z = plane.getZAtPoint2D(point2D)
      return new G2Vector3D(
        point2D.x,
        point2D.y,
        z
      )
    })
    return new G2Rectangle(
      fourCorners[0],
      fourCorners[1],
      fourCorners[2],
      fourCorners[3]
    )
  }

  public intersectAt(line: G2Line): G2Vector3D[] {
    const intersectAtVertices = this.edges()
      .map(edge => line.intersectAtSegment(edge.point, edge.anotherPoint!))
    return intersectAtVertices.filter(point => point !== null) as G2Vector3D[]
  }

  // a line cut the polygon into several small polygons
  // here we assume polygon to be convex
  public split(line: G2Line): G2Polygon3D[] {
    const intersectAtVertices = this.intersectAt(line)
    //intersect might be at joints, causing intersectAtVertices to have duplicates, calculate unique values
    const uniqueIntersectedAtVertices = ArrayUtil.unique(intersectAtVertices, (a, b) => a.equals(b))
    if (uniqueIntersectedAtVertices.length === 2) {
      //normal case, line cut a convex polygon
      const edges = this.edges()
      const firstIntersectedEdgeIndex = edges.findIndex((edge) => {
        return line.intersectAtSegment(edge.point, edge.anotherPoint!) != null
      })
      //now traverse each edge to group edge vertices into 2 CCW arrays
      const verticesGroup1: G2Vector3D[] = []
      const verticesGroup2: G2Vector3D[] = []
      const rotatedEdges = edges.slice(firstIntersectedEdgeIndex).concat(edges.slice(0, firstIntersectedEdgeIndex))
      let intersectTimes = 0
      let lastIntersectedAt: G2Vector3D | null = null
      rotatedEdges.forEach(edge => {
        const intersectedAt = line.intersectAtSegment(edge.point, edge.anotherPoint!)
        if (intersectedAt != null && (lastIntersectedAt === null || !intersectedAt!.equals(lastIntersectedAt))) {
          intersectTimes += 1
          lastIntersectedAt = intersectedAt
          if (intersectTimes == 1) {
            verticesGroup1.push(lastIntersectedAt)   //starting verticesGroup1
          } else if (intersectTimes == 2) {
            verticesGroup1.push(lastIntersectedAt)   //closing verticesGroup1
            verticesGroup2.push(lastIntersectedAt)   //starting verticesGroup2
          }
        }
        if (intersectTimes == 1) {
          verticesGroup1.push(edge.anotherPoint!)
        } else if (intersectTimes == 2) {
          verticesGroup2.push(edge.anotherPoint!)
        }
      });
      //closing verticesGroup2
      verticesGroup2.push(verticesGroup1[0])
      //verticesGroups1 might have duplicate corners due to cutting line intersect with joints, calculate the unique
      const uniqueVerticesGroup1 = ArrayUtil.unique(verticesGroup1, (a, b) => a.equals(b))
      //verticesGroups2 might have duplicate corners due to cutting line intersect with joints, calculate the unique
      const uniqueVerticesGroup2 = ArrayUtil.unique(verticesGroup2, (a, b) => a.equals(b))
      //only take polygons with more than 3 vertices, and different from this polygon (effective cut)
      return (
        uniqueVerticesGroup1.length <= 2 ? [] : [new G2Polygon3D(uniqueVerticesGroup1)]
      ).concat(
        uniqueVerticesGroup2.length <= 2 ? [] : [new G2Polygon3D(uniqueVerticesGroup2)]
      ).filter(polygon => !polygon.equalsIgnoreStartingPoint(this))
    } else if (uniqueIntersectedAtVertices.length === 0) {
      console.log("line does not cross polygon")
      return []
    } else {
      console.log("line cutting concave polygon not supported")
      return []
    }
  }

  public toPolygon2D(): G2Polygon2D {
    return new G2Polygon2D(
      this.vertices.map((point) => {
        return point.toVector2D()
      })
    )
  }

  public toPlane(): G2Plane {
    return G2Plane.fromThreePointsCCW(this.vertices.slice(0, 3))
  }
}
