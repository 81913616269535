import {
  Box,
  Dialog,
  Fab,
  Grid,
  Paper,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SrButton } from "@sunrun/experience-ui-components";
import { useParams, useSearchParams } from "react-router-dom";
import { URLSearchParameterKey } from "src/types/URLSearchParameterKey";
import { useLoadingScreen } from "@sunrun/design-tools-loading-screen";

type ModalLayoutProps = {
  title?: string;
  children: JSX.Element;
  open: boolean;
  onClose: () => void;
  onCancel?: () => void;
  onContinue?: () => void | undefined;
  onSecondaryAction?: () => void | undefined;
  actionButtons?: boolean;
  cancelText?: string;
  continueText?: string;
  secondaryActionButton?: boolean;
  secondaryActionText?: string;
  fullscreen?: boolean;
  displayCloseButton?: boolean;
  error?: Error;
  titleIcon?: JSX.Element;
};

export const ModalLayout = ({
  title,
  children,
  open,
  onClose,
  onCancel,
  onContinue,
  onSecondaryAction,
  actionButtons,
  cancelText,
  continueText,
  secondaryActionButton,
  secondaryActionText,
  fullscreen,
  displayCloseButton = true,
  error,
  titleIcon,
}: ModalLayoutProps) => {
  const paperStyle: SxProps = {
    padding: 5,
    height: "100%",
  };
  const paperStyleFullScreen: SxProps = {
    ...paperStyle,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-start",
    paddingTop: 10,
  };
  const stackStyle: SxProps = {};
  const stackStyleFullScreen: SxProps = {
    ...stackStyle,
    width: "100%",
  };

  const { isLoading } = useLoadingScreen();

  return (
    <>
      {/* transitionDuration is set to 0 to fix an visual bug for modals with payloads */}
      {/* Dialog open is dependent on isLoading being false as we load data into iHD piecemeal and don't want to show errant modals while loading and updating state */}
      <Dialog
        open={open && !isLoading}
        onClose={onClose}
        fullScreen={fullscreen}
        transitionDuration={0}
        maxWidth={"lg"}
      >
        <Paper
          elevation={2}
          sx={fullscreen ? paperStyleFullScreen : paperStyle}
        >
          <Stack
            spacing={2}
            sx={fullscreen ? stackStyleFullScreen : stackStyle}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                {titleIcon && titleIcon}
                {title && (
                  <Typography variant="h5" color="primary">
                    {title}
                  </Typography>
                )}
              </Grid>
              {displayCloseButton && (
                <Fab
                  size="small"
                  color="primary"
                  onClick={onClose}
                  sx={{
                    mt: -3,
                    mr: -3,
                  }}
                >
                  <CloseIcon />
                </Fab>
              )}
            </Box>
            {/* styling with whiteSpace pre-wrap so that children strings can pass \n for newline */}
            <Box overflow="auto" style={{ whiteSpace: "pre-wrap" }}>
              {children}
            </Box>
            {error && <ErrorStack error={error} />}
            {actionButtons && (
              <ActionButtons
                onCancel={onCancel ?? onClose}
                cancelText={cancelText}
                onContinue={onContinue}
                continueText={continueText}
                secondaryActionButton={secondaryActionButton}
                secondaryActionText={secondaryActionText}
                onSecondaryAction={onSecondaryAction}
              />
            )}
          </Stack>
        </Paper>
      </Dialog>
    </>
  );
};

type ActionButtonsProps = {
  onCancel: () => void;
  cancelText?: string;
  onContinue?: () => void;
  continueText?: string;
  onSecondaryAction?: () => void;
  secondaryActionText?: string;
  secondaryActionButton?: boolean;
};

const ActionButtons = ({
  onCancel,
  cancelText,
  onContinue,
  continueText = "Continue",
  onSecondaryAction,
  secondaryActionText,
  secondaryActionButton,
}: ActionButtonsProps) => {
  cancelText =
    cancelText || (onContinue === undefined ? "Ok, Got it" : "Cancel");
  return (
    <Stack
      mt={2}
      direction="row"
      justifyContent="space-between"
      alignItems="end"
      alignContent="center"
      spacing={2}
    >
      <Box width="238px">
        <SrButton
          size="sm"
          type={onContinue ? "secondary" : "primary"}
          onPress={onCancel}
        >
          {cancelText}
        </SrButton>
      </Box>
      {onContinue && (
        <Box width="238px">
          {secondaryActionButton && (
            <Box mb="10px">
              <SrButton size="sm" type="secondary" onPress={onSecondaryAction}>
                {secondaryActionText}
              </SrButton>
            </Box>
          )}
          <SrButton size="sm" onPress={onContinue}>
            {continueText}
          </SrButton>
        </Box>
      )}
    </Stack>
  );
};

const ErrorStack = ({ error }: { error: Error }) => {
  const { projectId, designIndex } = useParams();
  const [searchParams] = useSearchParams();
  const designId = searchParams.get(URLSearchParameterKey.DesignId);
  const offerId = searchParams.get(URLSearchParameterKey.OfferId);
  const lightmileProjectId = searchParams.get(
    URLSearchParameterKey.LightmileProjectId
  );
  const signedRootId = searchParams.get(URLSearchParameterKey.SignedRootId);
  const effectiveDate = searchParams.get(URLSearchParameterKey.EffectiveDate);
  const getDevInfoText = () =>
    [
      `designId: ${designId || "unknown"}`,
      `lightmileProjectId: ${projectId || lightmileProjectId || "unknown"}`,
      `designIndex: ${designIndex || "--"}`,
      `offerId: ${offerId || "--"}`,
      `signedRootId: ${signedRootId || "--"}`,
      `effectiveDate: ${effectiveDate || "--"}`,
    ].join("\n");
  const errorDetails = [getDevInfoText(), error.message, error.stack].join(
    "\n\n"
  );

  return (
    <Typography
      //@ts-ignore
      variant="mono"
      sx={{
        display: "block",
        overflow: "auto",
        border: "1px solid #aaaaaa",
        width: "100%",
        maxHeight: 200,
        marginTop: 1,
        padding: 1,
      }}
    >
      {errorDetails}
    </Typography>
  );
};
