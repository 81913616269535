import {Offer, Offers} from "@sunrun/design-tools-domain-model";
import { createSelector } from "reselect";
import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {WorkspacePayloadAction} from "../../types/state-management/action";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SALES_DIVISION_AFFILIATE, SALES_DIVISION_NEW_HOMES } from "src/constants";

type OfferState  = {
  value: Offer | undefined;
}
const initialState: OfferState = {
  value: undefined,
};
  
export const offerSlice = createSlice({
  name: "offer",
  initialState,
  reducers: {
    setOffer: (state: OfferState, action: PayloadAction<Offer>) => {
      state.value = action.payload
    },
  }
});

export const { setOffer } = offerSlice.actions
export default offerSlice.reducer

// TODO: Remove the following and transition fully from WorkspaceState to RTK store
export type OfferWorkspaceAction = WorkspacePayloadAction<Offer, 'setOffer'>;

export const selectOffer = (state: WorkspaceState) => state.offer

export const selectSolarProductVariantId = createSelector(
  [selectOffer],
  (offer): string | undefined => {
    if (!offer) return undefined;
    return Offers.getSolarOfferLine(offer)?.variantId;
  }
);
export const selectBackupProductVariantId = createSelector(
  [selectOffer],
  (offer): string | undefined => {
    if (!offer) return undefined;
    return Offers.getBackupOfferLine(offer)?.variantId;
  }
);
export const selectShiftProductVariantId = createSelector(
  [selectOffer],
  (offer): string | undefined => {
    if (!offer) return undefined;
    return Offers.getShiftOfferLine(offer)?.variantId;
  }
);
// This isn't the best way to determine this, but it's what we have available
export const selectIsAffiliate = createSelector(
  [selectOffer],
  (offer): boolean => {
    const salesDivision = offer?.opportunity?.salesDivision;
    return salesDivision !== undefined && salesDivision === SALES_DIVISION_AFFILIATE;
  }
)

export const selectIsNewHomes = createSelector(
  [selectOffer],
  (offer): boolean => {
    const salesDivision = offer?.opportunity?.salesDivision;
    return salesDivision !== undefined && salesDivision === SALES_DIVISION_NEW_HOMES;
  }
)

// Right now the offer additionalSystem field is only being pulled from salesforce. 
// TODO: Add the second system fields from TSA and HQS to this selector.
// Return true if any are true.
export const selectIsAdditionalSystem = createSelector(
  [selectOffer],
  (offer): boolean => {
    return !!offer?.opportunity?.additionalSystem
  }
)

export const offerWorkspaceReducer = (state: WorkspaceState, action: WorkspaceAction): Offer | undefined => {
  switch (action.type) {
    case 'setOffer': {
      return action.payload;
    }
    default: {
      return state.offer;
    }
  }
};
