import {
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useWorkspace } from "src/hooks/useWorkspace";
import { DesignToolsTheme } from "@sunrun/design-tools-themes";
import DeleteIcon from "@mui/icons-material/Delete";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import GridOnIcon from "@mui/icons-material/GridOn";
import useModuleIssues from "src/hooks/useModuleIssues";
import { useEffect, useState } from "react";
import { isFlatRoof } from "@sunrun/design-tools-domain-model";
import { deriveSiteModelClass } from "src/features/siteModel/siteModelSlice";
import { deriveDesignClass } from "src/features/design/designSlice";
import { useAppDispatch } from "src/store";
import { clearSelectedModules } from "src/features/moduleSelection/moduleSelectionSlice";
import { setIsModuleIssuesOpen } from "src/features/modal/modalSlice";

export const ModuleAdjustmentActionBar = () => {
  const { state, dispatch: workspaceDispatch } = useWorkspace();
  const dispatch = useAppDispatch()
  const isModuleSelected = state.moduleSelection.selectedModuleIds.size > 0;
  const theme = useTheme<DesignToolsTheme>();
  const { uniqueIssuesOfSelectedModules } = useModuleIssues();
  const moduleIssueCount = uniqueIssuesOfSelectedModules.size;
  const [disableRotateButton, setDisableRotateButton] = useState<boolean>(false);
  const [disableFillRoofFaceAroundModuleButton, setDisableFillRoofFaceAroundModuleButton] = useState<boolean>(false);
  const [rotateSelectedModulesMsg, setRotateSelectedModulesMsg] = useState<string>("rotate selected modules");
  const siteModel = deriveSiteModelClass(state);  
  const design = deriveDesignClass(state);

  useEffect(() => {
    if (design == null || siteModel == null) return;

    const disableRotate = design.isRotateDisabled(
      state.moduleSelection.selectedModuleIds,
      siteModel
    );
    setDisableRotateButton(disableRotate);
    if (
      disableRotate &&
      design &&
      state.moduleSelection.selectedModuleIds.size > 0
    ) {
      const modulesToRotate = Array.from(
        state.moduleSelection.selectedModuleIds
      ).map((id) => design!.getModuleById(id));
      const firstModuleToRotate = modulesToRotate[0]!;
      const roofFaceId = firstModuleToRotate.properties.roofFaceId;
      const roofFace = siteModel.getRoofFaceById(roofFaceId);
      if (roofFace && isFlatRoof(roofFace)) {
        setRotateSelectedModulesMsg(
          "To rotate modules on a flat roof, select one module or a single row of modules with same orientation"
        );
      } else {
        setRotateSelectedModulesMsg("Please select modules on a roof face");
      }
    } else {
      setRotateSelectedModulesMsg("");
    }
    const disableFillRoofFaceFromModuleButton = design.isFillRoofFaceAroundModuleDisabled(state.moduleSelection.selectedModuleIds, siteModel)
    setDisableFillRoofFaceAroundModuleButton(disableFillRoofFaceFromModuleButton)
  }, [state.moduleSelection.selectedModuleIds]);

  const handleDelete = () => {
    workspaceDispatch({ type: "removeModules" });
    dispatch(clearSelectedModules())
    workspaceDispatch({ type: "clearSelectedModules" });
  };

  const handleRotate = () => {
    workspaceDispatch({ type: "rotateModules" });
  };

  const handleFillAround = () => {
    workspaceDispatch({ type: "fillRoofFaceAroundModule" });
    dispatch(clearSelectedModules())
    workspaceDispatch({ type: "clearSelectedModules" });
  };

  const handleModuleIssues = () => {
    dispatch(setIsModuleIssuesOpen(true))
    workspaceDispatch({ type: "setIsModuleIssuesOpen", payload: true });
  };

  if (!isModuleSelected) return null;
  return (
    <Paper
      sx={{
        zIndex: theme.zIndex.appBar,
        padding: 1,
        mx: 2,
      }}
    >
      <Stack 
        direction={{ xs: "column", sm: "row" }} 
        spacing={{ xs: 0, sm: 1 }}
        alignItems="center"
      >
        <Button size="large" endIcon={<DeleteIcon />} onClick={handleDelete}>
          Delete
        </Button>
        <Divider orientation="vertical" flexItem />
        <Tooltip title={rotateSelectedModulesMsg}>
          <Button
            size="large"
            endIcon={<RotateLeftIcon />}
            onClick={handleRotate}
            disabled={disableRotateButton}
          >
            Rotate
          </Button>
        </Tooltip>
        <Divider orientation="vertical" flexItem />
        <Tooltip
          title={
            disableFillRoofFaceAroundModuleButton
              ? "Fill around is only supported with a single module on a roof face"
              : ""
          }
        >
          <span>
            <Button
              size="large"
              endIcon={<GridOnIcon />}
              onClick={handleFillAround}
              disabled={disableFillRoofFaceAroundModuleButton}
            >
              Fill Around
            </Button>
          </span>
        </Tooltip>
        {moduleIssueCount > 0 && (
          <>
            <Divider orientation="vertical" flexItem />
            <Button size="large" onClick={handleModuleIssues}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>Module Issues</Typography>
                <Box
                  sx={{
                    backgroundColor: "#C3DBFF",
                    color: theme.palette.primary.main,
                    borderRadius: "4px",
                    width: "15px",
                    height: "15px",
                    padding: "6px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {moduleIssueCount}
                </Box>
              </Stack>
            </Button>
          </>
        )}
      </Stack>
    </Paper>
  );
};
