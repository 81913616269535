import { useEffect, useState } from "react";
import { useIsMutating, useMutation, useQuery } from "react-query";
import { useErrorHandler } from "react-error-boundary";
import { useLoadingScreen } from "@sunrun/design-tools-loading-screen";
import { Design } from "@sunrun/design-tools-domain-model";
import { repository } from "@sunrun/design-tools-graphql-clients";
import { LoadingProcessGroups, LoadingProcessNames } from "src/types/LoadingScreenProcess";
import { useWorkspace } from "src/hooks/useWorkspace";
import * as FullStory from "@fullstory/browser";
import { convertToReadableDateAndTime } from "src/utils/designDateTime";
import { setDesign } from "src/features/design/designSlice";
import { useAppDispatch } from "src/store";
import { setInfoModal } from "src/features/modal/modalSlice";

type UseDesignProps = {
  designId?: string;
  designVersion?: number;
  enableGetDesign?: boolean;
};

export interface SaveStatus {
  isSaving: boolean;
  lastSavedAt?: Date;
}

export const useDesign = ({ designId, designVersion, enableGetDesign = true }: UseDesignProps) => {
  const { dispatch: workspaceDispatch } = useWorkspace();
  const dispatch = useAppDispatch();

  const { helpers: loadingScreenHelpers } = useLoadingScreen();
  const handleError = useErrorHandler();
  const [saveStatus, setSaveStatus] = useState<SaveStatus>({
    isSaving: false,
  });
  const [didShowSaveError, setDidShowSaveError] = useState(false);

  const getDesign = async (): Promise<Design> => {
    if (designId) return repository.get(Design, designId, designVersion ?? 0);
    throw Error(`useQuery should be disabled when designId is undefined.`);
  };

  const query = useQuery(["getDesign", designId], getDesign, {
    refetchOnWindowFocus: false, // TODO support these use cases for conflict resolution
    refetchOnReconnect: false,
    enabled: !!designId && enableGetDesign, // https://react-query.tanstack.com/guides/dependent-queries
    onSuccess: (newDesign: Design) => {
      dispatch(setDesign(newDesign.getIDesign()));
      workspaceDispatch({ type: "setDesign", payload: newDesign });
    },
    onError: handleError,
  });

  const mutateDesign = async (design: Design): Promise<Design> => {
    if (didShowSaveError) {
      return design;
    } else if (!mutationInProgress) {
      const saveResult = await repository.save(design);
      console.log(`Saving design ${design.id}:${design._version}`);
      return saveResult.v0;
    } else {
      return design;
    }
  };

  // useIsMutating to skip any saves that would conflict with saveDesignMutation initiated in useSimulateDesign or useLegacyDesign
  const mutationInProgress = useIsMutating(["saveDesignMutation"]);

  const showSaveError = async () => {
    if (!didShowSaveError) {
      const serverDesign = await getDesign();
      dispatch(
        setInfoModal({
          title: `Save Conflict`,
          message: `You are not working on the most recent version of this design. Please close this session.
        \nThe most recent changes were created by ${serverDesign?.createdBy} at ${convertToReadableDateAndTime(serverDesign?.updatedAt, "h:mma on YYYY-MM-DD")}.
        \nIf you believe you are seeing this message in error you may continue working and select overwrite when finalizing the design.
        \nYou may also see this message if you are working on the same session in multiple tabs or windows.`,
        }),
      );
      workspaceDispatch({
        type: "setInfoModal",
        payload: {
          title: `Save Conflict`,
          message: `You are not working on the most recent version of this design. Please close this session.
            \nThe most recent changes were created by ${serverDesign?.createdBy} at ${convertToReadableDateAndTime(serverDesign?.updatedAt, "h:mma on YYYY-MM-DD")}.
            \nIf you believe you are seeing this message in error you may continue working and select overwrite when finalizing the design.
            \nYou may also see this message if you are working on the same session in multiple tabs or windows.`,
        },
      });
      setDidShowSaveError(true);
    }
    return;
  };

  const saveDesignMutation = useMutation(mutateDesign, {
    mutationKey: "saveDesignMutation",
    onError: () => {
      showSaveError();
      return;
    },
    onSuccess: (newDesign: Design) => {
      setSaveStatus({
        isSaving: false,
        lastSavedAt: new Date(newDesign.updatedAt as string),
      });

      // Update the version of the local design Object without altering the state.
      workspaceDispatch({ type: "resolveSave", payload: newDesign });
      FullStory.event("Saved Design Changes", {
        designId: newDesign.id,
        saveVersion: newDesign.latest,
      });
    },
  });

  // Loading Screen
  useEffect(
    function addLoadingScreenProcess() {
      if (query.isFetching) {
        loadingScreenHelpers.addProcess({
          name: LoadingProcessNames.DESIGN,
          group: LoadingProcessGroups.INITIALIZE_IHD,
        });
        return function completeLoadingScreenProcess() {
          loadingScreenHelpers.completeProcess(LoadingProcessNames.DESIGN);
        };
      }
    },
    [query.isFetching],
  );

  return {
    saveStatus,
    saveDesignMutation,
    didShowSaveError,
  };
};
