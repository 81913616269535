import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useWorkspace} from "src/hooks/useWorkspace";
import {
  Customer,
  Design,
  DesignConstraints,
  GeoRaster,
  ProductionSimulation,
  SiteModel,
  SolarResource,
} from "@sunrun/design-tools-domain-model";
import {IFrameCommandType, IFrameEventType, IFrameHostType, IFrameMessage, SourceApp,} from "src/types/IFrame";
import {deriveIsDesignFinalized} from "src/features/designGuidance/deriveIsDesignFinalized";
import {URLSearchParameterKey} from "src/types/URLSearchParameterKey"
import { deriveIsDesignReadyForFinalize } from "src/features/designGuidance/deriveIsDesignReadyForFinalize";
import { setCancelContinueModal } from "src/features/modal/modalSlice";
import { useRegenerateLightmileDesign } from "./useRegenerateDesign";
import { useAppDispatch, useAppSelector } from "src/store";
import { selectIsOfferExperience } from "src/features/host/hostSlice";

export const postIFrameMessage = (
  type: IFrameMessage["type"],
  payload: IFrameMessage["payload"]
) => {
  console.log("iHD Post Message:", type, payload)
  window.parent.postMessage(
    {
      type,
      payload,
    },
    "*"
  );
};

type UseIFrameHostProps = {
  legacyFinalizeDesign: () => Promise<void>;
  refetchOffer: () => Promise<void>;
  design?: Design;
  siteModel?: SiteModel,
  productionSimulation?: ProductionSimulation,
  customer?: Customer,
  designConstraints?: DesignConstraints,
  geoRaster?: GeoRaster,
  solarResource?: SolarResource,
};

export const useIFrameHost = ({
  legacyFinalizeDesign,
  refetchOffer,
  design,
  siteModel,
  productionSimulation,
  customer,
  designConstraints,
  geoRaster,
  solarResource,
}: UseIFrameHostProps): IFrameHostType => {
  const [searchParams] = useSearchParams();
  const { state: workspaceState, dispatch: workspaceDispatch } = useWorkspace();
  const dispatch = useAppDispatch();
  const { workflowState } = workspaceState
  const [isFirstReady, setIsFirstReady] = useState<boolean>(true);
  const [isFinalized, setIsFinalized] = useState<boolean>(false);
  const [disableFinalizeButton, setDisableFinalizeButton] = useState<boolean>(true);    
  const {completeRegenerateDesign} = useRegenerateLightmileDesign()

  const importLightmileProjectUrl = useAppSelector((state) => state.host?.importLightmileProjectUrl);
  const isOfferExperience = selectIsOfferExperience(workspaceState);

  const hostParam: string = searchParams.get(URLSearchParameterKey.Host) ?? '';
  const iFrameHost = hostParam in IFrameHostType ? hostParam as IFrameHostType : IFrameHostType.UNDEFINED;

  useEffect(() => {
    if (
      isFirstReady &&
      design &&
      siteModel &&
      productionSimulation &&
      customer &&
      designConstraints &&
      geoRaster &&
      solarResource
    ) {
      setIsFirstReady(false);
      postIFrameMessage(IFrameEventType.READY, { designId: design.id });
      postIFrameMessage(IFrameEventType.DESIGN_EDIT_START, { designId: design.id });
    }
  }, [
    isFirstReady,
    design,
    siteModel,
    productionSimulation,
    customer,
    designConstraints,
    geoRaster,
    solarResource,
  ]);
    
  const handleGenerateDesignSuccess = (e: MessageEvent<IFrameMessage>) => {
    const payload = e.data.payload;
    if (payload.isSuccess) {
      completeRegenerateDesign(importLightmileProjectUrl);
      if (importLightmileProjectUrl) {
        console.info("navigating to: ", importLightmileProjectUrl);
        // start fresh from lightmile import
        window.location.replace(importLightmileProjectUrl);
      } else {
        console.error(
          "Designs regenerated. No importLightmileProjectUrl found."
        );
      }
    }
  };

  // Legacy iframe post messaging
  useEffect(() => {
    if (design && productionSimulation) {
      const isDesignFinalized = design?.isFinalized(productionSimulation)
      setIsFinalized(isDesignFinalized)
    }
    setDisableFinalizeButton(!deriveIsDesignReadyForFinalize(workspaceState))
  },[
    design,
    productionSimulation,
    deriveIsDesignReadyForFinalize(workspaceState)
  ])

  useEffect(() => {
    if (!isOfferExperience && !isFinalized && design) {
      postIFrameMessage(IFrameEventType.DESIGN_EDIT_START, {
        designId: design?.id,
      });
    }
  },[isFinalized, isOfferExperience])

  useEffect(() => {
    if (!isOfferExperience) {
      const CANCEL_BUTTON_EVENT = workflowState.isLegacyFinalizeDesignInProgress ?
        IFrameEventType.DISABLE_CANCEL_BUTTON :
        IFrameEventType.ENABLE_CANCEL_BUTTON;
      postIFrameMessage(CANCEL_BUTTON_EVENT, { designId: design?.id as string });
    }
  }, [workflowState.isLegacyFinalizeDesignInProgress, isOfferExperience]);

  // Legacy Lightmile post messaging
  useEffect(() => {
    if (iFrameHost === IFrameHostType.LIGHTMILE) {
      const FINALIZE_DESIGN_BUTTON_EVENT = disableFinalizeButton
        ? IFrameEventType.DISABLE_FINALIZE_DESIGN_BUTTON
        : IFrameEventType.ENABLE_FINALIZE_DESIGN_BUTTON;
      postIFrameMessage(FINALIZE_DESIGN_BUTTON_EVENT, {
        designId: design?.id as string,
      });
    }
  },[disableFinalizeButton, iFrameHost]) 

  
  // Incoming post messaging
  useEffect(() => {
    const handlePostMessage = (e: MessageEvent<IFrameMessage>) => {
      switch (e.data.type) {
        //cancel design command
        case IFrameCommandType.CANCEL_DESIGN:
          if (!design) break;
          if (!deriveIsDesignFinalized(workspaceState)) {
            dispatch(setCancelContinueModal({
              title: 'Design is still in progress.',
              message:'"Dismiss Changes" will discard any unsaved changes. Please finalize the design to save the modifications.',
              cancelText:'Continue Editing',
              continueText:'Dismiss Changes',
              onContinue:() => {
                postIFrameMessage(IFrameEventType.DESIGN_EDIT_CANCEL, { designId: design.id })
              },
            }))
            workspaceDispatch({
              type: "setCancelContinueModal",
              payload: {
                title: 'Design is still in progress.',
                message:'"Dismiss Changes" will discard any unsaved changes. Please finalize the design to save the modifications.',
                cancelText:'Continue Editing',
                continueText:'Dismiss Changes',
                onContinue:() => {
                  postIFrameMessage(IFrameEventType.DESIGN_EDIT_CANCEL, { designId: design.id })
                },
              }
            })
          } else {
            postIFrameMessage(IFrameEventType.DESIGN_EDIT_CANCEL, { designId: design.id });
          }
          break;
        //finalize design command
        case IFrameCommandType.FINALIZE_DESIGN:
          void legacyFinalizeDesign();
          break;

        // update offer command
        case IFrameCommandType.OFFER_UPDATED: // TODO remove once OE removes its usage
        case IFrameCommandType.IHD_HACK_GO_TO_DESIGN:
          refetchOffer().then(() => {
            console.log("offer refetched")
            // ET-1777: Deactivate undo coming back from pricing
            workspaceDispatch({ type: "clearUndoDesign" });
          });
          break;
          
        // SPLAT post messaging for Regenerating Design
        case IFrameCommandType.GENERATE_DESIGNS:
          if(e.data.source === SourceApp.LEGACY_SPLAT){            
            handleGenerateDesignSuccess(e);
          }
          break;
      }
    };
    window.addEventListener("message", handlePostMessage);
    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  });

  return iFrameHost
};
