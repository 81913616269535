import {Vector2D} from "../vectors/vector2D";
import {Vector3D} from "../vectors/vector3D";

const defaultTolerance = Number.EPSILON
/*
 * This is structurally identical to the GeoJSON library Position type. Use this type working with GeoJSON coordinates
 * but not needing higher-level GeoJSON types like Features so you don't need a dependency on the `geojson` package
 */
export type Position = number[]; // [number, number] | [number, number, number];

/*
 * This is structurally identical to the LatLng type in the Leaflet library. Use when you don't want to introduce a
 * dependency on the `leaflet` package (which dependency should exist only in the app code).
 */
export type LatLng = {
  lat: number;
  lng: number;
}

/*
 * Note well! This does NOT project degree latitudes and longitudes to UTM meter coordinates.
 * It just converts coordinates (in any system) from Leaflet's LatLng object data type to the
 * array data type used by GeoJSON. In normal use within Design Tools the `lat` and `lng` coordinates
 * and the returned Position coordinates will both be denominated in meters (UTM).
 */
export const latLngToPosition = (latLng: LatLng): Position => {
  return [latLng.lng, latLng.lat]
};

export const positionToVector2D = (position: Position): Vector2D => {
  return [position[0], position[1]];
};

export const vector2DToPosition = (vector: Vector2D): Position => {
  return [vector[0], vector[1]];
};

export const positionToVector3D = (position: Position): Vector3D => {
  return new Vector3D(position[0], position[1], position[2]);
};

export const vector3DToPosition = (vector: Vector3D): Position => {
  return [vector.x, vector.y, vector.z];
};

export const translate2dPosition = (position: Position, delta: Vector2D): Position => {
  const newPosition = []
  newPosition[0] = position[0] + delta[0]
  newPosition[1] = position[1] + delta[1]
  if(position[2] != undefined) newPosition[2] = position[2]
  return newPosition
}

export const positionsSame = (position0: Position, position1: Position, tolerance=defaultTolerance): boolean => {
  if (position0.length !== position1.length) {
    console.warn("Comparing positions of different dimensions. Likely a bug somewhere!")
    return false
  }
  if (position0.length === 3 && Math.abs(position0[2] - position1[2]) > tolerance) {
    return false
  }
  return Math.abs(position0[0] - position1[0]) < tolerance && Math.abs(position0[1] - position1[1]) < tolerance
}
