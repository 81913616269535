import { LeafletMapContainer } from "src/components/LeafletMapContainer";
import { LeafletMapContainerEventHandler } from "src/components/LeafletMapEventHandler";
import { MapImageLayer } from "src/components/MapImageLayer";
import { RoofFaceFeatureGroup } from "src/components/RoofFaceFeatureGroup";
import { RoofFaceLayer } from "src/components/RoofFaceLayer";
import HeatMapLayer from "src/components/HeatMapLayer";
import { SetbackFeatureGroup } from "src/components/SetbackFeatureGroup";
import { ObstructionFeatureGroup } from "src/components/ObstructionFeatureGroup";
import { TreeFeatureGroup } from "src/components/TreeFeatureGroup";
import { ModuleFeatureGroup } from "src/components/ModuleFeatureGroup";
import { ModuleSpacingFeatureGroup } from "src/components/ModuleSpacingFeatureGroup";
import { ModuleLayer } from "src/components/ModuleLayer";
import { ModuleSpacingLayer } from "src/components/ModuleSpacingLayer";
import { useWorkspace } from "src/hooks/useWorkspace";
import { ZoomControl as CustomZoomControl } from "./MapControl";
import { Container } from "@mui/material";
import { deriveSolarResourceClass } from "src/features/solarResource/solarResourceSlice";
import { deriveSiteModelClass } from "src/features/siteModel/siteModelSlice";
import { deriveDesignClass } from "src/features/design/designSlice";
import { RoofFaceDecoratorLayer } from "./RoofFaceDecoratorLayer";
import React from "react";
import { useFlags } from "flagsmith/react";
import { FlagsmithFeatureEnums } from "src/config/flagsmithConfig";
import { SetbackObstructionFeatureGroup } from "./SetbackObstructionFeatureGroup";

export const WorkspaceLeafletMap = () => {
  const { state } = useWorkspace();
  const { geoRaster } = state;
  const siteModel = deriveSiteModelClass(state);
  const solarResource = deriveSolarResourceClass(state);
  const design = deriveDesignClass(state);
  const roofToggleIconOnDisabledRoofFaces = useFlags([
    FlagsmithFeatureEnums.SHOW_ICONS_ON_DISABLED_ROOFS,
  ])[FlagsmithFeatureEnums.SHOW_ICONS_ON_DISABLED_ROOFS].enabled;

  return (
    <>
      {design && siteModel && geoRaster && solarResource && (
        <LeafletMapContainer siteModel={siteModel}>
          <LeafletMapContainerEventHandler />
          <MapImageLayer geoRaster={geoRaster} />
          <RoofFaceFeatureGroup>
            {siteModel.roofFaces.map((it, i) => (
              <React.Fragment key={`decorator-${i}`}>
                {roofToggleIconOnDisabledRoofFaces && (
                  <RoofFaceDecoratorLayer key={`${it.id}-decorator`} roofFace={it} />
                )}
                <RoofFaceLayer key={it.id} roofFace={it} />
              </React.Fragment>
            ))}
          </RoofFaceFeatureGroup>
          <HeatMapLayer solarResource={solarResource} siteModel={siteModel} />
          <SetbackFeatureGroup siteModel={siteModel} />
          <ObstructionFeatureGroup siteModel={siteModel} />
          <SetbackObstructionFeatureGroup siteModel={siteModel} />
          <ModuleSpacingFeatureGroup>
            {design?.modules?.map((it) => {
              const roofFace = siteModel?.getRoofFaceById(it.properties.roofFaceId);
              return (
                roofFace && (
                  <ModuleSpacingLayer
                    key={it.getModuleSpacing(roofFace).id}
                    moduleSpacing={it.getModuleSpacing(roofFace)}
                  />
                )
              );
            })}
          </ModuleSpacingFeatureGroup>
          <ModuleFeatureGroup>
            {design?.modules?.map((it) => (
              <ModuleLayer key={it.id} module={it} design={design} />
            ))}
          </ModuleFeatureGroup>
          <TreeFeatureGroup siteModel={siteModel} />
          <Container
            maxWidth="xl"
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              marginX: "auto",
              marginBottom: 2,
            }}
          >
            <CustomZoomControl />
          </Container>
        </LeafletMapContainer>
      )}
    </>
  );
};
