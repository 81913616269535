import { List, ListItem, ListItemText } from "@mui/material";
import { ModalLayout } from "./components/ModalLayout";
import { useWorkspace } from "../../hooks/useWorkspace";
import { deriveDesignReadyForExport } from "../../features/designGuidance/deriveDesignReadyForExport";
import {
  deriveProductOptionChangesMessages,
} from "src/features/designGuidance/deriveOfferProductChanges";
import { deriveDisplayNameForInverterType } from "src/features/products/productsSlice";

type OfferUpdateConfirmationModalProps = {
  open: boolean;
  onClose: () => void;
}

export const OfferUpdateConfirmationModal = ({ open, onClose }: OfferUpdateConfirmationModalProps) => {
  const {state} = useWorkspace();
  const readyToGoToPricing = deriveDesignReadyForExport(state, undefined);
  const headerMsg = readyToGoToPricing ? undefined : "Unable to map all product selections to equipment without making the following changes:";
  const productChangesMessages = deriveProductOptionChangesMessages(state)

  return (
    <ModalLayout
      open={open}
      title="Your product selection will be updated"
      onClose={onClose}
      actionButtons
    >
      <>
        {
          headerMsg &&
          <span>{headerMsg}</span>
        }
        This design requires the following changes to be made to your Offer:
        <List>
          {productChangesMessages.map((msg) => (
            <ListItem key={msg}>
              <ListItemText primary={msg}/>
            </ListItem>
          ))}
        </List>
        These changes won't go into effect until you go to Pricing.
      </>
    </ModalLayout>
  );
};
