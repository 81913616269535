import {
  ProductsAvailability,
  PvProduct,
  type ProductAvailability,
} from "@sunrun/design-tools-domain-model";
import type { WorkspacePayloadAction } from "src/types/state-management/action";
import type { WorkspaceAction, WorkspaceState } from "src/hooks/useWorkspace";
import { createSelector } from "reselect";

export type ProductAvailabilityAction = WorkspacePayloadAction<
  ProductAvailability,
  "setProductAvailability"
>;

export const selectProductAvailability = (state: WorkspaceState) =>
  state.productAvailability;

export const productAvailabilityReducer = (
  state: WorkspaceState,
  action: WorkspaceAction
): ProductAvailability | undefined => {
  switch (action.type) {
    case "setProductAvailability": {
      return action.payload;
    }
    default: {
      return state.productAvailability;
    }
  }
};

export const deriveFinancialProductsList = createSelector(
  [selectProductAvailability],
  (productAvailability) => {
    return ProductsAvailability.listUniqueFinancialProductsOnBundles(
      productAvailability
    );
  }
);

export const deriveDesignFinancialProductTypes = createSelector(
  [deriveFinancialProductsList],
  (financialProductList): PvProduct[] => {
    return ProductsAvailability.mapFinancialProductTypeToPvProduct(
      financialProductList
    ).sort(sortFlexFirst);
  }
);

// sorting function to make Flex move to the first position if it exists
const sortFlexFirst = (a: PvProduct,b: PvProduct) => {
  if (a === PvProduct.FlexMonthly) return -1;
  if (b === PvProduct.FlexMonthly) return 1;
  return 0;
}
