import { ReactNode } from "react";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
  ThemeOptions,
  Theme,
  Palette,
  ZIndex,
} from "@mui/material";
import { deepmerge } from "@mui/utils";
import { offerBuilderTheme } from "./offerBuilderTheme";

/**
 * MUI itself is written in pure JS. Themes are explicit extension point of the library but for unknown reasons,
 * the maintainers chose to use TS module augmentation as the mechanism for users to provide their own themes.
 * We have found that patching the ootb types from MUI is painful and error-prone. Instead, we take a much simpler
 * approach and use basic interface extension to define how the DesignToolsTheme is a superset of the ootb MUI theme.
 *
 * In cases like `createTheme()` where such type extension is not possible due to the way the MUI provided declaration
 * files are written, we simply ts-ignore those declarations, which how MUI itself uses the code internally (again it's
 * written in pure JS).
 */
export interface DesignToolsTheme extends Theme {
  palette: DesignToolsPalette;
  zIndex: DesignToolsZIndex;
}

export interface DesignToolsZIndex extends ZIndex {
  floatingActionButton: number;
  prPreviewURL: number;
}

export interface DesignToolsPalette extends Palette {
  maxProgressBarColor: string;
  targetProgressBarColor: string;
  belowTargetColor: string;
  targetMetColor: string;
  aboveMaxColor: string;
}

export const rootTheme = createTheme({
  zIndex: {
    appBar: 1100,
    drawer: 1200,
    // @ts-ignore
    floatingActionButton: 1050,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
    // @ts-ignore
    prPreviewURL: 500
  },
  palette: {
    primary: {
      main: "#0073E0", // "Sunrun Blue"
    },
    secondary: {
      main: "#038761",
    },
    text: {
      primary: "#001A3C",
    },
    // @ts-ignore
    maxProgressBarColor: "rgba(0, 26, 60, 0.1)",
    targetProgressBarColor: "#B3BAC5",
    belowTargetColor: "#0073e0",
    targetMetColor: "#157245",
    aboveMaxColor: "#CC0000",
  },
  shape: {
    borderRadius: 4,
  },
  spacing: 8,
  typography: {
    fontSize: 16,
    fontFamily: "Inter, Roboto, Helvetica, Arial, sans-serif",
    h2: {
      fontSize: 28,
      fontWeight: 500,
    },
    h3: {
      fontSize: 16,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: 1.5,
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1,
    },
    caption: {
      fontSize: 14,
      lineHeight: 1.5,
    },
    // @ts-ignore
    mono: {
      fontFamily: "monospace",
      whiteSpace: "pre",
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          verticalAlign: "middle",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // @ts-ignore
        root: ({ ownerState, theme }) => ({
          ...Object.assign({}, ownerState.variant === "text" && {
            textTransform: "none",
            padding: 0,
            textDecoration: "underline"
          }, { textTransform: "none" }),
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        // @ts-ignore
        root: ({ theme }) => ({
          ...{
            padding: theme.spacing(0, 3, 3),
          },
        }),
      },
    },
  },
});

export function RootThemeProvider({
  appTheme = {},
  children,
}: {
  appTheme?: ThemeOptions;
  children: ReactNode;
}) {
  appTheme = offerBuilderTheme
  const mergedTheme = createTheme(deepmerge(rootTheme, appTheme));
  
  return <MuiThemeProvider theme={mergedTheme}>{children}</MuiThemeProvider>;
}

export default RootThemeProvider;
