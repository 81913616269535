import { KeystoneEquipmentSellingRule } from "../keystoneClient";

export const KEYSTONE_BASE_ADD_GATEWAY: KeystoneEquipmentSellingRule = {
  sys: {
    id: "mVZuEQsyfnV9TuCJId2nI",
  },
  title: "MID: Base ADD Rule",
  effectiveDatetime: "2024-07-02T00:00:00.000-07:00",
  expirationDatetime: "2026-07-31T00:00:00.000-07:00",
  type: {
    name: "Equipment",
  },
  aggregationAction: {
    name: "Add",
  },
  stateCollection: {
    items: [
      {
        postalAbbreviation: "ALL",
      },
    ],
  },
  utilityCollection: {
    items: [
      {
        name: "ALL",
        aliases: ["ALL"],
      },
    ],
  },
  acquisitionChannelCollection: {
    items: [
      {
        name: "ALL",
      },
    ],
  },
  salesDivisionCollection: {
    items: [
      {
        name: "ALL",
      },
    ],
  },
  purchaseThroughCollection: {
    items: [
      {
        name: "ALL",
      },
    ],
  },
  equipmentCollection: {
    items: [
      {
        sys: {
          id: "4m4PkuqUxd1oIepBp4ZFz9",
        },
      },
    ],
  },
};

export const KEYSTONE_ADD_BUS_RULE = {
  sys: {
    id: "5egKeH71pu7T7gszSkCm2P",
  },
  title: "MID: BUS Include Rule",
  effectiveDatetime: "2024-07-05T00:00:00.000-07:00",
  expirationDatetime: "2026-07-31T00:00:00.000-07:00",
  type: {
    name: "Equipment",
  },
  aggregationAction: {
    name: "Add",
  },
  stateCollection: {
    items: [
      {
        postalAbbreviation: "ALL",
      },
    ],
  },
  utilityCollection: {
    items: [
      {
        name: "Pacific Gas & Electric (PG&E)",
        aliases: [
          "Pacific Gas & Electric (PG&E)",
          "PG&E",
          "PGE",
          "'Pacific Gas & Electric (PG&E)",
          "Pacific Gas & Electric",
          "PG & E",
          "PG&amp;E",
          "Pacific Gas and Electric Co (PG&E)",
          "Pacific Gas &amp; Electric (PG&amp;E)",
        ],
      },
      {
        name: "San Diego Gas & Electric (SDG&E)",
        aliases: [
          "San Diego Gas & Electric (SDG&E)",
          "SDG&E",
          "SDGE",
          "San Diego Gas & Electric",
          "San Diego Gas &amp; Electric (SDG&amp;E)",
        ],
      },
      {
        name: "United Power",
        aliases: ["United Power", "United_Power"],
      },
      {
        name: "Colorado Springs Utilities",
        aliases: ["Colorado Springs Utilities"],
      },
      {
        name: "Mountain View Electric Association",
        aliases: ["Mountain View Electric Association"],
      },
      {
        name: "Eversource",
        aliases: ["Eversource"],
      },
      {
        name: "United Illuminating",
        aliases: [
          "United Illuminating",
          "United_Illuminating",
          "United Illuminating Co",
        ],
      },
      {
        name: "TECO Energy",
        aliases: ["TECO Energy", "TECO"],
      },
      {
        name: "Duke Energy",
        aliases: ["Duke Energy", "Duke Energy (Florida)"],
      },
      {
        name: "FPL",
        aliases: ["FPL", "Florida Power & Light"],
      },
      {
        name: "OUC",
        aliases: ["OUC", "Orlando Utilities Commission"],
      },
      {
        name: "Sumter Electric Coop",
        aliases: ["Sumter Electric Coop"],
      },
      {
        name: "MECO",
        aliases: ["MECO", "Maui Electric", "MECO - Molokai"],
      },
      {
        name: "HECO",
        aliases: ["HECO", "Hawaii Electric"],
      },
      {
        name: "HELCO",
        aliases: ["HELCO", "Hawaii Electric Light"],
      },
      {
        name: "ComEd",
        aliases: ["ComEd", "Commonwealth Edison"],
      },
      {
        name: "NSTAR",
        aliases: [
          "NSTAR",
          "NSTAR Electric & Gas",
          "Eversource - MA (NSTAR)",
          "MA - NSTAR (NEC)",
          "NSTAR - Cape Cod",
        ],
      },
      {
        name: "National Grid",
        aliases: ["National Grid", "National_Grid"],
      },
      {
        name: "UNITIL",
        aliases: [
          "UNITIL",
          "MA - Unitil (UC)",
          "Unitil- Massachusetts (Fitchburg Gas and Electric Light Company)",
          "Unitil Corporation",
        ],
      },
      {
        name: "PEPCO",
        aliases: ["PEPCO", "Potomac Electric Power Co", "PEPCO - DC"],
      },
      {
        name: "Potomac Edison",
        aliases: ["Potomac Edison"],
      },
      {
        name: "BG&E",
        aliases: ["BG&E", "Baltimore Gas and Electric"],
      },
      {
        name: "Liberty Utilities",
        aliases: ["Liberty Utilities"],
      },
      {
        name: "PSE&G",
        aliases: ["PSE&G", "Public Service Electric and Gas", "PSEG"],
      },
      {
        name: "JCP&L",
        aliases: ["JCP&L", "JCPL", "JCP&amp;L", "Jersey Central Power & Light"],
      },
      {
        name: "ACE",
        aliases: ["ACE", "ACE Utilities"],
      },
      {
        name: "Rockland Electric",
        aliases: ["Rockland Electric", "Rockland", "Rockland Electric Company"],
      },
      {
        name: "O&R",
        aliases: [
          "O&R",
          "ORANGE_ROCKLAND",
          "Orange & Rockland",
          "Rockland Electric",
          "Rockland Electric Company",
        ],
      },
      {
        name: "PSEGLI",
        aliases: [
          "PSEGLI",
          "PSEG Long Island",
          "LIPA",
          "Long Island Power Authority",
        ],
      },
      {
        name: "ConEdison",
        aliases: ["ConEdison", "Con Edison"],
      },
      {
        name: "NYSE&G",
        aliases: [
          "NYSE&G",
          "NYSEG",
          "New York State Electric & Gas",
          "NY - New York State Electric & Gas (NYSEG)",
        ],
      },
      {
        name: "Oncor",
        aliases: ["Oncor"],
      },
      {
        name: "Centerpoint Energy",
        aliases: ["Centerpoint Energy"],
      },
      {
        name: "Entergy",
        aliases: ["Entergy"],
      },
    ],
  },
  acquisitionChannelCollection: {
    items: [
      {
        name: "ALL",
      },
    ],
  },
  salesDivisionCollection: {
    items: [
      {
        name: "ALL",
      },
    ],
  },
  purchaseThroughCollection: {
    items: [
      {
        name: "ALL",
      },
    ],
  },
  equipmentCollection: {
    items: [
      {
        sys: {
          id: "mZB9Yi9wgT0deGvdTfZJK",
        },
      },
    ],
  },
};
export const keystoneSellingRulesFixture: KeystoneEquipmentSellingRule[] = [
  KEYSTONE_BASE_ADD_GATEWAY,
  KEYSTONE_ADD_BUS_RULE,
];
