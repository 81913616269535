import { Obstruction } from "@sunrun/design-tools-domain-model";
import { obstructionStripes } from "src/styles/stylingPatterns";
import { GeoJsonLayer } from "src/components/GeoJsonLayer";
import { PathOptions } from "leaflet";
import React from "react";

export type ObstructionProps = {
  obstruction: Obstruction;
};

export const ObstructionLayer = (props: ObstructionProps) => {
  const [pathOptions] = React.useState<PathOptions>({
    color: "#EE0000",
    fillOpacity: 0.5,
    fillPattern: obstructionStripes,
  });

  return (
    <GeoJsonLayer data={props.obstruction} pathOptions={pathOptions} key={props.obstruction.id} />
  );
};
