import React from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {LoadingScreen} from "@sunrun/design-tools-loading-screen";
import {LightmileProjectSource} from "@sunrun/design-tools-domain-model";
import {useImportLightmileProject} from "../../../../useImportLightmileProject";
import {URLSearchParameterKey} from "src/types/URLSearchParameterKey"

export const ImportLightmileProjectByProspectRoute = () => {
  const {setImportParams} = useImportLightmileProject();

  const {prospectId, offerId} = useParams();
  const [searchParams] = useSearchParams();
  const signedRootId = searchParams.get(URLSearchParameterKey.SignedRootId) || undefined;
  const parentProposalId = searchParams.get(URLSearchParameterKey.ParentProposalId) || undefined;
  const signedRootIdDashProduct = searchParams.get(URLSearchParameterKey.SignedRootIdDashProduct) || undefined;
  const interconnectionAppliedProposalId = searchParams.get(URLSearchParameterKey.InterconnectionAppliedProposalId) || undefined;
  const isChangeOrder = signedRootId && parentProposalId;
  // Always copy from Parent Proposal on Change Orders because otherwise the Active Child Project's Design list may be stale.
  const copyFromProposalId = isChangeOrder
    ? parentProposalId
    : searchParams.get(URLSearchParameterKey.CopyFromProposalId) || undefined;
  const copyFromDesignId = searchParams.get(URLSearchParameterKey.CopyFromDesignId) || undefined;

  console.log("prospectId:", prospectId);
  if (signedRootId) console.log("signedRootId:", signedRootId);
  if (parentProposalId) console.log("parentProposalId:", parentProposalId);
  if (interconnectionAppliedProposalId) console.log("interconnectionAppliedProposalId:", interconnectionAppliedProposalId);
  if (copyFromProposalId) console.log("copyFromProposalId:", copyFromProposalId);
  if (copyFromDesignId) console.log("copyFromDesignId:", copyFromDesignId);

  React.useEffect(() => {
    if (prospectId && offerId) {
      const activeProjectSource: LightmileProjectSource = {
        sourceByProspect: {
          prospectId,
          parentProposalId: parentProposalId  || "",
          offerId: offerId || "",
          signedRootId: signedRootId || ""
        }
      };
      setImportParams({
        activeProjectSource,
        prospectId,
        signedRootId,
        signedRootIdDashProduct,
        interconnectionAppliedProposalId,
        copyFromProposalId,
        offerId
      });
    }
  }, [prospectId, offerId, parentProposalId, signedRootId]);

  return (
    <LoadingScreen />
  );
};
