import {useQuery} from "react-query";
import {storefrontClient} from "@sunrun/design-tools-graphql-clients";
import { useWorkspace } from "./useWorkspace";
import { useAppDispatch } from "src/store";
import { setErrorModal } from "src/features/modal/modalSlice";

export const useListProducts = () => {

  // useListProducts makes a call to Storefront to get all Products (Solar, Backup, Shift, EV, and all future product types).
  // These products are not limited to those products associated with an offer returned by getOffer
  // products option values are mapped to design changes to ensure our users always see the appropriate language for our product offerings.

  const { state, dispatch: workspaceDispatch } = useWorkspace();
  const dispatch = useAppDispatch()
  const offerId = state.offer?.id
  const listProducts = () => {
      return storefrontClient.listProducts();
  }
  return useQuery(['listProducts'], listProducts, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!offerId,
    onSuccess: (products) => {
      if (products.items){
        workspaceDispatch({type: 'setProducts', payload: products.items })
      }
      else{
        console.log('Error fetching listProducts. No Items on paginatedProducts');
      }
    },
    onError: (error: any) => {
      let userError = (error instanceof Error) ? error : new Error("Unable to fetch listProducts");
      dispatch(setErrorModal({error: userError, message: "Unable to fetch listProducts"}))
      workspaceDispatch({ type: "setErrorModal", payload: {error: userError, message: "Unable to fetch listProducts"}})
      console.log('Error fetching listProducts', error);
    },
  });
}
