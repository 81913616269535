import { Box, Divider, IconButton, Stack, Tooltip } from "@mui/material";
import StatDisplay from "src/components/StatDisplay";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import ProgressBar from "./ProgressBar";
import { useWorkspace } from "src/hooks/useWorkspace";
import { DesignConstraintType, calculateUsageOffset } from "@sunrun/design-tools-domain-model";
import { deriveDesignConstraintsClass } from "src/features/designConstraints/designConstraintsSlice";
import { deriveSolarResourceClass } from "src/features/solarResource/solarResourceSlice";
import { useAppDispatch } from "src/store";
import { setInfoModal } from "src/features/modal/modalSlice";

type ToolbarStatsProps = {
  customerUsage: number;
  annualProductionKwh: number;
  targetOffset: number;
  maxOffset: number;
  dcSystemSizeKw: number;
  sunHours: number;
  minSunHours: number;
  maxSunHours: number;
};

const ToolbarStats = ({
  customerUsage,
  annualProductionKwh,
  targetOffset,
  maxOffset,
  dcSystemSizeKw,
  sunHours,
  minSunHours,
  maxSunHours,
}: ToolbarStatsProps) => {
  const { state, dispatch: workspaceDispatch } = useWorkspace();
  const dispatch = useAppDispatch();
  const solarResource = deriveSolarResourceClass(state);
  const usageOffset = calculateUsageOffset(annualProductionKwh, customerUsage);
  const formattedOffset = (usageOffset * 100).toFixed(0) + "%";
  const formattedTargetOffset = (targetOffset * 100).toFixed(0) + "%";
  const formattedMaxOffset = (maxOffset * 100).toFixed(0) + "%";
  const designConstraints = deriveDesignConstraintsClass(state);
  const maxSystemSize = designConstraints?.getConstraintValue(
    DesignConstraintType.MaxDcSystemSizeKw,
  );
  const minSystemSize = designConstraints?.getConstraintValue(
    DesignConstraintType.MinDcSystemSizeKw,
  );
  const targetSunHours = solarResource?.siteMaxSunHours.toFixed(0);
  const offsetInfoModal = {
    title: "Usage Offset",
    message: `The usage offset refers to the portion of the customer's energy consumption covered by the designed system's production. 
    Depending on your customer's needs, the usage target should be around ${formattedTargetOffset} and the max allowable usage offset is ${formattedMaxOffset}. 
    The usage offset value is rounded up to the nearest whole number to avoid potential upper bound issues with the AHJ.`,
  };
  const systemSizeInfoModal = {
    title: "System Size",
    message: `The system size refers to the capacity of the designed solar system. The optimal system size depends on factors such as the customer's energy consumption, 
    desired usage offset, and available roof space. The minimum system size is ${minSystemSize} kW. The max allowable system size in this area is ${maxSystemSize} kW.`,
  };
  const sunHoursInfoModal = {
    title: "Sun Hours",
    message: `Sun Hours are a measure of the amount of solar irradiance received at a particular location during a typical day. 
    It represents the number of hours when sunlight is strong enough for effective solar energy generation. The optimal Sun Hours in this area should be ${targetSunHours} or greater.`,
  };

  return (
    <>
      <Stack flexGrow={1} direction="row" spacing={2} marginX={2}>
        <Stack flexGrow={1} direction="row" maxWidth="200px">
          <StatDisplay title="Offset" stat={formattedOffset} noWrap />
          <IconButton
            size="small"
            sx={{ height: "fit-content", padding: 0 }}
            onClick={() => {
              dispatch(setInfoModal(offsetInfoModal));
              workspaceDispatch({ type: "setInfoModal", payload: offsetInfoModal });
            }}
          >
            <HelpOutlineOutlinedIcon fontSize="inherit" color="primary" />
          </IconButton>
          <Tooltip
            title={
              <span>
                Target Offset: {formattedTargetOffset} <br />
                Maximum Offset: {formattedMaxOffset}
              </span>
            }
          >
            <Box sx={{ alignSelf: "center", marginLeft: 1, width: "100%" }}>
              <ProgressBar
                variant="offset"
                value={usageOffset}
                maxValue={maxOffset}
                targetValue={targetOffset}
              />
            </Box>
          </Tooltip>
        </Stack>
        <Stack
          flexGrow={1}
          direction="row"
          maxWidth="200px"
          sx={{ display: { xs: "none", toolbar1: "flex" } }}
        >
          <Divider orientation="vertical" sx={{ marginRight: 2 }} />
          <StatDisplay title="Sun Hours" stat={sunHours} noWrap />
          <IconButton
            size="small"
            sx={{ height: "fit-content", padding: 0 }}
            onClick={() => {
              dispatch(setInfoModal(sunHoursInfoModal));
              workspaceDispatch({ type: "setInfoModal", payload: sunHoursInfoModal });
            }}
          >
            <HelpOutlineOutlinedIcon fontSize="inherit" color="primary" />
          </IconButton>
          <Tooltip
            title={
              <span>
                Minimum Sun Hours: {minSunHours} <br />
                Optimal Sun Hours: {targetSunHours}+
              </span>
            }
          >
            <Box sx={{ alignSelf: "center", marginLeft: 1, width: "100%" }}>
              <ProgressBar
                variant="sunhour"
                value={sunHours}
                minValue={minSunHours}
                maxValue={maxSunHours}
              />
            </Box>
          </Tooltip>
        </Stack>
        <Stack
          flexGrow={1}
          direction="row"
          maxWidth="200px"
          sx={{ display: { xs: "none", toolbar2: "flex" } }}
        >
          <Divider orientation="vertical" sx={{ marginRight: 2 }} />
          <StatDisplay title="Production" stat={annualProductionKwh} unit={"kWh"} noWrap />
        </Stack>
        <Stack
          flexGrow={1}
          direction="row"
          maxWidth="200px"
          sx={{ display: { xs: "none", toolbar3: "flex" } }}
        >
          <Divider orientation="vertical" sx={{ marginRight: 2 }} />
          <StatDisplay title="Consumption" stat={customerUsage} unit={"kWh"} noWrap />
        </Stack>
        <Stack
          flexGrow={1}
          direction="row"
          maxWidth="200px"
          sx={{ display: { xs: "none", toolbar4: "flex" } }}
        >
          <Divider orientation="vertical" sx={{ marginRight: 2 }} />
          <StatDisplay
            title="DC System Size"
            stat={dcSystemSizeKw}
            unit="kW"
            toDecimal={3}
            noWrap
          />
          <IconButton
            size="small"
            sx={{ height: "fit-content", padding: 0 }}
            onClick={() => {
                dispatch(setInfoModal(systemSizeInfoModal)),
                workspaceDispatch({
                  type: "setInfoModal",
                  payload: systemSizeInfoModal,
                })
              }
            }
          >
            <HelpOutlineOutlinedIcon fontSize="inherit" color="primary" />
          </IconButton>
        </Stack>
      </Stack>
    </>
  );
};

export default ToolbarStats;
