import { createSelector } from "reselect"; 
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {useWorkspace, WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {WorkspacePayloadAction} from "../../types/state-management/action";
import {DesignConstraints, IDesignConstraints} from "@sunrun/design-tools-domain-model";
import { deriveFinancialProductsList } from "../productAvailability/productAvailabilitySlice";
import { deriveDesignClass, selectIsAcStorage, selectIsDcStorage } from "../design/designSlice";

export type DesignConstraintsState = {
   value: IDesignConstraints | undefined;
} 

export const initialState: DesignConstraintsState = {
  value: undefined,
}

const designConstraintsSlice = createSlice({
  name: "designConstraints",
  initialState,
  reducers: {
    setDesignConstraints: (state: DesignConstraintsState, action: PayloadAction<IDesignConstraints>) => {
      state.value = action.payload
    },
  },
});

export const { setDesignConstraints } = designConstraintsSlice.actions;
export default designConstraintsSlice.reducer;

// TODO: Remove the following and transition fully from WorkspaceState to RTK store
export type DesignConstraintsWorkspaceAction = WorkspacePayloadAction<DesignConstraints, 'setDesignConstraints'>


export const designConstraintsWorkspaceReducer = (state: WorkspaceState, action: WorkspaceAction): IDesignConstraints | undefined => {
  switch (action.type) {
    case 'setDesignConstraints': {
      return action.payload.getIDesignConstraints()
    }
    default: {
      return state.designConstraints
    }
  }
}

export const selectDesignConstraints = (state: WorkspaceState) => state.designConstraints;
export const deriveDesignConstraintsClass = createSelector(
  [selectDesignConstraints, deriveFinancialProductsList],
  (designConstraints, availableFinancialProducts) => { return designConstraints ? new DesignConstraints(designConstraints, availableFinancialProducts) : undefined}
);
