export const formatNumber = ({
  value,
  maximumFractionDigits = 2,
  defaultDisplay = "--",
  replaceZeroWithDefault = true,
}: {
  value: number;
  maximumFractionDigits?: number;
  defaultDisplay?: string;
  replaceZeroWithDefault?: boolean;
}) => {
  if (isNaN(value) || (replaceZeroWithDefault && !value)) {
    return defaultDisplay;
  }
  return Intl.NumberFormat("en-US", { maximumFractionDigits }).format(value);
};

export default formatNumber;