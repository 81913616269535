import {
  TableCell,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutline";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircleOutline";
import { DesignConstraint } from "@sunrun/design-tools-domain-model";

export type CellData = {
  allowed: boolean;
  designConstraint: DesignConstraint;
  value: string | undefined;
}

type FinancialProductsCellProps = {
  cellData: CellData;
  fullscreen: boolean | undefined;
};

export const FinancialProductsCell = ({
  cellData,
  fullscreen
}: FinancialProductsCellProps) => {
  const { allowed, designConstraint, value } = cellData;
  const SourceTooltip = value && designConstraint?.source ? (
    <>
      <div>Source:</div>
      <div>{designConstraint?.source}</div>
      {designConstraint?.sourceName && <div>{designConstraint?.sourceName}</div>}
    </>
  ) : ""
  return (
    <TableCell sx={{borderLeft: "1px solid rgba(224, 224, 224, 1)", px: fullscreen ? 1 : "default"}}>
      <Tooltip title={SourceTooltip}>
        <Stack direction={"row"} justifyContent="center" columnGap={1}>
          {value ? (
            <>
              {allowed ? (
                <CheckCircleIcon fontSize="small" color="success" />
              ) : (
                <ReportProblemIcon fontSize="small" color="error" />
              )}
              <Typography variant="body2">
                {value}
              </Typography>
            </>
          ) : (
            <>
              <RemoveCircleIcon fontSize="small" color="disabled" />
              <Typography variant="body2" >
               N/A
              </Typography>
            </>
          )}
        </Stack>
      </Tooltip>
    </TableCell>
  );
};
