import {G2Vector3D} from "./vector3D";
import {roundToDecimalPlaces} from "./util"
import {Polygon} from "../geometry/polygon";
import {G2Polygon3D} from "./polygon3D";
import {G2Line} from "./line";

//3d rectangle, rename to G2Rectangle3D?
export class G2Rectangle {
  readonly lowerLeft: G2Vector3D;
  readonly lowerRight: G2Vector3D;
  readonly upperRight: G2Vector3D;
  readonly upperLeft: G2Vector3D;

  constructor(lowerLeft: G2Vector3D, lowerRight: G2Vector3D, upperRight: G2Vector3D, upperLeft: G2Vector3D) {
    this.lowerLeft = lowerLeft;
    this.lowerRight = lowerRight;
    this.upperRight = upperRight;
    this.upperLeft = upperLeft;
  }

  static fromGeoJsonAndXAxis(polygon: Polygon, xAxis: G2Vector3D | null = null): G2Rectangle {
    const cornersCounterClockWise = polygon.coordinates[0].map(it => {
      return new G2Vector3D(
        it[0], it[1], it[2]
      )
    })
    return G2Rectangle.fromFourCornersCounterClockWiseAndXAxis([
      cornersCounterClockWise[0],
      cornersCounterClockWise[1],
      cornersCounterClockWise[2],
      cornersCounterClockWise[3]
    ], xAxis)
  }

  // Construct G2Rectangle from 4 corners in counter clock wise order.
  // We do not know which corner is lower left, so either pass xAxis parameter (best performance)
  // or this will calculate the xAxis from first 2 corners since we always have 4 corners in order of lowerLeft, lowerRight, upperRight, upperLeft
  static fromFourCornersCounterClockWiseAndXAxis(
    fourCornersCounterClockWise: [G2Vector3D, G2Vector3D, G2Vector3D, G2Vector3D],
    xAxis: G2Vector3D | null = null
  ): G2Rectangle {
    const foundXAxis = xAxis ? xAxis : fourCornersCounterClockWise[1].sub(fourCornersCounterClockWise[0]).normalize()
    //3 counterclockwise points determine the z vector using right hand rule
    const zAxis = G2Vector3D.normalOfPoints(
      fourCornersCounterClockWise[0],
      fourCornersCounterClockWise[1],
      fourCornersCounterClockWise[2],
    )
    const yAxis = zAxis.cross(foundXAxis)
    const centroid = fourCornersCounterClockWise[0].midpoint(fourCornersCounterClockWise[2])
    const fourCornersRelativeToCentroid = fourCornersCounterClockWise.map((corner) => corner.sub(centroid))
    const anglesCounterClockWiseFromYAxis = fourCornersRelativeToCentroid.map((cornerRelativeToCentroid) => {
      return yAxis.anglesCounterClockwiseInRadiansToVectorAroundAxis(cornerRelativeToCentroid, zAxis)
    })
    const fourCornersWithAnglesFromYAxis = fourCornersCounterClockWise.map((corner, i) => {
      return {
        corner: corner,
        anglesCounterClockWiseFromYAxis: anglesCounterClockWiseFromYAxis[i]
      }
    })
    const fourCornersWithAnglesFromYAxisSorted = fourCornersWithAnglesFromYAxis
      .slice(0)
      .sort((cornerWithAngle1, cornerWithAngle2) => {
        return cornerWithAngle1.anglesCounterClockWiseFromYAxis > cornerWithAngle2.anglesCounterClockWiseFromYAxis ? 1 : -1
      })
    //following order:
    //[3] has largest positive angle (hence indexed at 3) from Y hence is lowerLeft
    //[0] has the largest negative angle (hence indexed at 0) from Y hence is lowerRight
    //[1] has the second largest negative angle (hence indexed at 1) from Y hence is aboveRight
    //[2] has the second largest positive angle (hence indexed at 2) from Y hence is aboveLeft
    return new G2Rectangle(
      fourCornersWithAnglesFromYAxisSorted[3].corner,
      fourCornersWithAnglesFromYAxisSorted[0].corner,
      fourCornersWithAnglesFromYAxisSorted[1].corner,
      fourCornersWithAnglesFromYAxisSorted[2].corner,
    )
  }

  public fourCornersCounterClockwise(): [G2Vector3D, G2Vector3D, G2Vector3D, G2Vector3D] {
    return [this.lowerLeft, this.lowerRight, this.upperRight, this.upperLeft]
  }

  // local rectangle coordinate system, imagine you are looking at the rectangle straight facing it
  public xAxis(): G2Vector3D {
    return this.lowerRight.sub(this.lowerLeft).normalize()
  }

  public yAxis(): G2Vector3D {
    return this.upperLeft.sub(this.lowerLeft).normalize()
  }

  public zAxis(): G2Vector3D {
    return this.normalVector()
  }

  public normalVector(): G2Vector3D {
    //3 points with known winding direction determine a plane, hence we can get its norm
    return G2Vector3D.normalOfPoints(this.lowerLeft, this.lowerRight, this.upperRight)
  }

  public shortSideLength(): number {
    return Math.min(
      this.lowerLeft.sub(this.lowerRight).length(),
      this.upperLeft.sub(this.lowerLeft).length()
    )
  }

  public longSideLength(): number {
    return Math.max(
      this.lowerLeft.sub(this.lowerRight).length(),
      this.upperLeft.sub(this.lowerLeft).length()
    )
  }

  //facing straight to the rectangle, it is portrait if its vertical side is longer than its horizontal side
  public isPortrait(): boolean {
    return roundToDecimalPlaces(this.shortSideLength()) === roundToDecimalPlaces(this.lowerLeft.sub(this.lowerRight).length())
  }

  public lowerLine(): G2Line {
    //line dir from lowerLeft to lowerRight
    return G2Line.fromTwoPoints(this.lowerLeft, this.lowerRight)
  }

  public lowerSideLength(): number {
    return this.lowerLine().point.dist(this.lowerLine().anotherPoint!)
  }

  public rightLine(): G2Line {
    //line dir from lowerRight to upperRight
    return G2Line.fromTwoPoints(this.lowerRight, this.upperRight)
  }

  public upperLine(): G2Line {
    //line dir from upperRight to upperLeft
    return G2Line.fromTwoPoints(this.upperRight, this.upperLeft)
  }

  public leftLine(): G2Line {
    //line dir from upperLeft to lowerLeft
    return G2Line.fromTwoPoints(this.upperLeft, this.lowerLeft)
  }

  //a more readable implementation to replace getBufferedRectangle
  //enlarge by absolute sizes
  public enlargeAbsolute(bySizes: number[]): G2Rectangle {
    const side0Downward = bySizes[0]
    const side1Rightward = bySizes[1]
    const side2Upward = bySizes[2]
    const side3Leftward = bySizes[3]
    return this.calculateScaledRectangle(side0Downward, side1Rightward, side2Upward, side3Leftward)
  }

  //enlarge by absolute sizes
  public enlargeRelative(byPercentWidth: number, byPercentHeight: number): G2Rectangle {
    const width = this.lowerLeft.sub(this.lowerRight).length()
    const height = this.upperLeft.sub(this.lowerLeft).length()
    const side0Downward = height * byPercentHeight / 2.0
    const side1Rightward = width * byPercentWidth / 2.0
    const side2Upward = height * byPercentHeight / 2.0
    const side3Leftward = width * byPercentWidth / 2.0
    return this.calculateScaledRectangle(side0Downward, side1Rightward, side2Upward, side3Leftward)
  }

  private calculateScaledRectangle(
    side0Downward: number,
    side1Rightward: number,
    side2Upward: number,
    side3Leftward: number
  ):G2Rectangle {
    return new G2Rectangle(
      this.lowerLeft
        .add(this.lowerLeft.sub(this.lowerRight).normalize().scale(side3Leftward))
        .add(this.lowerLeft.sub(this.upperLeft).normalize().scale(side0Downward)),
      this.lowerRight
        .add(this.lowerRight.sub(this.lowerLeft).normalize().scale(side1Rightward))
        .add(this.lowerRight.sub(this.upperRight).normalize().scale(side0Downward)),
      this.upperRight
        .add(this.upperRight.sub(this.lowerRight).normalize().scale(side2Upward))
        .add(this.upperRight.sub(this.upperLeft).normalize().scale(side1Rightward)),
      this.upperLeft
        .add(this.upperLeft.sub(this.lowerLeft).normalize().scale(side2Upward))
        .add(this.upperLeft.sub(this.upperRight).normalize().scale(side3Leftward))
    )
  }

  public equals(rect: G2Rectangle, toDecimalPlaces: number = 2) {
    return this.lowerLeft.equals(rect.lowerLeft, toDecimalPlaces) &&
      this.lowerRight.equals(rect.lowerRight, toDecimalPlaces) &&
      this.upperRight.equals(rect.upperRight, toDecimalPlaces) &&
      this.upperLeft.equals(rect.upperLeft, toDecimalPlaces)
  }

  public centroid(): G2Vector3D {
    const vectorFromUpperLeftToLowerRight = this.lowerRight.sub(this.upperLeft)
    return this.upperLeft
      .add(vectorFromUpperLeftToLowerRight.normalize().scale(vectorFromUpperLeftToLowerRight.length() / 2))
  }

  // image a right angled rectangle containing the provided polygon, then find the center
  public centerOfRectangleContainingPolygonAsNumArray() {
    const largestX = Math.max(
      this.lowerLeft.x,
      this.upperLeft.x,
      this.lowerRight.x,
      this.upperRight.x,
    );
    const smallestX = Math.min(
      this.lowerLeft.x,
      this.upperLeft.x,
      this.lowerRight.x,
      this.upperRight.x,
    );
    const largestY = Math.max(
      this.lowerLeft.y,
      this.upperLeft.y,
      this.lowerRight.y,
      this.upperRight.y,
    );
    const smallestY = Math.min(
      this.lowerLeft.y,
      this.upperLeft.y,
      this.lowerRight.y,
      this.upperRight.y,
    );
    return [largestX - ((largestX - smallestX) / 2), largestY - ((largestY - smallestY) / 2)]
  }


  //rotate counter clock wise aroundPoint aroundAxis of the rectangle
  //you can pass in an xAxis if you want to orient (determine lowerleft) the result of rotation according to the passed xAxis
  //if no xAxis is passed, current xAxis will be observed
  public rotateCounterClockwise(
    byRadians: number = Math.PI/2, 
    aroundPoint: G2Vector3D = this.centroid(), 
    aroundAxis: G2Vector3D = this.normalVector(), 
    xAxis: G2Vector3D = this.xAxis()
  ): G2Rectangle {
    const rotatedFourCornersCounterClockwise = this.fourCornersCounterClockwise().map((corner) => {
      return corner.rotateCounterClockwiseByRadiansAroundAxis(
        byRadians, 
        aroundAxis, 
        aroundPoint
      )
    })
    //G2Rectangle have 4 corners with name: lowerLeft, lowerRight, aboveRight, aboveLeft
    //after rotate, the corners should be renamed
    return G2Rectangle.fromFourCornersCounterClockWiseAndXAxis([
      rotatedFourCornersCounterClockwise[0],
      rotatedFourCornersCounterClockwise[1],
      rotatedFourCornersCounterClockwise[2],
      rotatedFourCornersCounterClockwise[3],
    ],xAxis)
  }

  //move centroid to
  public moveTo(newCentroid: G2Vector3D): G2Rectangle {
    const oldCentroidToNewCentroid = newCentroid.sub(this.centroid())
    return new G2Rectangle(
      this.lowerLeft.add(oldCentroidToNewCentroid),
      this.lowerRight.add(oldCentroidToNewCentroid),
      this.upperRight.add(oldCentroidToNewCentroid),
      this.upperLeft.add(oldCentroidToNewCentroid)
    )
  }

  public moveLowerLeftTo(newLowerLeft: G2Vector3D): G2Rectangle {
    const oldLowerLeftToNewLowerLeft = newLowerLeft.sub(this.lowerLeft)
    const oldCentroidToNewCentroid = oldLowerLeftToNewLowerLeft
    return new G2Rectangle(
      this.lowerLeft.add(oldCentroidToNewCentroid),
      this.lowerRight.add(oldCentroidToNewCentroid),
      this.upperRight.add(oldCentroidToNewCentroid),
      this.upperLeft.add(oldCentroidToNewCentroid)
    )
  }

  public moveLowerRightTo(newLowerRight: G2Vector3D): G2Rectangle {
    const oldLowerRightToNewLowerRight = newLowerRight.sub(this.lowerRight)
    const oldCentroidToNewCentroid = oldLowerRightToNewLowerRight
    return new G2Rectangle(
      this.lowerLeft.add(oldCentroidToNewCentroid),
      this.lowerRight.add(oldCentroidToNewCentroid),
      this.upperRight.add(oldCentroidToNewCentroid),
      this.upperLeft.add(oldCentroidToNewCentroid)
    )
  }

  public moveUpperLeftTo(newUpperLeft: G2Vector3D): G2Rectangle {
    const oldUpperLeftToNewUpperLeft = newUpperLeft.sub(this.upperLeft)
    const oldCentroidToNewCentroid = oldUpperLeftToNewUpperLeft
    return new G2Rectangle(
      this.lowerLeft.add(oldCentroidToNewCentroid),
      this.lowerRight.add(oldCentroidToNewCentroid),
      this.upperRight.add(oldCentroidToNewCentroid),
      this.upperLeft.add(oldCentroidToNewCentroid)
    )
  }

  public moveUpperRightTo(newUpperRight: G2Vector3D): G2Rectangle {
    const oldUpperRightToNewUpperRight = newUpperRight.sub(this.upperRight)
    const oldCentroidToNewCentroid = oldUpperRightToNewUpperRight
    return new G2Rectangle(
      this.lowerLeft.add(oldCentroidToNewCentroid),
      this.lowerRight.add(oldCentroidToNewCentroid),
      this.upperRight.add(oldCentroidToNewCentroid),
      this.upperLeft.add(oldCentroidToNewCentroid)
    )
  }

  public moveLeft(): G2Rectangle {
    return new G2Rectangle(
      this.lowerLeft
        .add(this.lowerLeft.sub(this.lowerRight)),
      this.lowerLeft,
      this.upperLeft,
      this.upperLeft
        .add(this.upperLeft.sub(this.upperRight))
    )
  }

  public moveRight(): G2Rectangle {
    return new G2Rectangle(
      this.lowerRight,
      this.lowerRight
        .add(this.lowerRight.sub(this.lowerLeft)),
      this.upperRight
        .add(this.upperRight.sub(this.upperLeft)),
      this.upperRight
    )
  }

  public moveUp(): G2Rectangle {
    return new G2Rectangle(
      this.upperLeft,
      this.upperRight,
      this.upperRight
        .add(this.upperRight.sub(this.lowerRight)),
      this.upperLeft
        .add(this.upperLeft.sub(this.lowerLeft)),
    )
  }

  public moveDown(): G2Rectangle {
    return new G2Rectangle(
      this.lowerLeft
        .add(this.lowerLeft.sub(this.upperLeft)),
      this.lowerRight
        .add(this.lowerRight.sub(this.upperRight)),
      this.lowerRight,
      this.lowerLeft,
    )
  }

  public moveLowerLeft(): G2Rectangle {
    return new G2Rectangle(
      this.lowerLeft
        .add(this.lowerLeft.sub(this.upperLeft))
        .add(this.lowerLeft.sub(this.lowerRight)),
      this.lowerLeft
        .add(this.lowerLeft.sub(this.upperLeft)),
      this.lowerLeft,
      this.lowerLeft
        .add(this.lowerLeft.sub(this.lowerRight)),
    )
  }

  public moveLowerRight(): G2Rectangle {
    return new G2Rectangle(
      this.lowerRight
        .add(this.lowerRight.sub(this.upperRight)),
      this.lowerRight
        .add(this.lowerRight.sub(this.upperRight))
        .add(this.lowerRight.sub(this.lowerLeft)),
      this.lowerRight
        .add(this.lowerRight.sub(this.lowerLeft)),
      this.lowerRight,
    )
  }

  public moveUpperRight(): G2Rectangle {
    return new G2Rectangle(
      this.upperRight,
      this.upperRight
        .add(this.upperRight.sub(this.upperLeft)),
      this.upperRight
        .add(this.upperRight.sub(this.upperLeft))
        .add(this.upperRight.sub(this.lowerRight)),
      this.upperRight
        .add(this.upperRight.sub(this.lowerRight))
    )
  }

  public moveUpperLeft(): G2Rectangle {
    return new G2Rectangle(
      this.upperLeft
        .add(this.upperLeft.sub(this.upperRight)),
      this.upperLeft,
      this.upperLeft
        .add(this.upperLeft.sub(this.lowerLeft)),
      this.upperLeft
        .add(this.upperLeft.sub(this.upperRight))
        .add(this.upperLeft.sub(this.lowerLeft))
    )
  }

  //aside from the above 8 methods, we could move rectangle by "xtimes"

  public moveLeftBy(xtimes: number = 1): G2Rectangle {
    const vectorLowerLeftSubLowerRight = this.lowerLeft.sub(this.lowerRight)
    const vectorUpperLeftSubUpperRight = this.upperLeft.sub(this.upperRight)
    return new G2Rectangle(
      this.lowerLeft
        .add(vectorLowerLeftSubLowerRight.normalize().scale(xtimes * vectorLowerLeftSubLowerRight.length())),
      this.lowerRight
        .add(vectorLowerLeftSubLowerRight.normalize().scale(xtimes * vectorLowerLeftSubLowerRight.length())),
      this.upperRight
        .add(vectorUpperLeftSubUpperRight.normalize().scale(xtimes * vectorUpperLeftSubUpperRight.length())),
      this.upperLeft
        .add(vectorUpperLeftSubUpperRight.normalize().scale(xtimes * vectorUpperLeftSubUpperRight.length()))
    )
  }

  public moveRightBy(xtimes: number = 1): G2Rectangle {
    const vectorLowerRightSubLowerLeft = this.lowerRight.sub(this.lowerLeft)
    const vectorUpperRightSubUpperLeft = this.upperRight.sub(this.upperLeft)
    return new G2Rectangle(
      this.lowerLeft
        .add(vectorLowerRightSubLowerLeft.normalize().scale(xtimes * vectorLowerRightSubLowerLeft.length())),
      this.lowerRight
        .add(vectorLowerRightSubLowerLeft.normalize().scale(xtimes * vectorLowerRightSubLowerLeft.length())),
      this.upperRight
        .add(vectorUpperRightSubUpperLeft.normalize().scale(xtimes * vectorUpperRightSubUpperLeft.length())),
      this.upperLeft
        .add(vectorUpperRightSubUpperLeft.normalize().scale(xtimes * vectorUpperRightSubUpperLeft.length()))
    )
  }

  public moveUpBy(ytimes: number = 1): G2Rectangle {
    const vectorUpperLeftSubLowerLeft = this.upperLeft.sub(this.lowerLeft)
    const vectorUpperRightSubLowerRight = this.upperRight.sub(this.lowerRight)
    return new G2Rectangle(
      this.lowerLeft
        .add(vectorUpperLeftSubLowerLeft.normalize().scale(ytimes * vectorUpperLeftSubLowerLeft.length())),
      this.lowerRight
        .add(vectorUpperRightSubLowerRight.normalize().scale(ytimes * vectorUpperRightSubLowerRight.length())),
      this.upperRight
        .add(vectorUpperRightSubLowerRight.normalize().scale(ytimes * vectorUpperRightSubLowerRight.length())),
      this.upperLeft
        .add(vectorUpperLeftSubLowerLeft.normalize().scale(ytimes * vectorUpperLeftSubLowerLeft.length()))
    )
  }

  public moveDownBy(ytimes: number = 1): G2Rectangle {
    const vectorlowerLeftSubupperLeft = this.lowerLeft.sub(this.upperLeft)
    const vectorlowerRightSubupperRight = this.lowerRight.sub(this.upperRight)
    return new G2Rectangle(
      this.lowerLeft
        .add(vectorlowerLeftSubupperLeft.normalize().scale(ytimes * vectorlowerLeftSubupperLeft.length())),
      this.lowerRight
        .add(vectorlowerRightSubupperRight.normalize().scale(ytimes * vectorlowerRightSubupperRight.length())),
      this.upperRight
        .add(vectorlowerRightSubupperRight.normalize().scale(ytimes * vectorlowerRightSubupperRight.length())),
      this.upperLeft
        .add(vectorlowerLeftSubupperLeft.normalize().scale(ytimes * vectorlowerLeftSubupperLeft.length()))
    )
  }

  public moveLowerLeftBy(xtimes: number = 1, ytimes: number = 1): G2Rectangle {
    return this
      .moveLeftBy(xtimes)
      .moveDownBy(ytimes)
  }

  public moveLowerRightBy(xtimes: number = 1, ytimes: number = 1): G2Rectangle {
    return this
      .moveRightBy(xtimes)
      .moveDownBy(ytimes)
  }

  public moveUpperLeftBy(xtimes: number = 1, ytimes: number = 1): G2Rectangle {
    return this
      .moveLeftBy(xtimes)
      .moveUpBy(ytimes)
  }

  public moveUpperRightBy(xtimes: number = 1, ytimes: number = 1): G2Rectangle {
    return this
      .moveRightBy(xtimes)
      .moveUpBy(ytimes)
  }

  public toGeoJson(): Polygon {
    const coordinates = this.fourCornersCounterClockwise().map(v => [v.x, v.y, v.z])
    return {type: "Polygon", coordinates: [coordinates.concat([coordinates[0]])]}
  }

  public toPolygon(): G2Polygon3D {
    return new G2Polygon3D(
      this.fourCornersCounterClockwise()
    )
  }

  public toString(): string {
    return "rect("+this.lowerLeft.toString()+", "+this.lowerRight.toString()+", "+this.upperRight.toString()+", "+this.upperLeft.toString()+")"
  }
}
