import { TableCell, TableRow, Typography } from "@mui/material";
import { CellData, FinancialProductsCell } from "./FinancialProductsCell";
import {
  DesignConstraintType,
  DesignConstraintsByProduct,
  PvProduct,
} from "@sunrun/design-tools-domain-model";
import { useWorkspace } from "src/hooks/useWorkspace";
import { deriveIsDesignFinalized } from "src/features/designGuidance/deriveIsDesignFinalized";
import { deriveLowResProductionSimulation } from "src/features/lowResProductionSimulation/deriveLowResProductionSimulation";
import { formatNumber } from "@sunrun/design-tools-themes";
import { deriveDesignConstraintsClass } from "src/features/designConstraints/designConstraintsSlice";
import { deriveDesignClass } from "src/features/design/designSlice";
import { deriveProductionSimulationClass } from "src/features/productionSimulation/productionSimulationSlice";

type FinancialProductsRowDesignConstraintsProps = {
  designConstraintType: DesignConstraintType;
  fullscreen: boolean | undefined;
  products: PvProduct[];
};

export const FinancialProductsRowDesignConstraints = ({
  designConstraintType,
  fullscreen,
  products,
}: FinancialProductsRowDesignConstraintsProps) => {
  const { state } = useWorkspace();
  const { customer } = state;
  const designConstraints = deriveDesignConstraintsClass(state);
  const design = deriveDesignClass(state);
  const productionSimulation = deriveProductionSimulationClass(state);
  const designFinalized = deriveIsDesignFinalized(state);
  const simulationToUse = designFinalized
    ? productionSimulation
    : deriveLowResProductionSimulation(state);
  const systemSunHours = simulationToUse?.getSystemSunHours(design) ?? 0;
  const usageOffsetPercent =
    simulationToUse?.getUsageOffsetPercent(customer) ?? 0;
  if (!design || !designConstraints) {
    return null;
  }
  const { acSystemSizeKw, cecAcSystemSizeKw, dcSystemSizeKw, batteryCount } =
    design;

  const selectedBatteryId =
    design?.selectedEquipmentSpecificationIds
      ?.selectedBatterySpecificationIds[0];
  let isAcBattery = false;
  if (selectedBatteryId) {
    const spec = design?.getBatterySpec(selectedBatteryId);
    isAcBattery = spec?.manufacturerSpecifications?.acStorage || false;
  }
  const acBatteryCount = isAcBattery ? batteryCount : 0;
  let rowLabel: string = "";
  let getValueLabel: (value: number) => string;
  let getMinAndMaxValueLabel: (
    minValue?: number,
    maxValue?: number
  ) => string | undefined;
  let designValue: number | undefined;
  let minConstraintType: DesignConstraintType | undefined;
  let maxConstraintType: DesignConstraintType | undefined;
  switch (designConstraintType) {
    case DesignConstraintType.MinSunHours:
      rowLabel = "Min Sun Hours";
      getValueLabel = (value) =>
        formatNumber({ value, maximumFractionDigits: 0 });
      designValue = systemSunHours;
      break;
    case DesignConstraintType.MaxOffsetPercent:
      rowLabel = "Max Offset";
      getValueLabel = (value) =>
        `${formatNumber({ value: value * 100, maximumFractionDigits: 0 })}%`;
      designValue = usageOffsetPercent;
      break;
    case DesignConstraintType.MaxDcSystemSizeKw:
      rowLabel = "Max DC";
      getValueLabel = (value) =>
        `${formatNumber({ value, maximumFractionDigits: 0 })}kW`;
      designValue = dcSystemSizeKw;
      break;
    case DesignConstraintType.MinDcSystemSizeKw:
      rowLabel = "Min DC";
      getValueLabel = (value) =>
        `${formatNumber({ value, maximumFractionDigits: 1 })}kW`;
      designValue = dcSystemSizeKw;
      break;
    case DesignConstraintType.MaxAcSystemSizeKw:
      rowLabel = "Max AC";
      getValueLabel = (value) =>
        `${formatNumber({ value, maximumFractionDigits: 1 })}kW`;
      designValue = acSystemSizeKw;
      break;
    case DesignConstraintType.MaxCecAcSystemSizeKw:
      rowLabel = "Max CEC-AC";
      getValueLabel = (value) =>
        `${formatNumber({ value, maximumFractionDigits: 1 })}kW`;
      designValue = cecAcSystemSizeKw;
      break;
    case DesignConstraintType.MaxAcBatteries:
      rowLabel = "Max Battery Count";
      getValueLabel = (value) =>
        `<= ${formatNumber({ value, maximumFractionDigits: 0 })}`;
      designValue = acBatteryCount;
      break;
    case DesignConstraintType.MinAndMaxDcSystemSizeKw:
      rowLabel = "Min < Max DC";
      getMinAndMaxValueLabel = (minValue, maxValue) =>
        [minValue, "<=", maxValue, "kW"].join(" ");
      designValue = dcSystemSizeKw;
      minConstraintType = DesignConstraintType.MinDcSystemSizeKw;
      maxConstraintType = DesignConstraintType.MaxDcSystemSizeKw;
      break;
    case DesignConstraintType.MinAndMaxOffsetPercent:
      rowLabel = "Min < Max Offset";
      getMinAndMaxValueLabel = (minValue, maxValue) => {
        const formattedMinValue = minValue
          ? `${formatNumber({
              value: minValue * 100,
              maximumFractionDigits: 0,
            })}`
          : undefined;
        const formattedMaxValue = maxValue
          ? `${formatNumber({
              value: maxValue * 100,
              maximumFractionDigits: 0,
            })}%`
          : undefined;

        return [formattedMinValue, "<=", formattedMaxValue].join(" ");
      };
      designValue = usageOffsetPercent;
      minConstraintType = DesignConstraintType.MinOffsetPercent;
      maxConstraintType = DesignConstraintType.MaxOffsetPercent;
      break;
    default:
      rowLabel = "";
      break;
  }

  const getCellDataForMinAndMaxConstraints = (
    designValue: number | undefined,
    minConstraintType: DesignConstraintType,
    maxConstraintType: DesignConstraintType,
    product: PvProduct,
    constraintsByProduct: DesignConstraintsByProduct
  ): CellData => {
    const isMinConstraintMet = designConstraints.validateDesignConstraint(
      designValue,
      minConstraintType,
      product
    );
    const isMaxConstraintMet = designConstraints.validateDesignConstraint(
      designValue,
      maxConstraintType,
      product
    );

    const minConstraint = designConstraints.getConstraintByType(
      constraintsByProduct,
      minConstraintType
    );

    const maxConstraint = designConstraints.getConstraintByType(
      constraintsByProduct,
      maxConstraintType
    );

    const constraintValueLabel = getMinAndMaxValueLabel(
      minConstraint?.value || undefined,
      maxConstraint?.value || undefined
    );

    const cellData = {
      allowed: isMinConstraintMet && isMaxConstraintMet,
      designConstraint: minConstraint,
      value: constraintValueLabel,
    };

    return cellData;
  };

  const rowData = () => {
    let data: CellData[] = [];
    products.forEach((product, index) => {
      const constraintsByProduct =
        designConstraints.getConstraintsByProduct(product);
      if (minConstraintType && maxConstraintType) {
        const cellData = getCellDataForMinAndMaxConstraints(
          designValue,
          minConstraintType,
          maxConstraintType,
          product,
          constraintsByProduct
        );
        data.push(cellData);
        return;
      }

      const constraint = designConstraints.getConstraintByType(
        constraintsByProduct,
        designConstraintType
      );

      const { value } = constraint;
      const isConstraintMet = designConstraints.validateDesignConstraint(
        designValue,
        designConstraintType,
        product
      );

      const constraintValueLabel = value ? getValueLabel(value) : undefined;
      const cellData = {
        allowed: isConstraintMet,
        designConstraint: constraint,
        value: constraintValueLabel,
      };

      data.push(cellData);
    });
    return data;
  };

  const calculatedRowData = rowData();
  const entireRowIsUndefined = calculatedRowData.every(
    ({ value }) => value === undefined
  );
  if (entireRowIsUndefined) return null;

  return (
    <TableRow>
      <TableCell sx={{ px: fullscreen ? 1 : "default" }}>
        <Typography color="primary" variant="body2">
          {rowLabel}
        </Typography>
      </TableCell>
      {calculatedRowData.map((cellData, index) => (
        <FinancialProductsCell
          key={index}
          cellData={cellData}
          fullscreen={fullscreen}
        />
      ))}
    </TableRow>
  );
};
