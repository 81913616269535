import { useCallback, useEffect } from "react";
import { useWorkspace } from "src/hooks/useWorkspace";
import { Map } from "leaflet";
import {
  WorkspaceEmptyAction,
  InputDevice,
  InteractiveLeafletLayer,
  KeyboardEventType,
  MouseButtonOrKeyboardKey,
  WorkspaceEvent,
} from "src/types/state-management/action";
import {
  createWorkspaceAction,
  makeWorkspaceEvent,
} from "../actionResolvers/workspaceEvent";
import { useMaxFill } from "./useMaxFill";
import {
  openHotKeyDescriptions,
  selectIsAnyModalOpen,
} from "src/features/modal/modalSlice";
import { deriveDesignClass } from "src/features/design/designSlice";
import { useAppDispatch, useAppSelector } from "src/store";
import { showSnackbarMessage } from "src/features/notifications/notificationSlice";
import {
  closeDesignMenu,
  toggleIsMagneticSlideEnabled,
  toggleIsMagneticSnapEnabled,
  toggleIsSunHoursVisible,
  toggleModuleOrientationSetting,
} from "src/features/settings/settingsSlice";
import { clearSelectedModules } from "src/features/moduleSelection/moduleSelectionSlice";
import { useDesignUndo } from "./useDesignUndo";

export type HotkeyAction =
  | WorkspaceEmptyAction<"keyupWindowShift">
  | WorkspaceEmptyAction<"keydownWindowShift">;

const ArrowKeys: string[] = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"];

export type UseHotkeyProps = {
  map: Map;
};

export const useHotkeys = ({ map }: UseHotkeyProps) => {
  const { state, dispatch: workspaceDispatch } = useWorkspace();
  const dispatch = useAppDispatch();
  const {handleUndoDesign} = useDesignUndo();
  const isConfigureSelectInverterOpen = useAppSelector(
    (state) => state.modal.isConfigureManualInverterOpen
  );
  const { siteModel, moduleSelection } = state;
  const { handleMaxFillAndSetDesign } = useMaxFill();
  const isAnyModalOpen = selectIsAnyModalOpen(
    state,
    isConfigureSelectInverterOpen
  );
  const design = deriveDesignClass(state);  

  const keyupEventHandler = useCallback(
    (keyupEvent: KeyboardEvent) => {
      const shift = keyupEvent.key === "Shift";
      if (shift) workspaceDispatch({ type: "keyupWindowShift" });
      if (ArrowKeys.includes(keyupEvent.key)) {
        if (design && siteModel && moduleSelection.selectedModuleIds.size > 0) {
          const keyEvent: KeyboardEventType =
            keyupEvent.key as KeyboardEventType; // type coercion: not great! FIXME
          const moduleId = Array.from(moduleSelection.selectedModuleIds)[0]; // this is arbitrary and that's ok
          const module = design!.getModuleById(moduleId)!;
          const event: WorkspaceEvent = makeWorkspaceEvent(
            module.centroid(),
            module,
            MouseButtonOrKeyboardKey.keyUp
          );
          workspaceDispatch(
            createWorkspaceAction(
              keyEvent,
              InteractiveLeafletLayer.module,
              InputDevice.Keyboard,
              event,
              map
            )
          );
        }
      }
    },
    [design, siteModel, moduleSelection, isAnyModalOpen]
  );

  const keydownEventHandler = useCallback(
    (keydownEvent: KeyboardEvent) => {
      if (isAnyModalOpen) {
        return;
      }
      const shift = keydownEvent.shiftKey;
      const ctrl = keydownEvent.ctrlKey;
      const cmd = keydownEvent.metaKey;

      const isMac = navigator.userAgent.indexOf("Mac") !== -1;
      const defaultCtrl = isMac ? cmd : ctrl;
      if (shift) {
        workspaceDispatch({ type: "keydownWindowShift" });
      } else if (defaultCtrl) {
        // control+ keys
        switch (keydownEvent.key) {
          case "h":
          case "H":
            keydownEvent.preventDefault();
            dispatch(toggleIsSunHoursVisible());
            workspaceDispatch({ type: "toggleIsSunHoursVisible" });
            break;
          case "l":
          case "L":
            keydownEvent.preventDefault();
            dispatch(toggleIsMagneticSlideEnabled());
            workspaceDispatch({ type: "toggleIsMagneticSlideEnabled" });
            break;
          case "m":
          case "M":
            keydownEvent.preventDefault();
            dispatch(toggleIsMagneticSnapEnabled());
            workspaceDispatch({ type: "toggleIsMagneticSnapEnabled" });
            break;
          case "r":
          case "R":
            keydownEvent.preventDefault();
            dispatch(toggleModuleOrientationSetting());
            workspaceDispatch({ type: "toggleModuleOrientationSetting" });
            break;
          case "z":
          case "Z":            
            keydownEvent.preventDefault();
            handleUndoDesign();
            break;
          //fillRoof
          case "f":
          case "F":
            keydownEvent.preventDefault();
            if (design && design.moduleCount > 0) {
              dispatch(
                showSnackbarMessage(
                  "Cannot fill all roof faces while modules are present. Please remove all modules and try again."
                )
              );
              workspaceDispatch({
                type: "showSnackbarMessage",
                payload:
                  "Cannot fill all roof faces while modules are present. Please remove all modules and try again.",
              });
            } else {
              handleMaxFillAndSetDesign();
            }
            break;
          case "/":
          case "?":
            dispatch(closeDesignMenu());
            workspaceDispatch({ type: "closeDesignMenu" });
            dispatch(openHotKeyDescriptions());
            workspaceDispatch({ type: "openHotKeyDescriptions" });
            break;
        }
      } else {
        // no modifiers... will have to maybe refactor again if we ever care about shift
        switch (keydownEvent.key) {
          case "r":
          case "R":
            workspaceDispatch({ type: "rotateModules" });
            break;
          case "Delete":
          case "Backspace":
            workspaceDispatch({ type: "removeModules" });
            dispatch(clearSelectedModules());
            workspaceDispatch({ type: "clearSelectedModules" }); // smell: that you must know to clear selection after dispatching "removeModules"
            break;
          case "Escape":
            dispatch(clearSelectedModules());
            workspaceDispatch({ type: "clearSelectedModules" });
            break;
          case "ArrowLeft":
          case "ArrowRight":
          case "ArrowUp":
          case "ArrowDown":
            if (
              design &&
              siteModel &&
              moduleSelection.selectedModuleIds.size > 0
            ) {
              const moduleId = Array.from(moduleSelection.selectedModuleIds)[0]; // this is arbitrary and that's ok
              const module = design!.getModuleById(moduleId)!;
              const event: WorkspaceEvent = makeWorkspaceEvent(
                module.centroid(),
                module,
                MouseButtonOrKeyboardKey.keyDown
              );
              workspaceDispatch(
                createWorkspaceAction(
                  keydownEvent.key,
                  InteractiveLeafletLayer.module,
                  InputDevice.Keyboard,
                  event,
                  map
                )
              );
            }
            break;
        }
      }
    },
    [
      design,
      siteModel,
      moduleSelection,
      handleMaxFillAndSetDesign,
      isAnyModalOpen,
      handleUndoDesign,
    ]
  );

  useEffect(() => {
    // Global keydown listener
    window.addEventListener("keydown", keydownEventHandler);
    window.addEventListener("keyup", keyupEventHandler);
    return () => {
      window.removeEventListener("keydown", keydownEventHandler);
      window.removeEventListener("keyup", keyupEventHandler);
    };
  }, [keydownEventHandler, keyupEventHandler, isAnyModalOpen]);
};
