import {add, project, sub, unitVectorFromCardinalDirectionAngleDegrees} from "../vectors/vector2D";
import {Position, positionToVector2D, vector2DToPosition} from "./point";
import {windsCounterClockwise} from "./polygon";

export const rotatedBoundingBox = (
  posA: Position,
  posB: Position,
  azimuthDegrees: number
): Position[] => {
  const vecA = positionToVector2D(posA)
  const vecB = positionToVector2D(posB)
  const strokeVec = sub(vecB, vecA)
  const azVec = unitVectorFromCardinalDirectionAngleDegrees(azimuthDegrees)
  const projection = project(strokeVec, azVec)
  const inverseProjection = sub(strokeVec, projection)
  const secondPos = vector2DToPosition(add(vecA, projection))
  const fourthPos = vector2DToPosition(add(vecA, inverseProjection))
  let polygon: Position[] = [posA, secondPos, posB, fourthPos, posA]
  if (!windsCounterClockwise(polygon)) {
    polygon = [posA, fourthPos, posB, secondPos, posA]
  }
  return polygon
}
