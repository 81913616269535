import {
  Design,
  Offer,
  Offers,
  SiteModel,
} from "@sunrun/design-tools-domain-model";
import { useCallback } from "react";
import { useWorkspace } from "./useWorkspace";
import { deriveSolarResourceClass } from "src/features/solarResource/solarResourceSlice";
import { deriveSiteModelClass } from "src/features/siteModel/siteModelSlice";
import { deriveDesignClass, setDesign } from "src/features/design/designSlice";
import { useAppDispatch } from "src/store";
import { setErrorModal } from "src/features/modal/modalSlice";

export const useMaxFill = () => {
  const { state, dispatch: workspaceDispatch } = useWorkspace();
  const dispatch = useAppDispatch()
  const { customer, designConstraints } = state;
  const siteModel = deriveSiteModelClass(state);
  const solarResource = deriveSolarResourceClass(state);
  const design = deriveDesignClass(state);

  const handleMaxFill = useCallback(
    (design: Design, loggingOnly: boolean = false) => {
      if (!siteModel || !solarResource || !customer || !designConstraints) {
        console.warn("Cannot maxfill without all required aggregates!");
        return design;
      }
      const allModules = new Set<string>(design.modules.map((m) => m.id));
      const emptyDesign = design.deleteModules(allModules);
      const customerUsageKwh = customer.annualUsagekWh || 10000; // better to guess here than fail completely
      const maxOffsetPercent =
        designConstraints.defaultConstraints?.maxOffsetPercentConstraint
          ?.value || 0;
      const maxFilledDesign = emptyDesign.attemptToFillAllRoofFacesFromCenter(
        siteModel,
        solarResource,
        state.settings.moduleOrientation,
        customerUsageKwh,
        maxOffsetPercent,
        state.settings.isMagneticSnapEnabled
      );
      const maxFillErrorMessage = `Our module placement algorithm was unable to generate a design. Please add modules to manually create your design.\n\nNote: after placing the first panel on a roof plane select that panel and click Fill Around to automatically fill that whole roof plane.`;
      if (maxFilledDesign.moduleCount === 0 && !loggingOnly) {
        console.error(
          "MaxFill Failed: The maxFilledDesign contains no modules."
        );
        dispatch(setErrorModal({
          title: "Failed to Fill Roof",
          message: maxFillErrorMessage,
        }))
        workspaceDispatch({
          type: "setErrorModal",
          payload: {
            title: "Failed to Fill Roof",
            message: maxFillErrorMessage,
          },
        });
      }
      return maxFilledDesign;
    },
    [siteModel, solarResource, customer, designConstraints]
  );

  const handleMaxFillAndSetDesign = useCallback(() => {
    if (!design) {
      console.warn("Cannot maxfill without all required aggregates!");
      return;
    }
    const maxFilledDesign = handleMaxFill(design);
    dispatch(setDesign(maxFilledDesign.getIDesign()))
    workspaceDispatch({ type: "setDesign", payload: maxFilledDesign });
  }, [design, handleMaxFill]);

  return {
    handleMaxFill,
    handleMaxFillAndSetDesign,
  };
};
