import React from 'react';
import { useLoadingScreen } from "./hooks/useLoadingScreen";
import { Box, Modal, Typography } from '@material-ui/core';
import { Spinner } from './components/Spinner';


export const LoadingScreen = () => {
    const { state } = useLoadingScreen();
    const { processGroups } = state;
    const currentGroup = processGroups.find(({processes}) => processes.some(process => !process.complete));
    if (!currentGroup) return null;
    const currentProcess = currentGroup.processes.find(subProcess => !subProcess.complete);

    return (
      <Modal open={!!currentGroup} aria-label='iHD Loading Screen'>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <SpinnerMemoized />
        <Typography variant="h4" style={{ color: "white", marginTop: "20px" }}>
          {currentGroup.name}
        </Typography>
        <Typography variant="h6" style={{ color: "white" }}>
          {currentProcess?.name}
        </Typography>
      </Box>
    </Modal>
    )
}

const SpinnerMemoized = React.memo(Spinner);
