import {Env, getEnv} from "@sunrun/design-tools-react-router-require-auth";

export interface FlagsmithConfiguration {
  enabled: boolean;
  environmentID: string;
}

export const flagsmithExports: { [key in Env]: FlagsmithConfiguration } = {
  localhost: {
    enabled: true,
    environmentID: "knsXMkWcQrJJ83vyMyS6XV",
  },
  prPreview: {
    enabled: true,
    environmentID: "knsXMkWcQrJJ83vyMyS6XV",
  },
  dev: {
    enabled: true,
    environmentID: "knsXMkWcQrJJ83vyMyS6XV",
  },
  relcert: {
    enabled: true,
    environmentID: "knsXMkWcQrJJ83vyMyS6XV",
  },
  stg: {
    enabled: true,
    environmentID: "knsXMkWcQrJJ83vyMyS6XV",
  },
  prd: {
    enabled: true,
    environmentID: "eyZgnuwbveRVcUsUC5PDre",
  },
};

export const getFlagsmithConfiguration = (): FlagsmithConfiguration => {
  const env = getEnv();
  return flagsmithExports[env];
};

// Set a local default copy when remote is not reachable
export const getDefaultFeatures = () => {
  return {
    design_tools_init_maxfill: { enabled: true },
    design_tools_create_design_images: { enabled: false },
    design_tools_enable_bookmark_design: { enabled: false },
    design_tools_enable_undo_design: { enabled: false },
    design_tools_enable_fire_setback_refresh: { enabled: false },
    design_tools_enable_financial_products_panel: { enabled: false },
    design_tools_enable_experimental_equipment: { enabled: false },
    design_tools_show_flex_info: { enabled: false },
    design_tools_display_system_message: { enabled: false },
    design_tools_enable_regenerate_designs: { enabled: false },
    design_tools_enable_keystone_optimizers: { enabled: false },
    design_tools_enable_manual_inverter_selection: { enabled: false },
    design_tools_min_import_proposal_date_pw3: { enabled: false },
    design_tools_show_icons_on_disabled_roofs: { enabled: false },
    design_tools_opt_users_into_enable_fire_setback: { enabled: false },
  };
};

export enum FlagsmithFeatureEnums {
  INIT_MAXFILL = "design_tools_init_maxfill",
  CREATE_DESIGN_IMAGES = "design_tools_create_design_images",
  ENABLE_BOOKMARK_DESIGN = "design_tools_enable_bookmark_design",
  ENABLE_UNDO_DESIGN = "design_tools_enable_undo_design",
  ENABLE_FIRE_SETBACK = "design_tools_enable_fire_setback_refresh",
  ENABLE_FINANCIAL_PRODUCTS_PANEL = "design_tools_enable_financial_products_panel",
  ENABLE_CHANGE_ORDER_DOUBLE_SIMULATE_LOGIC = "design_tools_enable_change_order_double_simulate_logic",
  ENABLE_EXPERIMENTAL_EQUIPMENT = "design_tools_enable_experimental_equipment",
  ENABLE_REGENERATE_DESIGNS = "design_tools_enable_regenerate_designs",
  SHOW_FLEX_INFO = "design_tools_show_flex_info",
  DISPLAY_SYSTEM_MESSAGE = "design_tools_display_system_message",
  ENABLE_KEYSTONE_OPTIMIZERS = "design_tools_enable_keystone_optimizers",
  ENABLE_MANUAL_INVERTER_SELECTION = "design_tools_enable_manual_inverter_selection",
  MIN_IMPORT_PROPOSAL_DATE_PW3 = "design_tools_min_import_proposal_date_pw3",
  SHOW_ICONS_ON_DISABLED_ROOFS = "design_tools_show_icons_on_disabled_roofs",
  OPT_USERS_INTO_DYNAMIC_FIRE_SETBACK = "design_tools_opt_users_into_enable_fire_setback",
}

export type AppAlert = {
  title?: string;
  content?: string;
};
