import React from "react";
import { useQuery } from "react-query";
import { useLoadingScreen } from "@sunrun/design-tools-loading-screen";
import { LoadingProcessGroups, LoadingProcessNames } from "src/types/LoadingScreenProcess";
import { useWorkspace } from "src/hooks/useWorkspace";
import { tsaClient } from "@sunrun/design-tools-graphql-clients";
import { SiteSurvey } from "@sunrun/site-survey-graphql";
import { useAppDispatch } from "src/store";
import { setSiteSurvey } from "src/features/siteSurvey/siteSurveySlice";

export const useSiteSurvey = () => {
  const { helpers: loadingScreenHelpers } = useLoadingScreen();
  const { state } = useWorkspace();
  const dispatch = useAppDispatch()
  const prospectId = state.siteModel?.prospectId;

  const getSiteSurvey = async (): Promise<SiteSurvey | undefined> => {
    if (!prospectId) {
      throw Error("getSiteSurvey should be disabled when prospectId is undefined.");
    }
    try {
      const siteSurvey = await tsaClient.getSiteSurvey(prospectId);
      return siteSurvey;
    } catch (e) {
      // Swallow errors - this is an optional query
      // This avoids the error boundary thrown by the top level QueryClientProvider
      console.warn(`Unable to retrieve SiteSurvey`);
    }
  };

  const query = useQuery(['getSiteSurvey', prospectId], getSiteSurvey, {
    refetchOnWindowFocus: false,              // TODO support these use cases for conflict resolution
    refetchOnReconnect: false,
    enabled: !!prospectId,                   // https://react-query.tanstack.com/guides/dependent-queries
    onSuccess: (siteSurvey: SiteSurvey | undefined) => {
      if (siteSurvey) {
        dispatch(setSiteSurvey(siteSurvey));
      }
    },
  });

  React.useEffect(function addLoadingScreenProcess() {
    if (query.isFetching) {
      loadingScreenHelpers.addProcess({
        name: LoadingProcessNames.SITE_SURVEY,
        group: LoadingProcessGroups.INITIALIZE_IHD,
      });
      return function completeLoadingScreenProcess() {
        loadingScreenHelpers.completeProcess(LoadingProcessNames.SITE_SURVEY);
      };
    }
  }, [query.isFetching]);
}
