// All query string keys used in iHD (enum for referencing key names in code)
export enum URLSearchParameterKey {
  ApiUrl = "apiUrl",
  AllowEditEquipment = "allowEditEquipment",
  DesignId = "designId",
  DesignVersion = "designVersion",
  EffectiveDate = "effectiveDate",
  EnvOverride = "envOverride",
  EPermitting = "ePermitting",
  Host = "host",
  InterconnectionAppliedDesignId = "interconnectionAppliedDesignId",
  InterconnectionAppliedProposalId = "interconnectionAppliedProposalId",
  LightmileDesignIndex = "lightmileDesignIndex",
  LightmileProjectId = "lightmileProjectId",
  OfferId = "offerId",
  ParentProposalId = "parentProposalId",
  ProspectId = "prospectId",
  SignedRootDesignId = "signedRootDesignId",
  SignedRootId = "signedRootId",
  SignedRootIdDashProduct = "signedRootIdDashProduct",
  CopyFromProposalId = "copyFromProposalId",
  CopyFromDesignId = "copyFromDesignId",
}

// Query string keys used by iHD Workspace route (for type checking only)
export type WorkspaceURLSearchParameterKey =
  | URLSearchParameterKey.ApiUrl
  | URLSearchParameterKey.AllowEditEquipment
  | URLSearchParameterKey.DesignId
  | URLSearchParameterKey.EffectiveDate
  | URLSearchParameterKey.EnvOverride
  | URLSearchParameterKey.EPermitting
  | URLSearchParameterKey.Host
  | URLSearchParameterKey.InterconnectionAppliedDesignId
  | URLSearchParameterKey.LightmileProjectId
  | URLSearchParameterKey.OfferId
  | URLSearchParameterKey.ParentProposalId
  | URLSearchParameterKey.SignedRootDesignId
  | URLSearchParameterKey.SignedRootId
  | URLSearchParameterKey.SignedRootIdDashProduct
  | URLSearchParameterKey.CopyFromProposalId
  | URLSearchParameterKey.CopyFromDesignId
