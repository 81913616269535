import CanvasOverlay from 'react-leaflet-canvas-overlay';
import {useMap, useMapEvent} from "react-leaflet"
import {SiteModel, LightmileSolarResource, SolarResource} from '@sunrun/design-tools-domain-model';
import {useWebGL} from 'src/hooks/useWebGL';
import {useEffect} from 'react';
import {useWorkspace} from "src/hooks/useWorkspace";
import { useAppDispatch } from 'src/store';
import { setHeatMapBounds } from 'src/features/heatMap/heatMapSlice';


type heatMapProps = {
  solarResource: SolarResource
  siteModel: SiteModel
};
// when using webGL with React, we have a problem!
// we want render(props) to be a pure function of props -
// but WebGL doesn't work like that - its a state machine!
// TLDR - we dont want to regenerate the buffers for geometry every time we
// pan or zoom - only when the data in the solarResource[] props changes!

const HeatMapLayer = (props: heatMapProps) => {
  const map = useMap();
  const { state, dispatch: workspaceDispatch } = useWorkspace();
  const dispatch = useAppDispatch()
  const w = map.getContainer().clientWidth;
  const h = map.getContainer().clientHeight;
  useMapEvent('zoomend', () => {
    const bounds = map.getBounds();
    //dispatch(setHeatMapBounds(bounds))   //TODO: https://sunrun.jira.com/browse/ET-1617 
    workspaceDispatch({type: 'setHeatMapBounds', payload: bounds});
  });
  useMapEvent('moveend', () => {
    const bounds = map.getBounds();
    //dispatch(setHeatMapBounds(bounds))
    workspaceDispatch({type: 'setHeatMapBounds', payload: bounds});
  });

  const {mngr} = useWebGL(state.heatMap.bounds || map.getBounds(), props.siteModel, props.solarResource);
  useEffect(() => {
    return () => {
      if (mngr) {
        console.log('clean up gl stuff, because this component is about to be unmounted?')
        mngr.destroy();
      }
    }
  }, [mngr]);

  if (mngr) {
    mngr.draw(state.heatMap.bounds  || map.getBounds(), state.heatMap.isVisible, props.solarResource.siteMaxSunHours);
    return (<CanvasOverlay canvas={mngr.getCanvas()!} bounds={state.heatMap.bounds || map.getBounds()} width={w} height={h}/>)
  }
  return (<></>)
}

export default HeatMapLayer;
