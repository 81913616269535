import * as React from "react";
import { equipmentAvailabilityClient } from "@sunrun/design-tools-graphql-clients";
import {
  EquipmentSpecifications,
  KeystoneEquipmentUtils,
  jsonToEquipmentSpecsPartial,
  mergeEquipmentSpecs,
  manualOverrideCompatibleOptimizers,
} from "@sunrun/design-tools-domain-model";
import { useQuery } from "react-query";
import { useWorkspace } from "./useWorkspace";
import { parseLambdaError } from "../utils/lambdaErrorParser";
import { useErrorHandler } from "react-error-boundary";
import { LoadingProcessGroups, LoadingProcessNames } from "../types/LoadingScreenProcess";
import { useLoadingScreen } from "@sunrun/design-tools-loading-screen";
import { useSearchParams } from "react-router-dom";
import { URLSearchParameterKey } from "src/types/URLSearchParameterKey";
import {
  setAvailableEquipment,
  setAvailableEquipmentIfNewOffer,
  setKeystoneEquipment,
  setExperimentalEquipmentSpecs,
} from "src/features/equipment/availableEquipmentSlice";
import { deriveDesignConstraintsClass } from "src/features/designConstraints/designConstraintsSlice";
import { useAppDispatch } from "src/store";
import { KeystoneEquipment, keystoneClient } from "@sunrun/design-tools-keystone-client";
import { useFlags } from "flagsmith/react";
import { FlagsmithFeatureEnums } from "src/config/flagsmithConfig";

type AvailableEquipmentResponse = {
  availableEquipment: EquipmentSpecifications;
  availableEquipmentIfNewOffer: EquipmentSpecifications;
  keystoneEquipment: KeystoneEquipment[];
};

export const useAvailableEquipment = () => {
  const { state, dispatch: workspaceDispatch } = useWorkspace();
  const dispatch = useAppDispatch();
  const { offer, workflowState } = state;
  const designConstraints = deriveDesignConstraintsClass(state);
  const { helpers: loadingScreenHelpers } = useLoadingScreen();
  const handleError = useErrorHandler();
  const [searchParams] = useSearchParams();
  const experimentalEquipmentFlag = useFlags([FlagsmithFeatureEnums.ENABLE_EXPERIMENTAL_EQUIPMENT])[
    FlagsmithFeatureEnums.ENABLE_EXPERIMENTAL_EQUIPMENT
  ];
  const keystoneOptimizerFlagEnabled = useFlags([FlagsmithFeatureEnums.ENABLE_KEYSTONE_OPTIMIZERS])[
    FlagsmithFeatureEnums.ENABLE_KEYSTONE_OPTIMIZERS
  ].enabled;
  const getAvailableEquipment = async () => {
    if (!offer || !offer.opportunity || !designConstraints) {
      throw Error(`An offer and design constraints are required to query available equipment`);
    }

    // parentProposalId denotes a change order and grants access to an expanded list of equipment (change order list of AVL filters)
    // ePermittingAuthorityPresent is a permission that grants access to a larger expanded list of equipment (epermitting list of AVL filters)
    // Users need access to the expanded list of equipment BUT our users want to default to the original market equipment for a new offer
    // solution : make 2 calls. expanded change order available equipment and default (original market equipment for a new offer)
    const ePermittingAuthorityPresent =
      searchParams.get(URLSearchParameterKey.EPermitting) === "true" ? true : false;
    const parentProposalId = searchParams.get(URLSearchParameterKey.ParentProposalId);

    const availableEquipmentPromise = equipmentAvailabilityClient.getAvailableEquipment(
      offer,
      designConstraints,
      ePermittingAuthorityPresent,
      parentProposalId
    );
    // if ePermittingAuthorityPresent and parentProposalId are falsey set availableEquipmentIfNewOfferPromise to undefined to avoid an unnecessary call to lightmile
    const availableEquipmentIfNewOfferPromise =
      ePermittingAuthorityPresent || parentProposalId
        ? equipmentAvailabilityClient.getAvailableEquipment(offer, designConstraints, false)
        : undefined;
    const keystoneEquipmentPromise = keystoneClient.getAllEquipmentBySellingRules(
      offer.opportunity
    );
    const availableEquipment = await Promise.resolve(availableEquipmentPromise);
    const availableEquipmentIfNewOffer = await Promise.resolve(availableEquipmentIfNewOfferPromise);
    const keystoneEquipment = await Promise.resolve(keystoneEquipmentPromise);
    console.log("Equipment from Keystone:", keystoneEquipment);
    console.log(
      `getAvailableEquipment complete. ePermittingAuthorityPresent: ${ePermittingAuthorityPresent}, parentProposalId: ${parentProposalId}`
    );

    let finalAvailableEquipment: EquipmentSpecifications | undefined = availableEquipment;
    let finalAvailableEquipmentIfNewOffer: EquipmentSpecifications | undefined =
      availableEquipmentIfNewOffer;

    if (experimentalEquipmentFlag.enabled && typeof experimentalEquipmentFlag.value === "string") {
      console.warn(
        "experimentalEquipmentFlag enabled. There may be equipment on the design that will be blocked from proceeding to pricing."
      );
      const experimentalEquipmentSpecs = jsonToEquipmentSpecsPartial(
        experimentalEquipmentFlag.value
      );
      dispatch(setExperimentalEquipmentSpecs(experimentalEquipmentSpecs));

      finalAvailableEquipment = mergeEquipmentSpecs(availableEquipment, experimentalEquipmentSpecs);
      finalAvailableEquipmentIfNewOffer = mergeEquipmentSpecs(
        availableEquipmentIfNewOffer,
        experimentalEquipmentSpecs
      );
    }

    if (keystoneOptimizerFlagEnabled) {
      console.warn(
        "Keystone optimizer flag is enabled. New keystone optimizer spec is enabled. These will be added on to the design schema manually."
      );
      // ET-1834: For manual optimizer selection, we need to pass the spec from keystone to LM. We manually hardcode append this spec to the design
      // so it can be used in the adapter to pass to LM on the SDP. In order to do that, we need to translate to DT schema for optimizers.
      const translatedKeystoneOptimizersForAvailableEquipment =
        KeystoneEquipmentUtils.translateOptimizerKeystoneEquipmentToDesignSchema(
          keystoneEquipment,
          availableEquipment
        );
      const translatedKeystoneOptimizersForAvailableEquipmentIfNewOffer =
        KeystoneEquipmentUtils.translateOptimizerKeystoneEquipmentToDesignSchema(
          keystoneEquipment,
          availableEquipmentIfNewOffer
        );

      // This mutates the inverter specs to have it appended to the compatiblePowerOptimizerSpecification list and also
      // adds it to the equipment specifications.
      const overrideSpecsForAvailableEquipment: Partial<EquipmentSpecifications> = {
        inverterSpecifications: manualOverrideCompatibleOptimizers(
          finalAvailableEquipment?.inverterSpecifications,
          translatedKeystoneOptimizersForAvailableEquipment
        ),
        powerOptimizerSpecifications: translatedKeystoneOptimizersForAvailableEquipment,
      };

      const overrideSpecsForAvailableEquipmentIfNewOffer: Partial<EquipmentSpecifications> = {
        inverterSpecifications: manualOverrideCompatibleOptimizers(
          finalAvailableEquipmentIfNewOffer?.inverterSpecifications,
          translatedKeystoneOptimizersForAvailableEquipmentIfNewOffer
        ),
        powerOptimizerSpecifications: translatedKeystoneOptimizersForAvailableEquipmentIfNewOffer,
      };
      // This adds the translated power optimizers to the specs
      finalAvailableEquipment = mergeEquipmentSpecs(
        finalAvailableEquipment,
        overrideSpecsForAvailableEquipment
      );
      finalAvailableEquipmentIfNewOffer = mergeEquipmentSpecs(
        finalAvailableEquipmentIfNewOffer,
        overrideSpecsForAvailableEquipmentIfNewOffer
      );
    }

    return {
      availableEquipment: finalAvailableEquipment,
      availableEquipmentIfNewOffer: finalAvailableEquipmentIfNewOffer,
      keystoneEquipment,
    };
  };

  const query = useQuery(
    ["getAvailableEquipment", offer, designConstraints],
    getAvailableEquipment,
    {
      refetchOnWindowFocus: false, // TODO support these use cases for conflict resolution
      refetchOnReconnect: false,
      enabled: !!offer && !!designConstraints && !workflowState.hasAvailableEquipmentLoaded,
      onSuccess: (response: AvailableEquipmentResponse) => {
        dispatch(setAvailableEquipment(response.availableEquipment));
        dispatch(setAvailableEquipmentIfNewOffer(response.availableEquipmentIfNewOffer));
        dispatch(setKeystoneEquipment(response.keystoneEquipment));
        workspaceDispatch({
          type: "setAvailableEquipment",
          payload: response.availableEquipment,
        });
        workspaceDispatch({
          type: "setAvailableEquipmentIfNewOffer",
          payload: response.availableEquipmentIfNewOffer,
        });
        workspaceDispatch({ type: "availableEquipmentLoaded" });
      },
      onError: (error: Error) => {
        const parsedError = parseLambdaError(
          error,
          new Error("Failed to retrieve available equipment.")
        );
        handleError(parsedError);
      },
    }
  );

  React.useEffect(
    function addLoadingScreenProcess() {
      if (query.isFetching) {
        loadingScreenHelpers.addProcess({
          name: LoadingProcessNames.AVAILABLE_EQUIPMENT,
          group: LoadingProcessGroups.INITIALIZE_IHD,
        });
        return function completeLoadingScreenProcess() {
          loadingScreenHelpers.completeProcess(LoadingProcessNames.AVAILABLE_EQUIPMENT);
        };
      }
    },
    [query.isFetching]
  );
};
