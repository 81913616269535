import {G2Vector3D} from "./vector3D";
import {G2Vector2D} from "./vector2D";
import { degreesFromRadians, roundToDecimalPlaces } from "./util";

export type PlaneEquation = {
  normal: G2Vector3D; // <x,y,z>
  // D=Ax+By+Cz where <a,b,c> is any point on the plane
  D: number;
}

export class G2Plane {
  //a 3d plane is defined by 3 point in counter clockwise order (so we know it normal)
  //or a normal vector and one point
  readonly normalVector: G2Vector3D;
  readonly anyPointOnPlane: G2Vector3D;

  constructor(normalVector: G2Vector3D, anyPointOnPlane: G2Vector3D) {
    this.normalVector = normalVector
    this.anyPointOnPlane = anyPointOnPlane
  }

  static fromThreePointsCCW(threePointsCCW: G2Vector3D[]): G2Plane {
    const normal = G2Vector3D.normalOfPoints(threePointsCCW[0], threePointsCCW[1], threePointsCCW[2])
    return new G2Plane(normal, threePointsCCW[0])
  }

  public isXAxisOnPlane(xAxis: G2Vector3D): boolean {
    //has to make sure the passed in xAxis is perpendicular to this.normalVector
    const normalCrossXAxis = this.normalVector.cross(xAxis)
    const degreesFromNormalToXAxis = degreesFromRadians(this.normalVector.anglesCounterClockwiseInRadiansToVectorAroundAxis(xAxis, normalCrossXAxis))
    return roundToDecimalPlaces(degreesFromNormalToXAxis) == 90
  }

  public isFlat(): boolean {
    return this.normalVector.equals(new G2Vector3D(0,0,1))
  }

  //for non flat plane, we could infer xAxis hence its ok to not pass in xAsis, but if a xAxis is passed in, it has to be on the plane
  //for     flat plane, we could not infer xAxis, hence you have to pass in xAxis
  public getAzimuthInDegreesClockwiseFromNorth(xAxis: G2Vector3D | null = null): number {
    if (xAxis != null && !this.isXAxisOnPlane(xAxis)) {
      throw Error("the xAxis is NOT on the plane hence it is NOT a valid xAxis")
    }
    if (this.isFlat() && xAxis == null) {
      throw Error("to calculate flat plane azimuth, you must pass in xAxis")
    }
    //now we are good, either plane is not flat, or we have the passed-in xAxis on plane
    const north = new G2Vector3D(0,1,0)
    const up = new G2Vector3D(0,0,1)
    const useXAxis = xAxis == null ? up.cross(this.normalVector).normalize() : xAxis
    const yAxis = this.normalVector.cross(useXAxis).normalize()
    const azimuthDir = yAxis.scale(-1)
    const azimuthDirWithZeroZ = new G2Vector3D(
      azimuthDir.x,
      azimuthDir.y,
      0
    )
    const azimuthDegrees = degreesFromRadians(azimuthDirWithZeroZ.anglesCounterClockwiseInRadiansToVectorAroundAxis(north, up))
    return azimuthDegrees >= 0? azimuthDegrees : 360 + azimuthDegrees
  }

  //this is just pitch but we are in this general purpose geometric package so we avoid using pitch here
  public getDegreesToHorizontalPlane(): number {
    let res: number = 0
    const up = new G2Vector3D(0,0,1)
    if (!this.isFlat()) {
      const xAxis = up.cross(this.normalVector).normalize()
      res = degreesFromRadians(up.anglesCounterClockwiseInRadiansToVectorAroundAxis(this.normalVector, xAxis))
    }
    return res
  }

  public getAnyPointOnPlane(): G2Vector3D {
    return this.anyPointOnPlane
  }

  public getEquation(): PlaneEquation {
    // first, we need the normal (x,y,z):
    const normal = this.normalVector;
    // then from anyPointOnRoofPlane, we can calculate D
    const D = normal.dot(this.anyPointOnPlane);
    return {normal, D};
  }

  public getZAtPoint2D(point: G2Vector2D): number {
    // re-arrange the plane eq to be a fn of x and y
    // Cz = (D - Ax) - By
    // z = ((D- Ax)-By)/C
    const planeEquation = this.getEquation()
    const {x: A, y: B, z: C} = planeEquation.normal;
    if (Math.abs(C) < 0.001) {
      throw Error("given plane is vertical, and thus no function Z(x,y) can exist");
    }
    const {D} = planeEquation;
    return ((D - (A * point.x)) - (B * point.y)) / C;
  }

  public getPoint3DAtPoint2D(point: G2Vector2D): G2Vector3D {
    return new G2Vector3D(
      point.x,
      point.y,
      this.getZAtPoint2D(point)
    )
  }
}
