import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {WorkspacePayloadAction} from "../../types/state-management/action";
import {Customer} from "@sunrun/design-tools-domain-model";

type CustomerState = {
  value: Customer | undefined;
}

const initialState: CustomerState = {
  value: undefined,
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomer: (state: CustomerState, action: PayloadAction<Customer>) => {
      state.value = action.payload
    },
  }
});

export const { setCustomer } = customerSlice.actions
export default customerSlice.reducer

// TODO: Remove the following and transition fully from WorkspaceState to RTK store
export type CustomerWorkspaceAction = WorkspacePayloadAction<Customer, 'setCustomer'>

export const customerWorkspaceReducer = (state: WorkspaceState, action: WorkspaceAction): Customer | undefined => {
  switch (action.type) {
    case 'setCustomer': {
      return action.payload
    }
    default: {
      return state.customer
    }
  }
}

export const selectCustomer = (state: WorkspaceState) => state.customer
