import { G2Vector3D } from "./vector3D";
/**
 * See docs/concepts/Concept-3D-Math.md for more information about the math used here.
 * Quaternions are special 4-dimensional vectors with homogeneous coordinates useful for efficiently performing 3D
 * rotations. See https://en.wikipedia.org/wiki/Quaternion.  This class is expected to be used for creating a special
 * class of quaternions for 3D rotation. If you're not rotating some geometry around an axis in a 3D model you
 * probably don't need to be using it.
 *
 * Much of the code here is from https://github.com/infusion/Quaternion.js, as specifically indicated for each
 * Quaternion method.
 */
export class G2Quaternion {
  w: number;
  x: number;
  y: number;
  z: number;

  constructor(w: number, x: number, y: number, z: number) {
    this.w = w;
    this.x = x;
    this.y = y;
    this.z = z;
  }

  static forPosition(x: number, y: number, z: number): G2Quaternion {
    return new G2Quaternion(0, x, y, z)
  }

  static noRotation(): G2Quaternion {
    // return the identity quaternion
    return new G2Quaternion(1, 0, 0, 0,)
  }

  /**
   * Construct a rotation quaternion from an axis of rotation (represented as a 3D vector) and an angle of rotation
   * @param axis
   * @param rotationRadians
   */
  static fromAxisAngle(axis: G2Vector3D, rotationRadians: number): G2Quaternion {
    // from https://github.com/infusion/Quaternion.js/blob/master/quaternion.js#L943-L959
    const halfAngle = rotationRadians * 0.5;
    const a = axis.x;
    const b = axis.y;
    const c = axis.z;
    const sin_2 = Math.sin(halfAngle);
    const cos_2 = Math.cos(halfAngle);
    const sin_norm = sin_2 / Math.sqrt(a * a + b * b + c * c);
    return new G2Quaternion(cos_2, a * sin_norm, b * sin_norm, c * sin_norm);
  }

  public rotatePosition(vx:number,vy:number,vz:number): [number,number,number] {
    const {x:qx,y:qy,z:qz,w:qw}=this;

    // t = 2q x v
    var tx = 2 * (qy * vz - qz * vy);
    var ty = 2 * (qz * vx - qx * vz);
    var tz = 2 * (qx * vy - qy * vx);

    // v + w t + q x t
    return [
      vx + qw * tx + qy * tz - qz * ty,
      vy + qw * ty + qz * tx - qx * tz,
      vz + qw * tz + qx * ty - qy * tx
    ]
  }

  public rotateVector(v: G2Vector3D): G2Vector3D {
    const [x,y,z]=this.rotatePosition(v.x,v.y,v.z);
    return new G2Vector3D(x,y,z);
  }
  
  /**
   * Calculates the Hamilton product of two quaternions. See https://en.wikipedia.org/wiki/Quaternion#Hamilton_product
   * @param q: the quaternion to multiply by
   */
  public multiply(q: G2Quaternion): G2Quaternion {
    // from https://github.com/infusion/Quaternion.js/blob/master/quaternion.js#L366-L399
    const w1 = this.w;
    const x1 = this.x;
    const y1 = this.y;
    const z1 = this.z;

    const w2 = q.w;
    const x2 = q.x;
    const y2 = q.y;
    const z2 = q.z;

    return new G2Quaternion(
      w1 * w2 - x1 * x2 - y1 * y2 - z1 * z2,
      w1 * x2 + x1 * w2 + y1 * z2 - z1 * y2,
      w1 * y2 + y1 * w2 + z1 * x2 - x1 * z2,
      w1 * z2 + z1 * w2 + x1 * y2 - y1 * x2
    );
  }

  /**
   * Generates a new quaternion that is the inverse (a.k.a. conjugate) of this one.
   * See https://en.wikipedia.org/wiki/Quaternion#Conjugation,_the_norm,_and_reciprocal.
   * This
   */
  invert() {
    // from https://github.com/infusion/Quaternion.js/blob/master/quaternion.js#L431-L462

    // The inverse of a quaternion is obtained by negating the imaginary components
    // Q' = [s, -v]

    // don't assume Q is normalized
    const normSq = this.w * this.w + this.x * this.x + this.y * this.y + this.z * this.z;
    return new G2Quaternion(this.w / normSq, -this.x / normSq, -this.y / normSq, -this.z / normSq);
  }
}

