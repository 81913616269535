import {G2Vector3D} from "./vector3D";
import {roundToDecimalPlaces} from "./util";

//3d line, rename to G2Line3D?
export class G2Line {
  //a 3d line in space: line passes through the point (x0,y0,z0) and is traveling in the direction (a,b,c)
  //(x,y,z)=(x0,y0,z0)+t(a,b,c)
  readonly point: G2Vector3D;  //x0
  readonly anotherPoint: G2Vector3D | null;

  readonly dir: G2Vector3D;    //t

  constructor(point: G2Vector3D, dir: G2Vector3D, anotherPoint: G2Vector3D | null = null) {
    this.point = point           //(x0,y0,z0)
    this.anotherPoint = anotherPoint
    this.dir = dir.normalize()   //dir is not a good name, its just (a,b,c), we have a undirected line here
  }

  static fromTwoPoints(point1: G2Vector3D, point2: G2Vector3D): G2Line {
    return new G2Line(
      point1,
      point2.sub(point1).normalize(),
      /*new G2Vector3D(
          point2.x - point1.x,
          point2.y - point1.y,
          point2.z - point1.z
      ).normalize(),*/
      point2
    )
  }

  //come up with another representation of the same line with different point
  //we do this because line1.intersectAt(line2) can not handle line1.point on line2, so we can do line1.morph() first
  //to make sure line.point is not on line2
  public morph(t: number): G2Line {
    return new G2Line(
      this.getAPointOnLine(t),
      this.dir
    )
  }

  public getAPointOnLine(t: number): G2Vector3D {
    return this.point.add(this.dir.scale(t))
  }

  public contains(anotherPoint: G2Vector3D): boolean {
    if (anotherPoint.equals(this.point)) {
      return true
    } else {
      const pointToAnotherPoint = G2Line.fromTwoPoints(this.point, anotherPoint)
      //dir is normal vector with length 1
      //and we know:
      //   vectorA dot vectorB
      // = vectoA.length() * vectorB.length() * cos(theta)
      // = 1 * 1 * cos(theta)
      // = cos(theta)
      //cos(0) == 1, cos(180) == -1
      const dirsDot = roundToDecimalPlaces(this.dir.dot(pointToAnotherPoint.dir))
      return dirsDot === 1 || dirsDot === -1
    }
  }

  public containsBetweenTwoPointsOnLine(anotherPoint: G2Vector3D, point1 = this.point, point2 = this.anotherPoint): boolean {
    const a = this.contains(anotherPoint)
    const b = this.contains(point1)
    const c = this.contains(point2!)
    const anotherPointSubPoint1 = anotherPoint.sub(point1)
    const anotherPointSubPoint2 = anotherPoint.sub(point2!)
    const d = anotherPoint.sub(point1).parallelAndOppositeDirTo(anotherPoint.sub(point2!))
    const e = anotherPointSubPoint1.isZeroVector()
    const f = anotherPointSubPoint2.isZeroVector()
    return a && b && c && (e || f || d)
  }

  //this will intersect regardless of line segment
  //https://web.archive.org/web/20180927042445/http://mathforum.org/library/drmath/view/62814.html
  public intersectAt(line: G2Line): G2Vector3D | null {
    //two overlapping parallel lines are just the same line, treat the same line intersect with itself as empty
    if (line.contains(this.point) && this.contains(line.point) && (line.dir.parallelTo(this.dir))) {
      return null
    }
    //intersectAt can not handle case when this.point is on line, if it is, we can morph this a bit
    if (line.contains(this.point)) {
      return this.morph(3).intersectAt(line)
    }
    if (this.contains(line.point)) {
      return this.intersectAt(line.morph(3))
    }
    //1: calculate a:
    //a (V1 X V2) = (P2 - P1) X V2
    const V1 = this.dir
    const V2 = line.dir
    const P1 = this.point
    const P2 = line.point
    const V1CrossV2 = V1.cross(V2)
    const P2SubP1CrossV2 = P2.sub(P1).cross(V2)
    //If the lines intersect at a single point, then:
    //the resultant vectors on each side of this equation must be parallel, and the left side must not be the zero vector
    if (V1CrossV2.parallelTo(P2SubP1CrossV2) && !V1CrossV2.isZeroVector()) {
      const a = (P2SubP1CrossV2.length() / V1CrossV2.length()) * (
        V1CrossV2.parallelAndSameDirTo(P2SubP1CrossV2) ? 1 : -1
      )
      return P1.add(V1.scale(a))
    } else {
      return null
    }
  }

  public intersectAtSegment(point1: G2Vector3D, point2: G2Vector3D): G2Vector3D | null {
    const lineOfSegment = G2Line.fromTwoPoints(point1, point2)
    const intersectAtPoint = this.intersectAt(lineOfSegment)
    return intersectAtPoint == null ? null :
      lineOfSegment.containsBetweenTwoPointsOnLine(intersectAtPoint, point1, point2) ? intersectAtPoint : null
  }
}
