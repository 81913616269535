import { Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import {
  Battery,
  DesignConstraintType,
  Inverter,
  isHybridBattery,
  DesignEquipmentUtils,
  Optimizer,
} from "@sunrun/design-tools-domain-model";
import StatDisplay, { StatDisplayProps } from "src/components/StatDisplay";
import { deriveIsDesignFinalized } from "src/features/designGuidance/deriveIsDesignFinalized";
import { deriveLowResProductionSimulation } from "src/features/lowResProductionSimulation/deriveLowResProductionSimulation";
import { useWorkspace, WorkspaceAction } from "src/hooks/useWorkspace";
import { countBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import {
  deriveDesignClass,
  selectAllowEditBatteryCount,
  selectDesignIsUsingHybridBatteries,
} from "src/features/design/designSlice";
import { selectHost } from "src/features/host/hostSlice";
import { IFrameHostType } from "src/types/IFrame";
import { deriveDesignConstraintsClass } from "src/features/designConstraints/designConstraintsSlice";
import { deriveSolarResourceClass } from "src/features/solarResource/solarResourceSlice";
import { deriveSiteModelClass } from "src/features/siteModel/siteModelSlice";
import { deriveProductionSimulationClass } from "src/features/productionSimulation/productionSimulationSlice";
import { useAppDispatch, useAppSelector } from "src/store";
import {
  openSelectManualInverterModal,
  openSelectStorageModal,
} from "src/features/modal/modalSlice";
import { selectIsAffiliate } from "src/features/offer/offerSlice";

export const DesignDetails = () => {
  const { state, dispatch: workspaceDispatch } = useWorkspace();
  const dispatch = useAppDispatch();
  const { customer } = state;
  const theme = useTheme();
  const isToolbarSize1 = useMediaQuery(theme.breakpoints.down("toolbar1"));
  const isToolbarSize2 = useMediaQuery(theme.breakpoints.down("toolbar2"));
  const isToolbarSize3 = useMediaQuery(theme.breakpoints.down("toolbar3"));
  const isToolbarSize4 = useMediaQuery(theme.breakpoints.down("toolbar4"));
  const designFinalized = deriveIsDesignFinalized(state);
  const solarResource = deriveSolarResourceClass(state);
  const designConstraints = deriveDesignConstraintsClass(state);
  const siteModel = deriveSiteModelClass(state);
  const design = deriveDesignClass(state);
  const productionSimulation = deriveProductionSimulationClass(state);
  const designIsUsingHybridBatteries = selectDesignIsUsingHybridBatteries(state);
  const simulationToUse = designFinalized
    ? productionSimulation
    : deriveLowResProductionSimulation(state);
  const hasMaxAcConstraint =
    designConstraints?.getConstraintValueRaw(DesignConstraintType.MaxAcSystemSizeKw) != undefined;
  const hasMaxCecAcConstraint =
    designConstraints?.getConstraintValueRaw(DesignConstraintType.MaxCecAcSystemSizeKw) !=
    undefined;
  const sunHours = solarResource?.siteMaxSunHours.toFixed(0);
  const isAffiliate = selectIsAffiliate(state);

  const allowEditEquipment =
    selectHost(state) === IFrameHostType.LIGHTMILE_OFFER_BUILDER || isAffiliate;
  const allowEditBatteryCount = selectAllowEditBatteryCount(state);

  const [moduleStatDisplay, setModuleStatDisplay] = useState<StatDisplayProps | undefined>();
  const [inverterStatDisplay, setInverterStatDisplay] = useState<StatDisplayProps | undefined>();
  const [optimizerStatDisplay, setOptimizerStatDisplay] = useState<StatDisplayProps | undefined>();
  const [batteryStatDisplay, setBatteryStatDisplay] = useState<StatDisplayProps | undefined>();
  const [hybridBatteryStatDisplay, setHybridBatteryStatDisplay] = useState<
    StatDisplayProps | undefined
  >();
  const [extensionBatteryStatDisplay, setExtensionBatteryStatDisplay] = useState<
    StatDisplayProps | undefined
  >();
  const [designEquipmentStatDisplays, setDesignEquipmentStatDisplays] = useState<
    StatDisplayProps[]
  >([]);
  const [equipmentList, setEquipmentList] = useState<StatDisplayProps[]>([]);
  const [designList, setDesignList] = useState<StatDisplayProps[]>([]);
  const keystoneEquipment = useAppSelector((state) => state.availableEquipment.keystoneEquipment);
  const selectedInvertersWithCounts = useAppSelector(
    (state) => state.selectedEquipment.selectedInverters
  );

  function createOnEditClickObj(allowOnEditClick: boolean | undefined, action: WorkspaceAction) {
    return allowOnEditClick ? { onEditClick: () => workspaceDispatch(action) } : {};
  }

  // derive moduleStatDisplay
  useEffect(() => {
    const manufacturer = design?.getModuleManufacturer();
    const model = design?.getModuleModel();
    const newModuleStatDisplay: StatDisplayProps = {
      title: "Modules",
      stat: `${design?.moduleCount} x ${manufacturer}: ${model}`,
      ...createOnEditClickObj(allowEditEquipment, {
        type: "openSelectModuleModal",
      }),
    };
    setModuleStatDisplay(newModuleStatDisplay);
  }, [
    design?.moduleCount,
    design?.selectedEquipmentSpecificationIds?.selectedModuleSpecificationIds,
    design?.equipmentSpecifications,
  ]);

  // derive inverterStatDisplay
  useEffect(() => {
    if (designIsUsingHybridBatteries) {
      setInverterStatDisplay(undefined);
      return;
    }
    const inverters: Inverter[] | undefined = design?.inverters;

    const title = designFinalized ? "Inverter" : "Inverter Manufacturer";
    let stat = undefined;
    if (inverters === undefined || inverters.length <= 0) {
      // set stat to undefined
    } else if (designFinalized) {
      const inverterCounts = countBy(
        inverters,
        (inverter: Inverter) => inverter.properties.inverterSpecificationId
      );
      stat = Object.entries(inverterCounts)
        .map(([specId, count]) => {
          const manufacturer = design?.getInverterManufacturer(specId);
          const model = design?.getInverterModel(specId);
          return `${count} x ${manufacturer}: ${model}`;
        })
        .join("\n");
    } else if (selectedInvertersWithCounts) {
      stat = selectedInvertersWithCounts
        .filter((inverter) => inverter.count !== 0)
        .map((inverter) => {
          const manufacturer = design?.getInverterManufacturer(inverter?.id);
          const model = design?.getInverterModel(inverter?.id);
          return `${inverter?.count} x ${manufacturer}: ${model}`;
        })
        .join("\n");
    } else {
      const inverterSpecId = inverters[0].properties.inverterSpecificationId;
      const manufacturer = design?.getInverterManufacturer(inverterSpecId);
      stat = `${manufacturer}`;
    }

    const onEditClickObj =
      allowEditEquipment && !selectedInvertersWithCounts
        ? {
            onEditClick: () => workspaceDispatch({ type: "openSelectInverterModal" }),
          }
        : allowEditEquipment && selectedInvertersWithCounts
        ? { onEditClick: () => dispatch(openSelectManualInverterModal()) }
        : {};
    const newInverterStatDisplay: StatDisplayProps = {
      title,
      stat,
      ...onEditClickObj,
    };

    setInverterStatDisplay(newInverterStatDisplay);
  }, [
    designIsUsingHybridBatteries,
    design?.selectedEquipmentSpecificationIds?.selectedInverterSpecificationIds,
    design?.equipmentSpecifications,
    designFinalized,
    selectedInvertersWithCounts,
  ]);

  useEffect(() => {
    // If there is a selected optimizer id, then design should be using a inverter with a optimizer. If the user
    // manually selected an optimizer show which one they selected. If they did a Default Auto-Select, no need to show
    // the stat.
    let stat = undefined;
    const optimizers = design?.optimizers;
    if (designFinalized && optimizers && optimizers.length > 0) {
      const optimizerCounts = countBy(
        optimizers,
        (optimizer: Optimizer) => optimizer.properties.optimizerSpecificationId
      );
      stat = Object.entries(optimizerCounts)
        .map(([specId, count]) => {
          const optimizerManufacturer = design?.getOptimizerManufacturer(specId);
          const optimizerModel = design?.getOptimizerModel(specId);
          return `${count} x ${optimizerManufacturer}: ${optimizerModel}`;
        })
        .join("\n");
    } else if (design?.selectedEquipmentSpecificationIds?.selectedOptimizerSpecificationIds.length === 1) {
      const optimizerModel = design?.selectedOptimizerSpecifications[0].model;
      const optimizerManufacturer = design?.selectedOptimizerSpecifications[0].manufacturer;
      stat = `${optimizerManufacturer}: ${optimizerModel}`
    }
    
    const newOptimizerStatDisplay: StatDisplayProps = {
      title: "Power Optimizer",
      stat: stat,
    };
    
    if (stat) {
      setOptimizerStatDisplay(newOptimizerStatDisplay);
    } else {
      setOptimizerStatDisplay(undefined);
    }
  }, [
    design?.selectedEquipmentSpecificationIds?.selectedOptimizerSpecificationIds,
    design?.equipmentSpecifications,
    designFinalized,
  ]);

  // helper method for batteries
  const generateBatteryStatString = useCallback(
    (batteries: Battery[] | undefined) => {
      if (batteries === undefined || batteries.length <= 0) {
        return undefined;
      }

      if (allowEditBatteryCount || designFinalized) {
        const batteryCounts = countBy(
          batteries,
          (battery: Battery) => battery.properties.batterySpecificationId
        );
        return Object.entries(batteryCounts)
          .map(([specId, count]) => {
            const manufacturer = design?.getBatteryManufacturer(specId);
            const model = design?.getBatteryModel(specId);
            return `${count} x ${manufacturer}: ${model}`;
          })
          .join("\n");
      }

      const batterySpecId = batteries[0].properties.batterySpecificationId;
      const manufacturer = design?.getBatteryManufacturer(batterySpecId);
      const model = design?.getBatteryModel(batterySpecId);
      return `${manufacturer}: ${model}`;
    },
    [allowEditBatteryCount, designFinalized, design?.equipmentSpecifications]
  );

  // derive BatteryStatDisplay
  useEffect(() => {
    if (design?.batteryCount === 0 || designIsUsingHybridBatteries) {
      setBatteryStatDisplay(undefined);
      return;
    }

    const onEditClickObj =
      allowEditEquipment || allowEditBatteryCount
        ? {
            onEditClick: () => {
              dispatch(openSelectStorageModal());
              workspaceDispatch({ type: "openSelectStorageModal" });
            },
          }
        : {};
    const newBatteryStatDisplay: StatDisplayProps = {
      title: "Storage",
      stat: generateBatteryStatString(design?.batteries),
      ...onEditClickObj,
    };
    setBatteryStatDisplay(newBatteryStatDisplay);
  }, [designIsUsingHybridBatteries, design?.batteries, designFinalized]);

  // derive hybridBatteryDisplays
  useEffect(() => {
    // Separate Hybrid Batteries from Extension batteries
    const batteries = design?.batteries ?? [];
    const hybridBatteries: Battery[] = [];
    const extensionBatteries: Battery[] = [];
    batteries.forEach((battery) => {
      const batterySpec = design?.getBatterySpec(battery.properties.batterySpecificationId);
      const batteryModel = batterySpec?.model ?? "";
      const isHybrid = isHybridBattery(batteryModel);
      if (isHybrid) {
        hybridBatteries.push(battery);
      } else {
        extensionBatteries.push(battery);
      }
    });

    const allowEditHybridBatteries =
      allowEditEquipment || (allowEditBatteryCount && extensionBatteries.length === 0);
    const onEditClickObj = allowEditHybridBatteries
      ? {
          onEditClick: () => {
            dispatch(openSelectStorageModal());
            workspaceDispatch({ type: "openSelectStorageModal" });
          },
        }
      : {};
    const newHybridBatteryStatDisplay: StatDisplayProps = {
      title: "Inverter/Battery Hybrids",
      stat: generateBatteryStatString(hybridBatteries),
      ...onEditClickObj,
    };
    setHybridBatteryStatDisplay(newHybridBatteryStatDisplay);

    let newExtensionBatteryStatDisplay: StatDisplayProps | undefined = undefined;
    if (extensionBatteries.length) {
      const onEditClickObj = allowEditBatteryCount
        ? {
            onEditClick: () => {
              dispatch(openSelectStorageModal());
              workspaceDispatch({ type: "openSelectStorageModal" });
            },
          }
        : {};
      newExtensionBatteryStatDisplay = {
        title: "Battery Extensions",
        stat: generateBatteryStatString(extensionBatteries),
        ...onEditClickObj,
      };
    }
    setExtensionBatteryStatDisplay(newExtensionBatteryStatDisplay);
  }, [designIsUsingHybridBatteries, design?.batteries, designFinalized]);

  // Derive all of the designEquipment stat displays
  useEffect(() => {
    const statDisplays: StatDisplayProps[] = [];
    if (design && design.designEquipment && keystoneEquipment) {
      const groupedEquipmentByType = DesignEquipmentUtils.mapByEquipmentType(
        design.designEquipment
      );
      groupedEquipmentByType.forEach((designEquipment, type) => {
        const groupedEquipmentBySpec = DesignEquipmentUtils.mapBySpecificationId(designEquipment);
        const stat = Array.from(groupedEquipmentBySpec.values())
          .map((designEquipmentArray) => {
            const spec = DesignEquipmentUtils.getSpecification(designEquipmentArray[0], {
              keystoneEquipment,
              legacyEquipment: design.equipmentSpecifications,
            });
            const count = designEquipmentArray.length;
            return `${count} x ${spec.manufacturer?.name}: ${spec.model}`;
          })
          .join("\n");
        const statDisplay = {
          title: type,
          stat,
        };
        statDisplays.push(statDisplay);
      });
      setDesignEquipmentStatDisplays(statDisplays);
    }
  }, [design?.designEquipment, keystoneEquipment]);

  // assemble equipmentList
  useEffect(() => {
    let equipmentList: StatDisplayProps[] = [];
    if (moduleStatDisplay) {
      equipmentList.push(moduleStatDisplay);
    }
    if (designIsUsingHybridBatteries) {
      // Hybrid Batteries
      if (hybridBatteryStatDisplay) {
        equipmentList.push(hybridBatteryStatDisplay);
      }

      // Hybrid Battery Extensions
      if (extensionBatteryStatDisplay) {
        equipmentList.push(extensionBatteryStatDisplay);
      }
    } else {
      // Inverters
      if (inverterStatDisplay) {
        equipmentList.push(inverterStatDisplay);
      }

      if (optimizerStatDisplay) {
        equipmentList.push(optimizerStatDisplay);
      }

      // Non-Hybrid Batteries
      if (design?.batteryCount && batteryStatDisplay) {
        equipmentList.push(batteryStatDisplay);
      }
    }
    equipmentList.push(...designEquipmentStatDisplays);

    setEquipmentList(equipmentList);
  }, [
    moduleStatDisplay,
    designIsUsingHybridBatteries,
    inverterStatDisplay,
    optimizerStatDisplay,
    batteryStatDisplay,
    hybridBatteryStatDisplay,
    extensionBatteryStatDisplay,
    designEquipmentStatDisplays,
  ]);

  useEffect(() => {
    const designList: StatDisplayProps[] = [
      {
        title: "Total Roof Area",
        stat: siteModel?.roofAreaSquareFt() ?? 0,
        unit: "sq ft.",
        toDecimal: 0,
      },
    ];

    if (hasMaxAcConstraint) {
      designList.unshift({
        title: "AC System Size",
        stat: design?.acSystemSizeKw,
        unit: "kW",
        toDecimal: 3,
      });
    }

    if (hasMaxCecAcConstraint) {
      designList.unshift({
        title: "CEC-AC System Size",
        stat: design?.cecAcSystemSizeKw ?? 0,
        unit: "kW",
        toDecimal: 3,
      });
    }

    // Use Toolbar Breakpoints to decide when to show information in Design List
    if (isToolbarSize4) {
      designList.unshift({
        title: "DC System Size",
        stat: design?.dcSystemSizeKw,
        unit: "kW",
        toDecimal: 3,
      });
    }
    if (isToolbarSize3) {
      designList.unshift({
        title: "Consumption",
        stat: customer?.annualUsagekWh ?? 0,
        unit: "kWh",
      });
    }
    if (isToolbarSize2) {
      designList.unshift({
        title: "Production",
        stat: simulationToUse?.annualProductionKwh ?? 0,
        unit: "kWh",
      });
    }
    if (isToolbarSize1) {
      designList.unshift({
        title: "Sun Hours",
        stat: sunHours ?? 0,
      });
    }

    setDesignList(designList);
  }, [design, customer, simulationToUse, sunHours, siteModel]);

  return <DesignDetailsDisplay designList={designList} equipmentList={equipmentList} />;
};

export const DesignDetailsDisplay = ({
  designList,
  equipmentList,
}: {
  designList: StatDisplayProps[];
  equipmentList: StatDisplayProps[];
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} data-testid="design-details-display-design-list">
        {designList.map((statDisplay: StatDisplayProps) => (
          <Stack mb={1} key={statDisplay.title}>
            <StatDisplay {...statDisplay} />
          </Stack>
        ))}
      </Grid>
      <Grid item xs={6} data-testid="design-details-display-equipment-list">
        {equipmentList.map((statDisplay: StatDisplayProps) => (
          <Stack mb={1} key={statDisplay.title}>
            <StatDisplay {...statDisplay} />
          </Stack>
        ))}
      </Grid>
    </Grid>
  );
};
