import { WorkspacePayloadAction } from "../../types/state-management/action";
import { WorkspaceAction, WorkspaceState } from "../../hooks/useWorkspace";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type MostRecentSignedDesignState = {
  projectHasSignedDesign: boolean
  signedRootId?: string
  effectiveDate?: string
  productType?: string
};

export const initialState: MostRecentSignedDesignState = {
  projectHasSignedDesign: false,
  signedRootId: undefined,
  effectiveDate: undefined,
  productType: undefined,
}


export const mostRecentSignedDesignSlice = createSlice({
  name: "mostRecentSignedDesign",
  initialState,
  reducers: {
    setSignedRootId: (state: MostRecentSignedDesignState, action: PayloadAction<string>) => {
      state.signedRootId = action.payload;
      state.projectHasSignedDesign = true;
    },
    setEffectiveDate: (state: MostRecentSignedDesignState, action: PayloadAction<string>) => {
      state.effectiveDate = action.payload;
    },
    setSignedRootProductType: (state: MostRecentSignedDesignState, action: PayloadAction<string>) => {
      state.productType = action.payload;
    },
  },
});

export const { setSignedRootId, setEffectiveDate, setSignedRootProductType } = mostRecentSignedDesignSlice.actions
export default mostRecentSignedDesignSlice.reducer

// TODO: Remove the following and transition fully from WorkspaceState to RTK store
export type MostRecentSignedDesignAction =
  | WorkspacePayloadAction<string, 'setSignedRootId'>
  | WorkspacePayloadAction<string, 'setEffectiveDate'>
  | WorkspacePayloadAction<string, 'setSignedRootProductType'>

export const mostRecentSignedDesignWorkspaceReducer = (state: WorkspaceState, action: WorkspaceAction): MostRecentSignedDesignState => {
  switch (action.type) {
    case "setSignedRootId": {
      if (!action.payload) {
        return initialState
      }
      return {
        ...state.mostRecentSignedDesign,
        projectHasSignedDesign: true,
        signedRootId: action.payload,
      }
    }

    case "setEffectiveDate": {
      return {
        ...state.mostRecentSignedDesign,
        effectiveDate: action.payload,
      }
    }

    case "setSignedRootProductType": {
      return {
        ...state.mostRecentSignedDesign,
        productType: action.payload,
      }
    }

    default: {
      return state.mostRecentSignedDesign
    }
  }
}
