"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.findFunctionArnByNamePrefix = exports.getLambdaFunction = void 0;
const client_lambda_1 = require("@aws-sdk/client-lambda");
const client = new client_lambda_1.LambdaClient({ region: 'us-west-2' });
function getLambdaFunction(functionName) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            return yield client.send(new client_lambda_1.GetFunctionCommand({
                FunctionName: functionName
            }));
        }
        catch (err) {
            throw new Error(`Could not find lambda with name: "${functionName}"`);
        }
    });
}
exports.getLambdaFunction = getLambdaFunction;
/**
 * This code is super so slow, and gets slower as we add devs/services to the accounts, but it is what it is.
 * One more reason to replace the entire service registry.
 */
function findFunctionArnByNamePrefix(functionNamePrefix) {
    return __awaiter(this, void 0, void 0, function* () {
        console.log(`Attempting to find function arn given function name: ${functionNamePrefix}`);
        let nextToken = undefined;
        do {
            const listOutput = yield client.send(new client_lambda_1.ListFunctionsCommand({ Marker: nextToken, MaxItems: 50 }));
            // SNS Topic Arn Format: arn:aws:sns:{region}:{account-id}:{topicName}
            const foundIt = listOutput.Functions.find(it => it.FunctionName.startsWith(functionNamePrefix));
            if (foundIt) {
                return foundIt.FunctionArn;
            }
            nextToken = listOutput === null || listOutput === void 0 ? void 0 : listOutput.NextMarker;
        } while (nextToken);
        throw new Error(`Could not find Function Arn given Function Name: ${functionNamePrefix}`);
    });
}
exports.findFunctionArnByNamePrefix = findFunctionArnByNamePrefix;
