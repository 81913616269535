import { MIN_MODULE_SUN_HOURS, SiteModel, SolarResource } from "@sunrun/design-tools-domain-model";
import { Vector2D } from "@sunrun/design-tools-geometry";
import { LatLngBounds } from "leaflet";
import { useEffect, useRef, useState } from "react";

///--------------------------WEBGL SHADER STUFF (GLSL)--------------------------------///
// just keep scrolling if you dont want to read gpu programming stuff!
// why are these here?
// because they're actually annoyingly specific to the data we're going to render, and how
// I dont want them to be imported by anyone else, they're considered private to this module

// helper fn for picking a gradient color
const colorGradientGLSL = `vec3 gradientPart(vec4 colorLow, vec4 colorHi, float p){
  float lRange = colorHi.a-colorLow.a;
  float localP = clamp(0.0,1.0, (p-colorLow.a)/lRange);
  return mix(colorLow.rgb,colorHi.rgb,localP);
}
vec3 sunHoursToColor(float minKWH, float maxKWH, float valueKWH){
  vec3 black = vec3(0,0,0);
  vec3 brown = vec3(172.0,74.0,19.0)/255.0;
  vec3 yellow= vec3(255, 212,92)/255.0;
  vec3 white = vec3(1,1,1);

  float rangeKWH = maxKWH-minKWH;
  float p = (valueKWH-minKWH)/rangeKWH;
  
  vec3 blackToBrown = gradientPart(vec4(black,0.0),vec4(brown,0.50),p);
  vec3 brownToYellow = gradientPart(vec4(brown,0.50),vec4(yellow,0.85),p);
  vec3 yellowToWhite = gradientPart(vec4(yellow,0.85),vec4(white,0.95),p);
  // mix each using steps for the thresholds
  vec3 middle = mix(brownToYellow,yellowToWhite, step(0.85, p));
  return mix(blackToBrown, middle, step(0.5, p));
}`;
// this program runs once per pixel
const fragmentShaderGLSL = `precision highp float;

uniform vec2 minAndMaxSunHours;
varying float sunHours;

${colorGradientGLSL}
float heatMapLayerOpacity = 0.87;
void main(){
  vec3 heatColor = sunHoursToColor(minAndMaxSunHours.x, minAndMaxSunHours.y, sunHours);
  gl_FragColor = vec4(heatColor,heatMapLayerOpacity);
}`;

// this program once per vertes
const vertexShaderGLSL = `precision highp float;
attribute vec2 offsetInMeters; // east,north
attribute vec3 utmAndSAV; // east,north,savPercent

uniform float roofAzimuth; // for aligning our points to the roof surface...
uniform vec4 mapBounds; // the ortho projection, which we must map to gl.clipspace

vec2 projToClip(vec2 p, vec2 minBound, vec2 maxBound){
  vec2 size = maxBound-minBound;
  return (2.0*(p-minBound)/size)-1.0;
}

varying float sunHours;

void main(){

  float theta = 3.14159*roofAzimuth/180.0;
  mat2 rot = mat2(cos(theta),-sin(theta),sin(theta),cos(theta));

  vec2 p = utmAndSAV.xy +(rot*offsetInMeters);

  sunHours = utmAndSAV.z;
  gl_Position = vec4(projToClip(p,mapBounds.xy,mapBounds.zw),0.0,1.0);
}`;
///-----------------------END SHADER STUFF-----------------------------------///

class HeatMapRenderManager {
  gl: WebGL2RenderingContext | null | undefined;
  canvas: HTMLCanvasElement;
  shader: WebGLProgram | undefined;
  square: WebGLBuffer | undefined;
  perRoofData: { azimuthDegrees: number, buffer: WebGLBuffer, numPoints:number }[] | undefined;
  dataOffsetToAvoidFloat32PrecisionIssues: Vector2D;
  minimumGradientSunHours: number = MIN_MODULE_SUN_HOURS;
  constructor(canvas: HTMLCanvasElement, siteModel: SiteModel, solarResource: SolarResource) {
    this.canvas = canvas;
    const gl = canvas.getContext('webgl2');
    this.dataOffsetToAvoidFloat32PrecisionIssues = [0, 0];
    if (gl) {
      this.gl = gl;
      this.shader = HeatMapRenderManager.buildShaders(this.gl);
      this.setupUnitSquare();
    }
    this.uploadDataToWebGLBuffers(solarResource, siteModel);
  }
  getCanvas():HTMLCanvasElement | undefined{
    return this.canvas;
  }
  setupUnitSquare() {
    const gl = this.gl;
    if (!gl) return;


    const square = gl.createBuffer();
    if (square) {
      const halfFootInMeters = 0.5 / 3.28084;
      const unitSquare = new Float32Array([
        -halfFootInMeters, -halfFootInMeters,
        halfFootInMeters, -halfFootInMeters,
        -halfFootInMeters, halfFootInMeters,
        halfFootInMeters, -halfFootInMeters,
        halfFootInMeters, halfFootInMeters,
        -halfFootInMeters, halfFootInMeters,
      ]);
      gl.bindBuffer(gl.ARRAY_BUFFER, square);
      gl.bufferData(gl.ARRAY_BUFFER, unitSquare, gl.STATIC_DRAW);
    }
    this.square = square || undefined;
  }
  static buildShaders(gl: WebGL2RenderingContext): WebGLShader | undefined {
    if (!gl) return undefined;

    
    const vs = gl.createShader(gl.VERTEX_SHADER);
    const fs = gl.createShader(gl.FRAGMENT_SHADER);

    const program = gl.createProgram();
    if (vs && fs && program) {
      // upload the GLSL strings defined at the top to WebGL
      gl.shaderSource(vs, vertexShaderGLSL);
      gl.shaderSource(fs, fragmentShaderGLSL);

      // compile them, check for errors
      gl.compileShader(vs);
      if (!gl.getShaderParameter(vs, gl.COMPILE_STATUS)) {
        console.error(gl.getShaderInfoLog(vs));
        return undefined;
      }
      gl.compileShader(fs);
      if (!gl.getShaderParameter(fs, gl.COMPILE_STATUS)) {
        console.error(gl.getShaderInfoLog(fs));
        return undefined;
      }
      gl.attachShader(program, vs);
      gl.attachShader(program, fs);
      // now check for linker errors
      gl.linkProgram(program);
      if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
        console.error(gl.getProgramInfoLog(program));
        return undefined;
      }
      // having produced the program - its technically good form
      // to delete the individual (attached) shader objects...
      gl.deleteShader(vs);
      gl.deleteShader(fs);
      return program;
    }
    return undefined;
  }
  cleanupWebGLBuffers() {
    if (!this.gl) {
      return;
    }
    if (this.perRoofData !== undefined) {
      for (const c of this.perRoofData) {
        this.gl.deleteBuffer(c.buffer);
      }
      this.perRoofData = [];
    }
  }
  destroy() {
    if (this.gl) {
      this.cleanupWebGLBuffers();
      this.gl.deleteBuffer(this.square || null);
      this.gl.deleteProgram(this.shader || null);
      this.gl = undefined;
    }
  }
  uploadDataToWebGLBuffers(solarResource: SolarResource, siteModel: SiteModel) {
    const gl = this.gl;
    if (!gl) return;

    const startTime = performance.now();
    const solarModels = solarResource.lightmileSolarResources || [];
    this.perRoofData = [];
    let first: boolean = true;
    for (const mdl of solarModels) {
      const numPoints = mdl.geometry.coordinates.length;
      const curRoofSunHours = mdl.properties.unshadedPVWattsSunHours||0;
      if (!mdl.properties.annualSolarAccessValues) continue;
      const centers = gl.createBuffer();
      if (!centers) continue;

      const coords = mdl.geometry.coordinates;
      const centerData = new Float32Array(coords.length * 3);
      for (let i = 0; i < coords.length; i++) {
        if (i == 0 && first) {
          // common numbers are around half a million, in meters from some utm reference - making
          // 32bit float numbers a bit nervous, especially when we want sub-meter values (like 1ft grid points)
          // so - since all of the values of a site are often within a mile of each other ==>
          //  take any coordinate in the scene, and subtract it from all the others.
          //  stash this number so that the view-bounds can be adjusted as the user pans the camera:
          this.dataOffsetToAvoidFloat32PrecisionIssues = [coords[i][0], coords[i][1]];
          first = false;
        }
        const ci = i * 3;
        centerData[ci] = coords[i][0] - this.dataOffsetToAvoidFloat32PrecisionIssues[0];
        centerData[ci + 1] = coords[i][1] - this.dataOffsetToAvoidFloat32PrecisionIssues[1];
        // for some reason, annualSolarAccessValues[i] is number | null...
        const kwH_per_sqft = (mdl.properties.annualSolarAccessValues[i] || 0)*curRoofSunHours;
        centerData[ci + 2] = kwH_per_sqft;
      }

      gl.bindBuffer(gl.ARRAY_BUFFER, centers);
      gl.bufferData(gl.ARRAY_BUFFER, centerData, gl.STATIC_DRAW);
      this.perRoofData.push({numPoints, azimuthDegrees: siteModel.getRoofFaceById(mdl.properties.roofFaceId as string)?.properties.azimuthDegrees || 0, buffer: centers });
    }
    console.log('building geometry for webGL took: ', performance.now() - startTime)
  }
  // given the geometry for a single square, 1ft*1ft in meters
  // we repeatedly draw that square, once for every
  // datapoint in centers[roof].buffer
  // note each datapoint is packed into a vec3 like so: <East,North, solarAccessValue>
  // webGL handles the conceptual 'foreach(centerPoint).draw(square)` for us - its pretty fast
  // this technique of drawing one 'mesh' many times with slightly different colors/locations/whatever
  // is called instanced rendering
  // there are of course several other possibly more performant ways to render what we want, but this
  // is one of the easiest to write, given our data formats.
  draw(bounds: LatLngBounds, show:boolean, siteMaxSunHours: number) {
    const gl = this.gl;
    if (!gl) return;
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
    gl.clearColor(0, 0, 0, 0); // transparent black
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    gl.enable(gl.BLEND);
    gl.enable(gl.DEPTH_TEST);
    gl.blendEquation(gl.FUNC_ADD);
    
    // for a number of reasons, its helpful to simply draw a blank canvas
    // rather than omit the component from the react-render tree
    // for one thing, we get a bunch of weird bugs the other way
    // if you mount/unmount on toggle,
    // we must create/destroy webGL contexts repeatedly
    // technically, that's a thing that should work fine, but in my experience,
    // browsers get pretty cranky about it.
    if(!show) return;

    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
    if (this.square && this.perRoofData && this.shader) {
      const perCellDataLocation = gl.getAttribLocation(this.shader, 'utmAndSAV');
      const squareFootGeometryLocation = gl.getAttribLocation(this.shader, 'offsetInMeters');
      const mapBoundsLocation = gl.getUniformLocation(this.shader, 'mapBounds');
      const roofAzimuthLocation = gl.getUniformLocation(this.shader, 'roofAzimuth');
      const gradientRangeSunHoursLocation = gl.getUniformLocation(this.shader, 'minAndMaxSunHours');
      gl.useProgram(this.shader);
      // assumes top of screen is always north... ?
      const [W, S, E, N] = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];
      gl.uniform2f(gradientRangeSunHoursLocation, this.minimumGradientSunHours, siteMaxSunHours);
      // the bounds are 64bit values, and we had to massage the data to make them fit in 32bit floats.
      // handle that by doing the same to the viewing box here:
      gl.uniform4f(mapBoundsLocation,
        W - this.dataOffsetToAvoidFloat32PrecisionIssues[0],
        S - this.dataOffsetToAvoidFloat32PrecisionIssues[1],
        E - this.dataOffsetToAvoidFloat32PrecisionIssues[0],
        N - this.dataOffsetToAvoidFloat32PrecisionIssues[1]);
      // bind the data stored in this.square to squareFootGeometryLocation. it will be shared accross all roofs
      gl.bindBuffer(gl.ARRAY_BUFFER, this.square);
      gl.enableVertexAttribArray(squareFootGeometryLocation);
      gl.vertexAttribPointer(squareFootGeometryLocation, 2, gl.FLOAT, false, 0, 0);
      gl.vertexAttribDivisor(squareFootGeometryLocation, 0);
      
      gl.enableVertexAttribArray(perCellDataLocation);
      gl.vertexAttribDivisor(perCellDataLocation, 1);
      for (let i = 0; i < this.perRoofData.length; i++) {
        gl.clear(gl.DEPTH_BUFFER_BIT);
        const { buffer, azimuthDegrees, numPoints } = this.perRoofData[i];
        gl.uniform1f(roofAzimuthLocation, azimuthDegrees + Math.random() * 0);
        // bind this roof's buffer - it will be used to instance the square-foot geometry while drawing
        gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
        gl.vertexAttribPointer(perCellDataLocation, 3, gl.FLOAT, false, 0, 0);
        gl.drawArraysInstanced(gl.TRIANGLES, 0, 6, numPoints);
      }
    }
  }
}


export const useWebGL = (bounds: LatLngBounds, siteModel:SiteModel, solarResource?: SolarResource)=> {
  const canvasRef = useRef(document.createElement('canvas'));

  const [mngr, setMngr] = useState<HeatMapRenderManager>();
  useEffect(()=>{
    if(mngr){
      mngr.destroy(); // clean up the old one
    }
    if(solarResource?.lightmileSolarResources && solarResource?.lightmileSolarResources.length > 0){
      console.log('build new renderManager(', siteModel, solarResource.lightmileSolarResources.length);
      const mngr = new HeatMapRenderManager(canvasRef.current, siteModel, solarResource);
      mngr.draw(bounds, false, solarResource.siteMaxSunHours);
      setMngr(mngr);
    }
  },[siteModel, solarResource]);

  return {
    mngr
  }
  
}
