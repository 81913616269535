import { max, min, sub, Vector2D } from "../vectors/vector2D";

// a helper type - Axis Aligned Bounding Box
export type AxisAlignedBox = {
  readonly minCorner: Vector2D;
  readonly maxCorner: Vector2D;
}
// return the axis aligned box which contains the two given points
export function fromCorners(a: Vector2D, b: Vector2D): AxisAlignedBox{
  return {
    minCorner: min(a,b),
    maxCorner: max(a,b),
  }
}
export function expandToContain(box: AxisAlignedBox, point: Vector2D): AxisAlignedBox{
  return {
    minCorner: min(box.minCorner, point),
    maxCorner: max(box.maxCorner, point),
  } 
}
// returns the intersection of two axis aligned boxes - or undefined if no intersection exists
export function intersection(a: AxisAlignedBox, b: AxisAlignedBox): AxisAlignedBox | undefined {
  const result = {
    minCorner: max(a.minCorner, b.minCorner),
    maxCorner: min(a.maxCorner, b.maxCorner)
  };
  return isValid(result) ? result : undefined;
}
export function isValid(box: AxisAlignedBox): boolean{
  return box.minCorner[0] < box.maxCorner[0] && box.minCorner[1] < box.maxCorner[1]
}
// return the area of a box
// return zero if the box is invalid (min>=max)
export function area(box:AxisAlignedBox): number {
  if(isValid(box)){
    const size = sub(box.maxCorner, box.minCorner)
    return size[0] * size[1];
  }
  return 0;
}
