import { Design, type IDesign } from "@sunrun/design-tools-domain-model";
import type { WorkspacePayloadAction } from "src/types/state-management/action";
import type { WorkspaceAction, WorkspaceState } from "src/hooks/useWorkspace";
import { createSelector } from "reselect";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type BookmarkedDesignsState = {
  value: IDesign[] | undefined;
}

export const initialState: BookmarkedDesignsState = {
  value: undefined,
};

export const bookmarkedDesignsSlice = createSlice({
  name: "bookmarkedDesigns",
  initialState,
  reducers: {
    setBookmarkedDesigns: (state: BookmarkedDesignsState, action: PayloadAction<IDesign[]>) => {
      state.value = action.payload
    },
  }
});

export const { setBookmarkedDesigns } = bookmarkedDesignsSlice.actions
export default bookmarkedDesignsSlice.reducer

// TODO: Remove the following and transition fully from WorkspaceState to RTK store
export type BookmarkWorkspaceAction = WorkspacePayloadAction<
  Design[],
  "setBookmarkedDesigns"
>;

export const selectBookmarkedDesigns = (state: WorkspaceState) =>
  state.bookmarkedDesigns;

export const bookmarkWorkspaceReducer = (
  state: WorkspaceState,
  action: WorkspaceAction
): Array<IDesign> => {
  switch (action.type) {
    case "setBookmarkedDesigns": {
      return action.payload.map(designClass => designClass.getIDesign());
    }
    default: {
      return state.bookmarkedDesigns;
    }
  }
};

export const deriveBookmarkedDesignClasses = createSelector(
  [selectBookmarkedDesigns],
  (bookmarkedDesigns) => {
    return bookmarkedDesigns
      ? bookmarkedDesigns.map((design) => new Design(design))
      : undefined;
  }
);
