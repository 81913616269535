import { createTheme } from "@mui/material";

export const offerBuilderTheme = createTheme({
  palette: {
    primary: {
      main: "#1F2647",
    },
    secondary: {
      main: "#FFFFFF",
    },
    text: {
      primary: "#6F6D68",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        // @ts-ignore
        root: ({
          borderRadius: "80px",
          textTransform: "none",
        }),
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      toolbar1 : 720,
      toolbar2 : 840,
      toolbar3 : 960,
      toolbar4 : 1080
    },
  },
});

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    toolbar1 : true,
    toolbar2 : true,
    toolbar3 : true,
    toolbar4 : true
  }
}