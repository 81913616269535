import {Position} from "./point"

export const distanceXYZ = (position1: Position, position2: Position): number => {
  if (position1.length === 2 || position2.length === 2) {
    throw Error("One or both coordinates does not have z coordinates")
  }
  const xDistance = position2[0] - position1[0];
  const yDistance = position2[1] - position1[1];
  const zDistance = position2[2] - position1[2];
  return Math.sqrt(xDistance * xDistance + yDistance * yDistance + zDistance * zDistance)
}

export const distanceXY = (position1: Position, position2: Position): number => {
  const xDistance = position2[0] - position1[0];
  const yDistance = position2[1] - position1[1];
  return Math.sqrt(xDistance * xDistance + yDistance * yDistance)
}
