import { useEffect, useState } from "react";
import { ModalLayout } from "./components/ModalLayout";
import {
  Design,
  InverterSpecification,
  inverterSpecToInverterType,
  sortEquipmentSpecificationsByModel,
} from "@sunrun/design-tools-domain-model";
import {
  Alert,
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { SelectedInverterIdsByManufacturer } from "./SelectInverterModal";
import { useAppDispatch, useAppSelector } from "src/store";
import { closeSelectManualInverterModal } from "src/features/modal/modalSlice";
import { useWorkspace } from "src/hooks/useWorkspace";
import {
  EQUIPMENT_MODAL_BOX_SIZING,
  EQUIPMENT_MODAL_FORM_SELECT_MAX_HEIGHT,
  EQUIPMENT_MODAL_SPACING,
} from ".";
import { formatNumber } from "@sunrun/design-tools-themes";
import {
  SelectedInverter,
  setSelectedInverters,
} from "src/features/equipment/selectedEquipmentSlice";
import { deriveDesignClass, selectBatteryCount } from "src/features/design/designSlice";

type ConfigureManualInverterModalProps = {
  availableInverters: InverterSpecification[];
  open: boolean;
  selectedManufacturer: string;
  selectedInvertersByManufacturer: SelectedInverterIdsByManufacturer;
  defaultInverterChange: () => void;
};

export type SelectedInverterIdsWithCount = Record<string, number | undefined>;

export const ConfigureManualInverterModal = (props: ConfigureManualInverterModalProps) => {
  const { availableInverters, selectedInvertersByManufacturer, selectedManufacturer } = props;
  const { state, dispatch: workspaceDispatch } = useWorkspace();
  const { settings } = state;
  const maxInverterCount = state.design?.designParameters?.maxNumInverters ?? 4;
  const dispatch = useAppDispatch();
  const selectedInverters = useAppSelector((state) => state.selectedEquipment.selectedInverters);
  const design = deriveDesignClass(state);
  const batteryCount = selectBatteryCount(state);
  const isFilterOut10k =
    settings.isFilter10kLogicEnabled && design?.isOnlyPowerwall2Design && batteryCount === 1;
  // From iHD we can only check for it is if shift for filtering out 10ks. The logic for maximize self consumption
  // is in the adapter we don't have access to. Potentially we can refactor this, but some types belong in adapter and it can get tricky.
  const canRetryWithOnly10k = design?.isShift(); // arcTODO this?

  const [selectedInvertersWithCount, setSelectedInvertersWithCount] =
    useState<SelectedInverterIdsWithCount>({});
  const [currentCounts, setCurrentCounts] = useState(0);
  const [isDirty, setIsDirty] = useState(false);
  const [hasMaxCountError, setHasMaxCountError] = useState(false);
  const [hasMinCountError, setHasMinCountError] = useState(false);

  useEffect(() => {
    const selectedInverterIds = selectedInvertersByManufacturer[selectedManufacturer];
    const initialInverterCounts: SelectedInverterIdsWithCount = {};
    selectedInverterIds?.forEach((id) => {
      if (!(id in selectedInvertersWithCount)) {
        initialInverterCounts[id] = 0;
      }
      const selectedInverter = selectedInverters?.find((inverter) => {
        return inverter.id === id;
      });
      if (selectedInverter) {
        initialInverterCounts[id] = selectedInverter.count;
      }
    });
    setSelectedInvertersWithCount(initialInverterCounts);
  }, []);

  useEffect(() => {
    let currentInverterCount = 0;
    Object.values(selectedInvertersWithCount).forEach((count) => {
      if (count) {
        currentInverterCount += count;
      }
    });
    setCurrentCounts(currentInverterCount);
  }, [selectedInvertersWithCount]);

  useEffect(() => {
    if (currentCounts > maxInverterCount) {
      setHasMaxCountError(true);
    } else {
      setHasMaxCountError(false);
    }

    if (currentCounts === 0 && isDirty) {
      setHasMinCountError(true);
    } else {
      setHasMinCountError(false);
    }
  }, [currentCounts]);

  const handleInverterCountChange = (id: string, inverterCount: number) => {
    setSelectedInvertersWithCount((prevSelectedInvertersWithCount) => ({
      ...prevSelectedInvertersWithCount,
      [id]: inverterCount,
    }));
    setIsDirty(true);
  };

  const handleClose = () => {
    setIsDirty(false);
    setHasMaxCountError(false);
    setHasMinCountError(false);
    dispatch(closeSelectManualInverterModal());
  };

  const handleGoBack = () => {
    setIsDirty(false);
    setHasMaxCountError(false);
    setHasMinCountError(false);
    dispatch(closeSelectManualInverterModal());
    workspaceDispatch({ type: "openSelectInverterModal" });
  };

  const handleContinue = () => {
    let selectedInverters: SelectedInverter[] = [];
    const selectedInverterIds = selectedInvertersByManufacturer[selectedManufacturer];
    if (hasMaxCountError || hasMinCountError) {
      return;
    }

    if (selectedInverterIds.length > 0 && selectedInvertersWithCount && currentCounts > 0) {
      // This onInverterChange logic hasn't been deprecated fully with upcoming keystone changes
      props.defaultInverterChange();

      Object.keys(selectedInvertersWithCount)?.forEach((id) => {
        selectedInverters.push({
          id: id,
          count: selectedInvertersWithCount[id],
        });
      });
      dispatch(setSelectedInverters(selectedInverters));
      dispatch(closeSelectManualInverterModal());
    } else {
      setHasMinCountError(true);
    }
  };

  const filterInvertersBySelectedManufacturer = (manufacturer: string) => {
    const selectedInverterIds = selectedInvertersByManufacturer[selectedManufacturer] ?? [];

    return availableInverters
      .filter(
        (spec) => spec?.manufacturer === manufacturer && selectedInverterIds.includes(spec?.id)
      )
      .sort(sortEquipmentSpecificationsByModel);
  };
  const invertersForSelectedManufacturer =
    filterInvertersBySelectedManufacturer(selectedManufacturer);

  let inverterCountArray: Array<number> = [];
  for (let i = 0; i <= maxInverterCount; i++) {
    inverterCountArray.push(i);
  }
  const inverterCountOptions = inverterCountArray.map((item, idx) => {
    return (
      <MenuItem key={idx} value={item}>
        {item}
      </MenuItem>
    );
  });
  const modelCountConfiguration = invertersForSelectedManufacturer.map((spec) => {
    const { pvPowerSTC } = spec.manufacturerSpecifications;
    const productOption = inverterSpecToInverterType(spec);
    const wattsStcString = formatNumber({
      value: pvPowerSTC,
      maximumFractionDigits: 0,
    });

    return (
      <Grid container key={spec?.id} alignItems="center" spacing={2} minHeight={"80px"}>
        <Grid item xs={10}>
          <Typography variant="body2">{`${spec?.model} (${productOption} - ${wattsStcString}W STC)`}</Typography>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <Select
              labelId="inverter-model-count"
              value={
                selectedInvertersWithCount[spec?.id] === undefined
                  ? 0
                  : selectedInvertersWithCount[spec?.id]
              }
              onChange={(e) => handleInverterCountChange(spec?.id, Number(e.target.value))}
              style={{ height: "50px", minWidth: "80px" }}
            >
              {inverterCountOptions}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  });

  return (
    <ModalLayout
      open={props.open}
      title="Configure Selected Inverters"
      onClose={handleClose}
      // ModalLayout is a bit strict in UI controls, for now using the onCancel as a goBack for this new modal type to prevent copying to a new modal layout
      onCancel={handleGoBack}
      onContinue={handleContinue}
      actionButtons={true}
      cancelText="Go Back"
      continueText="Select Manual Inverters"
    >
      <Box>
        <Stack spacing={EQUIPMENT_MODAL_SPACING}>
          <Typography variant="body2">
            Configure inverter counts from selected inverters in the previous step.
          </Typography>
          <Typography variant="body2">
            If you need to update the selected inverters, please return to the select inverter modal
            with Go Back.
          </Typography>
          {/* If it is backup and flag is on, show message that this will filter out 10ks, update battery count to allow 10k equipment */}
          {isFilterOut10k && (
            <Alert
              severity="info"
              sx={{
                alignItems: "center",
              }}
            >
              Design currently uses a backup battery. If you want to use 10k inverter equipment,
              <br />
              please update battery count in the battery selection section or turn off 10k Filter
              Logic
              <br />
              in the settings menu.
            </Alert>
          )}
          {/* If it is maximize self consumption market with shift, use verbose message here as we don't know what the designMap has until after 
          simulate. We can only tell if it is shift, and other factors is dependent on LM type, etc.. Rethink how to reuse adapter in iHD.
          There are other factors for this filter only for 10k check that are not accessible in iHD. */}
          {canRetryWithOnly10k && (
            <Alert
              severity="info"
              sx={{
                alignItems: "center",
              }}
            >
              This is a shift design and may require 1 or 2 batteries depending on finalized
              production thresholds.
            </Alert>
          )}
          <Box pt={EQUIPMENT_MODAL_BOX_SIZING}>Selected Manufacturer: {selectedManufacturer}</Box>
          <FormControl
            fullWidth
            component="fieldset"
            sx={{
              maxHeight: EQUIPMENT_MODAL_FORM_SELECT_MAX_HEIGHT,
              overflow: "auto",
            }}
          >
            <FormLabel component="legend">Selected Models</FormLabel>
            {modelCountConfiguration}
          </FormControl>
          {hasMaxCountError && (
            <FormHelperText error>
              {`Total max inverter count of ${maxInverterCount} exceeded.`}
            </FormHelperText>
          )}
          {hasMinCountError && (
            <FormHelperText error>At least one inverter is required</FormHelperText>
          )}
        </Stack>
      </Box>
    </ModalLayout>
  );
};
