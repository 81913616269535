import { useEffect, useState } from 'react'
import { Alert, Box, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { formatNumber } from "@sunrun/design-tools-themes";
import { getModuleColor, sortEquipmentSpecificationsByModel } from "@sunrun/design-tools-domain-model";
import { ModalLayout } from "./components/ModalLayout";
import { EQUIPMENT_MODAL_BOX_SIZING, EQUIPMENT_MODAL_SPACING } from '.';

import type { ModuleSpecification } from "@sunrun/design-tools-domain-model";
import type { SelectChangeEvent } from "@mui/material";
import { useWorkspace } from 'src/hooks/useWorkspace';
import { deriveDesignClass } from 'src/features/design/designSlice';
import { useAppDispatch } from 'src/store';
import { closeSelectModuleModal } from 'src/features/modal/modalSlice';

const MODAL_TITLE = "Select Module";

type SelectModuleModalProps = {
  open: boolean
  onClose: () => void
}

export const SelectModuleModal = (props: SelectModuleModalProps) => {
  const {state, dispatch: workspaceDispatch} = useWorkspace();
  const dispatch = useAppDispatch();
  const availableModules = state.availableEquipment?.moduleSpecifications
  const design = deriveDesignClass(state)
  const [selectedManufacturer, setSelectedManufacturer] = useState('')
  const [selectedModuleSpec, setSelectedModuleSpec] = useState<ModuleSpecification | undefined>(undefined);

  // Used to determine if there are unsaved changes to the module selection
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (!isDirty) {
      setSelectedModuleSpec(design?.selectedModuleSpecification)
      setSelectedManufacturer(design?.selectedModuleSpecification.manufacturer ?? "")
    }
  }, [isDirty, design?.selectedModuleSpecification])
  
  if (!availableModules) return null;

  const availableManufacturers = [...new Set(availableModules.map(spec => spec.manufacturer))].sort();
  const manufacturerOptions = availableManufacturers.map(manufacturer => {
    return <MenuItem key={manufacturer} value={manufacturer}>{manufacturer}</MenuItem>
  });

  const getModulesForManufacturer = (manufacturer: string | undefined) => {
    return availableModules.filter(spec => spec.manufacturer === manufacturer).sort(sortEquipmentSpecificationsByModel);
  }

  const modulesForSelectedManufacturer = getModulesForManufacturer(selectedModuleSpec?.manufacturer);
  const modelOptions = modulesForSelectedManufacturer.map(spec => {
    const { ratedPowerSTC } = spec.manufacturerSpecifications;
    const wattsStcString = formatNumber({ value: ratedPowerSTC, maximumFractionDigits: 0 } )

    let detailString = `${wattsStcString}W STC`;
    try {
      // Shouldn't happen, but if we do get an unexpected value, we shouldn't break the UI
      const productOption = getModuleColor(spec);
      detailString = `${productOption} - ${detailString}`
    } catch(e) {
      console.warn(e);
    }

    return <MenuItem key={spec.id} value={spec.id}>
      {spec.model} ({detailString})
    </MenuItem>
  });

  const handleManufacturerChange = (event: SelectChangeEvent) => {
    setIsDirty(true);
    setSelectedManufacturer(event.target.value)
    setSelectedModuleSpec(getModulesForManufacturer(event.target.value)[0])
  }

  const handleModelChange = (event: SelectChangeEvent) => {
    setIsDirty(true);
    const moduleSpec = availableModules.find(moduleSpec => moduleSpec.id === event.target.value)
    setSelectedModuleSpec(moduleSpec);
  }

  const handleClose = () => {
    setIsDirty(false);
    props.onClose();
  }

  const handleSelectNewModule = () => {
    if (selectedModuleSpec) {
      dispatch(closeSelectModuleModal)
      workspaceDispatch({ type: "closeSelectModuleModal" });
      workspaceDispatch({type: "updateDesignWithModuleSpec", payload: selectedModuleSpec});
    } else {
      console.error("Tried to select new modules when none are selected.")
    }
  };

  return(
    <ModalLayout
      open={props.open}
      title={MODAL_TITLE}
      onClose={handleClose}
      onContinue={handleSelectNewModule}
      actionButtons={true}
      cancelText="Cancel"
      continueText="Select Module"
    >
      {availableModules.length === 0 ? (
        <Typography variant="body2">No modules available. Something went wrong.</Typography>
      ) : (
        <Box pt={EQUIPMENT_MODAL_BOX_SIZING}>
          <Stack spacing={EQUIPMENT_MODAL_SPACING}>
            <Alert severity="warning">Changing the selected module may impact the design and require changes due to different dimensions</Alert>
            <FormControl fullWidth>
              <InputLabel id="module-manufacturer-label">Manufacturer</InputLabel>
              <Select
                label="Manufacturer"
                labelId="module-manufacturer-label"
                onChange={handleManufacturerChange}
                value={selectedManufacturer}
              >
                {manufacturerOptions}
              </Select>
            </FormControl>
            {selectedManufacturer && (
              <FormControl fullWidth>
                <InputLabel id="module-model-label">Model</InputLabel>
                <Select
                  label="Model"
                  labelId="module-model-label"
                  onChange={handleModelChange}
                  value={selectedModuleSpec?.id}
                >
                  {modelOptions}
                </Select>
              </FormControl>
            )}
          </Stack>
        </Box>
      )}
    </ModalLayout>
  )
}
