import { useEffect, useState, ChangeEvent } from "react";
import {
  Box,
  Link,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import {
  InverterSpecification,
  PowerOptimizerSpecification,
  inverterSpecToInverterType,
  isPowerwallPlusInverter,
  sortEquipmentSpecificationsByModel,
} from "@sunrun/design-tools-domain-model";
import { formatNumber } from "@sunrun/design-tools-themes";
import { ModalLayout } from "./components/ModalLayout";
import { postIFrameMessage } from "src//hooks/useIFrameHost";
import { IFrameEventType } from "src/types/IFrame";
import {
  EQUIPMENT_MODAL_BOX_SIZING,
  EQUIPMENT_MODAL_FORM_SELECT_MAX_HEIGHT,
  EQUIPMENT_MODAL_SPACING,
} from ".";
import { useFlags } from "flagsmith/react";
import { FlagsmithFeatureEnums } from "src/config/flagsmithConfig";
import { useWorkspace } from "src/hooks/useWorkspace";
import { ConfigureManualInverterModal } from "./ConfigureManualInverterModal";
import { useAppDispatch, useAppSelector } from "src/store";
import { openSelectManualInverterModal } from "src/features/modal/modalSlice";
import { setSelectedInverters } from "src/features/equipment/selectedEquipmentSlice";
import { deriveDesignClass } from "src/features/design/designSlice";
import { selectIsAffiliate, selectIsNewHomes } from "src/features/offer/offerSlice";

type SelectInverterModalProps = {
  availableInverters: InverterSpecification[];
  designId?: string;
  offerId?: string;
  open: boolean;
  selectedInverterIds?: string[];
  onInverterChange: (newInverterIds: string[], newPowerOptimizerSpecIds: string[]) => void;
  onClose: () => void;
};

export type SelectedInverterIdsByManufacturer = Record<string, string[]>;

// This const is used in order to let the user to have option to retain auto selection in LM for optimizers
const DEFAULT_SELECT_OPTIMIZER = "Default Auto-Selection";

export const SelectInverterModal = (props: SelectInverterModalProps) => {
  const { state } = useWorkspace();
  const design = deriveDesignClass(state);
  const dispatch = useAppDispatch();
  const isConfigureSelectInverterOpen = useAppSelector(
    (state) => state.modal.isConfigureManualInverterOpen
  );
  const [selectedManufacturer, setSelectedManufacturer] = useState("");
  const [selectedInvertersByManufacturer, setSelectedInvertersByManufacturer] =
    useState<SelectedInverterIdsByManufacturer>({});
  const [availableInverters, setAvailableInverters] = useState<InverterSpecification[]>([]);
  const [availableOptimizers, setAvailableOptimizers] = useState<PowerOptimizerSpecification[]>([]);
  const [selectedOptimizer, setSelectedOptimizer] = useState<string>(DEFAULT_SELECT_OPTIMIZER);
  const [isDirty, setIsDirty] = useState(false);
  const [hasError, setHasError] = useState(false);
  const enableManualConfigureInverters = useFlags([
    FlagsmithFeatureEnums.ENABLE_MANUAL_INVERTER_SELECTION,
  ])[FlagsmithFeatureEnums.ENABLE_MANUAL_INVERTER_SELECTION].enabled;

  // ET-2013 : Set to new homes initially for quick release, need to switch back to not affiliate
  const isNewHomes = selectIsNewHomes(state);
  // Do not allow affiliate projects to configure inverters
  // const isNonAffiliateProject = !selectIsAffiliate(state);
  const selectedOptimizerIds =
    design?.selectedEquipmentSpecificationIds?.selectedOptimizerSpecificationIds;
  useEffect(() => {
    setAvailableInverters(props.availableInverters);

    const initialSelectedInverterIds = props.selectedInverterIds;
    const initialSelectedManufacturer = props.availableInverters.find(
      (spec) => spec.id === initialSelectedInverterIds?.[0]
    )?.manufacturer;

    // If the previous inverter selections are not all viable with the new inverters, then reset the form.
    const availableInverterIdSet = new Set(props.availableInverters.map((obj) => obj.id));
    const shouldResetForm = !selectedInvertersByManufacturer[selectedManufacturer]?.every((id) =>
      availableInverterIdSet.has(id)
    );
    if (shouldResetForm) {
      // If manual selection is no longer valid, reset back to the original list.
      dispatch(setSelectedInverters(undefined));
      setIsDirty(false);
    }
    if (
      !isDirty &&
      initialSelectedManufacturer !== undefined &&
      initialSelectedInverterIds !== undefined
    ) {
      setSelectedManufacturer(initialSelectedManufacturer);

      let initialSelectedInvertersByManufacturer = availableManufacturers.reduce(
        (acc: SelectedInverterIdsByManufacturer, manufacturer) => {
          acc[manufacturer] = [];
          return acc;
        },
        {}
      );
      initialSelectedInvertersByManufacturer[initialSelectedManufacturer] =
        initialSelectedInverterIds;
      setSelectedInvertersByManufacturer(initialSelectedInvertersByManufacturer);
    }
  }, [props.availableInverters, props.selectedInverterIds]);

  useEffect(() => {
    const selectedInverterIds = selectedInvertersByManufacturer[selectedManufacturer];
    if (selectedInverterIds?.length > 0) {
      const compatibleOptimizers =
        design?.getCompatiblePowerOptimizers(selectedInverterIds).map((id) => {
          return design?.getOptimizerSpec(id);
        }) ?? [];
      setAvailableOptimizers(compatibleOptimizers);

      if (selectedOptimizerIds?.length === 1 && !isDirty) {
        setSelectedOptimizer(selectedOptimizerIds[0]);
      }
    } else {
      setAvailableOptimizers([]);
      setSelectedOptimizer(DEFAULT_SELECT_OPTIMIZER);
    }
  }, [selectedManufacturer, selectedInvertersByManufacturer]);

  const availableManufacturers = [
    ...new Set(availableInverters.map((spec) => spec.manufacturer)),
  ].sort();
  const manufacturerOptions = availableManufacturers.map((manufacturer) => {
    return (
      <MenuItem key={manufacturer} value={manufacturer}>
        {manufacturer}
      </MenuItem>
    );
  });

  const availableOptimizerManufacturerAndModels: PowerOptimizerSpecification[] = Array.from(
    availableOptimizers
      .reduce((map, item) => {
        const key = item.id;
        if (!map.has(key)) {
          map.set(key, item);
        }
        return map;
      }, new Map())
      .values()
  );
  const optimizerOptions = availableOptimizerManufacturerAndModels.map((option) => {
    return (
      <MenuItem
        key={option.id}
        value={option.id}
      >{`${option.manufacturer} - ${option.model}`}</MenuItem>
    );
  });

  const filterInvertersByManufacturer = (manufacturer: string) => {
    return availableInverters
      .filter((spec) => spec.manufacturer === manufacturer)
      .sort(sortEquipmentSpecificationsByModel);
  };
  const invertersForSelectedManufacturer = filterInvertersByManufacturer(selectedManufacturer);

  const isMicroInverterSelected = invertersForSelectedManufacturer?.some((inverter) => {
    return inverter?.manufacturerSpecifications?.microInverter;
  });

  const isPowerwallPlus = invertersForSelectedManufacturer?.some((inverter) => {
    return isPowerwallPlusInverter(inverter?.model);
  });

  const showConfigureInverters =
    !isMicroInverterSelected &&
    !isPowerwallPlus &&
    isNewHomes &&
    enableManualConfigureInverters;

  const handleModelCheckboxToggle = (event: ChangeEvent<HTMLInputElement>) => {
    const id = event.target.name;
    setIsDirty(true);
    const updatedSelectedInvertersByManufacturer = {
      ...selectedInvertersByManufacturer,
    };
    const updatedSelectedIds = [...(selectedInvertersByManufacturer[selectedManufacturer] ?? [])];
    const index = updatedSelectedIds.indexOf(id);
    if (index === -1) {
      updatedSelectedIds.push(id);
    } else {
      updatedSelectedIds.splice(index, 1);
    }

    if (updatedSelectedIds.length === 0) {
      setHasError(true);
    } else {
      setHasError(false);
    }

    updatedSelectedInvertersByManufacturer[selectedManufacturer] = updatedSelectedIds;

    setSelectedInvertersByManufacturer(updatedSelectedInvertersByManufacturer);
  };

  const modelControls = invertersForSelectedManufacturer.map((spec) => {
    const { pvPowerSTC } = spec.manufacturerSpecifications;
    const productOption = inverterSpecToInverterType(spec);
    const wattsStcString = formatNumber({
      value: pvPowerSTC,
      maximumFractionDigits: 0,
    });
    const selectedInverterIds = selectedInvertersByManufacturer[selectedManufacturer] ?? [];

    return (
      <FormControlLabel
        key={spec.id}
        control={
          <Checkbox
            checked={selectedInverterIds.includes(spec.id)}
            onChange={handleModelCheckboxToggle}
            name={spec.id}
          />
        }
        label={`${spec.model} (${productOption} - ${wattsStcString}W STC)`}
      />
    );
  });

  const handleManufacturerChange = (event: SelectChangeEvent) => {
    setHasError(false);
    setIsDirty(true);
    setSelectedManufacturer(event.target.value);
  };

  const handleOptimizerChange = (event: SelectChangeEvent) => {
    setHasError(false);
    setIsDirty(true);
    setSelectedOptimizer(event.target.value);
  };

  const handleContinue = () => {
    const selectedInverterIds = selectedInvertersByManufacturer[selectedManufacturer];
    // Empty selected optimizers will correct back to no selected optimizers
    // If it is default selected, we need to pass the full list to compatibility.
    let selectedOptimizerIds: string[];
    if (selectedOptimizer && selectedOptimizer !== DEFAULT_SELECT_OPTIMIZER) {
      selectedOptimizerIds = [selectedOptimizer];
    } else if (selectedOptimizer && selectedOptimizer === DEFAULT_SELECT_OPTIMIZER) {
      selectedOptimizerIds = availableOptimizers.map((spec) => spec.id);
    } else {
      selectedOptimizerIds = [];
    }

    if (selectedInverterIds.length > 0) {
      // If user goes back to original select inverters to reset state and use automatic flow again
      dispatch(setSelectedInverters(undefined));
      props.onInverterChange(selectedInverterIds, selectedOptimizerIds);
    } else {
      setHasError(true);
    }
  };

  const handleClose = () => {
    setIsDirty(false);
    setHasError(false);
    setSelectedInvertersByManufacturer({});
    setSelectedManufacturer("");
    props.onClose();
  };

  const handleConfigure = () => {
    const selectedInverterIds = selectedInvertersByManufacturer[selectedManufacturer];
    if (selectedInverterIds.length > 0) {
      props.onClose();
      dispatch(openSelectManualInverterModal());
    } else {
      setHasError(true);
    }
  };

  const updateProducts = (event: React.MouseEvent) => {
    event.preventDefault();
    handleClose();
    postIFrameMessage(IFrameEventType.DESIGN_EDIT_CANCEL, {
      designId: props.designId,
      offerId: props.offerId,
    });
  };

  return !isConfigureSelectInverterOpen ? (
    <ModalLayout
      open={props.open}
      title="Select Available Inverters"
      onClose={handleClose}
      onContinue={handleContinue}
      actionButtons={true}
      cancelText="Cancel"
      continueText="Select Available Inverters"
      secondaryActionButton={showConfigureInverters}
      secondaryActionText="Configure Inverter Counts"
      onSecondaryAction={handleConfigure}
    >
      {availableInverters.length === 0 ? (
        <Typography variant="body2">No inverters available. Something went wrong.</Typography>
      ) : (
        <Box>
          <Stack spacing={EQUIPMENT_MODAL_SPACING}>
            <Typography variant="body2">
              Select which models may be used for this design. You may only select models from a
              single manufacturer.
            </Typography>
            <Typography variant="body2">
              Only showing inverters that are compatible with selected products,
              <br />
              <Link href="#" onClick={updateProducts}>
                update products
              </Link>{" "}
              to select other inverters.
            </Typography>
            {showConfigureInverters && (
              <Typography variant="body2">
                Based on your current inverter selections, you can manually configure the inverter
                count with the Configure Inverter Count button.
              </Typography>
            )}
            <Box pt={EQUIPMENT_MODAL_BOX_SIZING}>
              <FormControl fullWidth>
                <InputLabel id="inverter-manufacturer-label">Manufacturer</InputLabel>
                <Select
                  label="Manufacturer"
                  labelId="inverter-manufacturer-label"
                  onChange={handleManufacturerChange}
                  value={selectedManufacturer}
                >
                  {manufacturerOptions}
                </Select>
              </FormControl>
            </Box>
            {selectedManufacturer && (
              <>
                <FormControl
                  fullWidth
                  component="fieldset"
                  sx={{
                    maxHeight: EQUIPMENT_MODAL_FORM_SELECT_MAX_HEIGHT,
                    overflow: "auto",
                  }}
                >
                  <FormLabel component="legend">Model</FormLabel>
                  <Box px={EQUIPMENT_MODAL_BOX_SIZING}>
                    <FormGroup>{modelControls}</FormGroup>
                  </Box>
                </FormControl>
                {hasError && (
                  <FormHelperText error>At least one inverter is required</FormHelperText>
                )}
              </>
            )}
            {availableOptimizers.length > 1 && (
              <Box pt={EQUIPMENT_MODAL_BOX_SIZING}>
                <FormControl fullWidth>
                  <InputLabel id="power-optimizer-label">Power Optimizer</InputLabel>
                  <Select
                    label="Power Optimizer"
                    labelId="power-optimizer-label"
                    onChange={handleOptimizerChange}
                    value={selectedOptimizer}
                  >
                    <MenuItem key={DEFAULT_SELECT_OPTIMIZER} value={DEFAULT_SELECT_OPTIMIZER}>
                      {DEFAULT_SELECT_OPTIMIZER}
                    </MenuItem>
                    {optimizerOptions}
                  </Select>
                </FormControl>
              </Box>
            )}
          </Stack>
        </Box>
      )}
    </ModalLayout>
  ) : (
    <ConfigureManualInverterModal
      availableInverters={props.availableInverters}
      open={isConfigureSelectInverterOpen}
      selectedManufacturer={selectedManufacturer}
      selectedInvertersByManufacturer={selectedInvertersByManufacturer}
      defaultInverterChange={handleContinue} // Need this to call the original on inverter change
    ></ConfigureManualInverterModal>
  );
};
