import { FeatureGroup, Pane, useMap } from "react-leaflet";
import { setbackStripes } from "src/styles/stylingPatterns";
import { SiteModel } from "@sunrun/design-tools-domain-model";
import { LeafletLayerZIndexes } from "src/constants";
import { useWorkspace } from "src/hooks/useWorkspace";
import { SetbackObstructionLayer } from "./SetbackObstructionLayer";

type SetbackObstructionLayerGroupProps = {
  siteModel: SiteModel;
};

export const SetbackObstructionFeatureGroup = (props: SetbackObstructionLayerGroupProps) => {
  const map = useMap();
  setbackStripes.addTo(map);
  const { state } = useWorkspace();
  const { settings } = state;
  if (!settings.isObstructionsLayerVisible) return null;

  return (
    <Pane name="setbackObstructions" style={{ zIndex: LeafletLayerZIndexes.setbackObstructions }}>
      <FeatureGroup>
        {props.siteModel.setbacks
          .filter((setback) => !setback.properties.roofFaceId)
          .map((it) => (
            <SetbackObstructionLayer key={it.id} setback={it} />
          ))}
      </FeatureGroup>
    </Pane>
  );
};
