// based on https://lowrey.me/es6-javascript-combination-generator/
/**
 * Returns all combinations of `length` elements from `array`. E.g. for all possible pairs (order indifferent) use
 * length of 2. This returns a generator function, so you can invoke it in a for... of loop. You can also invoke it by
 * calling its next() method only as many times as you need in case you don't need to iterate every possible combination.
 * @param array elements to find combinations of
 * @param length number of elements in each combination
 */
export function* combinations<T>(array: T[], length: number): IterableIterator<T[]> {
  for (let i = 0; i < array.length; i++) {
    if (length === 1) {
      yield [array[i]!];
    } else {
      const remaining = combinations(array.slice(i + 1, array.length), length - 1);
      for (let next of remaining) {
        yield [array[i]!, ...next];
      }
    }
  }
}
