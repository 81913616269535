import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FinancialProductsRowDesignConstraints } from "./FinancialProductsRowDesignConstraints";
import {
  PvProduct,
  designConstraintTypesFinancialProductsModal,
  designConstraintTypesFlex,
  DesignConstraintType,
  FLEX_MONTHLY_DISPLAY_NAME,
  ProductsAvailability,
} from "@sunrun/design-tools-domain-model";
import {
  deriveDesignConstraintsClass,  
} from "src/features/designConstraints/designConstraintsSlice";
import { useWorkspace } from "src/hooks/useWorkspace";
import { FinancialProductsRowProductsAndEquipment } from "./FinancialProductsRowProductsAndEquipment";
import { deriveDesignClass } from "src/features/design/designSlice";
import { deriveLowResProductionSimulation } from "src/features/lowResProductionSimulation/deriveLowResProductionSimulation";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutline";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircleOutline";
import { FlagsmithFeatureEnums } from "src/config/flagsmithConfig";
import { useFlags } from "flagsmith/react";
import { IFrameHostType } from "src/types/IFrame";
import { deriveDesignFinancialProductTypes } from "src/features/productAvailability/productAvailabilitySlice";

export const FinancialProductsTable = ({
  fullscreen,
}: {
  fullscreen: boolean | undefined;
}) => {
  const { state } = useWorkspace();
  const { customer, offer, productAvailability } = state;
  const designConstraints = deriveDesignConstraintsClass(state);
  const lowResProductionSimulation = deriveLowResProductionSimulation(state);
  const design = deriveDesignClass(state);
  const isHostFlexCompatible = state.host === IFrameHostType.OFFER_BUILDER || state.host === IFrameHostType.LIGHTMILE_OFFER_BUILDER;
  const productAvailabilityHasFlexOption = ProductsAvailability.hasSunrunFlexFinancialProductOption(productAvailability)
  const showFlexInfo =
    useFlags([FlagsmithFeatureEnums.SHOW_FLEX_INFO])[
      FlagsmithFeatureEnums.SHOW_FLEX_INFO
    ].enabled && isHostFlexCompatible && productAvailabilityHasFlexOption;
  // Dictate column order for header row and financial product rows
  let financialProducts = 
  deriveDesignFinancialProductTypes(state);

  if (!showFlexInfo) {
    financialProducts = financialProducts?.filter(
      (product) => product !== PvProduct.FlexMonthly
    );
  }

  const isPvProductEligible = (product: PvProduct): boolean | undefined => {
    if (!design) return undefined;

    return designConstraints?.isPvProductEligible(
      design,
      lowResProductionSimulation,
      customer,
      product,
      offer
    );
  };

  const deriveIcon = (allowed?: boolean) => {
    return allowed !== undefined ? (
      <>
        {allowed ? (
          <CheckCircleIcon fontSize="medium" color="success" />
        ) : (
          <ReportProblemIcon fontSize="medium" color="error" />
        )}
      </>
    ) : (
      <>
        <RemoveCircleIcon fontSize="medium" color="disabled" />
        <Typography variant="body2">N/A</Typography>
      </>
    );
  };

  const getColumnLabel = (product: PvProduct): string => {
    if (product === PvProduct.FlexMonthly) return FLEX_MONTHLY_DISPLAY_NAME;
    return product;
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {financialProducts!.map((product) => (
              <TableCell
                key={product}
                sx={{ borderLeft: "1px solid rgba(224, 224, 224, 1)", px: 0 }}
              >
                <Box display="flex" justifyContent="center">
                  <Stack direction={"column"} alignItems="center" rowGap={1}>
                    {deriveIcon(isPvProductEligible(product))}
                    <Typography color="primary" variant="body2">
                      {getColumnLabel(product)}
                    </Typography>
                  </Stack>
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {designConstraintTypesFinancialProductsModal.map(
            (designConstraintType: DesignConstraintType) => (
              <FinancialProductsRowDesignConstraints
                key={designConstraintType}
                designConstraintType={designConstraintType}
                fullscreen={fullscreen}
                products={financialProducts!}
              />
            )
          )}
          {designConstraintTypesFlex.map(
            (designConstraintType: DesignConstraintType) => (
              <FinancialProductsRowProductsAndEquipment
                key={designConstraintType}
                designConstraintType={designConstraintType}
                fullscreen={fullscreen}
                products={financialProducts!}
              />
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
