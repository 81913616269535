import {ModalLayout} from "./components/ModalLayout";
import {useWorkspace} from "../../hooks/useWorkspace";
import { postIFrameMessage } from "src/hooks/useIFrameHost";
import { IFrameEventType } from "src/types/IFrame";
import { Offers } from "@sunrun/design-tools-domain-model";

type ProductEquipmentMappingErrorModalProps = {
  open: boolean;
}

export const ProductEquipmentMappingErrorModal = ({ open }: ProductEquipmentMappingErrorModalProps) => {
  const {state} = useWorkspace()
  const {design, offer, workflowState, modal} = state;
  const {productEquipmentMappingErrorModal} = modal;
  if (design && offer && workflowState.hasAvailableEquipmentLoaded && productEquipmentMappingErrorModal) {
    const bundleTitle = Offers.getBundleTitle(offer);
    const {error} = productEquipmentMappingErrorModal;

    const onClose = () => {
      // Return to OE product selection page
      // TODO: LS-1835 return to LM settings if hosted in LM
      postIFrameMessage(IFrameEventType.DESIGN_EDIT_CANCEL, {
        designId: design?.id,
        offerId: offer?.id,
      });
    };
    return (
      <ModalLayout
        open={open}
        title="Equipment not available"
        onClose={onClose}
        actionButtons
        cancelText="Update Products"
        fullscreen={true}
        error={error}
        displayCloseButton={false}
      >
        <>
          iHD could not find the right equipment to satisfy the Bundle option chosen ({bundleTitle}).<br/>
          <br/>
          Please return to product selection and try again with a different Bundle.<br/>
          <br/>
          If you believe you are seeing this message in error and should be able to create a design with the selected products, please reach out to support.<br/>
          <br/>
          { 
            !!error?.message &&
            <>
              Error:<br/>
              {error?.message}
            </>
          }
        </>
      </ModalLayout>
    )
  }
  return null
}
