import {G2Vector2D} from "./vector2D";
import {G2Polygon3D} from "./polygon3D";
import {G2CoordinateSystem2D} from "./crs2D";

export class G2Polygon2D {
  //by default, vertices is counter clock wise with last point not closing on first point
  readonly vertices: G2Vector2D[];

  constructor(vertices: G2Vector2D[]) {
    this.vertices = vertices
  }

  public centroid(): G2Vector2D {
    // exclude closing of the ring
    const numVertices = this.vertices.length
    const centroid = new G2Vector2D(
      this.vertices.reduce((prev, curr) => prev + curr.x, 0) / numVertices,
      this.vertices.reduce((prev, curr) => prev + curr.y, 0) / numVertices,
    )
    return centroid
  }

  //return a new polygon with vertices rotated
  //eg: vertices before: point1, point2, point3, point4, point5
  //after morph(1), it becomes: point2, point3, point4, point5, point1
  public morph(by: number = 0): G2Polygon2D {
    if (by === 0) {
      return this
    } else {
      return new G2Polygon2D(this.vertices.slice(by).concat(this.vertices.slice(0, by)))
    }
  }

  //strict equals, starting from the same point
  public equals(polygon: G2Polygon2D): boolean {
    return !this.vertices.map((point, index) => {
      return point.equals(polygon.vertices[index])
    }).some((it) => !it)
  }

  public equalsIgnoreStartingPoint(polygon: G2Polygon2D): boolean {
    const len = this.vertices.length
    for (let i = 0; i < len; i++) {
      if (this.equals(polygon.morph(i))) {
        return true
      }
    }
    return false
  }

  //compute the polygon area of a 2d polygon
  public area(): number {
    // this is an implementation of the "shoelace formula" for determining the area of a polygon given corner vertices
    // https://en.wikipedia.org/wiki/Shoelace_formula.  This follows the Trapezoid Shoelace formula.
    let total = 0;
    for (let i = 0, l = this.vertices.length; i < l; i++) {
      const xi = this.vertices[i].x
      const yi = this.vertices[i].y
      const next_i = i == this.vertices.length - 1 ? 0 : i + 1;
      const xi1 = this.vertices[next_i].x
      const yi1 = this.vertices[next_i].y
      total += (yi + yi1) * (xi - xi1) * 0.5
    }
    return total;
  }

  //test whether a 2d polygon contains a point
  //https://wrfranklin.org/Research/Short_Notes/pnpoly.html
  public containsPointNotOnEdge(point: G2Vector2D): boolean {
    const nvert = this.vertices.length
    let i, j, c = false;
    for (i = 0, j = nvert - 1; i < nvert; j = i++) {
      if (((this.vertices[i].y > point.y) != (this.vertices[j].y > point.y)) &&
        (point.x < (this.vertices[j].x - this.vertices[i].x) * (point.y - this.vertices[i].y) / (this.vertices[j].y - this.vertices[i].y) + this.vertices[i].x))
        c = !c;
    }
    return c;
  }

  public containsPointOnEdge(point: G2Vector2D): boolean {
    return this.toPolygon3D().edges().some(edge => edge.containsBetweenTwoPointsOnLine(point.toVector3D()))
  }

  public containsPoint(point: G2Vector2D): boolean {
    const isInternalPoint = this.containsPointNotOnEdge(point)
    const isEdgePoint = this.containsPointOnEdge(point)
    return isInternalPoint || isEdgePoint
  }

  public containsPolygon(polygon: G2Polygon2D): boolean {
    return polygon.vertices.every(point => this.containsPoint(point))
  }

  //partial contain
  public overlapsPolygon(polygon: G2Polygon2D): boolean {
    return polygon.vertices.some(point => this.containsPoint(point)) && polygon.vertices.some(point => !this.containsPoint(point))
  }

  //this method obly project to z=0 3D plane
  public toPolygon3D(): G2Polygon3D {
    return new G2Polygon3D(
      this.vertices.map((point) => {
        return point.toVector3D()
      })
    )
  }

  public bbox(): G2Polygon2D {
    const minX = Math.min(...this.vertices.map(point => point.x))
    const maxX = Math.max(...this.vertices.map(point => point.x))
    const minY = Math.min(...this.vertices.map(point => point.y))
    const maxY = Math.max(...this.vertices.map(point => point.y))
    const lowerLeft = new G2Vector2D(minX, minY)
    const lowerRight = new G2Vector2D(maxX, minY)
    const upperRight = new G2Vector2D(maxX, maxY)
    const upperLeft = new G2Vector2D(minX, maxY)
    return new G2Polygon2D([
      lowerLeft,
      lowerRight,
      upperRight,
      upperLeft
    ])
  }

  //minimum bounding Rectangle of the polygon aligned with supplied x axis which is local to the polygon
  //for convenience, here we still return a G2Polygon2D because G2Rectangle is G2Rcetangle3D
  public toRect(xAxis: G2Vector2D): G2Polygon2D {
    const thisCRS = new G2CoordinateSystem2D(
      new G2Vector2D(0, 0),
      new G2Vector2D(1, 0),
      new G2Vector2D(0, 1)
    )
    //convertToCRS is so cool, it converts to a new CRS and tell you how to convert back to this CRS
    const {convertedCRS, convertedBackCRS} = thisCRS.convertToCRS(
      this.centroid(),    //origin
      (new G2Vector2D(1, 0)).anglesCounterClockWiseInRadiansToVector(xAxis)  //rotate
    )
    const thisPolygonProjectedToNewCRS = new G2Polygon2D(
      //vertices are in thisCRS
      this.vertices.map(point =>
        point.projectToCRS(convertedCRS)
      )
    )
    //bbox thisPolygonProjectedToNewCRS and then convert back to thisCRS
    return new G2Polygon2D(
      thisPolygonProjectedToNewCRS.bbox().vertices.map(point =>
        //vertices are in newCRS
        point.projectToCRS(convertedBackCRS)
      )
    )
  }
}
