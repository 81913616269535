import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import hostReducer from "./features/host/hostSlice";
import designConstraintsReducer from "./features/designConstraints/designConstraintsSlice";
import productionSimulationReducer from "./features/productionSimulation/productionSimulationSlice";
import mostRecentSignedDesignReducer from "./features/mostRecentSignedDesign/mostRecentSignedDesign";
import availableEquipmentReducer from "./features/equipment/availableEquipmentSlice";
import siteModelReducer from "./features/siteModel/siteModelSlice";
import solarResourceReducer from "./features/solarResource/solarResourceSlice";
import siteSurveyReducer from './features/siteSurvey/siteSurveySlice';
import solarResourceExpandedReducer from "./features/solarResourceExpanded/solarResourceExpandedSlice";
import undoDesignReducer from "./features/undo/undoDesignSlice";
import customerReducer from "./features/customer/customerSlice";
import bookmarkedDesignsReducer from "./features/bookmark/bookmarkSlice";
import geoRasterReducer from "./features/geoRaster/geoRasterSlice";
import promotionsReducer from "./features/promotions/promotionsSlice";
import offerReducer from "./features/offer/offerSlice";
import modalReducer from "./features/modal/modalSlice";
import notificationReducer from "./features/notifications/notificationSlice";
import settingsReducer from "./features/settings/settingsSlice";
import designReducer from "./features/design/designSlice";
import workflowStateReducer from "./features/workflowState/workflowStateSlice";
import hacksReducer from "./features/hacks/hacksReducerSlice";
import moduleNudgeReducer from "./features/moduleNudge/moduleNudgeSlice";
import heatMapReducer from "./features/heatMap/heatMapSlice";
import marqueeReducer from "./features/marquee/marqueeSlice";
import moduleSelectionReducer from "./features/moduleSelection/moduleSelectionSlice";
import moduleDragReducer from "./features/moduleDrag/moduleDragSlice";
import setbackRulesReducer from "./features/setbackRules/setbackRulesSlice";
import displayReducer from "./features/display/displaySlice";
import selectedEquipmentReducer from "./features/equipment/selectedEquipmentSlice";

export const store = configureStore({
  reducer: {
    host: hostReducer,
    mostRecentSignedDesign: mostRecentSignedDesignReducer,
    availableEquipment: availableEquipmentReducer,
    productionSimulation: productionSimulationReducer,
    designConstraints: designConstraintsReducer,
    siteModel: siteModelReducer,
    solarResource: solarResourceReducer,
    siteSurvey: siteSurveyReducer,
    solarResourceExpanded: solarResourceExpandedReducer,
    undoDesign: undoDesignReducer,
    setbackRulesReducer: setbackRulesReducer,
    display: displayReducer,
    customer: customerReducer,
    bookmarkedDesigns: bookmarkedDesignsReducer,
    geoRaster: geoRasterReducer,
    promotions: promotionsReducer,
    offer: offerReducer,
    modal: modalReducer,
    notification: notificationReducer,
    settings: settingsReducer,
    design: designReducer,
    workflowState: workflowStateReducer,
    hacks: hacksReducer,
    moduleNudge: moduleNudgeReducer,
    heatMap: heatMapReducer,
    marquee: marqueeReducer,
    moduleSelection: moduleSelectionReducer,
    moduleDragSelection: moduleDragReducer,
    selectedEquipment: selectedEquipmentReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
