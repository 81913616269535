import { LoadingScreen } from "@sunrun/design-tools-loading-screen";
import { ReactNode, useEffect } from "react";
import {
  ErrorModal,
  FinancialProductsModal,
  HotkeyDescriptionsModal,
  InfoModal,
  ModuleIssuesModal,
  OfferUpdateConfirmationModal,
  WhatsNewModal,
  CancelContinueModal,
  DesignWarningsModal,
  SelectDesignVariantModal,
} from "./Modal";
import { useWorkspace } from "src/hooks/useWorkspace";
import { Container, useMediaQuery, useTheme } from "@mui/material";
import Toolbar, { WorkflowButtonProps } from "./Toolbar";
import FloatingActionButtons from "./FloatingActionButtons";
import { DesignToolsTheme } from "@sunrun/design-tools-themes";
import { ProductEquipmentMappingErrorModal } from "./Modal/ProductEquipmentMappingErrorModal";
import { useSelectLightmileDesign } from "src/hooks/useSelectLightmileDesign";
import { deriveIsDesignReadyForFinalize } from "src/features/designGuidance/deriveIsDesignReadyForFinalize";
import { BookmarkDesignModal } from "./Modal/BookmarkDesignModal/BookmarkDesignModal";
import { useFlags } from "flagsmith/react";
import { AppAlert, FlagsmithFeatureEnums } from "src/config/flagsmithConfig";
import { LeftFloatingActionButtons } from "./LeftFloatingActionButtons";
import SetbackRulesModal from "./Modal/SetbacksRuleModal";
import FinancialProductsPanel from "./FinancialProductsPanel";
import { useAppDispatch } from "src/store";
import { closeDesignWarningsModal, closeHotKeyDescriptions, closeSetbackRules, closeWhatsNew, dismissBookmarkDesigns, dismissCancelContinueModal, dismissErrorModal, dismissFinancialProducts, dismissInfoModal, dismissModuleIssues, dismissOfferUpdateConfirmationModal } from "src/features/modal/modalSlice";
import { deriveIsDesignFinalized } from "src/features/designGuidance/deriveIsDesignFinalized";

type WorkspaceProps = {
  children: ReactNode;
  simulateDesign: () => void;
  exportDesign: () => void;
  createDesignImages: () => void;
  legacyFinalizeDesign: () => void;
  recalculateSetbacks: () => void;
};

const Workspace = ({
  children,
  simulateDesign,
  exportDesign,
  createDesignImages,
  legacyFinalizeDesign,
  recalculateSetbacks,
}: WorkspaceProps) => {
  const {state, dispatch: workspaceDispatch} = useWorkspace();
  const dispatch = useAppDispatch();
  const {
    design,
    siteModel,
    geoRaster,
    solarResource,
    productionSimulation,
    customer,
    designConstraints,
  } = state;
  const { setLocalStateAndSelectLightmileDesign } = useSelectLightmileDesign();
  const theme = useTheme<DesignToolsTheme>();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isFinalized = deriveIsDesignFinalized(state)

  const workflowButtonProps: WorkflowButtonProps = {
    disableFinalizeDesignButton: !deriveIsDesignReadyForFinalize(state),
    simulateDesign,
    exportDesign,
    createDesignImages,
    legacyFinalizeDesign,
    recalculateSetbacks,
  };

  window.scrollTo(1, 0);
  const allowBookmarkDesign = useFlags([FlagsmithFeatureEnums.ENABLE_BOOKMARK_DESIGN])[FlagsmithFeatureEnums.ENABLE_BOOKMARK_DESIGN].enabled;
  const financialProductsPanelEnabled = useFlags([FlagsmithFeatureEnums.ENABLE_FINANCIAL_PRODUCTS_PANEL])[FlagsmithFeatureEnums.ENABLE_FINANCIAL_PRODUCTS_PANEL].enabled;
  const displaySystemMessageFlag = useFlags([FlagsmithFeatureEnums.DISPLAY_SYSTEM_MESSAGE]);
  const displaySystemMessage = displaySystemMessageFlag[FlagsmithFeatureEnums.DISPLAY_SYSTEM_MESSAGE].enabled;
  const displaySystemMessageValue = displaySystemMessageFlag.design_tools_display_system_message?.value;

  useEffect(() => {
    if(displaySystemMessage) {
      try {
        const appAlert = JSON.parse(displaySystemMessageValue as string) as AppAlert;
        if (appAlert.title && appAlert.content) {
          dispatch({type: "setInfoModal", payload: {
            title: appAlert.title,
            message: appAlert.content
          }})
        } else {
          console.error(`Could not parse system message title and content from Flagsmith: `, displaySystemMessageValue)
        }
      } catch (e) {
        console.error(`Could not parse flagsmith system message value.`)
      }
    }
  }, [displaySystemMessageFlag]);

  // Reset the inverters to a low res state
  useEffect(() => {
    if (design && !isFinalized && state.workflowState.hasDesignFinalized) {
      workspaceDispatch({ type: "resetToLowResEquipment" });
    }
  }, [isFinalized]);
  
    return (
    <div className="workspace">
      <LoadingScreen />
      <InfoModal
        title={state.modal.infoModal?.title}
        message={state.modal.infoModal?.message}
        open={!!state.modal.infoModal}
        onClose={() => {
          dispatch(dismissInfoModal())
          workspaceDispatch({ type: "dismissInfoModal" })}
        } 
      />
      {allowBookmarkDesign && (
        <BookmarkDesignModal
          open={state.modal.isBookmarkDesignOpen}
          onClose={() => {
            dispatch(dismissBookmarkDesigns())
            workspaceDispatch({ type: "dismissBookmarkDesigns" })}
          }
        />
      )}
      <FinancialProductsModal
        open={state.modal.financialProductsOpen}
        onClose={() => {
          dispatch(dismissFinancialProducts())
          workspaceDispatch({ type: "dismissFinancialProducts" })}
        }
      />
      <OfferUpdateConfirmationModal
        open={
          state.modal.isOfferUpdateConfirmationModalOpen &&
          !state.modal.isSelectDesignVariantModalOpen
        }
        onClose={() => {
          dispatch(dismissOfferUpdateConfirmationModal())
          workspaceDispatch({ type: "dismissOfferUpdateConfirmationModal" })
        }}
      />
      <ProductEquipmentMappingErrorModal
        open={state.modal.isProductEquipmentMappingErrorModalOpen}
      />
      <WhatsNewModal
        open={state.modal.isWhatsNewOpen}
        onClose={() => {
          dispatch(closeWhatsNew())
          workspaceDispatch({ type: "closeWhatsNew" })}
        }
      />
      <HotkeyDescriptionsModal
        open={state.modal.isHotkeyDescriptionsOpen}
        onClose={() => {
          dispatch(closeHotKeyDescriptions())
          workspaceDispatch({ type: "closeHotKeyDescriptions" })}
        }
      />
      <ModuleIssuesModal
        open={state.modal.isModuleIssuesOpen}
        onClose={() => {
          dispatch(dismissModuleIssues())
          workspaceDispatch({ type: "dismissModuleIssues" })}
        }
      />
      <CancelContinueModal
        title={state.modal.cancelContinueModal?.title}
        message={state.modal.cancelContinueModal?.message}
        open={!!state.modal.cancelContinueModal}
        cancelText={state.modal.cancelContinueModal?.cancelText}
        continueText={state.modal.cancelContinueModal?.continueText}
        onContinue={() => {
          (state.modal.cancelContinueModal?.onContinue ?? (()=>{}))();
          dispatch(dismissCancelContinueModal());
          workspaceDispatch({ type: "dismissCancelContinueModal" });
        }}
        onClose={() => {
          dispatch(dismissCancelContinueModal());
          workspaceDispatch({ type: "dismissCancelContinueModal" });
        }}
      />
      <DesignWarningsModal
        open={state.modal.isDesignWarningsModalOpen}
        onClose={() => {
          dispatch(closeDesignWarningsModal())
          workspaceDispatch({ type: "closeDesignWarningsModal" })}
        }
      />
      <SelectDesignVariantModal
        open={state.modal.isSelectDesignVariantModalOpen}
        defaultVariant={state.modal.selectDesignVariantDefault}
        onClose={setLocalStateAndSelectLightmileDesign}
      />
      <SetbackRulesModal
        open={state.modal.isSetbackRulesOpen}
        onClose={() => {
          dispatch(closeSetbackRules())
          workspaceDispatch({ type: "closeSetbackRules" })}
        }
      />
      <ErrorModal
        title={state.modal.errorModal?.title}
        message={state.modal.errorModal?.message}
        error={state.modal.errorModal?.error}
        open={!!state.modal.errorModal}
        onClose={() => {
          dispatch(dismissErrorModal())
          workspaceDispatch({ type: "dismissErrorModal" })}
        }
      />
      {design && siteModel && geoRaster && (
        <>
          {children}
          {solarResource &&
            productionSimulation &&
            customer &&
            designConstraints && (
              <Container
                maxWidth="xl"
                disableGutters={isXs}
                sx={{
                  py: isXs ? 0 : 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100dvh",
                }}
              >
                <Toolbar {...workflowButtonProps} />
                {financialProductsPanelEnabled && <FinancialProductsPanel/>}
                <FloatingActionButtons {...workflowButtonProps} />
                <LeftFloatingActionButtons />
              </Container>
            )}
        </>
      )}
    </div>
  );
};

export default Workspace;
