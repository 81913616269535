import { RoofFace } from "@sunrun/design-tools-domain-model";
import { useWorkspace } from "src/hooks/useWorkspace";
import { LeafletEventHandlerFnMap, PathOptions } from "leaflet";
import { GeoJsonLayer } from "src/components/GeoJsonLayer";
import React from "react";
import { setbackStripes } from "src/styles/stylingPatterns";
import { createEventHandlers } from "./LeafletEventHandler";
import { InteractiveLeafletLayer } from "../types/state-management/action";
import { useMap } from "react-leaflet";

export type RoofFaceProps = {
  roofFace: RoofFace;
};

const roofFaceStyleFunctions = (
  roofFace: RoofFace,
  isHeatMapVisible: boolean,
  isRecalculateFirecodeSetbacksEnabled: boolean,
): PathOptions => {
  if (roofFace.properties.usable) {
    const fillOpacity = isHeatMapVisible ? 0.1 : 0.5;
    return { color: "#292929", opacity: 0.5, fillColor: "#FFFFFF", fillOpacity };
  } else {
    const fillOpacity = isHeatMapVisible ? 0.1 : 0.4;
    return isRecalculateFirecodeSetbacksEnabled
      ? {
          color: "#ea3434",
          opacity: fillOpacity,
          fillPattern: undefined,
          fillColor: "#000000",
          fillOpacity,
        }
      : {
          color: "#EA3434",
          opacity: fillOpacity,
          fillPattern: setbackStripes,
          fillColor: undefined,
          fillOpacity,
        };
  }
};

export const RoofFaceLayer = ({ roofFace }: RoofFaceProps) => {
  const { state, dispatch } = useWorkspace();
  const recalculateFirecodeSetbacksOptIn = state.settings.isRecalculateFirecodeSetbacksEnabled;
  const map = useMap();
  const [eventHandlers] = React.useState<LeafletEventHandlerFnMap>(
    createEventHandlers(InteractiveLeafletLayer.roofFace, dispatch, map),
  );
  const [pathOption, setPathOption] = React.useState<PathOptions>();

  React.useEffect(() => {
    setPathOption(
      roofFaceStyleFunctions(roofFace, state.heatMap.isVisible, recalculateFirecodeSetbacksOptIn),
    );
  }, [state.heatMap.isVisible, roofFace, recalculateFirecodeSetbacksOptIn]);

  return (
    <GeoJsonLayer
      data={roofFace}
      eventHandlers={eventHandlers}
      pathOptions={pathOption}
      key={roofFace.id}
    />
  );
};
