
export const sortBy = <T>(arr: T[], pathToSortProperty: string, transformFn: (a: number) => number = (a) => a): T[] => {
  return arr.sort((a, b) => {
    const valueA = nestedValue(a, pathToSortProperty)
    const valueB = nestedValue(b, pathToSortProperty)
    if (valueA === null || valueA === undefined) {
      return 1;
    }
    if (valueB === null || valueB === undefined) {
      return -1;
    }
    const transformedA = transformFn(valueA)
    const transformedB = transformFn(valueB)
    if (transformedA < transformedB) return -1
    if (transformedA > transformedB) return 1
    return 0
  })
}

const nestedValue = (object: any, key: string) => {
  return key.split('.').reduce((obj, property) => obj[property], object)
}
