import {WorkspaceEmptyAction, WorkspacePayloadAction, WorkspaceEvent} from "../../types/state-management/action";
import {produce} from "immer";
import {LoadedWorkspaceState, WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {Position,} from "@sunrun/design-tools-geometry";
import {Module} from "@sunrun/design-tools-domain-model";
import { deriveDesignClass } from "../design/designSlice";
import { createSlice } from "@reduxjs/toolkit";

type ModuleDragState = {
  isPointerDown: boolean
  isDragging: boolean
  draggedModuleId?: string
  originalDraggedModuleCenter?: [number, number]
  startPosition?: Position
  dragPosition?: Position
}

const initialState = {
  isPointerDown: false,
  isDragging: false,
  draggedModuleId: undefined,
  originalDraggedModuleCenter: undefined,
  startPoint: undefined,
  dragPoint: undefined
}

export const moduleDragSlice = createSlice({
  name: "moduleDrag",
  initialState,
  reducers: {
    resetModuleDrag: (state: ModuleDragState) => {
      state = dragInitialState;
    },
    //TODO: move more actions https://sunrun.jira.com/browse/ET-1664
  }
});

export const { resetModuleDrag } = moduleDragSlice.actions
export default moduleDragSlice.reducer

// TODO: Remove the following and transition fully from WorkspaceState to RTK store
export type ModuleDragWorkspaceAction =
  | WorkspacePayloadAction<WorkspaceEvent, 'initModuleDrag'>
  | WorkspacePayloadAction<WorkspaceEvent, 'dragModules'>
  | WorkspaceEmptyAction<'resetModuleDrag'>

export type ModuleDrag = {
  isPointerDown: boolean
  isDragging: boolean
  draggedModuleId?: string
  // interesting: using Readonly Vector2D seems internally incompatible with immer...
  originalDraggedModuleCenter?: [number, number]
  startPosition?: Position
  dragPosition?: Position
}

export const dragInitialState = {
  isPointerDown: false,
  isDragging: false,
  draggedModuleId: undefined,
  originalDraggedModuleCenter: undefined,
  startPoint: undefined,
  dragPoint: undefined
}

export const selectIsLeafletDragging = (state: WorkspaceState) => state.moduleDrag.isDragging

export const dragWorkspaceReducer = (state: WorkspaceState, action: WorkspaceAction): ModuleDrag => {  
  const design = deriveDesignClass(state);
  switch (action.type) {
    case "initModuleDrag": {
      if(!design) return state.moduleDrag;
      const event = action.payload
      const layer = event.propagatedFrom.layer as Module
      const moduleId = layer.id
      const draggedModuleCentroid = design.getModuleById(moduleId)!.centroid() as [number, number];
      return produce(state.moduleDrag, (draft) => {
        draft.isPointerDown = true
        draft.startPosition = event.position
        draft.draggedModuleId = moduleId
        draft.originalDraggedModuleCenter = draggedModuleCentroid
      });
    }

    case "dragModules": {
      return produce(state.moduleDrag, (draft) => {
        draft.isDragging = true
        draft.dragPosition = action.payload.position
      })
    }

    case "resetModuleDrag": {
      // reset to initial state
      return {...dragInitialState}
    }

    default: {
      return state.moduleDrag
    }
  }
}

