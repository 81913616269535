import {Design} from "@sunrun/design-tools-domain-model";
import {useCallback,} from "react";
import {useWorkspace} from "./useWorkspace"
import * as FullStory from "@fullstory/browser";
import {deriveCollidingModuleDetailCounts} from "src/features/designGuidance/deriveCollidingModules";
import {useMaxFill} from "./useMaxFill";
import { deriveReadOnlyDesignClassSets } from "src/features/readOnlyDesignSet/readOnlyDesignSetSlice";
import { useAppDispatch } from "src/store";
import { designInitiatedWithCollidingModules } from "src/features/workflowState/workflowStateSlice";


export const useHandleDesignInitMetrics = () => {
  const {state, dispatch: workspaceDispatch} = useWorkspace();
  const dispatch = useAppDispatch();
  const {design, siteModel, host} = state;
  const {copyFromDesign, signedRootDesignSet} = deriveReadOnlyDesignClassSets(state)
  const {handleMaxFill} = useMaxFill();

  // Log specific types of modules issues in order to better categorize red x failures
  const handleDesignInitMetrics = useCallback (({initialDesign, copyDesign, signedRootDesign}: {initialDesign: Design, copyDesign?: Design, signedRootDesign?: Design}) =>{
      const initialDesignCollisionCounts = deriveCollidingModuleDetailCounts({...state, design: initialDesign})
      const initialDesignCollidingModulesCount = initialDesignCollisionCounts.collisionsCount
      const initialDesignSiteModelCollisionsCount = initialDesignCollisionCounts.siteModelCollisionsCount
      const initialDesignObstructionCollisionsCount = initialDesignCollisionCounts.obstructionCollisionsCount
      const initialDesignModulesOffRoofFaceCount = initialDesignCollisionCounts.modulesOffRoofFaceCount
      const initialDesignInterModuleCollisionsCount = initialDesignCollisionCounts.interModuleCollisionsCount
      const initialDesignModuleCount =  initialDesign.moduleCount 

    let copyFromDesignCollidingModulesCount, copyFromDesignSiteModelCollisionsCount, copyFromDesignObstructionCollisionsCount, copyFromDesignModulesOffRoofFaceCount, copyFromDesignInterModuleCollisionsCount, copyFromDesignModuleCount
    if (copyDesign) {
      const copyFromDesignCollisionCounts =  deriveCollidingModuleDetailCounts({...state, design: copyDesign})
      copyFromDesignCollidingModulesCount = copyFromDesignCollisionCounts.collisionsCount
      copyFromDesignSiteModelCollisionsCount = copyFromDesignCollisionCounts.siteModelCollisionsCount
      copyFromDesignObstructionCollisionsCount = copyFromDesignCollisionCounts.obstructionCollisionsCount
      copyFromDesignModulesOffRoofFaceCount = copyFromDesignCollisionCounts.modulesOffRoofFaceCount
      copyFromDesignInterModuleCollisionsCount = copyFromDesignCollisionCounts.interModuleCollisionsCount
      copyFromDesignModuleCount =  copyDesign.moduleCount
    }

    let signedRootDesignCollidingModulesCount, signedRootDesignSiteModelCollisionsCount, signedRootDesignObstructionCollisionsCount, signedRootDesignModulesOffRoofFaceCount, signedRootDesignInterModuleCollisionsCount, signedRootDesignModuleCount
    if (signedRootDesign) {
      const signedRootDesignCollisionCounts = deriveCollidingModuleDetailCounts({...state, design: signedRootDesign})
      signedRootDesignCollidingModulesCount = signedRootDesignCollisionCounts.collisionsCount
      signedRootDesignSiteModelCollisionsCount = signedRootDesignCollisionCounts.siteModelCollisionsCount
      signedRootDesignObstructionCollisionsCount = signedRootDesignCollisionCounts.obstructionCollisionsCount
      signedRootDesignModulesOffRoofFaceCount = signedRootDesignCollisionCounts.modulesOffRoofFaceCount
      signedRootDesignInterModuleCollisionsCount = signedRootDesignCollisionCounts.interModuleCollisionsCount
      signedRootDesignModuleCount = signedRootDesign.moduleCount
    }

    // calculate maxfill for selected Design. When a copyDesign is present we show copyDesign to the user otherwise we show initialDesign 
    const maxFillDesign = handleMaxFill(copyDesign || initialDesign, true);
    const maxFillDesignCollisionCounts = deriveCollidingModuleDetailCounts({...state, design: maxFillDesign})
    const maxFillDesignCollidingModulesCount = maxFillDesignCollisionCounts.collisionsCount
    const maxFillDesignSiteModelCollisionsCount = maxFillDesignCollisionCounts.siteModelCollisionsCount
    const maxFillDesignObstructionCollisionsCount = maxFillDesignCollisionCounts.obstructionCollisionsCount
    const maxFillDesignModulesOffRoofFaceCount = maxFillDesignCollisionCounts.modulesOffRoofFaceCount
    const maxFillDesignInterModuleCollisionsCount = maxFillDesignCollisionCounts.interModuleCollisionsCount
    const maxFillDesignModuleCount = maxFillDesign.moduleCount
    

    FullStory.event("Design, Parent, Signed Root Red X Details", {
      host,
      prospectId: siteModel?.prospectId,
      designId: design?.id, 
      copyFromDesignId: copyFromDesign?.id, 
      signedRootDesignId: signedRootDesignSet?.design.id, 

      initialDesignModuleCount,
      initialDesignCollidingModulesCount, 
      initialDesignSiteModelCollisionsCount, 
      initialDesignObstructionCollisionsCount, 
      initialDesignModulesOffRoofFaceCount, 
      initialDesignInterModuleCollisionsCount,

      copyFromDesignCollidingModulesCount,
      copyFromDesignModuleCount,
      copyFromDesignSiteModelCollisionsCount,
      copyFromDesignObstructionCollisionsCount,
      copyFromDesignModulesOffRoofFaceCount,
      copyFromDesignInterModuleCollisionsCount,

      signedRootDesignCollidingModulesCount,
      signedRootDesignModuleCount,
      signedRootDesignSiteModelCollisionsCount,
      signedRootDesignObstructionCollisionsCount,
      signedRootDesignModulesOffRoofFaceCount,
      signedRootDesignInterModuleCollisionsCount,

      maxFillDesignCollidingModulesCount,
      maxFillDesignModuleCount,
      maxFillDesignSiteModelCollisionsCount,
      maxFillDesignObstructionCollisionsCount,
      maxFillDesignModulesOffRoofFaceCount,
      maxFillDesignInterModuleCollisionsCount,
    })

    // Keep previous red X metric so we have access to historical trends
    const collidingModulesFromUpdatedDesign = copyFromDesign ? copyFromDesignCollidingModulesCount : initialDesignCollidingModulesCount
    if (collidingModulesFromUpdatedDesign && collidingModulesFromUpdatedDesign > 0){
      FullStory.event("Design Loads With Red X", {designId: design?.id, host: host, moduleCount: collidingModulesFromUpdatedDesign})
      dispatch(designInitiatedWithCollidingModules());
      workspaceDispatch({type: 'designInitiatedWithCollidingModules'});
    }
  }, [siteModel, design, copyFromDesign, signedRootDesignSet])

  return {
    handleDesignInitMetrics
  }
}