import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {WorkspacePayloadAction} from "../../types/state-management/action";
import {GeoRaster} from "@sunrun/design-tools-domain-model";

type GeoRasterState  = {
  value: GeoRaster | undefined;
}

const initialState: GeoRasterState = {
  value: undefined,
};

export const geoRasterSlice = createSlice({
  name: "geoRaster",
  initialState,
  reducers: {
    setGeoRaster: (state: GeoRasterState, action: PayloadAction<GeoRaster>) => {
      state.value = action.payload
    },
  }
});

export const { setGeoRaster } = geoRasterSlice.actions
export default geoRasterSlice.reducer

// TODO: Remove the following and transition fully from WorkspaceState to RTK store
export type GeoRasterWorkspaceAction = WorkspacePayloadAction<GeoRaster, 'setGeoRaster'>

export const geoRasterWorkspaceReducer = (state: WorkspaceState, action: WorkspaceAction): GeoRaster | undefined => {
  switch (action.type) {
    case 'setGeoRaster': {
      return action.payload
    }
    default: {
      return state.geoRaster
    }
  }
}
