import { IconButton, Menu } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useWorkspace } from "src/hooks/useWorkspace";
import { DesignMenuItemComponent } from "./DesignMenuItemComponent";
import { isMacOs, isMobile } from "react-device-detect";
import { useAppDispatch } from "src/store";
import {
  setIsRecalculateFirecodeSetbacksEnabled,
  toggleIsFilter10kLogicEnabled,
  toggleIsMagneticSlideEnabled,
  toggleIsMagneticSnapEnabled,
  toggleModuleOrientationSetting,
} from "src/features/settings/settingsSlice";
import {
  openHotKeyDescriptions,
  openSetbackRules,
  openWhatsNew,
} from "src/features/modal/modalSlice";
import { useRegenerateLightmileDesign } from "src/hooks/useRegenerateDesign";
import { useFlags } from "flagsmith/react";
import { FlagsmithFeatureEnums } from "src/config/flagsmithConfig";
import { IFrameHostType } from "src/types/IFrame";
import {
  designNotFinalized,
  setRequiresRecalculateSetbacks,
} from "src/features/workflowState/workflowStateSlice";
import { setIsDesignMenuExpanded } from "src/features/display/displaySlice";
import { deriveDesignClass } from "src/features/design/designSlice";
import { useCopyAndSyncDesignAndSiteModel } from "src/hooks/useCopyAndSyncDesignAndSiteModel";
import { selectIsOfferExperience } from "src/features/host/hostSlice";

export type DesignMenuItem = {
  title: string;
  value?: string;
  action: () => void;
  hotkey?: string | undefined;
};

export const DesignMenu = () => {
  const { regenerateDesigns } = useRegenerateLightmileDesign();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { state, dispatch: workspaceDispatch } = useWorkspace();
  const design = deriveDesignClass(state);
  const dispatch = useAppDispatch();
  const { copyAndSyncDesignAndSiteModel } = useCopyAndSyncDesignAndSiteModel();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    dispatch(setIsDesignMenuExpanded(true));
  };
  const handleClose = () => {
    setAnchorEl(null);
    dispatch(setIsDesignMenuExpanded(false));
  };

  const enableRegenerateDesign = useFlags([
    FlagsmithFeatureEnums.ENABLE_REGENERATE_DESIGNS,
  ])[FlagsmithFeatureEnums.ENABLE_REGENERATE_DESIGNS].enabled;

  const showFireSetbackRefresh = useFlags([
    FlagsmithFeatureEnums.ENABLE_FIRE_SETBACK,
  ])[FlagsmithFeatureEnums.ENABLE_FIRE_SETBACK].enabled;

  const isOfferExperience = selectIsOfferExperience(state);

  const getSystemHotkey = (hotkey: string) => {
    const modifierString = isMacOs ? "⌘" : "Ctrl+";
    if (isMobile) {
      return undefined;
    }
    return modifierString + hotkey;
  };

  const designMenuItems: DesignMenuItem[] = [
    {
      title: "Default Orientation",
      value: state.settings.moduleOrientation,
      action: () => {
        dispatch(toggleModuleOrientationSetting());
        workspaceDispatch({ type: "toggleModuleOrientationSetting" });
      },
      hotkey: getSystemHotkey("R"),
    },
    {
      title: "Magnetic Snap",
      value: state.settings.isMagneticSnapEnabled ? "On" : "Off",
      action: () => {
        dispatch(toggleIsMagneticSnapEnabled());
        workspaceDispatch({ type: "toggleIsMagneticSnapEnabled" });
      },
      hotkey: getSystemHotkey("M"),
    },
    {
      title: "Magnetic Slide",
      value: state.settings.isMagneticSlideEnabled ? "On" : "Off",
      action: () => {
        dispatch(toggleIsMagneticSlideEnabled());
        workspaceDispatch({ type: "toggleIsMagneticSlideEnabled" });
      },
      hotkey: getSystemHotkey("L"),
    },
    {
      title: "What's New",
      action: () => {
        handleClose();
        dispatch(openWhatsNew());
        workspaceDispatch({ type: "openWhatsNew" });
      },
    },
    {
      title: "10k Filter Logic",
      value: state.settings.isFilter10kLogicEnabled ? "On" : "Off",
      action: () => {
        dispatch(toggleIsFilter10kLogicEnabled());
        dispatch(designNotFinalized());
        workspaceDispatch({ type: "toggleIsFilter10kLogicEnabled" });
      },
    },
  ];

  if (enableRegenerateDesign && isOfferExperience) {
    designMenuItems.push({
      title: "Regenerate Designs",
      action: () => {
        handleClose();
        regenerateDesigns();
      },
    });
  }

  if (showFireSetbackRefresh && isOfferExperience) {
    designMenuItems.push({
      title: "Dynamic Firecode Setbacks Pilot",
      value: state.settings.isRecalculateFirecodeSetbacksEnabled ? "On" : "Off",
      action: async () => {
        handleClose();
        // when toggled from on to off retrieve site model version 1 (Legacy Setbacks)
        // Copy version 1 site model to the latest and increment version
        if (
          state.settings.isRecalculateFirecodeSetbacksEnabled &&
          state?.siteModel &&
          design
        ) {
          await copyAndSyncDesignAndSiteModel(design, {
            id: state.siteModel.id,
            version: 1,
          });
        }
        // when toggled from off to on recalculateSetbacks
        // site model versioning is taken care of in recalculate setbacks
        if (
          !state.settings.isRecalculateFirecodeSetbacksEnabled &&
          state.siteModel
        ) {
          workspaceDispatch({
            type: "setRequiresRecalculateSetbacks",
            payload: true,
          });
          dispatch(setRequiresRecalculateSetbacks(true));
        }
        dispatch(
          setIsRecalculateFirecodeSetbacksEnabled(
            !state.settings.isRecalculateFirecodeSetbacksEnabled
          )
        );
        workspaceDispatch({
          type: "setIsRecalculateFirecodeSetbacksEnabled",
          payload: !state.settings.isRecalculateFirecodeSetbacksEnabled,
        });
        // clear the undo state whenever we switch between firecode setback pilot
        workspaceDispatch({ type: "clearUndoDesign" });
      },
    });
  }

  if (state.settings.isRecalculateFirecodeSetbacksEnabled) {
    designMenuItems.push({
      title: "Setback Rules",
      action: () => {
        handleClose();
        dispatch(openSetbackRules());
        workspaceDispatch({ type: "openSetbackRules" });
      },
    });
  }

  if (!isMobile) {
    designMenuItems.push({
      title: "Hot Key Descriptions",
      action: () => {
        handleClose();
        dispatch(openHotKeyDescriptions());
        workspaceDispatch({ type: "openHotKeyDescriptions" });
      },
      hotkey: getSystemHotkey("/"),
    });
  }

  return (
    <>
      <IconButton
        id="design-button"
        aria-controls={open ? "design-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        color="primary"
        size="large"
        sx={{ borderRadius: "inherit" }}
      >
        {open ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
      <Menu
        id="design-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "design-button",
        }}
      >
        {designMenuItems.map((menuItem, index) => (
          <DesignMenuItemComponent
            key={menuItem.title}
            menuItem={menuItem}
            isLast={index === designMenuItems.length - 1}
          />
        ))}
      </Menu>
    </>
  );
};
