import { createSelector } from "reselect";
import { selectDesign } from "../design/designSlice";
import {deriveDesignClass} from "../design/designSlice";
import { selectBackupProductVariantId, selectSolarProductVariantId } from "../offer/offerSlice";
import {
  designToBackupProductVariantId,
  designToShiftProductVariantId,
  designToSolarProductVariantId,
  ProductOptionChange,
  productVariantIdChangesToProductOptionChanges
} from "@sunrun/design-tools-domain-model";
import { deriveDisplayNameForInverterType, selectProducts } from "../products/productsSlice";

export const deriveSolarVariantId = createSelector(
  [deriveDesignClass],
  (design): string | undefined => {
    if (!design) return undefined;
    return designToSolarProductVariantId(design);
  }
);

export const deriveBackupVariantId = createSelector(
  [deriveDesignClass],
  (design): string | undefined => {
    if (!design) return undefined;
    return designToBackupProductVariantId(design);
  }
);

export const deriveShiftVariantId = createSelector(
  [deriveDesignClass],
  (design): string | undefined => {
    if (!design) return undefined;
    return designToShiftProductVariantId(design);
  }
);

// Check for previous and new variant and then check if they match. Legacy iHD has no Offer so previousSolarVariantId value will be undefined  
export const deriveDoesSolarOfferLineRequireProductChange = createSelector(
  [selectSolarProductVariantId, deriveSolarVariantId],
  (previousSolarVariantId, newSolarVariantId): boolean => {
    return previousSolarVariantId && newSolarVariantId ? previousSolarVariantId !== newSolarVariantId : false
  }
);

// Check for previous and new variant and then check if they match. Legacy iHD has no Offer so previousBackupVariantId value will be undefined  
export const deriveDoesBackupOfferLineRequireProductChange = createSelector(
  [selectBackupProductVariantId, deriveBackupVariantId],
  (previousBackupVariantId, newBackupVariantId): boolean => {
    return previousBackupVariantId &&  newBackupVariantId ? previousBackupVariantId !== newBackupVariantId : false
  }
);

export const deriveDoesOfferRequireProductChange = createSelector( // ONLY looks at Solar,Backup (assumes no change to Shift, PowerManagement, EvCharger)
  [deriveDoesSolarOfferLineRequireProductChange, deriveDoesBackupOfferLineRequireProductChange],
  (doesSolarOfferLineRequireUpdate, doesBackupOfferLineRequireUpdate) => {
    return doesSolarOfferLineRequireUpdate || doesBackupOfferLineRequireUpdate;
  }
);

export const deriveSolarProductOptionChanges = createSelector(
  [selectSolarProductVariantId, deriveSolarVariantId, selectProducts],
  (previousSolarVariantId, newSolarVariantId, products): ProductOptionChange[] => {
    return productVariantIdChangesToProductOptionChanges(previousSolarVariantId, newSolarVariantId, products);
  }
);

export const deriveBackupProductOptionChanges = createSelector(
  [selectBackupProductVariantId, deriveBackupVariantId, selectProducts],
  (previousBackupVariantId, newBackupVariantId, products): ProductOptionChange[] => {
    return productVariantIdChangesToProductOptionChanges(previousBackupVariantId, newBackupVariantId, products);
  }
);

export const deriveProductOptionChanges = createSelector(
  [deriveSolarProductOptionChanges, deriveBackupProductOptionChanges],
  (solarProductOptionChanges, backupProductOptionChanges): ProductOptionChange[] => {
    return [...solarProductOptionChanges, ...backupProductOptionChanges];
  }
);

export const deriveProductOptionChangesMessages = createSelector(
  [deriveProductOptionChanges, deriveDisplayNameForInverterType],
  (productOptionChanges, inverterTypeDisplayName) => {
    let productChangesMessages = productOptionChanges.map(c => `${c.optionName} will update from ${c.previousValue} to ${c.newValue}`)
    // We are always in double simulate mode unless a user makes a inverter choice, in which case
    // they know they changed the inverter. The messages are filtered here instead of domain model to
    // preserve the UI change of inverter type between string and optimized
    productChangesMessages = productChangesMessages.filter(message => !message.includes(inverterTypeDisplayName))
    return productChangesMessages;
  }
)
