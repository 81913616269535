import {Position, positionsSame} from "./point";
import { ArrayUtil } from "@sunrun/design-tools-functional";

/**
 * Polygon geometry object. https://tools.ietf.org/html/rfc7946#section-3.1.6
 */
export type Polygon = {
  type: "Polygon";
  coordinates: Position[][];
}

export type PolygonValidation = {
  isValid: boolean,
  message: string
}

export const validatePolygonGeometry = (positions: Position[][], expect3D: boolean): PolygonValidation => {
  if (positions.length === 0) {
    return {isValid: false, message:"Invalid polygon: empty array"}
  }
  if (positions.some(ring => !Array.isArray(ring))) {
    return {isValid: false, message:"Invalid polygon: ring not an array"}
  }
  if (positions.some(outerRing => outerRing.length === 0)) {
    return {isValid: false, message:"Invalid polygon: no vertices"}
  }
  if (positions.some(outerRing => outerRing.some(innerRing => !Array.isArray(innerRing)))) {
    return {isValid: false, message:"Invalid polygon: ring did not contain position arrays"}
  }
  if (positions.some(ring => ring.some(position => position.length < 2))) {
    return {isValid: false, message:`Invalid polygon: every position must have at least two dimensions`}
  }
  const flattened = positions.flatMap(outerRing => outerRing.flatMap(innerRing => innerRing))
  if (flattened.some(val => isNaN(val) || typeof val !== "number")) {
    return {isValid: false, message:"Invalid polygon: non-numeric position value"}
  }
  if (positions.some(ring => !positionsSame(ring[0], ring[ring.length-1], .0000000001))) {
    return {isValid: false, message:"Invalid polygon: ring not closed"}
  }
  if (positions.some(item => item.length < 4)) {
    return {isValid: false, message:"Invalid polygon: less than three vertices"}
  }
  if (expect3D && positions.some(outerRing => outerRing.some(innerRing => innerRing.length !== 3))) {
    return {isValid: false, message:"Invalid polygon: positions not 3D"}
  }
  return {isValid: true, message: "polygon is valid"}
}

export const polygonCentroid = (polygon: Polygon): Position => {
  return polygonPositionsCentroid(polygon.coordinates[0])
}

export const polygonPositionsCentroid = (positions: Position[]): Position => {
  // exclude closing of the ring
  const coordinateCount = positions.length - 1
  const dimensionality = characterizeGeometry(positions)
  const centroid = [
    positions.slice(0, coordinateCount)
      .reduce((prev, curr) => prev + curr[0], 0) / coordinateCount,
    positions.slice(0, coordinateCount)
      .reduce((prev, curr) => prev + curr[1], 0) / coordinateCount,
  ]
  if (dimensionality === Dimensions.ThreeD) {
    centroid.push(positions.slice(0, coordinateCount)
      .reduce((prev, curr) => prev + curr[2], 0) / coordinateCount)
  }
  return centroid
}

export enum Dimensions {
  TwoD,
  ThreeD,
  Invalid
}

export const characterizeGeometry = (positions: Position[]): Dimensions => {
  if (positions.every((position: Position) => position.length === 3)) {
    return Dimensions.ThreeD
  } else if (positions.every((position: Position) => position.length === 2)) {
    return Dimensions.TwoD
  } else {
    throw Error("Invalid operation: mixing 2D and 3D geometry")
  }
}

// Do the polygons have the same vertices? Doesn't care if the first vertex in the position array is the same as long
// as the same polygon is delineated. *DOES* care if the winding order is different because that tells us if the
// geometry demarcates a positive or negative space (negative === a hole).
export const polygonsHaveSameGeometry = (polygon0: Position[], polygon1: Position[]): boolean => {
  if (isPolygonWithArea(polygon0) || isPolygonWithArea(polygon1)) {
    throw new Error("Degenerate polygon: too few positions to enclose an area.")
  }
  // same number of vertices?
  if (polygon0.length !== polygon1.length) {
    return false
  }
  // same number of dimensions for each position?
  for (const[i, position] of polygon0.entries()) {
    if (position.length !== polygon1[i].length) {
      return false
    }
  }
  // same winding order?
  const windingOrderSame = (windingOrder(polygon0) === windingOrder(polygon1))
  if (!windingOrderSame) {
    return false
  }
  // Check for rotated position arrays, which would have the same geometry
  // By "circular order" we mean the order of the positions in the polygon ring is different only by which position is
  // the starting point for the ring.
  const steps = ArrayUtil.consecutiveIntegers(polygon0.length, 0)
  for (const step of steps) {
    const rotatedPolygon = rotate(polygon0, step, true)
    if (JSON.stringify(rotatedPolygon) === JSON.stringify(polygon1)) {
      // If this comparison succeeds once we have the same coordinates in the same circular order, differing only in
      // starting position.
      return true
    }
  }
  return false
}

export const isPolygonWithArea = (positions: Position[]): boolean => {
  const isClosed = positions[0][0] === positions[positions.length - 1][0] && positions[0][1] === positions[positions.length - 1][1]
  return isClosed ? positions.length < 4 : positions.length < 3
}

export const polygonArea = (points: Position[]) => {
  // this is an implementation of the "shoelace formula" for determining the area of a polygon given corner vertices
  // https://en.wikipedia.org/wiki/Shoelace_formula.  This follows the Trapezoid Shoelace formula.
  let total = 0;
  for (let i = 0, l = points.length; i < l; i++) {
    const xi = points[i][0]
    const yi = points[i][1]
    const next_i = i == points.length - 1 ? 0 : i + 1;
    const xi1 = points[next_i][0]
    const yi1 = points[next_i][1]
    total += (yi + yi1) * (xi - xi1) * 0.5
  }
  return total;
}

export const polygonAreaWithAngle = (points: Position[], degrees: number) => {
  return polygonArea(points)/Math.cos((Math.PI/180) * degrees);
}

// Also based on shoelace formula. works very generically (even if non-convex or if self-intersecting)
export const windsCounterClockwise = (positions: Position[]): boolean => {
  const area = polygonArea(positions)
  return area > 0
}

export const windingOrder = (positions: Position[]): WindingOrder => {
  return windsCounterClockwise(positions) ? WindingOrder.counterclockwise : WindingOrder.clockwise
}

export enum WindingOrder {
  clockwise,
  counterclockwise
}

// In this context "rotate" doesn't mean "transform the geometry by rotating it around an axis" it means to shift
// the order of the vertices in the array, which does not change the geometry.
const rotate = <T>(arr: T[], shift: number, isClosedPolygonArray: boolean): T[] => {
  if (arr.length === 0) return []
  if (!isClosedPolygonArray && Array.isArray(arr[0]) && `${arr[0]}` === `${arr[arr.length-1]}`) {
    throw new Error(`Looks like you may be calling rotate() on closed polygon coordinates. This will not
    return valid closed geometry unless you invoke rotate() with the isClosedPolygonArray flag set to true.`)
  }
  if (isClosedPolygonArray && (!Array.isArray(arr[0]) || `${arr[0]}` !== `${arr[arr.length-1]}`)) {
    throw new Error(`rotate() was invoked with the isClosedPolygonArray flag set but the passed array is not
    a closed ring of polygon positions: ${JSON.stringify(arr)}`)
  }
  if (isClosedPolygonArray) {
    arr = arr.slice(0, arr.length - 1) // remove the closing position
  }
  if (shift > arr.length) {
    shift = shift % arr.length
  } else if (shift < (0 - arr.length)) {
    shift = 0 - (shift % arr.length - 1)
  }
  const newArrStart = arr.slice(shift)
  const newArrEnd = arr.slice(0, shift)
  const rotated = [...newArrStart, ...newArrEnd]
  if (isClosedPolygonArray) {
    rotated.push(rotated[0]) // re-close the polygon ring
  }
  return rotated
}

