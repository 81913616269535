import { GeoJsonLayer } from "src/components/GeoJsonLayer";
import { Marker, LeafletEventHandlerFnMap } from "leaflet";
import React from "react";
import { RoofFaceProps } from "./RoofFaceLayer";
import { G2Rectangle } from "@sunrun/design-tools-geometry";
import L from "leaflet";
import toggleSvg from "./icon.svg";
import { useWorkspace } from "src/hooks/useWorkspace";
import { createEventHandlers } from "./LeafletEventHandler";
import { InteractiveLeafletLayer } from "src/types/state-management/action";
import { useMap } from "react-leaflet";

export const RoofFaceDecoratorLayer = ({ roofFace }: RoofFaceProps) => {
  const { state, dispatch } = useWorkspace();
  const map = useMap();
  const center = G2Rectangle.fromGeoJsonAndXAxis(
    roofFace.geometry,
  ).centerOfRectangleContainingPolygonAsNumArray();
  const [eventHandlers] = React.useState<LeafletEventHandlerFnMap>(
    createEventHandlers(InteractiveLeafletLayer.roofFace, dispatch, map),
  );

  const geometry = { coordinates: [center[0], center[1]], type: "Point" };
  const roofFaceDataWithCenter: any = { ...roofFace, geometry: geometry };

  const toggleIcon = L.icon({
    iconUrl: toggleSvg,
    iconSize: [48, 36], // size of the icon
    iconAnchor: [24, 18], // point of the icon which will correspond to marker's location
  });

  const pointToLayer = (_: any, latlng: any) => {
    return new Marker(latlng, { icon: toggleIcon, opacity: 0.7 }) as any;
  };

  if (roofFace.properties.usable || !state.settings.isRecalculateFirecodeSetbacksEnabled) {
    return null;
  }

  return (
    <GeoJsonLayer
      data={roofFaceDataWithCenter}
      pointToLayer={pointToLayer}
      eventHandlers={eventHandlers}
      key={`roof-face-decorator-${roofFace.id}`}
    />
  );
};
