export enum LoadingProcessNames {
  LIGHTMILE_DESIGN = "Importing Lightmile Design",
  SITE_MODEL = "Loading Site Model",
  AERIAL_IMAGERY = "Loading Aerial Imagery",
  DESIGN = "Loading Design",
  CUSTOMER = "Loading Customer",
  DESIGN_CONSTRAINTS = "Loading Design Constraints",
  RENDER_STATIC_IMAGE = "Rendering Static Image",
  SHADE_MODEL = "Loading Shade Model",
  OFFER = "Loading Offer",
  SITE_SURVEY = "Loading Site Survey",
  AVAILABLE_EQUIPMENT = "Loading Available Equipment",
  DESIGN_INIT = "Initializing Design", // combines copy, p2eMapping, and maxfill

  DESIGN_SIMULATION = "Generating High-Res Simulation",
  RELOAD_DESIGN = "Re-Loading Design", // (and Production Simulation)
  EXPORT = "Exporting to Lightmile",
  UPDATING_OFFER = "Updating Offer",
  SELECTING_VARIANT_DESIGN = "Updating the design with selected design variant",
  FETCH_FIRE_SETBACK = "Recalculating Fire Setback",
  REGENERATING_DESIGNS = "Regenerating Designs",
  FETCH_EXPANDED_IRRADIANCE = "Fetching Expanded Irradiance Data",
}

export enum LoadingProcessGroups {
  INITIALIZE_IHD = "Initializing iHD",
  FINALIZE_DESIGN = "Finalizing Design",
  PREPARE_TO_GO_TO_PRICING = "Updating Product Information",
  SELECTING_LIGHTMILE_DESIGN = "Selecting Design",
  UPDATING_SITE_MODEL = "Updating Site Model",
  REGENERATING_DESIGNS = "Regenerating Designs",
  IHD_UPDATING = "iHD Updating",
}
