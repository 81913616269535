import { useIsMutating, useMutation, useQuery } from "react-query";
import { useErrorHandler } from "react-error-boundary";
import { Design, IDesign } from "@sunrun/design-tools-domain-model";
import { repository } from "@sunrun/design-tools-graphql-clients";
import { useWorkspace } from "src/hooks/useWorkspace";
import * as FullStory from "@fullstory/browser";
import { useFlags } from "flagsmith/react";
import { FlagsmithFeatureEnums } from "src/config/flagsmithConfig";
import {
  deriveBookmarkedDesignClasses,
  setBookmarkedDesigns,
} from "src/features/bookmark/bookmarkSlice";
import { useAppDispatch } from "src/store";
import produce from "immer";

export const useBookmarkDesign = (fetchBookmarks = true) => {
  const { state, dispatch: workspaceDispatch } = useWorkspace();
  const dispatch = useAppDispatch();
  const handleError = useErrorHandler();
  const allowBookmarkDesign = useFlags([
    FlagsmithFeatureEnums.ENABLE_BOOKMARK_DESIGN,
  ])[FlagsmithFeatureEnums.ENABLE_BOOKMARK_DESIGN].enabled;

  const prospectId = state?.design?.prospectId;
  const bookmarkedDesigns = deriveBookmarkedDesignClasses(state);

  const listBookmarkedDesign = async () => {
    if (prospectId) return repository.listDesignsByBookmarkId(prospectId);
  };

  const updateWorkspaceBookmarkDesignState = (bookmarkedDesigns: Design[]) => {
    dispatch(
      setBookmarkedDesigns(
        bookmarkedDesigns.map((design) => design.getIDesign())
      )
    );
    workspaceDispatch({
      type: "setBookmarkedDesigns",
      payload: bookmarkedDesigns,
    });
  };

  const bookmarkDesignQuery = useQuery(
    ["listDesignByBookmarkId", prospectId],
    listBookmarkedDesign,
    {
      refetchOnWindowFocus: false, // TODO support these use cases for conflict resolution
      refetchOnReconnect: false,
      enabled: !!prospectId && fetchBookmarks && allowBookmarkDesign, // https://react-query.tanstack.com/guides/dependent-queries
      onSuccess: (savedDesigns: Design[]) => {
        updateWorkspaceBookmarkDesignState(savedDesigns);
      },
      onError: handleError,
    }
  );

  const bookmarkDesign = async (design: Design): Promise<Design> => {
    const prospectId = design.prospectId;
    if (!bookmarkMutationInProgress && prospectId) {
      const designReadyForBookmark = design.prepareDesignForBookmarking();
      const saveResult = await repository.save(
        designReadyForBookmark,
        prospectId
      );
      return saveResult.v0;
    } else {
      return design;
    }
  };

  const addBookmarkToLatestDesign = async (design: Design): Promise<Design> => {
    const prospectId = design.prospectId;
    if (!bookmarkMutationInProgress && prospectId) {
      const updateLatestVersion = design.copyLatestVersionAfterUpdate(design);
      await repository.addBookmark(updateLatestVersion, prospectId);
    }
    return design;
  };

  const removeBookmarkDesign = async (
    design: Design
  ): Promise<Design | void> => {
    if (!removeBookmarkMutationInProgress) {
      await repository.removeBookmark(design);
    }
    return design;
  };

  const refetchAndUpdateBookmarkedDesigns = async () => {
    bookmarkDesignQuery.refetch().then(({ data: bookmarkedDesigns }) => {
      if (bookmarkedDesigns) {
        updateWorkspaceBookmarkDesignState(bookmarkedDesigns);
      }
    });
  };

  // useIsMutating to skip any saves that would conflict with saveBookmarkDesignMutation initiated in useSimulateDesign or useLegacyDesign
  const bookmarkMutationInProgress = useIsMutating([
    "saveBookmarkDesignMutation",
  ]);
  // useIsMutating to skip any saves that would conflict with saveBookmarkDesignMutation initiated in useSimulateDesign or useLegacyDesign
  const addBookmarkMutationInProgress = useIsMutating([
    "addBookmarkToLatestDesignMutation",
  ]);
  // useIsMutating to skip any saves that would conflict with removeBookmarkDesignMutation initiated in useSimulateDesign or useLegacyDesign
  const removeBookmarkMutationInProgress = useIsMutating([
    "removeBookmarkDesignMutation",
  ]);

  const saveBookmarkDesignMutation = useMutation(bookmarkDesign, {
    mutationKey: "saveBookmarkDesignMutation",
    onMutate: () => {
      // optimistic update noop
      // disabling bookmarking button
    },
    onSettled: () => {
      // refetching bookmarked designs after success or failure to keep local bookmarkDesign state in sync
      refetchAndUpdateBookmarkedDesigns();
    },
    onError: handleError,
    onSuccess: (newDesign: Design) => {
      // Update the version of the local design Object without altering the state.
      workspaceDispatch({ type: "resolveSave", payload: newDesign });
      FullStory.event("Bookmarked Design", {
        designId: newDesign.id,
        saveVersion: newDesign.latest,
        prospectId: newDesign.prospectId,
      });
    },
  });

  const addBookmarkToLatestDesignMutation = useMutation(
    addBookmarkToLatestDesign,
    {
      mutationKey: "addBookmarkToLatestDesignMutation",
      onError: handleError,
      onSuccess: async (newDesign: Design) => {
        FullStory.event("Bookmarked Design", {
          designId: newDesign.id,
          saveVersion: newDesign.latest,
          prospectId: newDesign.prospectId,
        });
        await refetchAndUpdateBookmarkedDesigns();
      },
    }
  );

  const removeBookmarkDesignMutation = useMutation(removeBookmarkDesign, {
    mutationKey: "removeBookmarkDesignMutation",
    onMutate: (removedDesign: Design) => {
      // handle optimistic update for bookmark removal
      if (bookmarkedDesigns) {
        updateWorkspaceBookmarkDesignState(
          bookmarkedDesigns.filter((design) => design !== removedDesign)
        );
      }
    },
    onSettled: () => {
      // refetching bookmarked designs after success or failure to keep local bookmarkDesign state in sync
      refetchAndUpdateBookmarkedDesigns();
    },
    onError: handleError,
    onSuccess: () => {},
  });

  return {
    saveBookmarkDesignMutation,
    removeBookmarkDesignMutation,
    bookmarkDesignQuery,
    addBookmarkToLatestDesignMutation,
  };
};
