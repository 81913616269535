import {
  Box,
  Divider,
  Grid,
} from "@mui/material";
import { DetailsSection } from "./DetailsSection";
import { DesignDetails } from "./DesignDetails";
import { useWorkspace } from "src/hooks/useWorkspace";
import { ProductsDetailsSection } from "./ProductsDetailsSection";
import { selectIsOfferExperience } from "src/features/host/hostSlice";

const MoreDetails = () => {
  const {state} = useWorkspace()
  const isOfferExperience = selectIsOfferExperience(state);
  return (
    <>
      <Divider />
      <Box padding={2} overflow="auto">
        {isOfferExperience ? (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ProductsDetailsSection />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailsSection title="Design">
                <DesignDetails />
              </DetailsSection>
            </Grid>
          </Grid>
        ): (
          <DetailsSection title="Design">
            <DesignDetails />
          </DetailsSection>
        )}
      </Box>
    </>
  );
};

export default MoreDetails;