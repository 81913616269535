// Randomize the order of elements in an array. Returns a new array, however be aware that
// the items in the array are copied by reference, not by value (no deep copying is occurring)
// This is an implementation of Fisher–Yates Shuffle.
export const shuffle = <T>(array: T[]): T[] => {
  const copy = [...array]
  let i = copy.length,
    j = 0,
    temp;

  while (i--) {
    j = Math.floor(Math.random() * (i+1));
    // swap randomly chosen element with current element
    temp = copy[i]!;
    copy[i] = copy[j]!;
    copy[j] = temp;
  }
  return copy
}
