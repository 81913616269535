import {
  GetEquipmentItemQuery,
  ListEquipmentSellingRulesQuery,
  Sdk,
  getKeystoneClient,
} from "@sunrun/keystone-sdk";
import { keystoneEquipmentListFixture } from "./fixtures/keystoneEquipment";
import { keystoneSellingRulesFixture } from "./fixtures/keystoneSellingRules";
import { OpportunityInput } from "@sunrun/design-tools-generated-graphql";
import { SellingRulesUtils } from "./sellingRulesUtil";
import { RuntimeContext, TestType } from './runtimeContext/runtimeContext'

export const keystoneConfig = {
  // This is a readonly token to the Contentful CDA. It is designed to be exposed safely. We either define it here, or in app parameters. This is easier.
  accessToken: "IveQJMwX3t5o3eU9KlD2qp0fsIE5I-PT-w2tG_MrXUA",
  environment: "master",
};

export type KeystoneEquipment = NonNullable<GetEquipmentItemQuery["equipment"]>;
export type KeystoneEquipmentSellingRule = NonNullable<
  NonNullable<ListEquipmentSellingRulesQuery["sellingRuleV2Collection"]>["items"][number]
>;

interface KeystoneClientInterface {
  listAllEquipment(): Promise<KeystoneEquipment[]>;
  getEquipmentItemById(id: string): Promise<KeystoneEquipment | undefined>;
  listAllEquipmentSellingRules(): Promise<KeystoneEquipmentSellingRule[]>;
  getAllEquipmentBySellingRules(
    opportunity: OpportunityInput
  ): Promise<KeystoneEquipment[]>;
}

class DelegatingKeystoneClient implements KeystoneClientInterface {
  private remoteClient: RemoteKeystoneClient;
  private localClient: LocalKeystoneClient;

  constructor() {
    this.remoteClient = new RemoteKeystoneClient();
    this.localClient = new LocalKeystoneClient();
  }

  private shouldUseLocalClient = (): boolean => {
    return (
      RuntimeContext.testType === TestType.LocalTest ||
      (RuntimeContext.browserContext.areWeRunningInTheBrowser &&
        RuntimeContext.browserContext.isOffline)
    );
  };

  listAllEquipment = async (): Promise<KeystoneEquipment[]> => {
    if (this.shouldUseLocalClient()) {
      return this.localClient.listAllEquipment();
    } else {
      return this.remoteClient.listAllEquipment();
    }
  };

  getEquipmentItemById = async (
    id: string
  ): Promise<KeystoneEquipment | undefined> => {
    if (this.shouldUseLocalClient()) {
      return this.localClient.getEquipmentItemById(id);
    } else {
      return this.remoteClient.getEquipmentItemById(id);
    }
  };

  listAllEquipmentSellingRules = async (): Promise<KeystoneEquipmentSellingRule[]> => {
    if (this.shouldUseLocalClient()) {
      return this.localClient.listAllEquipmentSellingRules();
    } else {
      return this.remoteClient.listAllEquipmentSellingRules();
    }
  };

  getAllEquipmentBySellingRules = async (
    opportunity: OpportunityInput
  ): Promise<KeystoneEquipment[]> => {
    if (this.shouldUseLocalClient()) {
      return this.localClient.getAllEquipmentBySellingRules(opportunity);
    } else {
      return this.remoteClient.getAllEquipmentBySellingRules(opportunity);
    }
  };
}

class RemoteKeystoneClient implements KeystoneClientInterface {
  client: any;
  sdk: Sdk;
  usePreview: boolean;
  constructor() {
    const { client, sdk } = getKeystoneClient(
      keystoneConfig.accessToken,
      keystoneConfig.environment
    );
    this.client = client;
    this.sdk = sdk;
    this.usePreview = RuntimeContext.env.name !== "prd";
  }

  listAllEquipment = async (): Promise<KeystoneEquipment[]> => {
    const response = await this.sdk.listEquipment({ preview: this.usePreview });
    const items = response.data.equipmentCollection?.items;
    const filteredItems = items?.filter(
      (item): item is KeystoneEquipment => !!item
    );
    return filteredItems ?? [];
  };

  getEquipmentItemById = async (
    id: string
  ): Promise<KeystoneEquipment | undefined> => {
    const response = await this.sdk.getEquipmentItem({
      id,
      preview: this.usePreview,
    });
    return response.data.equipment ?? undefined;
  };

  listAllEquipmentSellingRules = async (): Promise<KeystoneEquipmentSellingRule[]> => {
    const response = await this.sdk.listEquipmentSellingRules({
      preview: this.usePreview,
    });
    const items = response.data.sellingRuleV2Collection?.items;
    const filteredItems = items?.filter(
      (item): item is KeystoneEquipmentSellingRule => !!item
    );
    return filteredItems ?? [];
  };

  getAllEquipmentBySellingRules = async (
    opportunity: OpportunityInput
  ): Promise<KeystoneEquipment[]> => {
    const [equipment, sellingRules] = await Promise.all([
      this.listAllEquipment(),
      this.listAllEquipmentSellingRules(),
    ]);

    const availableEquipmentBySellingRules =
      SellingRulesUtils.mapEquipmentIdsToEquipmentSpecs(
        opportunity,
        equipment,
        sellingRules
      );

    return availableEquipmentBySellingRules ?? [];
  };
}

class LocalKeystoneClient implements KeystoneClientInterface {
  listAllEquipment = async (): Promise<KeystoneEquipment[]> => {
    return keystoneEquipmentListFixture;
  };

  getEquipmentItemById = async (
    id: string
  ): Promise<KeystoneEquipment | undefined> => {
    const equipment = keystoneEquipmentListFixture.find(
      (equipment) => equipment.sys.id === id
    );
    return equipment;
  };

  listAllEquipmentSellingRules = async (): Promise<KeystoneEquipmentSellingRule[]> => {
    return keystoneSellingRulesFixture;
  };

  getAllEquipmentBySellingRules = async (
    opportunity: OpportunityInput
  ): Promise<KeystoneEquipment[]> => {
    const availableEquipmentBySellingRules =
      SellingRulesUtils.mapEquipmentIdsToEquipmentSpecs(
        opportunity,
        keystoneEquipmentListFixture,
        keystoneSellingRulesFixture
      );
    return availableEquipmentBySellingRules;
  };
}

export const keystoneClient = new DelegatingKeystoneClient();
