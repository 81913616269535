import { LoadedWorkspaceState, WorkspaceAction, WorkspaceState } from "src/hooks/useWorkspace";
import { polygonCentroid, positionToVector2D, Vector2D } from "@sunrun/design-tools-geometry";
import {
  Battery,
  BATTERY_MODEL_POWERWALL_3_BASE_PACK,
  BATTERY_MODEL_POWERWALL_3_BASE_PACK_AC_COUPLED,
  Design,
  IDesign,
  Module,
  ModuleCollisionChecker,
  ModuleSpecification,
  SiteModel,
} from "@sunrun/design-tools-domain-model";
import {
  WorkspaceEmptyAction,
  WorkspacePayloadAction,
  WorkspaceEvent,
} from "../../types/state-management/action";
import { produce } from "immer";
import * as FullStory from "@fullstory/browser";
import { ModuleDrag } from "../moduleDrag/moduleDragSlice";
import { createSelector } from "reselect";
import { deriveSiteModelClass } from "../siteModel/siteModelSlice";
import { KeystoneEquipment } from "@sunrun/design-tools-keystone-client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { selectIsOfferExperience } from "../host/hostSlice";
import { deriveDesignConstraintsClass } from "../designConstraints/designConstraintsSlice";
import { selectIsAdditionalSystem } from "../offer/offerSlice";

export type DesignState = {
  value: IDesign | undefined;
};

export const initialState: DesignState = {
  value: undefined,
};

export const designSlice = createSlice({
  name: "design",
  initialState,
  reducers: {
    setDesign: (state: DesignState, action: PayloadAction<IDesign>) => {
      state.value = action.payload;
    },
    //More actions to be moved here: https://sunrun.jira.com/browse/ET-1664
  },
});

export const { setDesign } = designSlice.actions;
export default designSlice.reducer;

// TODO: Remove the following and transition fully from WorkspaceState to RTK store
export type DesignWorkspaceAction =
  | WorkspacePayloadAction<Design, "setDesign">
  | WorkspacePayloadAction<Design, "addModule">
  | WorkspacePayloadAction<WorkspaceEvent, "translateModules">
  | WorkspacePayloadAction<WorkspaceEvent, "translateModulesConstrainedByRoofEdge">
  | WorkspacePayloadAction<WorkspaceEvent, "commitModuleTranslation">
  | WorkspacePayloadAction<{ module: Module; delta: Vector2D }, "nudgeModules">
  | WorkspacePayloadAction<WorkspaceEvent, "undoModuleNudge">
  | WorkspacePayloadAction<RoofFaceId, "centerFillOneRoofFace">
  | WorkspacePayloadAction<ModuleSpecification, "updateDesignWithModuleSpec">
  | WorkspacePayloadAction<InverterSpecUpdate, "updateDesignWithInverterSpecIds">
  | WorkspaceEmptyAction<"resetToLowResEquipment">
  | WorkspacePayloadAction<BatterySpecUpdate, "updateDesignWithBatterySpecIds">
  | WorkspacePayloadAction<PowerOptimizerSpecUpdate, "updateDesignWithPowerOptimizerSpecIds">
  | WorkspacePayloadAction<number, "updateDesignWithBatteryCount">
  | WorkspacePayloadAction<BatteryConfig, "updateDesignWithBatteryConfiguration">
  | WorkspacePayloadAction<AutoPW3Update, "autoUpdateDesignWithPw3">
  | WorkspaceEmptyAction<"undoModuleTranslation">
  | WorkspaceEmptyAction<"removeModules">
  | WorkspaceEmptyAction<"rotateModules">
  | WorkspaceEmptyAction<"fillRoofFaceAroundModule">
  | WorkspacePayloadAction<Design, "resolveSave">
  | WorkspacePayloadAction<Design, "resolvePrepareDesignForOverride">
  | WorkspacePayloadAction<SiteModel, "setSiteModelVersion">;

export type BatteryConfig = {
  selectedMidIds: string[];
  selectedBatterySpecificationIds: string[];
  batteries: Battery[];
  keystoneEquipment?: KeystoneEquipment[];
  isFilter10kLogicEnabled?: boolean;
};
export type RoofFaceId = {
  roofFaceId: string;
};

export type InverterSpecUpdate = {
  newInverterSpecIds: string[];
  keystoneEquipment?: KeystoneEquipment[];
  isFilter10kLogicEnabled?: boolean;
};

export type PowerOptimizerSpecUpdate = {
  newPowerOptimizerSpecIds: string[];
  keystoneEquipment?: KeystoneEquipment[];
  isFilter10kLogicEnabled?: boolean;
};

export type BatterySpecUpdate = {
  newBatterySpecIds: string[];
  newMidIds?: string[];
  keystoneEquipment?: KeystoneEquipment[];
  isFilter10kLogicEnabled?: boolean;
};

export type AutoPW3Update = {
  keystoneEquipment?: KeystoneEquipment[];
  isFilter10kLogicEnabled?: boolean;
};

export const selectDesign = (state: WorkspaceState) => state.design;
export const deriveDesignClass = createSelector(
  [selectDesign],
  (design) => design ? new Design(design) : undefined
);
export const selectSelectedModules = createSelector(
  [deriveDesignClass],
  (design) => design?.selectedEquipmentSpecificationIds?.selectedModuleSpecificationIds
);
export const selectSelectedInverters = createSelector(
  [deriveDesignClass],
  (design) => design?.selectedEquipmentSpecificationIds?.selectedInverterSpecificationIds
);
export const selectSelectedBatteries = createSelector(
  [deriveDesignClass],
  (design) => design?.selectedEquipmentSpecificationIds?.selectedBatterySpecificationIds
);
export const selectBatteries = createSelector(
  [deriveDesignClass],
  (design) => design?.batteries ?? []
);
export const selectDesignIsUsingMicroInverter = createSelector(
  [deriveDesignClass],
  (design) => design?.usingMicroInverter ?? false
);
export const selectDesignIsUsingHybridBatteries = createSelector(
  [deriveDesignClass],
  (design) => design?.usingHybridBatteries ?? false
);
export const selectBatteryCount = createSelector(
  [deriveDesignClass],
  (design) => design?.batteryCount
);
export const selectBatteryMode = createSelector(
  [deriveDesignClass],
  (design) => design?.getBatteryMode()
);
export const selectIsDesignBackup = createSelector(
  [deriveDesignClass],
  (design) => design?.isBackup()
);
export const selectIsDesignShift = createSelector(
  [deriveDesignClass],
  (design) => design?.isShift()
);
export const selectIsAcStorage = createSelector(
  [deriveDesignClass],
  (design) => design?.isAcStorage
);
export const selectIsDcStorage = createSelector(
  [deriveDesignClass],
  (design) => design?.isDcStorage
);

export const selectIsMaximizeSelfConsumption = createSelector(
  [deriveDesignClass, deriveDesignConstraintsClass, selectIsAcStorage, selectIsDcStorage],
  (design, designConstraints) => {
    return designConstraints?.isMaximizeSelfConsumption(design) ?? false;
  }
);

export const selectAllowEditBatteryCount = createSelector(
  [
    selectIsOfferExperience,
    selectIsMaximizeSelfConsumption,
    selectIsDesignShift,
    selectIsDcStorage,
  ],
  (
    isOfferExperience,
    isMaximizeSelfConsumption,
    isDesignShift,
    isDcStorage,
  ) => {
    // Exclude access to edit battery count for DC Shift + MSC;
    // Battery count will be handled automatically by LM-Core / LM-Adapter
    const isMscDcShift =
      isMaximizeSelfConsumption &&
      isDesignShift &&
      isDcStorage;

    const allowEditBatteryCount =
      isOfferExperience &&
      !isMscDcShift;
    return allowEditBatteryCount;
  },
);

export const designWorkspaceReducer = (
  state: WorkspaceState,
  action: WorkspaceAction
): IDesign | undefined => {
  const siteModel = deriveSiteModelClass(state);
  const design = deriveDesignClass(state);
  const isAdditionalSystem = selectIsAdditionalSystem(state)

  switch (action.type) {
    case "setDesign": {
      return action.payload.getIDesign();
    }

    // TODO We need to update the WorkflowState Redux reducer to handle this action when we move the designSlice to Redux https://sunrun.jira.com/browse/ET-1664
    case "removeModules": {
      if (design && state.moduleSelection.selectedModuleIds.size > 0) {
        return design.deleteModules(state.moduleSelection.selectedModuleIds).getIDesign();
        // We'd like to clear selection automatically when we delete selected modules
        // but we've modeled selection as a separate slice of state from Design so we can't
        // do it from here or (better) from inside design.deleteModules. This is a leak
        // in our encapsulation. TODO: make module selection ephemeral design state
      }
      return state.design;
    }

    case "rotateModules": {
      const { moduleSelection } = state as LoadedWorkspaceState;
      if (design && siteModel && moduleSelection.selectedModuleIds.size > 0) {
        return design
          .rotateModules(state.moduleSelection.selectedModuleIds, siteModel!)
          .getIDesign();
      }
      return state.design;
    }

    case "translateModules": {
      const { moduleSelection, moduleDrag, settings } = state;
      if (design && siteModel && moduleDrag && moduleDrag.draggedModuleId) {
        const module = design.getModuleById(moduleDrag.draggedModuleId);
        if (!module) return state.design;
        const deltaDrag = calculateDragDelta(module, moduleDrag);
        const roofFace = siteModel.getRoofFaceById(module.properties.roofFaceId);
        return design
          .translateModules(
            moduleSelection.selectedModuleIds,
            roofFace!,
            module.id,
            deltaDrag,
            settings.isMagneticSnapEnabled
          )
          .getIDesign();
      }
      return state.design;
    }

    case "translateModulesConstrainedByRoofEdge": {
      const { moduleSelection, moduleDrag, settings } = state;
      if (design && siteModel && moduleDrag && moduleDrag.draggedModuleId) {
        const module = design.getModuleById(moduleDrag.draggedModuleId);
        if (!module) return state.design;
        const deltaDrag = calculateDragDelta(module, moduleDrag);
        return design
          .attemptToTranslateModules(
            moduleSelection.selectedModuleIds,
            siteModel,
            moduleDrag.draggedModuleId,
            deltaDrag,
            settings.isMagneticSnapEnabled
          )
          ?.getIDesign();
      }
      return state.design;
    }

    case "commitModuleTranslation": {
      if (design && siteModel) {
        console.log("inside slice commiting translation");
        return design.finishTranslation().getIDesign();
      }
      return state.design; // bail
    }

    case "undoModuleTranslation": {
      const { moduleDrag, moduleSelection } = state as LoadedWorkspaceState;
      if (design && siteModel && moduleDrag) {
        return design
          .undoTranslation(
            moduleSelection.selectedModuleIds,
            siteModel,
            moduleDrag.draggedModuleId!,
            moduleDrag.originalDraggedModuleCenter!
          )
          .getIDesign();
      }
      return state.design; // bail
    }

    case "nudgeModules": {
      const { moduleSelection } = state as LoadedWorkspaceState;
      if (design && siteModel) {
        const { module, delta } = action.payload;
        const roofFace = siteModel.getRoofFaceById(module.properties.roofFaceId);
        let selectedModules = Array.from(moduleSelection.selectedModuleIds).map(
          (id) => design.getModuleById(id)!
        );
        const initiallyCollidingModules =
          ModuleCollisionChecker.checkModulesAreWithinValidRoofSpace(
            selectedModules,
            siteModel,
            true
          );
        const newDesign = design
          .translateModules(moduleSelection.selectedModuleIds, roofFace!, module.id, delta, false)
          .finishTranslation();
        if (initiallyCollidingModules.size > 0) {
          // if the selected modules were in a collided state we want the nudge to go ahead so the user can fix that
          return newDesign.getIDesign();
        } else {
          // If they weren't colliding initially we want to prevent them from nudging into a collided state, at least
          // from going off the roof. This logic does allow module-to-module collision so the user has the freedom to
          // nudge modules through tight spots on their way to the desired position
          selectedModules = Array.from(moduleSelection.selectedModuleIds).map(
            (id) => newDesign.getModuleById(id)!
          );
          const siteModelCollisions = ModuleCollisionChecker.checkModulesAreWithinValidRoofSpace(
            selectedModules,
            siteModel,
            true
          );
          if (siteModelCollisions.size === 0) {
            return newDesign.getIDesign();
          }
        }
      }
      return state.design; // bail
    }

    case "undoModuleNudge": {
      const { moduleNudge, moduleSelection } = state as LoadedWorkspaceState;
      if (design && siteModel && moduleNudge && moduleNudge.originalNudgedModuleCenter) {
        const originalModuleCenter = positionToVector2D(moduleNudge.originalNudgedModuleCenter!);
        return design
          .undoTranslation(
            moduleSelection.selectedModuleIds,
            siteModel,
            moduleNudge.nudgedModuleId!,
            originalModuleCenter
          )
          .finishTranslation()
          .getIDesign();
      }
      return state.design; // bail
    }

    case "centerFillOneRoofFace": {
      return produce(design, (draft) => {
        const roofFace = siteModel!.getRoofFaceById(action.payload.roofFaceId)!;
        if (
          design &&
          siteModel &&
          design.getModulesOnRoofFace(roofFace.id).length == 0 &&
          roofFace.properties.usable
        ) {
          return design.attemptToFillRoofFaceFromCenter(
            roofFace.id,
            siteModel,
            state.settings.moduleOrientation,
            state.settings.isMagneticSnapEnabled
          );
        }
      })?.getIDesign();
    }

    case "fillRoofFaceAroundModule": {
      const { moduleSelection } = state as LoadedWorkspaceState;
      if (!design || !siteModel || !moduleSelection) {
        return state.design; // bail
      }
      const selectedModuleIds = moduleSelection.selectedModuleIds;
      if (selectedModuleIds.size === 0) {
        return state.design;
      }
      //just fill around the first selected module
      const fillAroundModuleId = [...selectedModuleIds][0];
      const fillAroundModule = design!.getModuleById(fillAroundModuleId)!;
      const fillAroundModuleOrientation = fillAroundModule.properties.orientation;
      const roofFace = siteModel!.getRoofFaceById(fillAroundModule!.properties.roofFaceId);
      if (roofFace!.properties.usable) {
        FullStory.event("Fill Roof Face Around Module", {
          roofFaceId: fillAroundModule!.properties.roofFaceId,
        });
        return design!
          .fillRoofFaceAroundModule(
            fillAroundModule!.id,
            siteModel!,
            fillAroundModuleOrientation!,
            state.settings.isMagneticSnapEnabled
          )
          .getIDesign();
      }
      return state.design; // bail
    }

    case "updateDesignWithModuleSpec": {
      if (design && siteModel) {
        return design.updateEquipmentWithModuleSpec(action.payload, siteModel).getIDesign();
      }
      return state.design;
    }

    case "updateDesignWithInverterSpecIds": {
      if (design) {
        return design
          .selectAndUpdateInverters(action.payload.newInverterSpecIds)
          .checkAndCorrectEquipmentCompatibility(
            action.payload.keystoneEquipment ?? [],
            isAdditionalSystem,
            action.payload.isFilter10kLogicEnabled
          )
          .getIDesign();
      }
      return state.design;
    }

    // This should reset the inverters and batteries to the same equipment
    // that exists in the initial low res design.
    case "resetToLowResEquipment": {
      if (design) {
        return design
          .resetToLowResEquipment(state.workflowState.hasManualBatteryConfig)
          .getIDesign();
      }
      return state.design;
    }

    case "updateDesignWithBatterySpecIds": {
      if (design) {
        return design
          .selectAndUpdateBatteries(action.payload.newBatterySpecIds)
          .selectAndUpdateMids(action.payload.newMidIds ?? [])
          .checkAndCorrectEquipmentCompatibility(
            action.payload.keystoneEquipment ?? [],
            isAdditionalSystem,
            action.payload.isFilter10kLogicEnabled
          )
          .getIDesign();
      }
      return state.design;
    }

    case "updateDesignWithPowerOptimizerSpecIds": {
      if (design) {
        return design
          .selectAndUpdatePowerOptimizers(action.payload.newPowerOptimizerSpecIds)
          .checkAndCorrectEquipmentCompatibility(
            action.payload.keystoneEquipment ?? [],
            isAdditionalSystem,
            action.payload.isFilter10kLogicEnabled
          )
          .getIDesign();
      }
      return state.design;
    }

    case "updateDesignWithBatteryCount": {
      if (design) {
        return design
          .resetToLowResEquipment(state.workflowState.hasManualBatteryConfig)
          .updateBatteryCount(action.payload)
          .getIDesign();
      }
      return state.design;
    }

    case "updateDesignWithBatteryConfiguration": {
      const { selectedBatterySpecificationIds, batteries } = action.payload;
      const batteryCount = action.payload.batteries.length;
      const batteryMode = batteries[0]?.properties.batteryMode ?? undefined;
      if (design) {
        return design
          .selectAndUpdateBatteries(selectedBatterySpecificationIds, batteryMode, batteryCount)
          .setBatteries(batteries)
          .selectAndUpdateMids(action.payload.selectedMidIds)
          .checkAndCorrectEquipmentCompatibility(
            action.payload.keystoneEquipment ?? [],
            isAdditionalSystem,
            action.payload.isFilter10kLogicEnabled
          )
          .getIDesign();
      }
      return state.design;
    }

    // Auto-updates the initial design from PW2 -> PW3
    // TODO: generalize this (ie not just pw3) using getEquipmentPriority?
    case "autoUpdateDesignWithPw3": {
      if (design && design.equipmentSpecifications) {
        // HANDLE PW3 CONVERSION
        // incoming assumptions:
        // design has pw2
        // availableEquipmentIfNewOffer includes pw3
        const pw3DcIfAvailable = state.availableEquipmentIfNewOffer?.batterySpecifications.find(
          (batterySpec) => batterySpec.model === BATTERY_MODEL_POWERWALL_3_BASE_PACK
        );
        const pw3AcIfAvailable = state.availableEquipmentIfNewOffer?.batterySpecifications.find(
          (batterySpec) => batterySpec.model === BATTERY_MODEL_POWERWALL_3_BASE_PACK_AC_COUPLED
        );
        if (!pw3DcIfAvailable && !pw3AcIfAvailable) {
          console.error(
            "BUG: Attempting to auto-swap to PW3 when no PW3 is available for new Offers"
          );
          return state.design;
        }
        // Default to DC if both are available
        const batteryToUseId = pw3DcIfAvailable ? pw3DcIfAvailable.id : pw3AcIfAvailable!.id;
        const keystoneEquipment = action.payload.keystoneEquipment ?? [];
        const updatedDesign = design
          .selectAndUpdateBatteries([batteryToUseId])
          .autoUpdateWithCompatibleMids(
            keystoneEquipment,
            isAdditionalSystem,
          )
          .checkAndCorrectEquipmentCompatibility(
            keystoneEquipment,
            isAdditionalSystem,
            action.payload.isFilter10kLogicEnabled
          )
          .getIDesign();
        return updatedDesign;
      }
      return state.design;
    }

    case "resolveSave": {
      if (design) {
        return design.resolveSave(action.payload).getIDesign();
      }
      return state.design;
    }

    case "resolvePrepareDesignForOverride": {
      if (design) {
        return design.resolvePrepareDesignForOverride(action.payload).getIDesign();
      }
      return state.design;
    }

    case "setSiteModelVersion": {
      if (design) {
        return design.updateSiteModelVersion(action.payload).getIDesign();
      }
      return state.design;
    }

    default: {
      return state.design;
    }
  }
};

const calculateDragDelta = (module: Module, moduleDrag: ModuleDrag): any => {
  const moduleCenter = polygonCentroid(module.geometry);
  const deltaDrag: Vector2D = [
    moduleDrag.dragPosition![0] - moduleCenter[0],
    moduleDrag.dragPosition![1] - moduleCenter[1],
  ];
  return deltaDrag;
};
