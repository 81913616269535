import { WorkspaceAction, WorkspaceState } from "../../hooks/useWorkspace";
import { produce } from "immer";
import { WorkspaceEmptyAction, WorkspacePayloadAction } from "../../types/state-management/action";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { deriveDesignClass } from "../design/designSlice";

export type WorkflowState = {
  hasAvailableEquipmentLoaded: boolean;
  hasDesignInitiated: boolean;
  isSimulateDesignInProgress: boolean;
  isLegacyFinalizeDesignInProgress: boolean;
  hasDesignFinalized: boolean;
  // track when a user is making inverter equipment selections in iHD.
  hasUserInverterUpdate: boolean;
  hasDesignInitiatedWithCollidingModules: boolean;
  variantDesignSelection: boolean;
  hasManualBatteryConfig: boolean;
  // these are used to track metrics on Go To Pricing
  variantDesignDefault: string | undefined;
  variantDesignSelected: string | undefined;
  finalizeDesignClickCount: number;
  requiresRecalculateSetbacks: boolean | undefined;
};

export const initialWorkflowState: WorkflowState = {
  hasAvailableEquipmentLoaded: false,
  hasDesignInitiated: false,
  isSimulateDesignInProgress: false,
  isLegacyFinalizeDesignInProgress: false,
  hasDesignFinalized: false,
  hasUserInverterUpdate: false,
  hasDesignInitiatedWithCollidingModules: false,
  variantDesignSelection: false,
  hasManualBatteryConfig: false,
  variantDesignDefault: undefined,
  variantDesignSelected: undefined,
  finalizeDesignClickCount: 0,
  requiresRecalculateSetbacks: false,
};

type WorkflowStateState = {
  hasAvailableEquipmentLoaded: boolean;
  hasDesignInitiated: boolean;
  isSimulateDesignInProgress: boolean;
  isLegacyFinalizeDesignInProgress: boolean;
  hasDesignFinalized: boolean;
  // track when a user is making inverter equipment selections in iHD.
  hasUserInverterUpdate: boolean;
  hasDesignInitiatedWithCollidingModules: boolean;
  variantDesignSelection: boolean;
  hasManualBatteryConfig: boolean;
  // these are used to track metrics on Go To Pricing
  variantDesignDefault: string | undefined;
  variantDesignSelected: string | undefined;
  finalizeDesignClickCount: number;
  requiresRecalculateSetbacks: boolean | undefined;
};

const initialState: WorkflowState = {
  hasAvailableEquipmentLoaded: false,
  hasDesignInitiated: false,
  isSimulateDesignInProgress: false,
  isLegacyFinalizeDesignInProgress: false,
  hasDesignFinalized: false,
  hasUserInverterUpdate: false,
  hasDesignInitiatedWithCollidingModules: false,
  variantDesignSelection: false,
  hasManualBatteryConfig: false,
  variantDesignDefault: undefined,
  variantDesignSelected: undefined,
  finalizeDesignClickCount: 0,
  requiresRecalculateSetbacks: false,
};

export const workflowStateSlice = createSlice({
  name: "workflowState",
  initialState,
  reducers: {
    availableEquipmentLoaded: (state: WorkflowStateState) => {
      state.hasAvailableEquipmentLoaded = true;
    },
    designInitiated: (state: WorkflowStateState) => {
      state.hasDesignInitiated = true;
    },
    simulateDesignInProgress: (state: WorkflowStateState) => {
      state.isSimulateDesignInProgress = true;
    },
    simulateDesignComplete: (state: WorkflowStateState) => {
      state.isSimulateDesignInProgress = false;
    },
    legacyFinalizeDesignInProgress: (state: WorkflowStateState) => {
      state.isLegacyFinalizeDesignInProgress = true;
    },
    legacyFinalizeDesignComplete: (state: WorkflowStateState) => {
      state.isLegacyFinalizeDesignInProgress = false;
    },
    designFinalized: (state: WorkflowStateState) => {
      state.hasDesignFinalized = true;
    },
    designNotFinalized: (state: WorkflowStateState) => {
      state.hasDesignFinalized = false;
    },
    setHasUserInverterUpdate: (state: WorkflowStateState) => {
      state.hasUserInverterUpdate = true;
    },
    designInitiatedWithCollidingModules: (state: WorkflowStateState) => {
      state.hasDesignInitiatedWithCollidingModules = true;
    },
    setVariantDesignSelection: (state: WorkflowStateState) => {
      state.variantDesignSelection = true;
    },
    dismissVariantDesignSelection: (state: WorkflowStateState) => {
      state.variantDesignSelection = false;
    },
    setVariantDesignDefault: (state: WorkflowStateState, action: PayloadAction<string>) => {
      state.variantDesignDefault = action.payload;
    },
    setVariantDesignSelected: (state: WorkflowStateState, action: PayloadAction<string>) => {
      state.variantDesignSelected = action.payload;
    },
    setHasManualBatteryConfig: (state: WorkflowStateState, action: PayloadAction<boolean>) => {
      state.hasManualBatteryConfig = action.payload
    },
    addFinalizeDesignClickCount: (state: WorkflowStateState) => {
      state.hasAvailableEquipmentLoaded = true;
    },
    setRequiresRecalculateSetbacks: (state: WorkflowStateState, action: PayloadAction<boolean>) => {
      state.requiresRecalculateSetbacks = action.payload;
    },
  },
});

export const {
  availableEquipmentLoaded,
  designInitiated,
  simulateDesignInProgress,
  simulateDesignComplete,
  legacyFinalizeDesignInProgress,
  legacyFinalizeDesignComplete,
  designFinalized,
  setHasUserInverterUpdate,
  designInitiatedWithCollidingModules,
  setVariantDesignSelection,
  dismissVariantDesignSelection,
  setVariantDesignDefault,
  setVariantDesignSelected,
  setHasManualBatteryConfig,
  addFinalizeDesignClickCount,
  designNotFinalized,
  setRequiresRecalculateSetbacks,
} = workflowStateSlice.actions;
export default workflowStateSlice.reducer;

// TODO: Remove the following and transition fully from WorkspaceState to RTK store

export type WorkflowStateWorkspaceAction =
  | WorkspaceEmptyAction<"availableEquipmentLoaded">
  | WorkspaceEmptyAction<"designInitiated">
  | WorkspaceEmptyAction<"simulateDesignInProgress">
  | WorkspaceEmptyAction<"simulateDesignComplete">
  | WorkspaceEmptyAction<"legacyFinalizeDesignInProgress">
  | WorkspaceEmptyAction<"legacyFinalizeDesignComplete">
  | WorkspaceEmptyAction<"designFinalized">
  | WorkspaceEmptyAction<"setHasUserInverterUpdate">
  | WorkspaceEmptyAction<"designInitiatedWithCollidingModules">
  | WorkspaceEmptyAction<"setVariantDesignSelection">
  | WorkspaceEmptyAction<"dismissVariantDesignSelection">
  | WorkspacePayloadAction<string, "setVariantDesignDefault">
  | WorkspacePayloadAction<string, "setVariantDesignSelected">
  | WorkspacePayloadAction<boolean, "setHasManualBatteryConfig">
  | WorkspaceEmptyAction<"addFinalizeDesignClickCount">
  | WorkspacePayloadAction<string | undefined, "setImportLightmileProjectUrl">
  | WorkspacePayloadAction<boolean, "setRequiresRecalculateSetbacks">;

export const selectWorkflowState = (state: WorkspaceState) => state.workflowState;
export const selectHasUserInverterUpdate = (state: WorkspaceState) =>
  state.workflowState.hasUserInverterUpdate;

export const workflowStateWorkspaceReducer = (
  state: WorkspaceState,
  action: WorkspaceAction,
): WorkflowState => {
  const design = deriveDesignClass(state);

  switch (action.type) {
    case "availableEquipmentLoaded": {
      return produce(state.workflowState, (draft) => {
        draft.hasAvailableEquipmentLoaded = true;
      });
    }
    case "designInitiated": {
      return produce(state.workflowState, (draft) => {
        draft.hasDesignInitiated = true;
      });
    }
    case "simulateDesignInProgress": {
      return produce(state.workflowState, (draft) => {
        draft.isSimulateDesignInProgress = true;
      });
    }
    case "simulateDesignComplete": {
      return produce(state.workflowState, (draft) => {
        draft.isSimulateDesignInProgress = false;
      });
    }
    case "legacyFinalizeDesignInProgress": {
      return produce(state.workflowState, (draft) => {
        draft.isLegacyFinalizeDesignInProgress = true;
      });
    }
    case "legacyFinalizeDesignComplete": {
      return produce(state.workflowState, (draft) => {
        draft.isLegacyFinalizeDesignInProgress = false;
      });
    }
    case "designFinalized": {
      return produce(state.workflowState, (draft) => {
        draft.hasDesignFinalized = true;
      });
    }
    case "setHasUserInverterUpdate": {
      return produce(state.workflowState, (draft) => {
        draft.hasUserInverterUpdate = true;
      });
    }
    case "designInitiatedWithCollidingModules": {
      return produce(state.workflowState, (draft) => {
        draft.hasDesignInitiatedWithCollidingModules = true;
      });
    }
    case "setVariantDesignSelection": {
      return produce(state.workflowState, (draft) => {
        draft.variantDesignSelection = true;
      });
    }
    case "dismissVariantDesignSelection": {
      return produce(state.workflowState, (draft) => {
        draft.variantDesignSelection = false;
      });
    }
    case "setVariantDesignDefault": {
      return produce(state.workflowState, (draft) => {
        draft.variantDesignDefault = action.payload;
      });
    }
    case "setVariantDesignSelected": {
      return produce(state.workflowState, (draft) => {
        draft.variantDesignSelected = action.payload;
      });
    }
    case "setHasManualBatteryConfig": {
      return produce(state.workflowState, (draft) => {
        draft.hasManualBatteryConfig = action.payload;
      });
    }
    // We don't use this pattern often but toggleIsFilter10kLogicEnabled is consumed by multiple reducers
    // This toggle will produce different designs when we simulate so set hasDesignFinalized to false when it changes
    // https://redux.js.org/style-guide/#allow-many-reducers-to-respond-to-the-same-action

    // We are changing away from this pattern as part of the move to Redux
    // This update is handled with designNotFinalized in Redux
    case "toggleIsFilter10kLogicEnabled": {
      return produce(state.workflowState, (draft) => {
        draft.hasDesignFinalized = false;
      });
    }
    case "addFinalizeDesignClickCount": {
      return produce(state.workflowState, (draft) => {
        draft.finalizeDesignClickCount = draft.finalizeDesignClickCount + 1;
      });
    }
    case "setRequiresRecalculateSetbacks": {
      return produce(state.workflowState, (draft) => {
        draft.requiresRecalculateSetbacks = action.payload;
      });
    }
    // Shares dispatch action with designSlice so we know if we need to set requiresRecalculateSetbacks to true
    // https://redux.js.org/style-guide/#allow-many-reducers-to-respond-to-the-same-action

    // TODO We need to update the Redux reducer to handle this action when we update designSlice https://sunrun.jira.com/browse/ET-1664
    // We can either move this logic outside of the slice or use combined reducer https://redux-toolkit.js.org/api/createSlice#extrareducers
    case "removeModules": {
      if (
        design &&
        state.moduleSelection.selectedModuleIds.size > 0 &&
        state.settings.isRecalculateFirecodeSetbacksEnabled
      ) {
        const newDesign = design.deleteModules(state.moduleSelection.selectedModuleIds);
        const hasRoofBeenClearedOfModules = design.hasDifferenceInRoofFacesWithModules(newDesign);
        if (hasRoofBeenClearedOfModules) {
          return produce(state.workflowState, (draft) => {
            draft.requiresRecalculateSetbacks = true;
          });
        }
      }
      return state.workflowState;
    }
    default: {
      return state.workflowState;
    }
  }
};
