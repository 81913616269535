import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Offers } from "@sunrun/design-tools-domain-model";
import { SrButton } from "@sunrun/experience-ui-components";
import StatDisplay, { StatDisplayProps } from "src/components/StatDisplay";
import { postIFrameMessage } from "src/hooks/useIFrameHost";
import { useWorkspace } from "src/hooks/useWorkspace";
import { IFrameEventType, SourceApp } from "src/types/IFrame";
import { deriveIsDesignFinalized } from "src/features/designGuidance/deriveIsDesignFinalized";
import { deriveLowResProductionSimulation } from "src/features/lowResProductionSimulation/deriveLowResProductionSimulation";
import { EvChargerPromotion } from "./EvChargerPromotion";
import {
  isEvChargerPromotionApplied,
  isEvChargerPromotionAvailable,
  isEligibleForEvChargerPromotion,
} from "src/features/promotions/evCharger";
import { useAddEvCharger } from "src/hooks/useAddEvCharger";
import {
  deriveBackupProductOptionChanges,
  deriveProductOptionChangesMessages,
  deriveSolarProductOptionChanges,
} from "src/features/designGuidance/deriveOfferProductChanges";
import { useEffect, useState } from "react";
import {
  deriveDisplayNameForBackupTier,
  deriveDisplayNameForBatteryType,
  deriveDisplayNameForInverterType,
  deriveDisplayNameForModuleType,
} from "src/features/products/productsSlice";
import { deriveDesignClass } from "src/features/design/designSlice";
import { useAppDispatch } from "src/store";
import { displayOfferUpdateConfirmationModal } from "src/features/modal/modalSlice";

export const ProductsDetailsSection = () => {
  const {state, dispatch: workspaceDispatch} = useWorkspace();
  const dispatch = useAppDispatch();
  const { addEvCharger } = useAddEvCharger();
  const { customer, offer, productionSimulation, products } = state;
  const productOptionChangesMessages =
    deriveProductOptionChangesMessages(state);
  const design = deriveDesignClass(state);
  const solarProductOptionChanges = deriveSolarProductOptionChanges(state);
  const backupProductOptionChanges = deriveBackupProductOptionChanges(state);

  // TODO: Extract logic?, copy-pasted similarly in a few places
  const designFinalized = deriveIsDesignFinalized(state);
  const simulationToUse = designFinalized
    ? productionSimulation
    : deriveLowResProductionSimulation(state);

  const [productsList, setProductsList] = useState<StatDisplayProps[]>([]);

  useEffect(() => {
    if (!offer) return;

    const inverterTypeNameDisplay = deriveDisplayNameForInverterType(state);
    const inverterTypeProductChange = solarProductOptionChanges.find(
      (productOptionChange) =>
        productOptionChange.optionName === inverterTypeNameDisplay
    );
    const inverterTypeValueDisplay = inverterTypeProductChange
      ? inverterTypeProductChange.newValue
      : Offers.getInverterTypeDisplay(offer, products);
    const backupTierNameDisplay = deriveDisplayNameForBackupTier(state);
    const backupTierProductChange = backupProductOptionChanges.find(
      (productOptionChange) =>
        productOptionChange.optionName === backupTierNameDisplay
    );
    const backupTierValueDisplay = backupTierProductChange
      ? backupTierProductChange.newValue + " *"
      : Offers.getBackupTierDisplay(offer, products);

    const fullProductsList = [
      {
        title: "Bundle",
        stat: Offers.getBundleTitle(offer),
      },
      {
        title: deriveDisplayNameForModuleType(state),
        stat: Offers.getModuleTypeDisplay(offer, products),
      },
      {
        title: deriveDisplayNameForInverterType(state),
        stat: inverterTypeValueDisplay,
      },
      {
        title: "Shift",
        stat: Offers.getShiftOfferLine(offer) ? "Included" : undefined,
      },
      {
        title: deriveDisplayNameForBatteryType(state),
        stat: Offers.getBatteryTypeDisplay(offer, products),
      },
      {
        title: deriveDisplayNameForBackupTier(state),
        stat: backupTierValueDisplay,
      },
      {
        title: "EV Charging",
        stat: Offers.getEvChargingOfferLine(offer)?.quantity,
      },
      {
        title: "Power Management",
        stat: Offers.getPowerManagementOfferLine(
          offer
        )?.variant?.title?.replace("Power Management: ", ""),
      },
    ];

    const filteredProductsList = fullProductsList.filter(
      (statDisplay) => statDisplay.stat !== undefined
    );
    setProductsList(filteredProductsList);
  }, [offer, products, solarProductOptionChanges, backupProductOptionChanges]);

  if (!offer) return null;

  const onUpdateProducts = () => {
    postIFrameMessage(IFrameEventType.DESIGN_EDIT_CANCEL, {
      designId: design!.id,
      offerId: offer.id,
    });
  };

  /**
   * Originally this was structured as:
   * <Details Section>
   *   <ProductsDetails>
   * </Details Section>
   *
   * But this adds customizations to both the Section and the Details portions that
   * would not apply to a generalized component. So this new component combines
   * both portions into a single custom component.
   */
  return (
    <>
      {/* DetailsSection component */}
      <Card
        sx={{ backgroundColor: "rgba(31, 38, 71, 0.05)", height: "100%" }}
        elevation={0}
      >
        <CardContent>
          <Stack direction="row">
            <Typography color="primary">Products</Typography>
            {productOptionChangesMessages.length > 0 && (
              <IconButton
                size="small"
                sx={{ height: "fit-content", padding: 0 }}
                onClick={() => {
                  dispatch(displayOfferUpdateConfirmationModal())
                  workspaceDispatch({ type: "displayOfferUpdateConfirmationModal" })
                }}
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" color="primary" />
              </IconButton>
            )}
          </Stack>
        </CardContent>
        <Divider variant="middle" />

        {/* ProductsDetails component */}
        <CardContent>
          <Grid container spacing={2}>
            {productsList.map((statDisplay) => (
              <Grid item xs={6} key={statDisplay.title}>
                <StatDisplay {...statDisplay} />
              </Grid>
            ))}
          </Grid>
          <Box sx={{ mt: 2, maxWidth: "300px" }}>
            <SrButton type="secondary" size="sm" onPress={onUpdateProducts}>
              Update Products
            </SrButton>
          </Box>
          {isEvChargerPromotionAvailable(state) && (
            <Box mt={2}>
              <EvChargerPromotion
                isEligible={isEligibleForEvChargerPromotion(state)}
                isApplied={isEvChargerPromotionApplied(state)}
                onCallToActionClick={async (
                  event: React.MouseEvent<HTMLButtonElement>
                ) => {
                  const { success } = await addEvCharger();
                  if (success) {
                    postIFrameMessage(IFrameEventType.OFFER_UPDATED, {
                      offerId: offer.id,
                      source: SourceApp.IHD,
                    });
                  }
                }}
                annualProduction={simulationToUse?.annualProductionKwh ?? 0}
                annualUsage={customer?.annualUsagekWh ?? 0}
              />
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
};
