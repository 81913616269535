import { IFrameEventType, SourceApp } from "src/types/IFrame";
import * as FullStory from "@fullstory/browser";
import { useLoadingScreen } from "@sunrun/design-tools-loading-screen";
import {
  LoadingProcessGroups,
  LoadingProcessNames,
} from "src/types/LoadingScreenProcess";
import { useWorkspace } from "./useWorkspace";
import { deriveDesignClass } from "src/features/design/designSlice";

export const useRegenerateLightmileDesign = () => {
  const { helpers: loadingScreenHelpers } = useLoadingScreen();
  const { state, dispatch } = useWorkspace();
  const design = deriveDesignClass(state);

  const regenerateDesigns = async (): Promise<void> => {
    dispatch({
      type: "setCancelContinueModal",
      payload: {
        title: "Confirm Regenerate Lightmile Designs",
        message: '"Regenerate Designs" will discard any changes.',
        cancelText: "Continue Editing",
        continueText: "Regenerate Designs",
        onContinue: () => {
          loadingScreenHelpers.addProcess({
            group: LoadingProcessGroups.REGENERATING_DESIGNS,
            name: LoadingProcessNames.REGENERATING_DESIGNS,
          });

          window.parent.postMessage(
            {
              type: IFrameEventType.GENERATE_DESIGNS,
              source: SourceApp.IHD,
            },
            "*"
          );
        },
      },
    });
  };

  const completeRegenerateDesign = (importLightmileProjectUrl?: string) => {  
    FullStory.event("Regenerated Design", {
      designId: design?.id,
      prospectId: design?.prospectId,
      importLightmileProjectUrl: importLightmileProjectUrl,
    });
    loadingScreenHelpers.completeProcess(
      LoadingProcessNames.REGENERATING_DESIGNS
    );
  };

  return {
    regenerateDesigns,
    completeRegenerateDesign,
  };
};
