import { WorkspaceAction, WorkspaceState } from "../../hooks/useWorkspace";
import { WorkspacePayloadAction as WorkspacePayloadAction } from "../../types/state-management/action";
import { IFrameHostType } from "../../types/IFrame";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

export type HostState = {
  value: IFrameHostType;
  importLightmileProjectUrl?: string;
};

export const initialState: HostState = {
  value: IFrameHostType.UNDEFINED,
  importLightmileProjectUrl: undefined,
};

export const hostSlice = createSlice({
  name: "host",
  initialState,
  reducers: {
    setHost: (state: HostState, action: PayloadAction<IFrameHostType>) => {
      state.value = action.payload;
    },
    // track the import url for refreshing after regenerating designs
    setImportLightmileProjectUrl: (state: HostState, action: PayloadAction<string>) => {
      if(!state?.importLightmileProjectUrl){
        state.importLightmileProjectUrl = action.payload
      }
    }              
  },  
});

export const { setHost, setImportLightmileProjectUrl } = hostSlice.actions
export default hostSlice.reducer

// TODO: Remove the following and transition fully from WorkspaceState to RTK store
export type HostAction = WorkspacePayloadAction<IFrameHostType, "setHost">;

export const hostWorkspaceReducer = (
  state: WorkspaceState,
  action: WorkspaceAction
): IFrameHostType | undefined => {
  switch (action.type) {
    case "setHost": {
      return action.payload;
    }
    default: {
      return state.host ? state.host : undefined;
    }
  }
};

export const selectHost = (state: WorkspaceState) => state.host;
export const selectIsOfferExperience = createSelector(
  [selectHost],
  (host) => {
    return (
      host === IFrameHostType.OFFER_BUILDER ||
      host === IFrameHostType.LIGHTMILE_OFFER_BUILDER
    );
  },
);
