import { useMediaQuery, useTheme } from "@mui/material";
import { ModalLayout } from "../components/ModalLayout";
import { DesignToolsTheme } from "@sunrun/design-tools-themes";
import { DesignBookmark } from "src/components/DesignBookmark/DesignBookmark";

type BookmarkDesignModalProps = {
  open: boolean;
  onClose: () => void;
};

export const BookmarkDesignModal = ({
  open,
  onClose,
}: BookmarkDesignModalProps) => {
  const theme = useTheme<DesignToolsTheme>();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <ModalLayout
      title="Bookmark Design"
      open={open}
      onClose={onClose}
      fullscreen={isXs}
    >
      <DesignBookmark />
    </ModalLayout>
  );
};
