import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Design, IDesign } from "@sunrun/design-tools-domain-model";
import {
  LoadedWorkspaceState,
  WorkspaceAction,
  WorkspaceState,
} from "src/hooks/useWorkspace";
import { deriveDesignClass } from "../design/designSlice";
import { WorkspaceEmptyAction } from "src/types/state-management/action";
import { createSelector } from "reselect";
import { deriveSiteModelClass } from "../siteModel/siteModelSlice";

// redux
export type undoDesignState = { design: IDesign | null };
// export const initialState: undoDesignState = null;
export const initialState: undoDesignState = { design: null };

export const undoDesignSlice = createSlice({
  name: "designEligibleForUndo",
  initialState,
  reducers: {
    setUndoableDesign: (
      state: undoDesignState,
      action: PayloadAction<IDesign>
    ) => {
      state.design = action.payload;
    },
  },
});

export const { setUndoableDesign } = undoDesignSlice.actions;
export default undoDesignSlice.reducer;

// useWorkspaceState
export type UndoDesignAction = WorkspaceEmptyAction<"clearUndoDesign">;

const selectUndoDesign = (state: WorkspaceState) => state.undoDesign;

export const undoDesignReducer = (
  state: WorkspaceState,
  action: WorkspaceAction
): IDesign | undefined => {
  const actionsEligibleForUndo = [
    "setDesign",
    "removeModules",
    "commitModuleTranslation",
    "rotateModules",
    "undoModuleTranslation",
    "clearUndoDesign",
    "fillRoofFaceAroundModule",
  ];
  const design = deriveDesignClass(state);

  if (!actionsEligibleForUndo.includes(action.type)) {
    // Keep undoDesign state the same for actions that are undo noop
    return state?.undoDesign;
  }

  switch (action.type) {
    case "commitModuleTranslation":
      // same logic in design as undoModuleTranslation - set the undoState to be undoTranslation design
      const { moduleDrag, moduleSelection } = state as LoadedWorkspaceState;
      const siteModel = deriveSiteModelClass(state);
      if (design && siteModel && moduleDrag) {
        return design
          .undoTranslation(
            moduleSelection.selectedModuleIds,
            siteModel,
            moduleDrag.draggedModuleId!,
            moduleDrag.originalDraggedModuleCenter!
          )
          .getIDesign();
      }
      return state.undoDesign;

    // cases for undo design
    case "undoModuleTranslation":
    case "clearUndoDesign": {
      return undefined;
    }

    default: {
      // undoable actions, save current design as undoDesign state
      return design?.getIDesign();
    }
  }
};

export const deriveUndoDesignClass = createSelector(
  [selectUndoDesign],
  (design) => {
    return design ? new Design(design) : undefined;
  }
);
