export const Constants = {
  LeafletDragThresholdInMeters: 0.2,
  nudgeDistanceInMeters: 0.01,
};

// https://leafletjs.com/examples/map-panes/
export const LeafletLayerZIndexes = {
  roofFaces: 410,
  setbacks: 411,
  setbackObstructions: 411,
  obstructions: 412,
  moduleSpacing: 413,
  modules: 414,
  trees: 415,
};

export const INSTALL_PARTNER_SUNRUN = "Sunrun";
export const SALES_DIVISION_AFFILIATE = "Channel Partner";
export const SALES_DIVISION_NEW_HOMES = "New Homes";
