import { Grid, Typography } from "@mui/material";
import { useWorkspace } from "src/hooks/useWorkspace";
import { SrButton } from "@sunrun/experience-ui-components";
import { useBookmarkDesign } from "src/hooks/useBookmarkDesign";
import { Design } from "@sunrun/design-tools-domain-model";
import { BookmarkedList } from "./DesignBookmarkedList";
import { DesignToolsTheme } from "@sunrun/design-tools-themes";
import { useMediaQuery, useTheme } from "@mui/material";
import { useDesign } from "src/hooks/useDesign";
import { deriveSiteModelClass } from "src/features/siteModel/siteModelSlice";
import { deriveDesignClass, setDesign } from "src/features/design/designSlice";
import { useAppDispatch } from "src/store";
import { dismissBookmarkDesigns } from "src/features/modal/modalSlice";
import { useCopyAndSyncDesignAndSiteModel } from "src/hooks/useCopyAndSyncDesignAndSiteModel";
import { setRequiresRecalculateSetbacks } from "src/features/workflowState/workflowStateSlice";

export const DesignBookmark = () => {
  const { state, dispatch: workspaceDispatch } = useWorkspace();
  const dispatch = useAppDispatch();
  const { copyAndSyncDesignAndSiteModel } = useCopyAndSyncDesignAndSiteModel();
  const design = deriveDesignClass(state);
  const {
    saveBookmarkDesignMutation,
    removeBookmarkDesignMutation,
    bookmarkDesignQuery,
  } = useBookmarkDesign(false);
  const { saveDesignMutation } = useDesign({
    designId: design!.id,
    designVersion: design!.version,
    enableGetDesign: false,
  });
  const siteModel = deriveSiteModelClass(state);

  const theme = useTheme<DesignToolsTheme>();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const fireSetbacksIsEnabled =
    state.settings.isRecalculateFirecodeSetbacksEnabled;

  if (!design || !siteModel) {
    return null;
  }
  const handleSave = async (): Promise<void> => {
    await saveBookmarkDesignMutation.mutateAsync(design);
    dispatch(dismissBookmarkDesigns());
    workspaceDispatch({ type: "dismissBookmarkDesigns" });
  };

  const handleBookmarkSelection = async (designToCopy: Design) => {
    // Whenever we load iHD we create a new Design and SiteModel with a version 0 and 1. We should fetch
    // this data to be copied into a new record of the current SiteModel ID to be referenced by the current design.
    // This will create a new version based on the design to copy SiteModel to be used in the current design. We can't
    // avoid the call if the design to copy SiteModel version is 1 in the case of the user wanting to go back to an
    // initial SiteModel after the setbacks have shifted.
    if (
      designToCopy.siteModelId &&
      designToCopy.siteModelVersion &&
      siteModel
    ) {
      // During the rollout of dynamic setbacks, a conflicting design with setbacks could be loaded into ihd.
      // I.e bookmarked design used dynamic setbacks but dynamic setback pilot is turned off.
      // In this case, we will load the bookmarked design with legacy siteModel (v1) and modules on disabled roofs
      // will be deleted within copyAndSyncDesignAndSiteModel hook.
      const siteModelIdToCopy = fireSetbacksIsEnabled
        ? designToCopy.siteModelId
        : siteModel.id;
      const siteModelVersionToCopy = fireSetbacksIsEnabled
        ? designToCopy.siteModelVersion
        : 1; // use version 1 for legacy setbacks

      const { updatedDesign, updatedSiteModel } =
        await copyAndSyncDesignAndSiteModel(designToCopy, {
          id: siteModelIdToCopy,
          version: siteModelVersionToCopy,
        });

      // Recalculate setbacks if dynamic setback is turned on and new design has empty roofs to depict setbacks accurately
      if (
        fireSetbacksIsEnabled &&
        updatedDesign.hasUsableRoofWithNoModules(updatedSiteModel)
      ) {
        console.log(
          "Recalculating setbacks after bookmarked design found to have roofs with no modules"
        );
        workspaceDispatch({
          type: "setRequiresRecalculateSetbacks",
          payload: true,
        });
        dispatch(setRequiresRecalculateSetbacks(true));
      }
    }

    dispatch(dismissBookmarkDesigns());
    workspaceDispatch({ type: "dismissBookmarkDesigns" });
  };

  const handleBookmarkRemoval = async (designToRemove: Design) => {
    await removeBookmarkDesignMutation.mutateAsync(designToRemove);
  };

  const disableClick =
    removeBookmarkDesignMutation.isLoading ||
    saveBookmarkDesignMutation.isLoading ||
    bookmarkDesignQuery.isRefetching || // prevents bookmark removal errors
    saveDesignMutation.isLoading; // prevents bookmarking errors during saving

  const bookmarkedDesignProps = {
    handleBookmarkSelection,
    handleBookmarkRemoval,
    disableClick,
  };

  return (
    <>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: ".5rem",
          height: isXs ? "30rem" : "15rem",
        }}
      >
        <SrButton
          isDisabled={disableClick}
          onPress={handleSave}
          onLongPress={handleSave}
          width="15rem"
          size="sm"
        >
          Bookmark Current Design
        </SrButton>
        <Typography sx={{ paddingTop: "1rem" }} variant="h6">
          {`Open Bookmarked Design (${state?.bookmarkedDesigns?.length || 0})`}:
        </Typography>
        <BookmarkedList bookmarkedDesignProp={bookmarkedDesignProps} />
      </Grid>
    </>
  );
};
