import {
  getProductOptionNameDisplay,
  getProductOptionValueDisplay,
  ProductOptionName,
  ProductOptionValue,
  Products
} from "@sunrun/design-tools-domain-model";
import { createSelector } from "reselect";
import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {WorkspacePayloadAction} from "../../types/state-management/action";


export type ProductsAction = WorkspacePayloadAction<Products, 'setProducts'>
export const selectProducts = (state: WorkspaceState) => state.products

// Display values for ProductOptionNames
export const deriveDisplayNameForModuleType = createSelector(
  [selectProducts],
  (products) => getProductOptionNameDisplay(ProductOptionName.ModuleType, products)
);
export const deriveDisplayNameForInverterType = createSelector(
  [selectProducts],
  (products) => getProductOptionNameDisplay(ProductOptionName.InverterType, products)
);
export const deriveDisplayNameForBackupTier = createSelector(
  [selectProducts],
  (products) => getProductOptionNameDisplay(ProductOptionName.BackupTier, products)
);
export const deriveDisplayNameForBatteryType = createSelector(
  [selectProducts],
  (products) => getProductOptionNameDisplay(ProductOptionName.BatteryType, products)
);

// Display values for ProductOptionValues
export const deriveDisplayNameForStandard = createSelector(
  [selectProducts],
  (products) => getProductOptionValueDisplay(ProductOptionValue.Standard, products)
);
export const deriveDisplayNameForCustom = createSelector(
  [selectProducts],
  (products) => getProductOptionValueDisplay(ProductOptionValue.Custom, products)
);

export const productsReducer = (state: WorkspaceState, action: WorkspaceAction): Products => {
  switch (action.type) {
    case 'setProducts': {
      return action.payload;

    }
    default: {
      return state.products
    }
  }
}
