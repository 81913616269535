import React from "react";
import _ from "lodash"
import {
  Customer,
  Design,
  GeoRaster,  
  SiteModel,
  EquipmentSpecifications
} from "@sunrun/design-tools-domain-model"
import {Mode} from "src/modes/Mode";
import {Settings, SettingsWorkspaceAction, settingsInitialState, settingsWorkspaceReducer} from "src/features/settings/settingsSlice"
import {ModuleDrag, ModuleDragWorkspaceAction, dragInitialState, dragWorkspaceReducer} from "../features/moduleDrag/moduleDragSlice";
import {DesignWorkspaceAction, designWorkspaceReducer} from "../features/design/designSlice";
import {ModuleSelection, ModuleSelectionWorkspaceAction, moduleSelectionWorkspaceReducer} from "../features/moduleSelection/moduleSelectionSlice"; 
import {marqueeInitialState, MarqueeWorkspaceAction, serializableMarquee} from "../features/marquee/marqueeSlice";
import {SiteModelWorkspaceAction, siteModelWorkspaceReducer} from "../features/siteModel/siteModelSlice";
import {
  LeafletPointerEventAction,
  KeyboardEventAction,
  NoOpAction,
  resolveFeatureAction
} from "../actionResolvers/featureActionResolver";
import {CustomerWorkspaceAction, customerWorkspaceReducer} from "../features/customer/customerSlice";
import {DesignConstraintsWorkspaceAction, designConstraintsWorkspaceReducer} from "../features/designConstraints/designConstraintsSlice";
import {GeoRasterWorkspaceAction, geoRasterWorkspaceReducer} from "../features/geoRaster/geoRasterSlice";
import {SolarResourceWorkspaceAction, solarResourceWorkspaceReducer} from "../features/solarResource/solarResourceSlice";
import {ProductionSimulationWorkspaceAction, productionSimulationWorkspaceReducer} from "../features/productionSimulation/productionSimulationSlice";
import {
  initialWorkflowState,
  WorkflowState,
  WorkflowStateWorkspaceAction,
  workflowStateWorkspaceReducer
} from "../features/workflowState/workflowStateSlice";
import {HeatMap, HeatMapWorkspaceAction, heatMapWorkspaceReducer} from "../features/heatMap/heatMapSlice";
import {HackWorkspaceAction, hacksWorkspaceReducer} from "../features/hacks/hacksReducerSlice";
import {HostAction, hostWorkspaceReducer} from "../features/host/hostSlice";
import {HotkeyAction} from "./useHotkeys";
import {notificationInitialState, notificationWorkspaceReducer, Notification, NotificationWorkspaceAction} from "../features/notifications/notificationSlice";
import {
  moduleNudgeInitialState,
  ModuleNudge,
  nudgeWorkspaceReducer,
  ModuleNudgeWorkspaceAction
} from "../features/moduleNudge/moduleNudgeSlice";
import {marqueeWorkspaceReducer} from "../features/marquee/marqueeSlice";
import {
  MostRecentSignedDesignAction,
  mostRecentSignedDesignWorkspaceReducer,
  initialState,
  MostRecentSignedDesignState
} from "../features/mostRecentSignedDesign/mostRecentSignedDesign";
import {IFrameHostType} from "../types/IFrame";
import {
  ReadOnlyDesignSetsAction,
  initialReadOnlyDesignState,
  readOnlyDesignSetsReducer,
  SerializableReadOnlyDesignSets
} from "src/features/readOnlyDesignSet/readOnlyDesignSetSlice";
import {OfferWorkspaceAction, offerWorkspaceReducer} from "src/features/offer/offerSlice";
import { Modal, ModalWorkspaceAction, modalInitialState, modalWorkspaceReducer } from "src/features/modal/modalSlice";
import { promotionsWorkspaceReducer } from "src/features/promotions/promotionsSlice";
import { productAvailabilityReducer } from "src/features/productAvailability/productAvailabilitySlice";

import type {Offer, ProductAvailability, Promotion, Products, IProductionSimulation, IDesignConstraints, ISiteModel, ISolarResource, IDesign} from "@sunrun/design-tools-domain-model";
import type { PromotionWorkspaceAction } from "src/features/promotions/promotionsSlice";
import type { ProductAvailabilityAction } from "src/features/productAvailability/productAvailabilitySlice";
import { AvailableEquipmentAction, availableEquipmentWorkspaceReducer } from "src/features/equipment/availableEquipmentSlice";
import { ProductsAction, productsReducer } from "src/features/products/productsSlice";
import { AvailableEquipmentIfNewOfferAction, availableEquipmentIfNewOfferReducer } from "src/features/equipment/availableEquipmentIfNewOfferSlice";
import { BookmarkWorkspaceAction, bookmarkWorkspaceReducer } from "src/features/bookmark/bookmarkSlice";
import { UndoDesignAction, undoDesignReducer } from "src/features/undo/undoDesignSlice";
import { SetbackRulesAction, setbackRulesInitialState, setbackRulesReducer, SetbackRulesState } from "src/features/setbackRules/setbackRulesSlice";
import { Display, DisplayAction, displayInitialState, displayReducer } from "src/features/display/displaySlice";
import { SolarResourceExpandedWorkspaceAction, solarResourceExpandedWorkspaceReducer } from "src/features/solarResourceExpanded/solarResourceExpandedSlice";

/**
 * General approach here is taken from https://kentcdodds.com/blog/how-to-use-react-context-effectively
 */

export type WorkspaceState = {
  // micro-frontend host
  host?: IFrameHostType

  // Aggregate State
  customer?: Customer
  availableEquipment?: EquipmentSpecifications
  availableEquipmentIfNewOffer?: EquipmentSpecifications
  design?: IDesign
  designConstraints?: IDesignConstraints
  geoRaster?: GeoRaster
  productionSimulation?: IProductionSimulation // note: this production sim is EITHER the high res sim, or the lowRes sim with FUDGE PRE-APPLIED!
  readOnlyDesignSets: SerializableReadOnlyDesignSets
  HACKS: {
    // there is currently an issue in which high-res  simulations are
    // quite different than low-res  simulation estimates.
    // these HACKS are here to power the fudge-factor that we use to improve the
    // iHD user experience, while in parallel we attempt to understand the discrepancy
    // more deeply. so - once we understand, we will remove this slice of the state.
    designAtSimulationTime?: Design,
  }
  siteModel?: ISiteModel
  solarResource?: ISolarResource
  solarResourceExpanded?: ISolarResource  
  offer?: Offer

  // Storefront State
  products?: Products

  // Process Manager State
  workflowState: WorkflowState

  // iHD Model
  mostRecentSignedDesign: MostRecentSignedDesignState
  heatMap: HeatMap
  mode: Mode
  moduleDrag: ModuleDrag
  moduleNudge: ModuleNudge
  moduleSelection: ModuleSelection
  marquee: serializableMarquee
  promotions?: Promotion[]
  productAvailability?: ProductAvailability
  settings: Settings
  notification: Notification
  modal: Modal
  actionHistory: WorkspaceAction[]
  bookmarkedDesigns: IDesign[]
  display: Display

  // undo design state
  undoDesign?: IDesign

  // Setback Rules
  setbackRules: SetbackRulesState
}

// a type we can use that guarantees the workspace has been initialized
export type LoadedWorkspaceState = WorkspaceState & {
  design: Design
  siteModel: SiteModel
}

export type WorkspaceAction =
  | HostAction
  | LeafletPointerEventAction
  | KeyboardEventAction
  | MostRecentSignedDesignAction
  | ModuleDragWorkspaceAction
  | ModuleNudgeWorkspaceAction
  | CustomerWorkspaceAction
  | GeoRasterWorkspaceAction
  | DesignConstraintsWorkspaceAction
  | SiteModelWorkspaceAction
  | DesignWorkspaceAction
  | ReadOnlyDesignSetsAction
  | AvailableEquipmentAction
  | AvailableEquipmentIfNewOfferAction
  | ModuleSelectionWorkspaceAction
  | MarqueeWorkspaceAction
  | SolarResourceWorkspaceAction
  | SolarResourceExpandedWorkspaceAction
  | ProductionSimulationWorkspaceAction
  | WorkflowStateWorkspaceAction
  | OfferWorkspaceAction
  | HeatMapWorkspaceAction
  | SettingsWorkspaceAction
  | HackWorkspaceAction
  | HotkeyAction
  | NotificationWorkspaceAction
  | ModalWorkspaceAction
  | NoOpAction
  | PromotionWorkspaceAction
  | ProductAvailabilityAction
  | ProductsAction
  | BookmarkWorkspaceAction
  | UndoDesignAction
  | SetbackRulesAction
  | DisplayAction

/**
 * Modes are implemented by swapping out the reducer function implementation, but the reducer function state / action
 * API will remain constant.
 */
const workspaceReducer = (state: WorkspaceState, action: WorkspaceAction) => {
  switch (state.mode) {
    case Mode.ModuleAdjustmentMode:
      return moduleAdjustmentReducer(state, action)
    default:
      throw new Error("Workspace mode was not set")
  }
}

const moduleAdjustmentReducer = (state: WorkspaceState, action: WorkspaceAction): WorkspaceState => {
  // Convert any low-level Leaflet actions to semantic actions. Leaflet event actions can resolve to multiple semantic
  // feature actions, so this returns an array of 1 or more semantic actions
  let actions: WorkspaceAction[] = resolveFeatureAction(state, action)
  actions = actions.filter(action => action.type !== 'noop')
  if (actions.length === 0) return state; // nothing to do return state unmodified
  // avoid spamming the logs with lots of repetitive actions (esp from noop or drag events)  
  for (action of actions) {
    // these are the undo designs that should be save
    if (!actionsWereRecentlyAddedToHistory(state.actionHistory, actions)) {      
      console.log(`action: ${action.type}`)
    }
  }
  let currState = state
  let nextState = state // this will always be reassigned below but initializing here to placate the compiler
  for (action of actions) {
    nextState = {
      mode: state.mode,
      host: hostWorkspaceReducer(currState, action),
      mostRecentSignedDesign: mostRecentSignedDesignWorkspaceReducer(currState, action),
      customer: customerWorkspaceReducer(currState, action),
      siteModel: siteModelWorkspaceReducer(currState, action),
      design: designWorkspaceReducer(currState, action),
      availableEquipment: availableEquipmentWorkspaceReducer(currState, action),
      availableEquipmentIfNewOffer: availableEquipmentIfNewOfferReducer(currState, action),
      moduleDrag: dragWorkspaceReducer(currState, action),
      moduleNudge: nudgeWorkspaceReducer(currState, action),
      moduleSelection: moduleSelectionWorkspaceReducer(currState, action),
      marquee: marqueeWorkspaceReducer(currState, action),
      designConstraints: designConstraintsWorkspaceReducer(currState, action),
      geoRaster: geoRasterWorkspaceReducer(currState, action),
      solarResource: solarResourceWorkspaceReducer(currState, action),
      solarResourceExpanded: solarResourceExpandedWorkspaceReducer(currState, action),
      productionSimulation: productionSimulationWorkspaceReducer(currState, action),
      readOnlyDesignSets: readOnlyDesignSetsReducer(currState, action),
      workflowState: workflowStateWorkspaceReducer(currState, action),
      offer: offerWorkspaceReducer(currState, action),
      heatMap: heatMapWorkspaceReducer(currState, action),
      settings: settingsWorkspaceReducer(currState, action),
      HACKS: hacksWorkspaceReducer(currState, action),
      notification: notificationWorkspaceReducer(currState, action),
      modal: modalWorkspaceReducer(currState, action),
      actionHistory: state.actionHistory,
      promotions: promotionsWorkspaceReducer(currState, action),
      productAvailability: productAvailabilityReducer(currState, action),
      products: productsReducer(currState, action),
      bookmarkedDesigns: bookmarkWorkspaceReducer(currState, action),
      undoDesign: undoDesignReducer(currState, action),
      setbackRules: setbackRulesReducer(currState, action),
      display: displayReducer(currState, action)
    }
    currState = nextState
  }  
  nextState.actionHistory = updateActionHistory(currState.actionHistory, actions)  
  return nextState
}

export function updateActionHistory(actionHistory: WorkspaceAction[], actions: WorkspaceAction[]) {
  const newHistory = [...actions.reverse(), ...actionHistory]
  return newHistory.slice(0, 10)
}

export function actionsWereRecentlyAddedToHistory(actionHistory: WorkspaceAction[], actions: WorkspaceAction[]): boolean {
  const recentHistory = _.take(actionHistory, actions.length)
  const reversedActions = [...actions].reverse()
  return recentHistory.every((historic: WorkspaceAction, index) => historic.type === reversedActions[index].type)
}

export type WorkspaceProps = { children: React.ReactNode, initialState?: WorkspaceState }

const WorkspaceContext = React.createContext<{state: WorkspaceState; dispatch: WorkspaceDispatch } | undefined>(undefined)

export const defaultInitialState: WorkspaceState = {
  host: undefined,
  mostRecentSignedDesign: initialState,
  customer: undefined,
  siteModel: undefined,
  design: undefined,
  bookmarkedDesigns: [], 
  availableEquipment: undefined,
  availableEquipmentIfNewOffer: undefined,
  designConstraints: undefined,
  geoRaster: undefined,
  solarResource: undefined,
  productionSimulation: undefined,
  readOnlyDesignSets: initialReadOnlyDesignState,
  workflowState: initialWorkflowState,
  offer: undefined,
  HACKS:{},
  heatMap: {
    isVisible: false,
    bounds: undefined
  },
  mode: Mode.ModuleAdjustmentMode,
  moduleDrag: dragInitialState,
  moduleNudge: moduleNudgeInitialState,
  moduleSelection: {
    selectedModuleIds: new Set<string>()
  },
  marquee: marqueeInitialState,
  promotions: undefined,
  settings: settingsInitialState,
  notification: notificationInitialState,
  modal: modalInitialState,
  actionHistory: [],
  undoDesign: undefined,
  setbackRules: setbackRulesInitialState,
  display: displayInitialState
}

export const WorkspaceProvider = ({ children, initialState }: WorkspaceProps) => {
  const [state, dispatch] = React.useReducer(workspaceReducer, {
      ...defaultInitialState,
      ...(initialState || {}),
    }
  )

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch }
  return (
    <WorkspaceContext.Provider value={value}>
      {children}
    </WorkspaceContext.Provider>
  )
}

export const useWorkspace = () => {  
  const context = React.useContext(WorkspaceContext)  

  if (context === undefined) {
    throw new Error('useWorkspace must be used within a WorkspaceProvider')
  }
  return context
}

export type WorkspaceDispatch = React.Dispatch<WorkspaceAction>