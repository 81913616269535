import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {WorkspacePayloadAction} from "../../types/state-management/action";
import {EquipmentSpecifications} from "@sunrun/design-tools-domain-model";

export type AvailableEquipmentIfNewOfferAction = WorkspacePayloadAction<EquipmentSpecifications, 'setAvailableEquipmentIfNewOffer'>;

export const selectAvailableEquipmentIfNewOffer = (state: WorkspaceState) => state.availableEquipmentIfNewOffer;

export const availableEquipmentIfNewOfferReducer = (state: WorkspaceState, action: WorkspaceAction): EquipmentSpecifications | undefined => {
  switch (action.type) {
    case 'setAvailableEquipmentIfNewOffer': {
        return action.payload;
    }
    default: {
      return state.availableEquipmentIfNewOffer;
    }
  }
};
