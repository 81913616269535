import { Box, useTheme, useMediaQuery } from "@mui/material";
import { DesignToolsTheme } from "@sunrun/design-tools-themes";
import { PrPreviewDisplay } from "./PrPreviewDisplay";

export const LeftFloatingActionButtons = () => {
  const theme = useTheme<DesignToolsTheme>();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  // This Component can only show for dev/staging. In Prod this section of the screen is used by the ONE menu button. 
  return (
    <>
      <Box
        sx={{
          zIndex: theme.zIndex.prPreviewURL,
          padding: 1,
          position: "fixed",
          bottom: isXs ? "88px" : 0,
          left: isXs ? 0 : "8px",
          textShadow: "1px 1px 2px black",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        <PrPreviewDisplay />
      </Box>
    </>
  );
};
