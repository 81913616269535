import {LoadingScreenProvider} from "@sunrun/design-tools-loading-screen";
import {QueryCache, QueryClient, QueryClientProvider} from "react-query";
import {AuthProvider} from "@sunrun/design-tools-react-router-require-auth";
import {RootThemeProvider} from "@sunrun/design-tools-themes";
import {WorkspaceProvider, WorkspaceState} from "src/hooks/useWorkspace";
import flagsmith from 'flagsmith'
import {FlagsmithProvider} from 'flagsmith/react'
import {getDefaultFeatures, getFlagsmithConfiguration} from "src/config/flagsmithConfig";
import React from "react";
import { store } from "src/store"; 
import { Provider as ReduxProvider } from 'react-redux'
import { FlagsmithProviderWrapper } from "./FlagsmithProviderWrapper";

export type GlobalProvidersProps = {
  children?: React.ReactNode,
  workspaceInitialState?: WorkspaceState
}
const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: unknown) => {
      /**
       * Currently query errors are nonrecoverable, so we throw them up to the top level Error Boundary.
       * https://tkdodo.eu/blog/react-query-error-handling#the-global-callbacks
       */
      let errorMessage = "Something went wrong querying for data"
      if (error instanceof Error) errorMessage += `: ${error.message}`
      throw new Error(`Something went wrong: ${errorMessage}`)
    }
  }),
})

const GlobalProviders = ({children, workspaceInitialState}: GlobalProvidersProps) => {
  return (
    <RootThemeProvider>
      <LoadingScreenProvider>
        <QueryClientProvider client={queryClient}>
          <LoadingScreenProvider>
            <AuthProvider>
              <FlagsmithProviderWrapper flagsmith={flagsmith}>
                <ReduxProvider store={store}>
                  <WorkspaceProvider initialState={workspaceInitialState}>
                    {children}
                  </WorkspaceProvider>
                </ReduxProvider>
              </FlagsmithProviderWrapper>
            </AuthProvider>
          </LoadingScreenProvider>
        </QueryClientProvider>
      </LoadingScreenProvider>
    </RootThemeProvider>
  );
}

export default GlobalProviders
