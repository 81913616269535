import { createSelector } from "reselect";
import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {WorkspacePayloadAction} from "../../types/state-management/action";
import {ISiteModel, SiteModel} from "@sunrun/design-tools-domain-model";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type SiteModelState  = {
  value: ISiteModel | undefined;
}

export const initialState: SiteModelState = {
  value: undefined,
};

export const siteModelSlice = createSlice({
  name: "siteModel",
  initialState,
  reducers: {
    setSiteModel: (state: SiteModelState, action: PayloadAction<ISiteModel>) => {
      state.value = action.payload
    },
  }
});

export const { setSiteModel } = siteModelSlice.actions
export default siteModelSlice.reducer

// TODO: Remove the following and transition fully from WorkspaceState to RTK store
export type SiteModelWorkspaceAction = WorkspacePayloadAction<SiteModel, 'setSiteModel'>

export const selectSiteModel = (state: WorkspaceState) => state.siteModel

export const siteModelWorkspaceReducer = (state: WorkspaceState, action: WorkspaceAction): ISiteModel | undefined => {
  switch (action.type) {
    case 'setSiteModel': {
      return action.payload.getISiteModel();
    }
    default: {
      return state.siteModel
    }
  }
}

export const deriveSiteModelClass = createSelector(
  [selectSiteModel],
  (siteModel) => { return siteModel ? new SiteModel(siteModel) : undefined}
)
