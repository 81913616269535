import {createSelector} from "reselect";
import {deriveDesignClass} from "src/features/design/designSlice";
import {deriveProductionSimulationClass, selectProductionSimulation} from "../productionSimulation/productionSimulationSlice";
import { selectWorkflowState } from "../workflowState/workflowStateSlice";
import { ProductionSimulation } from "@sunrun/design-tools-domain-model";


export const deriveIsDesignFinalized = createSelector(
  [deriveDesignClass, deriveProductionSimulationClass, selectWorkflowState],
  (design, productionSimulation, workflowState) => {
    if (design && productionSimulation && workflowState.hasDesignFinalized) {
      return design.isFinalized(productionSimulation);
    }
    return false;
  }
);
