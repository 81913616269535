import { List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { ModalLayout } from "./components/ModalLayout";
import { deriveDesignWarnings } from "src/features/designGuidance/deriveDesignWarnings";
import { useWorkspace } from "src/hooks/useWorkspace";

type DesignWarningsModalProps = {
  open: boolean;
  onClose: () => void;
}

export const DesignWarningsModal = ({ open, onClose }: DesignWarningsModalProps) => {
  const { state } = useWorkspace();
  const designWarnings = deriveDesignWarnings(state);

  return(
    <ModalLayout 
      open={open}
      title="Design Warnings"
      onClose={onClose}
      actionButtons
    >
      <Stack spacing={2}>
        <Typography
          variant="h6"
          color="primary"
        >
          This design has changes that will impact the proposal:
        </Typography>
        <List>
          {designWarnings.map(designWarning => (
            <ListItem>
             <ListItemIcon>
               <ErrorIcon color="primary" />
             </ListItemIcon>
             <ListItemText>
               <Typography variant="body1">
                 {designWarning.name}
               </Typography>
               <List>
                {designWarning.details?.map(detail => (
                  <ListItem dense>
                    <Typography variant="body2">
                      {detail}
                    </Typography>
                  </ListItem>
                ))}
               </List>
             </ListItemText>
           </ListItem>
          ))}
        </List>
      </Stack>
    </ModalLayout>
  )
}