import { TableCell, TableRow, Typography } from "@mui/material";
import { CellData, FinancialProductsCell } from "./FinancialProductsCell";
import {
  DesignConstraintType,
  PvProduct,
  flexMonthlyBackupConstraintMet,
  flexMonthlyProductConstraintMet
} from "@sunrun/design-tools-domain-model";
import { useWorkspace } from "src/hooks/useWorkspace";
import { deriveDesignClass } from "src/features/design/designSlice";

type FinancialProductsRowProductsAndEquipmentProps = {
  designConstraintType: DesignConstraintType;
  fullscreen: boolean | undefined;
  products: PvProduct[];
};

export const FinancialProductsRowProductsAndEquipment = ({
  designConstraintType,
  fullscreen,
  products,
}: FinancialProductsRowProductsAndEquipmentProps) => {
  const { state } = useWorkspace();
  const { offer } = state;
  const design = deriveDesignClass(state);
  const hasBackup = flexMonthlyBackupConstraintMet(offer);

  if (!design) {
    return null;
  }

  let rowLabel: string = "";
  let valueLabel: string = "";
  let designValue: string = "";
  let allowed = false;

  switch (designConstraintType) {
    case DesignConstraintType.requiredProductConstraint:
      rowLabel = "Required Product";
      valueLabel = "Backup";
      designValue = offer?.bundle?.title || "";
      allowed = hasBackup;
      break;
    case DesignConstraintType.requiredEquipmentConstraint:
      rowLabel = "Required Equipment";
      valueLabel = "Powerwall 3";
      allowed = flexMonthlyProductConstraintMet(design);
      break;
    default:
      rowLabel = "";
      break;
  }

  const rowData = () => {
    let data: CellData[] = [];
    products.forEach((product, index) => {
      if (product !== PvProduct.FlexMonthly) {
        data.push({
          allowed: false,
          designConstraint: {
            type: designConstraintType,
          },
          value: undefined,
        });
      } else {
        // is Flex and should have product and equipment
        data.push({
          allowed: allowed,
          designConstraint: {
            type: designConstraintType,
          },
          value: valueLabel,
        });
      }
    });
    return data;
  };

  const calculatedRowData = rowData();
  const entireRowIsUndefined = calculatedRowData.every(
    ({ value }) => value === undefined
  );
  if (entireRowIsUndefined) return null;

  return (
    <TableRow>
      <TableCell sx={{ px: fullscreen ? 1 : "default" }}>
        <Typography color="primary" variant="body2">
          {rowLabel}
        </Typography>
      </TableCell>
      {calculatedRowData.map((cellData, index) => (
        <FinancialProductsCell
          key={index}
          cellData={cellData}
          fullscreen={fullscreen}
        />
      ))}
    </TableRow>
  );
};
