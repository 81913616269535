import React from "react";
import {
  Box,
  Paper,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useMediaQuery,
  Grid,
} from "@mui/material";
import { DesignToolsTheme } from "@sunrun/design-tools-themes";
import { useWorkspace } from "src/hooks/useWorkspace";
import { deriveIsDesignFinalized } from "src/features/designGuidance/deriveIsDesignFinalized";
import { deriveLowResProductionSimulation } from "src/features/lowResProductionSimulation/deriveLowResProductionSimulation";
import {
  DesignConstraintType,
  designConstraintTypesFinancialProductsPanel,
  PvProduct,
  flexMonthlyProductConstraintMet,
  FLEX_MONTHLY_DISPLAY_NAME,
  flexMonthlyBackupConstraintMet,
  ProductsAvailability
} from "@sunrun/design-tools-domain-model";
import { IFrameEventType, IFrameHostType } from "src/types/IFrame";
import { deriveDesignConstraintsClass } from "src/features/designConstraints/designConstraintsSlice";
import { deriveProductionSimulationClass } from "src/features/productionSimulation/productionSimulationSlice";
import { deriveDesignClass } from "src/features/design/designSlice";
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { postIFrameMessage } from "src/hooks/useIFrameHost";
import Button from '@mui/material/Button';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { FlagsmithFeatureEnums } from "src/config/flagsmithConfig";
import { useFlags } from "flagsmith/react";
import { useAppSelector } from "src/store";
import { deriveDesignFinancialProductTypes } from "src/features/productAvailability/productAvailabilitySlice";

const FinancialProductsPanel: React.FC<any> = (props) => {
  const { state } = useWorkspace();
  const { customer, offer, display, productAvailability} = state;

  const theme = useTheme<DesignToolsTheme>();
  const designConstraints = deriveDesignConstraintsClass(state);
  const designConstraintTypes = designConstraintTypesFinancialProductsPanel
  let financialProducts = deriveDesignFinancialProductTypes(state)  
  const isDesignMenuExpanded = useAppSelector((state) => state.display.value.isDesignMenuExpanded);
  const isHostFlexCompatible = state.host === IFrameHostType.OFFER_BUILDER || state.host === IFrameHostType.LIGHTMILE_OFFER_BUILDER;
  const productAvailabilityHasFlexOption = ProductsAvailability.hasSunrunFlexFinancialProductOption(productAvailability)
  const showFlexInfo = useFlags([FlagsmithFeatureEnums.SHOW_FLEX_INFO])[FlagsmithFeatureEnums.SHOW_FLEX_INFO].enabled && isHostFlexCompatible && productAvailabilityHasFlexOption;
  if(!showFlexInfo){    
    financialProducts = financialProducts?.filter((product) => product !== PvProduct.FlexMonthly);    
  }

  const design = deriveDesignClass(state);
  const productionSimulation = deriveProductionSimulationClass(state);
  const designFinalized = deriveIsDesignFinalized(state)
  const simulationToUse = designFinalized ? productionSimulation : deriveLowResProductionSimulation(state);
  const systemSunHours = simulationToUse?.getSystemSunHours(design) ?? 0;
  const usageOffsetPercent = simulationToUse?.getUsageOffsetPercent(customer) ?? 0;
  const initialExpandedState = false
  const [expanded, setExpanded] = React.useState<string | false>(initialExpandedState);
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isShortScreen = useMediaQuery(`(max-height: 599px)`);
  const isFlexMonthly = financialProducts?.includes(PvProduct.FlexMonthly)   

  if (!design || !designConstraints || !financialProducts || !designConstraintTypes || financialProducts.length < 1) {
    return null;
  }
  const { acSystemSizeKw, cecAcSystemSizeKw, dcSystemSizeKw, batteryCount } = design;
  const selectedBatteryId = design?.selectedEquipmentSpecificationIds?.selectedBatterySpecificationIds[0];
  let isAcBattery = false;
  if(selectedBatteryId) {
    const spec = design?.getBatterySpec(selectedBatteryId);
   isAcBattery = spec?.manufacturerSpecifications?.acStorage || false;
  }
  const acBatteryCount = isAcBattery? batteryCount : 0;

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

    const onUpdateProducts = () => {
      postIFrameMessage(IFrameEventType.DESIGN_EDIT_CANCEL, {
        designId: design!.id,
        offerId: offer!.id,
      });
    };

  const calculateDesignConstraintIssues = (financialProduct:any) => {
    const issues = []
    designConstraintTypes.forEach((designConstraintType:any, i)=>{
      let designValue
      let issueVerbiage = `Issue with ${designConstraintType}.`
      switch (designConstraintType) {
        case DesignConstraintType.MinSunHours:
          designValue = systemSunHours;
          issueVerbiage = "Design does not meet minimum sun Hours, alter panel layout."
          break;
        case DesignConstraintType.MaxOffsetPercent:
          designValue = usageOffsetPercent;
          issueVerbiage = "Design is above maximum offset, remove modules."
          break;
        case DesignConstraintType.MaxDcSystemSizeKw:
          designValue = dcSystemSizeKw;
          issueVerbiage = "Design is above maximum DC size, remove modules."
          break;
        case DesignConstraintType.MinDcSystemSizeKw:
          designValue = dcSystemSizeKw;
          issueVerbiage = "Design is below minimum DC size, add additional modules."
          break;
        case DesignConstraintType.MaxAcSystemSizeKw:
          designValue = acSystemSizeKw;
          break;
        case DesignConstraintType.MaxCecAcSystemSizeKw:
          designValue = cecAcSystemSizeKw;
          break;
        case DesignConstraintType.MaxAcBatteries:
          designValue = acBatteryCount;
          issueVerbiage = "Max battery count exceeded."
          break;
        case DesignConstraintType.MinOffsetPercent:
          designValue = usageOffsetPercent;
          issueVerbiage = "Design is below minimum offset, add additional modules."
          break;
      }
      const isConstraintMet = designConstraints.validateDesignConstraint(
        designValue,
        designConstraintType,
        financialProduct!
      );
      if (!isConstraintMet){
        issues.push(<li style={{marginBottom:".25rem"}} key={`${designConstraintType}-${i}`}><Typography variant="body2">{issueVerbiage}</Typography></li>)
      }
  })


  // Handle Sunrun Flex
  if (financialProduct === PvProduct.FlexMonthly){
    if (offer && !flexMonthlyBackupConstraintMet(offer)){
        issues.push(
        <li>
          <Typography variant="body2">Sunrun Flex is only available for backup offers.  
            {offer && <Button 
              variant="text" 
              style={{padding: 0, margin: 0, marginLeft:".25rem", fontWeight:600, textDecoration:"underline"}} 
              onClick={onUpdateProducts}
            >
              Update Products
            </Button>}
          </Typography>
        </li>)
    } else if (!flexMonthlyProductConstraintMet(design)){
      issues.push( 
        <li>
          <Typography variant="body2">Sunrun Flex is only available for backup offers with Powerwall 3.</Typography>
        </li>
      )
    }
  }
    return issues
  } 
  
  const showPanel = !isXs && !display.isToolbarExpanded && !isDesignMenuExpanded && !isShortScreen;

  return (
    <>
    {showPanel &&(<Box
    className="financial-panel-box"
    height="100%"
    overflow="visible"
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    }}
  >
      <Paper
        elevation={2}        
        sx={{
          // We are low on screen real estate so respect the more important element's Z index
          zIndex: expanded ? theme.zIndex.floatingActionButton + 1 :theme.zIndex.floatingActionButton -1,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          flex:"flex-start",
          overflow: "hidden",
          width: expanded ? "18rem" : isFlexMonthly ? "9rem": "7rem",
          marginTop:"1rem",
          alignSelf:"flex-start"
        }}
      >
        <Box>
            <div 
              role="button" 
              aria-pressed="false"
              style={{
                cursor:"pointer",  
                height:"2rem", 
                display:"flex",
                justifyContent:"flex-start",
                alignItems:"center",
                padding:".5rem"
              }}
              onClick={()=> setExpanded(expanded ? false : financialProducts![0])}
            >                                                               
            <Grid sx={{display:'flex', justifyContent: 'space-between', width: '100%'}}>
                <Typography variant="body1" color="#6F6D68"  marginLeft={".25rem"}>
                  {expanded ? "Financial Product Eligibility" : "Eligibility"}
                </Typography>
                {expanded ? <ArrowCircleLeftIcon style={{color:"#1F2647"}}/> : <ArrowCircleRightIcon style={{color:"#1F2647"}}/>}  
            </Grid>            
            </div>
              {financialProducts.map((product, i) => {
                const issues = calculateDesignConstraintIssues(product)
                const productMatchesParentFinancialProduct = product === offer?.parentFinancialProduct 
                return (
                <Accordion key={`financialProductAccordion-${i}`} expanded={expanded === product} onChange={handleAccordionChange(product)} disableGutters={true}>
                  <AccordionSummary
                    aria-controls={`${product}-content`}
                    id={`${product}-header`}
                    style={{padding:".5rem", height:"3rem", backgroundColor: productMatchesParentFinancialProduct ? "#ebebeb" : "white"}}
                    >                          
                      {issues.length > 0 
                        ? <WarningIcon style={{color:"#9E1E19"}}/>
                        : <CheckCircleOutlineIcon style={{color:"#016005"}}/> 
                      }
                      <Typography variant="body1" color="primary" marginLeft={".25rem"}>
                        {product === PvProduct.FlexMonthly ? FLEX_MONTHLY_DISPLAY_NAME : product}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{padding:".5rem", background: issues.length > 0 ? "#F8EEED" : "white"}} >
                    {productMatchesParentFinancialProduct && <Typography variant="body2">{product} selected for parent proposal</Typography>}
                    {issues.length > 0 
                      ? <ul style={{padding:".5rem", marginTop:0, marginLeft:".5rem", marginRight:".5rem", marginBottom:0}}>
                          {issues}
                        </ul> 
                      : productMatchesParentFinancialProduct ? ""
                      : <Typography variant="body2">Your design qualifies for {product === PvProduct.FlexMonthly ? FLEX_MONTHLY_DISPLAY_NAME : product}</Typography>}
                    </AccordionDetails>
                  </Accordion>)
              }
          )}

        </Box>
      </Paper>
      </Box>)}
      </>
  );
};

export default FinancialProductsPanel;
