import {G2Vector2D} from "./vector2D";

//first we have the very initial coordinate system to operate from, that is just:
//origin = (0,0), xAxis = (1,0), yAxis = (0,1)
//origin, xAxis, yAxis in any coordinate system instance are measured against this initial coordinatesystem
export class G2CoordinateSystem2D {
  readonly origin: G2Vector2D;   //no panic, using G2Vector2D to represent a point has many benefits
  readonly xAxis: G2Vector2D;
  readonly yAxis: G2Vector2D;

  constructor(origin: G2Vector2D, xAxis: G2Vector2D, yAxis: G2Vector2D) {
    this.origin = origin
    this.xAxis = xAxis.normalize()
    this.yAxis = yAxis.normalize()   //really no need for yAxis since its always ccw 90 degree from xAxis
  }

  static baseCRS() {
    return new G2CoordinateSystem2D(
      new G2Vector2D(0, 0),
      new G2Vector2D(1, 0),
      new G2Vector2D(0, 1)
    )
  }

  public equals(crs: G2CoordinateSystem2D) {
    return this.origin.equals(crs.origin) &&
      this.xAxis.equals(crs.xAxis) &&
      this.yAxis.equals(crs.yAxis)
  }

  //whenever you convert one coordinate system to another, other than return the converted crs,
  //also need to return a crs of the original crs from perspective of the converted crs
  //so we can project point from originalCRS to convertedCRS and back to the originalCRS, albeit from perspective of convertedCRS
  //always convert from baseCRS
  public convertToCRS(moveDeltaToNewOrigin: G2Vector2D, rotateThetaCCWInRadians: number): { convertedCRS: G2CoordinateSystem2D, convertedBackCRS: G2CoordinateSystem2D } {
    if (!this.equals(G2CoordinateSystem2D.baseCRS())) {
      throw new Error("only baseCRS can be converted")
    }
    const newXAxis = this.xAxis.rotateCounterClockWiseByRadians(rotateThetaCCWInRadians)
    const newYAxis = this.yAxis.rotateCounterClockWiseByRadians(rotateThetaCCWInRadians)
    const newOrigin = this.origin.add(moveDeltaToNewOrigin)   //moveDelta = newOrigin - this.Origin
    const moveDeltaReversedAndRotated = moveDeltaToNewOrigin
      .scale(-1)   //reverse direction
      .rotateCounterClockWiseByRadians(-rotateThetaCCWInRadians)
    //looked from convertedCRS as localCRS, hence xAxis is (1,0), yAxis is (0,1)
    const oldOriginLookedFromConvertedCRS = new G2Vector2D(
      moveDeltaReversedAndRotated.scalarProjectTo(new G2Vector2D(1, 0)),
      moveDeltaReversedAndRotated.scalarProjectTo(new G2Vector2D(0, 1))
    )
    const oldXAxisLookedFromConvertedCRS = (new G2Vector2D(1, 0)).rotateCounterClockWiseByRadians(-rotateThetaCCWInRadians)
    const oldYAxisLookedFromConvertedCRS = (new G2Vector2D(0, 1)).rotateCounterClockWiseByRadians(-rotateThetaCCWInRadians)
    return {
      convertedCRS: new G2CoordinateSystem2D(
        newOrigin,
        newXAxis,
        newYAxis
      ),
      convertedBackCRS: new G2CoordinateSystem2D(
        oldOriginLookedFromConvertedCRS,
        oldXAxisLookedFromConvertedCRS,
        oldYAxisLookedFromConvertedCRS
      )
    }
  }

  public originDeltaToCRS(crs: G2CoordinateSystem2D): G2Vector2D {
    return crs.origin.sub(this.origin)
  }

  public anglesCounterClockWiseInRadiansToCRS(crs: G2CoordinateSystem2D): number {
    return this.xAxis.anglesCounterClockWiseInRadiansToVector(crs.xAxis)
  }
}
