import "./DesignBookmark.css";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Design } from "@sunrun/design-tools-domain-model";
import React from "react";
import { useWorkspace } from "src/hooks/useWorkspace";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  convertToReadableDateAndTime,
  sortDesignByMostRecentTime,
} from "src/utils/designDateTime";
import { deriveBookmarkedDesignClasses } from "src/features/bookmark/bookmarkSlice";

export type BookmarkedListProps = {
  bookmarkedDesignProp: Omit<BookmarkedDesignProp, "design">;
};

export const BookmarkedList: React.FC<BookmarkedListProps> = ({
  bookmarkedDesignProp,
}) => {
  const { state } = useWorkspace();
  const bookmarkedDesigns = deriveBookmarkedDesignClasses(state);

  if (!bookmarkedDesigns) return null;

  return (
    <List sx={{ flex: "1 1 auto", overflowY: "scroll" }} dense={true}>
      {bookmarkedDesigns.sort(sortDesignByMostRecentTime).map((design, idx) => (
        <BookmarkedDesign
          key={`${design.id}-${idx}`}
          design={design}
          {...bookmarkedDesignProp}
        />
      ))}
    </List>
  );
};

export interface BookmarkedDesignProp {
  design: Design;
  handleBookmarkSelection: (design: Design) => void;
  handleBookmarkRemoval: (design: Design) => void;
  disableClick: boolean;
}

const BookmarkedDesign: React.FC<BookmarkedDesignProp> = ({
  design,
  handleBookmarkSelection,
  handleBookmarkRemoval,
  disableClick,
}) => {
  const { createdBy, moduleCount, updatedAt } = design;
  const readableDate = convertToReadableDateAndTime(updatedAt, "MM/DD @h:mma");

  const modules = `${moduleCount || 0} ${
    moduleCount === 1 ? "Module" : "Modules"
  }`;

  const text = [modules, createdBy, readableDate].join(" | ");

  return (
    <ListItem
      style={{ cursor: "pointer" }}
      secondaryAction={
        <IconButton
          disabled={disableClick}
          onClick={() => handleBookmarkRemoval(design)}
        >
          <RemoveCircleOutlineIcon />
        </IconButton>
      }
    >
      <ListItemButton
        disabled={disableClick}
        onClick={() => handleBookmarkSelection(design)}
      >
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
};
