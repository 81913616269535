import { Position, Vector2D, Vector3D, zAxis } from "@sunrun/design-tools-geometry";
import { Quaternion } from "./quaternion";

const radsFromDegrees = (degrees: number): number => {
  return degrees * Math.PI / 180
}

//get the azimuth as a vector, given the azimuthDegrees of a roof face, return the utm vector (x:east, y:north)
export function getAzimuthVector(azimuthDegreesClockwiseFromNorth: number): Vector2D {
  const north = new Vector3D(0, 1, 0);
  // negate the clockwise measure so that we correctly use the quaternion, which is a right-hand rule entity
  const radiansCounterclockwise = -radsFromDegrees(azimuthDegreesClockwiseFromNorth);
  const rotClockwise = Quaternion.fromAxisAngle(zAxis, radiansCounterclockwise);
  const direction = rotClockwise.rotateVector(north);
  return [direction.x, direction.y];
}
export function getRoofCoordinateSystem(pitchDegrees: number, azimuthDegreesClockwiseFromNorth: number){
  const azimuth = getAzimuthVector(azimuthDegreesClockwiseFromNorth);
  const normal = getRoofNormalVector(pitchDegrees,azimuthDegreesClockwiseFromNorth).normalize();
  const eaves = Vector3D.cross(normal, new Vector3D(azimuth[0],azimuth[1],0)).normalize();
  return {
    normal,
    azimuth: Vector3D.cross(normal, eaves).normalize(),
    eaves
  }
}
// return a vector in the utm space <east,north,up>
// representing the vector which is orthogonal to the roof plane
export function getRoofNormalVector(pitchDegrees: number, azimuthDegreesClockwiseFromNorth: number): Vector3D {
  const up = new Vector3D(0, 0, 1);
  const azimuthDirection = getAzimuthVector(azimuthDegreesClockwiseFromNorth);
  // we want to rotate the up-vector about the vector orthogonal to the azimuth vector
  // in the xy plane. this vector is easy to get in 2D
  const rotateAxis = new Vector3D(-azimuthDirection[1], azimuthDirection[0], 0);
  const normal = Quaternion.fromAxisAngle(rotateAxis, radsFromDegrees(pitchDegrees)).rotateVector(up);
  return normal.normalize();
}
// Ax+By+Cz=D
export type PlaneEquation = {
  normal: Vector3D; // <x,y,z>
  // D=Aa+Bb+Cc where <a,b,c> is any point on the plane
  D: number;
}

export const rotateCoordinates = (positions: Position[], origin: Vector3D, rotationQuat: Quaternion): Position[] => {
  return positions.map((pos) => {
    const rp = Vector3D.add(origin,
      rotationQuat.rotateVector(Vector3D.sub(new Vector3D(pos[0],pos[1],pos[2]), origin)));
    return [rp.x,rp.y,rp.z]
  })
}
export function normalOfPoints(A: Vector3D, B: Vector3D, C: Vector3D): Vector3D {
  // positions is rectangle of the form A B C D
  const BA = Vector3D.sub(A, B);
  const BC = Vector3D.sub(C, B);
  return Vector3D.cross(BC.normalize(),BA.normalize()).normalize();
}
export function getRoofPlaneEquation(anyPointOnRoofPlane: Vector3D, pitchDegrees: number, azimuthDegreesClockwiseFromNorth: number): PlaneEquation {
  // first, we need the normal:
  const normal = getRoofNormalVector(pitchDegrees, azimuthDegreesClockwiseFromNorth).normalize();
  const D = Vector3D.dot(normal, anyPointOnRoofPlane);
  return { normal, D };
}
// return the height of the plane of the roof, given its xy point
export function getRoofElevationAtPoint(point: Vector2D, roof: PlaneEquation): number {
  // re-arrange the plane eq to be a fn of x and y
  // Cz = (D - Ax) - By
  // z = ((D- Ax)-By)/C
  const { x: A, y: B, z: C } = roof.normal;
  if (Math.abs(C) < 0.001) {
    throw Error("given roof plane is vertical, and thus no function Z(x,y) can exist");
  }
  const { D } = roof;
  const [x, y] = point;
  return ((D - (A * x)) - (B * y)) / C;
}
function toPosition(v: Vector3D): Position {
  return [v.x, v.y, v.z];
}
// get the plane equation given 3 points that define a plane - assumed to be in ccw ordering!
export function getPlaneEquationFromPoints(counterClockwiseABC: [Vector3D,Vector3D,Vector3D]): PlaneEquation {
  const [A,B,C] = counterClockwiseABC;
  const normal = normalOfPoints(A,B,C);
  const D = Vector3D.dot(normal,A);
  return {normal,D};
}
type FourCorners = [Position,Position,Position,Position]
export function placeModuleOnRoof(
  roof: { pitchDegrees: number, azimuthDegreesClockwiseFromNorth: number, anyPointOnRoof: Vector3D },
  module: { center: Vector2D, width: number, height: number, isLandscape: boolean, tiltDegreesAlongAzimuth: number }
): FourCorners{
  const normal = getRoofNormalVector(roof.pitchDegrees, roof.azimuthDegreesClockwiseFromNorth);
  const eq = getRoofPlaneEquation(roof.anyPointOnRoof, roof.pitchDegrees, roof.azimuthDegreesClockwiseFromNorth);
  const middleZ = getRoofElevationAtPoint(module.center, eq);
  const azimuth2D = getAzimuthVector(roof.azimuthDegreesClockwiseFromNorth);
  const vectorAlongEaves = Vector3D.cross(normal, new Vector3D(azimuth2D[0], azimuth2D[1], 0).normalize()).normalize();
  const middle = new Vector3D(module.center[0], module.center[1], middleZ);

  const azimuth = Vector3D.cross(normal, vectorAlongEaves).normalize();
  let { isLandscape, width, height } = module;
  if(Math.abs(Math.sin(radsFromDegrees(module.tiltDegreesAlongAzimuth)))<0.001){
    // no effective tilt!
    return buildRectangleOnPlane(azimuth,vectorAlongEaves,width,height,middle,isLandscape);
  }else {
    // apply the tilt, which gets a bit messy. compute a pretend plane that intersects the plane of the actual roof at the centerpoint of where the module
    // would lie if there were no tilt-kit.
    // this pretend plane has an effective pitch = roofPitch+tiltPitch. then build the corners of a module on that pretend plane
    // then translate the module upward in the Z direction, until its chin is touching the surface of the real roof.
    const tiltedModuleNormal = getRoofNormalVector(roof.pitchDegrees+module.tiltDegreesAlongAzimuth, roof.azimuthDegreesClockwiseFromNorth);
    const tiltedModuleAzimuthvector = Vector3D.cross(tiltedModuleNormal,vectorAlongEaves);
    const almost = buildRectangleOnPlane(tiltedModuleAzimuthvector,vectorAlongEaves,width,height,middle,isLandscape);
    // now figure out how far up to move our module corners. there's probably a simple trig thing we could do to figure it out -
    // or we could just use our plane eq!
    let deltaZ = 0;
    for(const corner of almost){
      const roofPoint = getRoofElevationAtPoint([corner[0],corner[1]],eq);
      deltaZ = Math.max(roofPoint-corner[2],deltaZ);
    }
    return almost.map((pos)=>[pos[0],pos[1],pos[2]+deltaZ]) as FourCorners;
  }
}
function buildRectangleOnPlane(azimuth: Vector3D, eaves:Vector3D, width:number, height:number, middle: Vector3D, isLandscape:boolean): FourCorners {
  if (isLandscape) {
    const w = width;
    width = height;
    height = w;
  }
  const right = Vector3D.scale(eaves, width / 2)
  const up = Vector3D.scale(azimuth, height / 2)
  const left = Vector3D.scale(eaves, -width / 2)
  const down = Vector3D.scale(azimuth, -height / 2)
  // be sure to return a polygon wound counter-clockwise!
  if (isLandscape) {
    return [
      toPosition(Vector3D.add(Vector3D.add(middle, left), up)),
      toPosition(Vector3D.add(Vector3D.add(middle, left), down)),
      toPosition(Vector3D.add(Vector3D.add(middle, right), down)),
      toPosition(Vector3D.add(Vector3D.add(middle, right), up)),
    ];
  } else {
    return [
      toPosition(Vector3D.add(Vector3D.add(middle, left), down)),
      toPosition(Vector3D.add(Vector3D.add(middle, right), down)),
      toPosition(Vector3D.add(Vector3D.add(middle, right), up)),
      toPosition(Vector3D.add(Vector3D.add(middle, left), up)),
    ];
  }
  //???always start from lowerleft so we can derive xAxis (eave) from first 2 positions of module geometry
  //expectRectangularModule in roofMath.test seems to want first side to be with, 2nd sid eto be height
  //is that test guarding anything?
  /*return [
    toPosition(Vector3D.add(Vector3D.add(middle, left), down)),
    toPosition(Vector3D.add(Vector3D.add(middle, right), down)),
    toPosition(Vector3D.add(Vector3D.add(middle, right), up)),
    toPosition(Vector3D.add(Vector3D.add(middle, left), up)),
  ]*/
}


export enum RelativeDirection {
  left = "Left",
  right = "Right",
  down = "Down",
  up = "Up"
}

// Lightmile-azimuth: north=0, E=90, S=180, W = 270
// these are just the degrees for the arrow keys relative to the screen
const directionsInDegrees = {
  [RelativeDirection.up]:90,
  [RelativeDirection.left]:180,
  [RelativeDirection.right]:0,
  [RelativeDirection.down]:-90,
}
function degreesFromRelativeDirection(dir:RelativeDirection){
  return directionsInDegrees[dir];
}
// | *left /
// |     /
// |   /  *right
// |_/__________
// 45 degrees is the cutoff between left or right twist
function getTwistFromAzimuth(azimuthClockwiseFromNorth:number) {
  const twist = azimuthClockwiseFromNorth%90;
  return twist >45 ? -(twist-90) : -twist
}
// Lightmile-azimuth: north=0, E=90, S=180, W = 270
// so - as far as sin() and cos() are concerned:
// N = 90, E = 0, S = -90 aka 270, W =180
// lightmile azimuth goes in the opposite direction from math.sin/math.cos
// its also offset (0 degrees according to math.cos/math.sin is due east, in LM its due north)
// we need a deltaXY value, which we will get from sin and cos
export function getRelativeDirectionNudgeDelta(azimuth: number, direction: RelativeDirection, distance: number): Vector2D {
  const twist = getTwistFromAzimuth(azimuth);
  const directionInDegrees = degreesFromRelativeDirection(direction)+twist;
  const radians = radsFromDegrees(directionInDegrees);
  return [Math.cos(radians) * distance, Math.sin(radians) * distance];
}
