import { KeystoneEquipment } from "../../src/keystoneClient";

export const KEYSTONE_SPAN: KeystoneEquipment = {
  __typename: "Equipment",
  sys: {
    id: "4mzTDeR570EbQvmXcvzLrE",
  },
  manufacturerModelNumber: "SPAN_100800",
  model: "1-00800",
  sunrunSku: "1-00800",
  specificationsCollection: {
    items: [],
  },
  usefulLife: 0,
  annualDegradation: 0,
  lengthInInches: null,
  widthInInches: null,
  depthInInches: null,
  weightInLbs: null,
  displayPriceInUsd: 3898,
  estGaapCostInUsd: 3898,
  variantsCollection: {
    items: [
      {
        sys: {
          id: "01GWAX4FTMD7TWT93K2W876B7X",
        },
      },
    ],
  },
  manufacturer: {
    name: "SPAN",
  },
  equipmentType: {
    name: "Power Management",
  },
};

export const KEYSTONE_EV_CHARGER: KeystoneEquipment = {
  __typename: "Equipment",
  sys: {
    id: "6fXW9c9qvjimFVzpC93h6L",
  },
  manufacturerModelNumber: "JuiceBox Smart Charger",
  model: "JuiceBox Smart Charger",
  sunrunSku: "JuiceBox Smart Charger",
  specificationsCollection: {
    items: [],
  },
  usefulLife: 0,
  annualDegradation: 0,
  lengthInInches: null,
  widthInInches: null,
  depthInInches: null,
  weightInLbs: null,
  displayPriceInUsd: 1749,
  estGaapCostInUsd: 1749,
  variantsCollection: {
    items: [
      {
        sys: {
          id: "01GWAWZ80CM1ZA1P2HDGT8EHYT",
        },
      },
    ],
  },
  manufacturer: {
    name: "Enel X",
  },
  equipmentType: {
    name: "Ev Charger",
  },
};

export const KEYSTONE_GATEWAY: KeystoneEquipment = {
  __typename: "Equipment",
  sys: {
    id: "4m4PkuqUxd1oIepBp4ZFz9",
  },
  manufacturerModelNumber: "Powerwall Gateway 2",
  model: "Powerwall Gateway 2",
  sunrunSku: "1232100-00-E",
  variantsCollection: {
    items: [],
  },
  specificationsCollection: {
    items: [],
  },
  usefulLife: 0,
  annualDegradation: 0,
  lengthInInches: null,
  widthInInches: null,
  depthInInches: null,
  weightInLbs: null,
  displayPriceInUsd: 1100,
  estGaapCostInUsd: 1100,
  manufacturer: {
    name: "Tesla",
  },
  equipmentType: {
    name: "Microgrid Interconnect Device",
    crmAssetType: "Microgrid Interconnect Device",
  },
};

export const KEYSTONE_BUS: KeystoneEquipment = {
  __typename: "Equipment",
  sys: {
    id: "mZB9Yi9wgT0deGvdTfZJK",
  },
  manufacturerModelNumber: "Backup Switch (1624171-xx-y)",
  model: "Backup Switch (1624171-xx-y)",
  sunrunSku: "1624171-xx-y",
  variantsCollection: {
    items: [],
  },
  specificationsCollection: {
    items: [],
  },
  usefulLife: 0,
  annualDegradation: 0,
  lengthInInches: null,
  widthInInches: null,
  depthInInches: null,
  weightInLbs: null,
  displayPriceInUsd: 350,
  estGaapCostInUsd: 350,
  manufacturer: {
    name: "Tesla",
  },
  equipmentType: {
    name: "Microgrid Interconnect Device",
    crmAssetType: "Microgrid Interconnect Device",
  },
};

export const KEYSTONE_P505: KeystoneEquipment = {
  __typename: "Equipment",
  sys: {
    id: "6zBv22YzAdphhNCCN52Nr",
  },
  manufacturerModelNumber: "P505",
  model: "P505",
  sunrunSku: null,
  variantsCollection: {
    items: [],
  },
  usefulLife: 0,
  annualDegradation: 0,
  lengthInInches: null,
  widthInInches: null,
  depthInInches: null,
  weightInLbs: null,
  displayPriceInUsd: null,
  estGaapCostInUsd: null,
  manufacturer: {
    name: "SolarEdge Technologies",
  },
  equipmentType: {
    name: "Power Optimizer",
    crmAssetType: "Power Optimizer",
  },
  specificationsCollection: {
    items: [
      {
        __typename: "PowerOptimizerSpecification",
        sys: {
          id: "5itEk3MiHUn65zKad6HctM",
        },
        name: "P505",
        maxRatedPowerStcInW: 505,
        maxPowerMinInV: 12.5,
        maxPowerMaxInV: 83,
        maxOpenCircuitVoltage: 83,
        maxShortCircuitCurrent: 14.1,
        peakEfficiency: 99.5,
        cecWeightedEfficiency: 98.8,
        maxContinuousOutputCurrent: 15,
        minStringLength: 6,
      },
    ],
  },
};

export const keystoneEquipmentListFixture: KeystoneEquipment[] = [
  KEYSTONE_SPAN,
  KEYSTONE_EV_CHARGER,
  KEYSTONE_GATEWAY,
  KEYSTONE_BUS,
  KEYSTONE_P505
];
