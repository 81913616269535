import { useGeoRaster } from "src/hooks/useGeoRaster";
import { useSiteModel } from "src/hooks/useSiteModel";
import { useProductionSimulation } from "src/hooks/useProductionSimulation";
import { useDesign } from "src/hooks/useDesign";
import { useCustomer } from "./useCustomer";
import { useDesignConstraints } from "./useDesignConstraints";
import { useSolarResource } from "./useSolarResource";
import { useSimulateDesign } from "./useSimulateDesign";
import { useExportDesign } from "./useExportDesign";
import { useTriggerCreateDesignImages } from "./useTriggerCreateDesignImages";
import { useLegacyFinalizeDesign } from "./useLegacyFinalizeDesign";
import { useReadOnlyDesignSet } from "./useReadOnlyDesignSet";
import { useBookmarkDesign } from "./useBookmarkDesign";
import { useRecalculateSetbacks } from "./useRecalculateSetbacks";
import { useOffer } from "./useOffer";
import { useSiteSurvey } from "./useSiteSurvey";
import { useSolarResourceExpanded } from "./useSolarResourceExpanded";

type UseDomainModelProps = {
  geoRasterId?: string;
  siteModelId?: string;
  siteModelVersion?: number;
  designId?: string;
  designVersion?: number;
  productionSimulationId?: string;
  productionSimulationVersion?: number;
  solarResourceId?: string;
  solarResourceVersion?: number;
  solarResourceExpandedId?: string;
  solarResourceExpandedVersion?: number;
  signedRootDesignId?: string;
  interconnectionAppliedDesignId?: string;
  copyFromDesignId?: string;
};

export const useDomainModel = ({
  geoRasterId,
  siteModelId,
  siteModelVersion,
  designId,
  designVersion,
  productionSimulationId,
  productionSimulationVersion,
  solarResourceId,
  solarResourceVersion,
  solarResourceExpandedId,
  solarResourceExpandedVersion,
  signedRootDesignId,
  interconnectionAppliedDesignId,
  copyFromDesignId,
}: UseDomainModelProps) => {
  useSiteModel({ siteModelId, siteModelVersion });
  const { saveStatus, saveDesignMutation, didShowSaveError } = useDesign({
    designId,
    designVersion,
  });
  useProductionSimulation({
    productionSimulationId,
    productionSimulationVersion,
  });
  useOffer();
  useCustomer();
  useSiteSurvey();
  useDesignConstraints();
  useGeoRaster({ geoRasterId }); // Takes the longest to load, so declare it last to allow other loading messages to show.
  useSolarResource({ solarResourceId, solarResourceVersion });
  useSolarResourceExpanded({ solarResourceExpandedId });
  const { simulateDesign } = useSimulateDesign({
    saveDesignMutation,
    designId,
    designVersion,
    didShowSaveError,
  });
  const { exportDesign } = useExportDesign();
  const { createDesignImages } = useTriggerCreateDesignImages();
  const { legacyFinalizeDesign } = useLegacyFinalizeDesign({
    saveDesignMutation,
  });
  useReadOnlyDesignSet({
    signedRootDesignId,
    interconnectionAppliedDesignId,
    copyFromDesignId,
  });
  useBookmarkDesign();
  const { recalculateSetbacks } = useRecalculateSetbacks({ saveDesignMutation });

  return {
    simulateDesign,
    exportDesign,
    createDesignImages,
    legacyFinalizeDesign,
    saveStatus,
    recalculateSetbacks,
  };
};
