import {
  checkDoesDesignSatisfyProductSelection,
  OfferLines,
} from "@sunrun/design-tools-domain-model";
import { storefrontClient } from "@sunrun/design-tools-graphql-clients";
import { useWorkspace } from "./useWorkspace";
import { useSearchParams } from "react-router-dom";
import { useLoadingScreen } from "@sunrun/design-tools-loading-screen";
import {
  LoadingProcessGroups,
  LoadingProcessNames,
} from "src/types/LoadingScreenProcess";
import { URLSearchParameterKey } from "src/types/URLSearchParameterKey";
import React from "react";
import { useProductToEquipmentMapping } from "./useProductToEquipmentMapping";
import {
  deriveBackupVariantId,
  deriveSolarVariantId,
  deriveProductOptionChanges,
  deriveShiftVariantId,
} from "src/features/designGuidance/deriveOfferProductChanges";
import {
  selectBackupProductVariantId,
  selectShiftProductVariantId,
  selectSolarProductVariantId,
} from "src/features/offer/offerSlice";
import {
  deriveDisplayNameForBatteryType,
  deriveDisplayNameForModuleType,
} from "src/features/products/productsSlice";
import { deriveProductionSimulationClass } from "src/features/productionSimulation/productionSimulationSlice";
import { deriveDesignClass } from "src/features/design/designSlice";
import { useAppDispatch } from "src/store";
import { setErrorModal } from "src/features/modal/modalSlice";

export const useOfferLineUpdates = () => {
  const { state, dispatch: workspaceDispatch } = useWorkspace();
  const dispatch = useAppDispatch()
  const { customer, offer } = state;
  const { handleProductToEquipmentMappingAndSetDesign } =
    useProductToEquipmentMapping();
  const [searchParams] = useSearchParams();
  const { helpers: loadingScreenHelpers } = useLoadingScreen();
  const lightmileProjectId =
    searchParams.get(URLSearchParameterKey.LightmileProjectId) || undefined;
  const productionSimulation = deriveProductionSimulationClass(state);
  const design = deriveDesignClass(state);

  const updateOfferLines = React.useCallback(async (): Promise<{
    success: boolean;
  }> => {
    let success = false;
    if (offer && customer && design && productionSimulation) {
      let isp2eMappingValid = true;
      try {
        loadingScreenHelpers.addProcess({
          group: LoadingProcessGroups.PREPARE_TO_GO_TO_PRICING,
          name: LoadingProcessNames.UPDATING_OFFER,
        });

        // Offer values
        const solarProductVariantId = selectSolarProductVariantId(state);
        const backupProductVariantId = selectBackupProductVariantId(state);
        const shiftProductVariantId = selectShiftProductVariantId(state);
        // derived new Offer values based on design
        const newSolarVariantId = deriveSolarVariantId(state);
        const newBackupVariantId = deriveBackupVariantId(state);
        const newShiftVariantId = deriveShiftVariantId(state);
        const productOptionChanges = deriveProductOptionChanges(state);

        // Safety net for unsupported changes
        const addsOrRemovesSolar =
          !!solarProductVariantId != !!newSolarVariantId;
        const addsOrRemovesStorage =
          !!backupProductVariantId != !!newBackupVariantId ||
          !!shiftProductVariantId != !!newShiftVariantId;
        if (addsOrRemovesSolar || addsOrRemovesStorage) {
          isp2eMappingValid = false;
          throw new Error(
            `An unexpected error occurred: iHD does not support adding or removing ${
              addsOrRemovesSolar ? `solar / ` : ""
            }${addsOrRemovesStorage ? `storage` : ""} products.`
          );
        }
        const unsupportedProductOptionChanges = productOptionChanges.filter(
          (optionChange) => {
            return optionChange.optionName === deriveDisplayNameForBatteryType(state);
          }
        );
        if (unsupportedProductOptionChanges.length > 0) {
          isp2eMappingValid = false;
          const optionNames = unsupportedProductOptionChanges.map(
            (optionChange) => optionChange.optionName
          );
          throw new Error(
            `An unexpected error occurred: iHD does not support the changes to the Offer Type required for this design to proceed (${optionNames.join(
              ", "
            )}).`
          );
        }

        // validate required fields
        const annualProductionKwh = productionSimulation.annualProductionKwh;
        if (!annualProductionKwh) {
          // Storefront requires this or bad things happen. See: https://sunrun.jira.com/browse/LS-1756
          throw new Error("Missing or zero simulated annual production");
        }
        const annualUsageKwh = customer.annualUsagekWh; // TODO: ET-1091 can derive this from Offer, not Customer
        if (!annualUsageKwh) {
          // Storefront requires this or bad things happen. See: https://sunrun.jira.com/browse/LS-1756
          throw new Error("Missing or zero customer annual usage");
        }

        // only update Solar / Backup / Shift offerLines
        // PowerManagement / EvCharging offerLines are ignored (nothing in iHD should update these)
        const offerLineUpdates = OfferLines.buildOfferLineInputs(
          newSolarVariantId,
          newBackupVariantId,
          newShiftVariantId,
          undefined,
          undefined,
          design,
          annualProductionKwh,
          annualUsageKwh,
          offer
        );

        console.log(
          `updating ${
            offerLineUpdates.length
          } offer lines: ${offerLineUpdates.map((line) => line.variantId)}`
        );
        const updatedOffer = await storefrontClient.updateOfferLines(
          offer.id,
          design.id,
          design._version || undefined,
          lightmileProjectId,
          offer.lockVersion,
          offerLineUpdates
        );
        workspaceDispatch({ type: "setOffer", payload: updatedOffer });

        // Do one last validation to make sure Design satisfies Offer in every way.
        isp2eMappingValid = checkDoesDesignSatisfyProductSelection(
          updatedOffer,
          design
        );
        if (!isp2eMappingValid) {
          throw new Error(
            "The current design does not satisfy the offer; something went wrong with product to equipment mapping."
          );
        }

        success = true;
        loadingScreenHelpers.completeProcess(
          LoadingProcessNames.UPDATING_OFFER
        );
      } catch (e: any) {
        loadingScreenHelpers.completeProcess(
          LoadingProcessNames.UPDATING_OFFER
        );
        const error = e instanceof Error ? e : new Error("Unknown Error");
        if (!isp2eMappingValid) {
          handleProductToEquipmentMappingAndSetDesign(offer);
          dispatch(setErrorModal({
            error,
            message: `${e.message} We have attempted to correct the issue. Please try again. If this problem persists, contact support.`,
          }))
          workspaceDispatch({
            type: "setErrorModal",
            payload: {
              error,
              message: `${e.message} We have attempted to correct the issue. Please try again. If this problem persists, contact support.`,
            },
          });
        } else {
          dispatch(setErrorModal({
            error,
            message: `Failed to update offer: ${error.message}`,
          }))
          workspaceDispatch({
            type: "setErrorModal",
            payload: {
              error,
              message: `Failed to update offer: ${error.message}`,
            },
          });
        }
      }
    }
    return { success };
  }, [offer, customer, design, productionSimulation]);

  return {
    updateOfferLines,
  };
};
