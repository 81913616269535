import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {produce} from "immer";
import {WorkspacePayloadAction} from "../../types/state-management/action";
import {Design, IProductionSimulation, ProductionSimulation} from "@sunrun/design-tools-domain-model";
import { createSelector } from "reselect";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type ProductionSimulationState =  {
  value: IProductionSimulation | undefined;
}

export const initialState: ProductionSimulationState = {
  value: undefined,
};

export const productionSimulationSlice = createSlice({
  name: "productionSimulation",
  initialState,
  reducers: {
    setProductionSimulation: (state: ProductionSimulationState, action: PayloadAction<IProductionSimulation>) => {
      state.value = action.payload
    },
  },
});

export const { setProductionSimulation } = productionSimulationSlice.actions
export default productionSimulationSlice.reducer

// TODO: Remove the following and transition fully from WorkspaceState to RTK store
export type ProductionSimulationWorkspaceAction = WorkspacePayloadAction<{simulation: ProductionSimulation, design?: Design}, 'setProductionSimulation'>

export const selectProductionSimulation = (state: WorkspaceState) => state.productionSimulation
 
export const productionSimulationWorkspaceReducer = (state: WorkspaceState, action: WorkspaceAction): IProductionSimulation | undefined => {
  switch (action.type) {
    case 'setProductionSimulation': {
      const {simulation: newProductionSimulation} = action.payload
      return newProductionSimulation.getIProductionSimulation()
    }
    default: {
      return state.productionSimulation;
    }
  }
}

export const deriveProductionSimulationClass = createSelector(
  [selectProductionSimulation],
  (productionSimulation) => { return productionSimulation ? new ProductionSimulation(productionSimulation) : undefined}
)