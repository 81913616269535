import { Typography, useTheme } from "@mui/material"
import { Env, getEnv } from "@sunrun/design-tools-react-router-require-auth";
import { DesignToolsTheme } from "@sunrun/design-tools-themes";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { URLSearchParameterKey } from "src/types/URLSearchParameterKey";

const PrPreviewDisplayContent = () =>
   {
    const theme = useTheme<DesignToolsTheme>();    
    const [searchParams] = useSearchParams();
    const env = getEnv();

    const apiUrl = searchParams.get(URLSearchParameterKey.ApiUrl);
    const envOverride = searchParams.get(URLSearchParameterKey.EnvOverride);

    const baseUrl = document.location.host
    const isPrPreview = env === Env.LocalHost || env === Env.PrPreview || apiUrl || envOverride
  
    return (
      <>          
            {isPrPreview && (
            <Typography color={"white"}>
              BaseUrl: {baseUrl}
              <br />
              {envOverride && <>EnvOverride: {envOverride}</>}
              <br />
              {apiUrl && <>ApiUrl: {apiUrl}</>}
            </Typography>
        )}        
      </>
    );
   }

   // We only need to check the document.location once so Memoize this component
   export const PrPreviewDisplay = React.memo(PrPreviewDisplayContent)
