import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {WorkspaceEmptyAction, WorkspacePayloadAction, WorkspaceEvent} from "../../types/state-management/action";
import {Position} from "@sunrun/design-tools-geometry";
import {Module} from "@sunrun/design-tools-domain-model";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type ModuleNudgeState  = {
  isModuleNudging: boolean
  nudgedModuleId: string | undefined
  originalNudgedModuleCenter: Position | undefined
}

const initialState = {
  isModuleNudging: false,
  nudgedModuleId: undefined,
  originalNudgedModuleCenter: undefined,
}

export const moduleNudgeSlice = createSlice({
  name: "moduleNudge",
  initialState,
  reducers: {
    initNudgingModules: (state: ModuleNudgeState, action: PayloadAction<WorkspaceEvent>) => {
      const layer = action.payload.propagatedFrom.layer as Module
      const position = action.payload.position;
      const newModuleNudgeState: ModuleNudgeState =  {
        isModuleNudging: true,
        nudgedModuleId: layer.id,
        originalNudgedModuleCenter: action.payload.position,
      }
      state = newModuleNudgeState;
    },
    finishNudgingModules: (state: ModuleNudgeState) => {
      const newModuleNudgeState: ModuleNudgeState =  {
        isModuleNudging: false,
        nudgedModuleId: undefined,
        originalNudgedModuleCenter: undefined,   
       }
       state = newModuleNudgeState;
      }
    },
});

export const { initNudgingModules, finishNudgingModules } = moduleNudgeSlice.actions
export default moduleNudgeSlice.reducer

// TODO: Remove the following and transition fully from WorkspaceState to RTK store
export type ModuleNudge = {
  isModuleNudging: boolean
  nudgedModuleId: string | undefined
  originalNudgedModuleCenter: Position | undefined
}

export const moduleNudgeInitialState = {
  isModuleNudging: false,
  nudgedModuleId: undefined,
  originalNudgedModuleCenter: undefined
}

export const selectIsModuleNudging = (state: WorkspaceState) => state.moduleNudge.isModuleNudging

export type ModuleNudgeWorkspaceAction =
  | WorkspacePayloadAction<WorkspaceEvent,'initNudgingModules'>
  | WorkspaceEmptyAction<'finishNudgingModules'>

export const nudgeWorkspaceReducer = (state: WorkspaceState, action: WorkspaceAction): ModuleNudge => {
  switch (action.type) {
    case "initNudgingModules": {
      const layer = action.payload.propagatedFrom.layer as Module
      return {
        isModuleNudging: true,
        nudgedModuleId: layer.id,
        originalNudgedModuleCenter: action.payload.position
      }
    }
    case "finishNudgingModules": {
      return {
        isModuleNudging: false,
        nudgedModuleId: undefined,
        originalNudgedModuleCenter: undefined
      }
    }
    default:
      return state.moduleNudge
  }

}
