import React from "react";
import { LoadingProcess, LoadingProcessGroup } from "../LoadingScreenTypes";

//user can add_process object with no group name, then we default to defaultGroupName
const defaultGroupName = "iHD"

enum ACTIONS {
    ADD_PROCESS = 'add_process',
    COMPLETE_PROCESS = 'complete_process',
    SET_PROCESS_GROUPS = 'set_process_groups',
    RESET = 'reset'
}

function reducerFunc(state: LoadingScreenState, action: LoadingScreenAction) {
    switch (action.type) {
        case ACTIONS.ADD_PROCESS:
            const processGroupName = action.payload.group || defaultGroupName
            //find if the processGroup already exist in state
            const group = state.processGroups.find(group => group.name === processGroupName);
            if (group) {
                //if group already exist, just add the process to the group
                return {
                    processGroups: state.processGroups.map(group => {
                        if (group.name === processGroupName) {
                            return {
                                name: group.name,
                                processes: [
                                    ...group.processes,
                                    {
                                        ...action.payload,
                                        group: processGroupName,
                                    }
                                ]
                            }
                        } else {
                            return group
                        }
                    })
                }
            } else {
                //group does not exist, create it
                return {
                    processGroups: [
                        ...state.processGroups,
                        {
                            name: processGroupName,
                            processes: [
                                {
                                    ...action.payload,
                                    group: processGroupName
                                }
                            ]
                        }
                    ]
                }
            }
        case ACTIONS.COMPLETE_PROCESS:
            //first set the process of the processName to be complete
            const newGroups = {
                processGroups: state.processGroups.map(group => {
                    return {
                        name: group.name,
                        processes: group.processes.map(process => {
                            if (process.name === action.payload) {
                                return {
                                    name: process.name,
                                    group: group.name,
                                    complete: true
                                }
                            } else {
                                return process
                            }
                        })
                    }
                })
            }
            //then check if all processes are completed
            if (newGroups.processGroups.some(group => {
                return group.processes.some(process => {
                    return !process.complete
                })
            })) {
                return newGroups
            } else {
                return {
                    processGroups: []
                }
            }
        case ACTIONS.SET_PROCESS_GROUPS:
            return {
                processGroups: action.payload
            }
        case ACTIONS.RESET:
            return {
                processGroups: []
            }
        default:
            return state
    }
}

type LoadingScreenAction = any
type LoadingScreenState = {
    processGroups: LoadingProcessGroup[]
}
type LoadingScreenProps = { children: React.ReactNode, defaultState?: LoadingScreenState }
type LoadingScreenDispatch = React.Dispatch<LoadingScreenAction>
const LoadingScreenContext = React.createContext<{state: LoadingScreenState; dispatch: LoadingScreenDispatch } | undefined>(undefined)

export const LoadingScreenProvider = ({defaultState, children}: LoadingScreenProps) => {
    const [state, dispatch] = React.useReducer(reducerFunc, {
        ...(defaultState || {processGroups: []}),
        }
    )
    const value = { state, dispatch }
    return (
        <LoadingScreenContext.Provider value={value}>
            {children}
        </LoadingScreenContext.Provider>
    )
}

export const useLoadingScreen = () => {
    const context = React.useContext(LoadingScreenContext)
    if (context === undefined) {
        throw new Error('useLoadingScreen must be used within a LoadingScreenProvider')
    }
    const { state, dispatch } = context;
    return {
        isLoading: state?.processGroups?.length === 0 ? false : true,
        state: state,
        dispatch: dispatch,
        helpers: {
            completeProcess: (processName: string) => dispatch({type: ACTIONS.COMPLETE_PROCESS, payload: processName}),
            addProcess: (process: LoadingProcess) => dispatch({type: ACTIONS.ADD_PROCESS, payload: {
                name: process.name,
                group: process.group || defaultGroupName,
                complete: false,
            }}),
          }
    }
}