import * as React from "react";
import { useQuery } from "react-query";
import { useErrorHandler } from "react-error-boundary";
import {
  DesignConstraints,
  ProductsAvailability,
  TestFixtures,
} from "@sunrun/design-tools-domain-model";
import { useLoadingScreen } from "@sunrun/design-tools-loading-screen";
import { productAndPricingClient } from "@sunrun/design-tools-graphql-clients";
import { parseLambdaError } from "src/utils/lambdaErrorParser";
import {
  LoadingProcessGroups,
  LoadingProcessNames,
} from "src/types/LoadingScreenProcess";
import { useWorkspace } from "src/hooks/useWorkspace";
import { URLSearchParameterKey } from "src/types/URLSearchParameterKey";
import { useSearchParams } from "react-router-dom";
import { setDesignConstraints } from "src/features/designConstraints/designConstraintsSlice";
import { useAppDispatch } from "src/store";
import { useProductAvailability } from "./useProductAvailability";
/**
 * This is hack to make it easy for TestDataScenarios to mock out the DesignConstraints object
 * TODO we probably should centralize query state to the context so that we can simply initialize the context exactly
 *   the way we want instead of hacking around in these hooks / repositories / etc
 */
let useDesignConstraintsMock: DesignConstraints | undefined;
export function mockUseDesignConstraints(mock: DesignConstraints) {
  useDesignConstraintsMock = mock;
}

export const useDesignConstraints = () => {
  const { state, dispatch: workspaceDispatch } = useWorkspace();
  const dispatch = useAppDispatch();
  const {
    customer,
    mostRecentSignedDesign: { effectiveDate, productType },
    offer,
  } = state;
  const { helpers: loadingScreenHelpers } = useLoadingScreen();
  const handleError = useErrorHandler();
  const [searchParams] = useSearchParams();
  const productAvailability = useProductAvailability();
  const isChangeOrder = searchParams.has(URLSearchParameterKey.SignedRootId);
  const changeOrderType = isChangeOrder ? "child" : "parent";
  const parentProductType = offer?.parentFinancialProduct;

  const getDesignConstraints = async () => {
    if (!customer) {
      throw Error(`A customer is required to get DesignConstraints`);
    }
    if (useDesignConstraintsMock) {
      return useDesignConstraintsMock;
    }
    if (customer.prospectId === "testFixture") {
      return TestFixtures.DesignConstraintsFixtures.buildSimpleDesignConstraints();
    } else {
      return productAndPricingClient.queryDesignConstraintsByCustomer(
        customer,
        changeOrderType,
        parentProductType,
        effectiveDate,
        productType
      );
    }
  };

  const query = useQuery(
    ["getDesignConstraints", customer, effectiveDate, productType],
    getDesignConstraints,
    {
      refetchOnWindowFocus: false, // TODO support these use cases for conflict resolution
      refetchOnReconnect: false,
      enabled: !!customer, // https://react-query.tanstack.com/guides/dependent-queries
      onSuccess: (newDesignConstraints: DesignConstraints) => {
        dispatch(
          setDesignConstraints(newDesignConstraints.getIDesignConstraints())
        );
        workspaceDispatch({
          type: "setDesignConstraints",
          payload: newDesignConstraints,
        });
      },
      onError: (error: Error) => {
        const parsedError = parseLambdaError(
          error,
          new Error("Failed to get Design Constraints.")
        );
        handleError(parsedError);
      },
    }
  );

  React.useEffect(
    function addLoadingScreenProcess() {
      if (query.isFetching) {
        loadingScreenHelpers.addProcess({
          name: LoadingProcessNames.DESIGN_CONSTRAINTS,
          group: LoadingProcessGroups.INITIALIZE_IHD,
        });
        return function completeLoadingScreenProcess() {
          loadingScreenHelpers.completeProcess(
            LoadingProcessNames.DESIGN_CONSTRAINTS
          );
        };
      }
    },
    [query.isFetching]
  );

  React.useEffect(() => {
    // Design Constraints will be updated in state after data is received from both offer constraints and storefront API
    // in order to display accurate finanical products for the prospect
    const designConstraints = query.data;
    const productAvailabilityData = productAvailability.data;

    if (designConstraints && productAvailabilityData) {
      const availableFinancialProducts =
        ProductsAvailability.listUniqueFinancialProductsOnBundles(
          productAvailabilityData
        );
      const updatedDesignConstraints = new DesignConstraints(
        designConstraints,
        availableFinancialProducts
      );
      dispatch(
        setDesignConstraints(updatedDesignConstraints.getIDesignConstraints())
      );
      workspaceDispatch({
        type: "setDesignConstraints",
        payload: updatedDesignConstraints,
      });
    }
  }, [query.data, productAvailability.data]);
};
