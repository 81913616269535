import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Design } from "@sunrun/design-tools-domain-model";

dayjs.extend(utc);

export const convertToReadableDateAndTime = (
  date: string | undefined,
  format: string
): string | undefined => {
  if (!date) return;
  return `${dayjs.utc(date).local().format(format)}`;
};

export function sortDesignByMostRecentTime(a: Design, b: Design) {
  const timeA = new Date(a.updatedAt || 0);
  const timeB = new Date(b.updatedAt || 0);
  return timeB.getTime() - timeA.getTime();
}
