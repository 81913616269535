import { ModalLayout } from "../components/ModalLayout";
import Markdown from "react-markdown";
import { useEffect, useState } from "react";

type WhatsNewModalProps = {
  open: boolean;
  onClose: () => void;
};

const WhatsNewModal = ({ open, onClose }: WhatsNewModalProps) => {
  const [newReleaseNotes, setNewReleaseNotes] = useState("");

  useEffect(() => {
    fetch("/ReleaseNotes.md")
      .then(((response) => {
        return response.text();
      }))
      .then((md) => setNewReleaseNotes(md));
  }, [])

  return (
    <ModalLayout open={open} title="What's New" onClose={onClose}>
      <Markdown className="markdown-content" children={newReleaseNotes}></Markdown>
    </ModalLayout>
  );
};

export default WhatsNewModal;
