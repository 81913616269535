import {useDomainModel} from "src/hooks/useDomainModel";
import React from "react";
import {useWorkspace} from "src/hooks/useWorkspace";
import { deriveDesignClass } from "src/features/design/designSlice";
import { useAppSelector } from "src/store";


type UseDomainModelByDesignIdProp = {
  designId?: string
  designVersion?: number
  signedRootDesignId?: string
  interconnectionAppliedDesignId?: string
  copyFromDesignId?: string
}

export const useDomainModelByDesignId = ({
    designId,
    designVersion,
    signedRootDesignId,
    interconnectionAppliedDesignId,
    copyFromDesignId,
  } : UseDomainModelByDesignIdProp
) => {
  const {state} = useWorkspace()

  const [siteModelId, setSiteModelId] = React.useState<string | undefined>()
  const [siteModelVersion, setSiteModelVersion] = React.useState<number | undefined>()
  const [geoRasterId, setGeoRasterId] = React.useState<string | undefined>()

  const [productionSimulationId, setProductionSimulationId] = React.useState<string | undefined>()
  const [productionSimulationVersion, setProductionSimulationVersion] = React.useState<number | undefined>()
  const [solarResourceId, setSolarResourceId] = React.useState<string | undefined>()
  const [solarResourceVersion, setSolarResourceVersion] = React.useState<number | undefined>()
  const {id: solarResourceExpandedId, version: solarResourceExpandedVersion} = useAppSelector(({solarResourceExpanded}) => solarResourceExpanded);

  const design = deriveDesignClass(state);

  const useDomainModelValue = useDomainModel({
    designId,
    designVersion,
    geoRasterId,
    siteModelId,
    siteModelVersion,
    productionSimulationId,
    productionSimulationVersion,
    solarResourceId,
    solarResourceVersion,
    signedRootDesignId,
    interconnectionAppliedDesignId,
    copyFromDesignId,
    solarResourceExpandedId,
    solarResourceExpandedVersion
  })

  React.useEffect(() => {
    if (design){
      if (design.siteModelId != null && design.siteModelVersion != null) {
        setSiteModelId(design.siteModelId)
        setSiteModelVersion(design.siteModelVersion)
      }
      if (design.productionSimulationId != null && design.productionSimulationVersion != null) {
        setProductionSimulationId(design.productionSimulationId)
        setProductionSimulationVersion(design.productionSimulationVersion)
      }
      if (design.solarResourceId != null && design.solarResourceVersion != null) {
        setSolarResourceId(design.solarResourceId)
        setSolarResourceVersion(design.solarResourceVersion)
      }      
    }
  }, [
    design?.siteModelId,
    design?.siteModelVersion,
    design?.productionSimulationId,
    design?.productionSimulationVersion,
    design?.solarResourceId,  
    design?.solarResourceVersion  
  ])  

  React.useEffect(() => {
    if (state.siteModel && state.siteModel.selectedOrthogonalGeoRasterId){
      setGeoRasterId(state.siteModel.selectedOrthogonalGeoRasterId)
    }
  }, [state.siteModel?.selectedOrthogonalGeoRasterId])

  return useDomainModelValue
}
