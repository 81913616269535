import {ProductVariantId} from "@sunrun/design-tools-domain-model";
import {storefrontClient} from "@sunrun/design-tools-graphql-clients";
import {useWorkspace} from "./useWorkspace";
import {useSearchParams} from "react-router-dom";
import {useLoadingScreen} from "@sunrun/design-tools-loading-screen";
import {LoadingProcessGroups, LoadingProcessNames} from "src/types/LoadingScreenProcess";
import {URLSearchParameterKey} from "src/types/URLSearchParameterKey"
import { useAppDispatch } from "src/store";
import { setOffer } from "src/features/offer/offerSlice";
import { setErrorModal } from "src/features/modal/modalSlice";

export const useAddEvCharger = () => {
  const { state, dispatch: workspaceDispatch } = useWorkspace();
  const dispatch = useAppDispatch()
  const {customer, design, offer} = state
  const [searchParams] = useSearchParams();
  const {helpers: loadingScreenHelpers} = useLoadingScreen();
  const lightmileProjectId = searchParams.get(URLSearchParameterKey.LightmileProjectId) || undefined;

  const addEvCharger = async (): Promise<{success: boolean}> => {
    let success = false
    if (offer && customer && design) {
      try {
        // TODO: Do we need different loading screen?
        loadingScreenHelpers.addProcess({
          group: LoadingProcessGroups.PREPARE_TO_GO_TO_PRICING,
          name: LoadingProcessNames.UPDATING_OFFER,
        });
        const updatedOffer = await storefrontClient.createOfferLines(
          offer.id,
          offer.lockVersion,
          [{
            quantity: 1,
            variantId: ProductVariantId.EvCharging_Default,
          }]
        )
        dispatch(setOffer(updatedOffer))
        workspaceDispatch({type: 'setOffer', payload: updatedOffer})
        success = true
      } catch(e: any) {
        const error = (e instanceof Error) ? e : new Error("Failed to add EV Charger to offer");
        dispatch(setErrorModal({error: error, message: "Failed to add EV Charger to offer"}))
        workspaceDispatch({ type: "setErrorModal", payload: {error: error, message: "Failed to add EV Charger to offer"}})
      } finally {
        loadingScreenHelpers.completeProcess(LoadingProcessNames.UPDATING_OFFER);
      }
    }
    return {success}
  }

  return {
    addEvCharger
  }
}
