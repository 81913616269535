import { Fab } from "@mui/material";
import React from "react";
import UndoIcon from "@mui/icons-material/Undo";
import { FlagsmithFeatureEnums } from "src/config/flagsmithConfig";
import { useFlags } from "flagsmith/react";
import { useDesignUndo } from "src/hooks/useDesignUndo";

export const DesignUndo: React.FC = () => {
  const allowUndoDesign = useFlags(
    [FlagsmithFeatureEnums.ENABLE_UNDO_DESIGN],
    ["email"]
  )[FlagsmithFeatureEnums.ENABLE_UNDO_DESIGN].enabled;

  const { handleUndoDesign, isUndoDesignDisabled } = useDesignUndo();

  const disableClick = isUndoDesignDisabled();

  const handleUndoDesignClick = async () => {
    await handleUndoDesign();
  };

  if (!allowUndoDesign) return null;

  return (
    <Fab
      color="secondary"
      aria-label="undo design"
      onClick={handleUndoDesignClick}
      disabled={disableClick}
    >
      <UndoIcon fontSize="large" />
    </Fab>
  );
};
