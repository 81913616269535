import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { ModalLayout } from "./components/ModalLayout";
import {
  DesignVariantName,
  DesignWarning,
  selectVariantDesignDefaultTooltipDescription,
  Design,
} from "@sunrun/design-tools-domain-model";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useWorkspace } from "src/hooks/useWorkspace";
import { deriveDesignWarnings } from "src/features/designGuidance/deriveDesignWarnings";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import * as FullStory from "@fullstory/browser";
import { deriveReadOnlyDesignClassSets } from "src/features/readOnlyDesignSet/readOnlyDesignSetSlice";
import {
  deriveDisplayNameForCustom,
  deriveDisplayNameForStandard,
} from "src/features/products/productsSlice";
import { setVariantDesignSelected } from "src/features/workflowState/workflowStateSlice";
import { useAppDispatch } from "src/store";

type SelectDesignVariantModalProps = {
  open: boolean;
  onClose: (designVariant: DesignVariantName) => void;
  defaultVariant: DesignVariantName | undefined;
};

type VariantDesignOption = {
  designVariant: string;
  label?: string;
  warnings: DesignWarning[];
  tooltipDescription?: string;
};

export const SelectDesignVariantModal = ({
  open,
  onClose,
  defaultVariant,
}: SelectDesignVariantModalProps) => {
  const [hasError, setHasError] = useState(false);
  // in order to drive users to more often select the default we are hiding other options until they select "edit"
  const [hideVariantOptions, setHideVariantOptions] = useState(true);
  const {state, dispatch: workspaceDispatch} = useWorkspace();
  const dispatch = useAppDispatch();
  const { variantDesignSets } = deriveReadOnlyDesignClassSets(state);
  const [selectedDesignVariant, setSelectedDesignVariant] = useState<
    DesignVariantName | string
  >("");
  const [designVariantOptions, setDesignVariantOptions] = useState<
    VariantDesignOption[]
  >([]);

  const custom = deriveDisplayNameForCustom(state);
  const standard = deriveDisplayNameForStandard(state);
  const designLabel = useCallback(
    (variantName: DesignVariantName): string => {
      switch (variantName) {
        case DesignVariantName.Mlpe:
          return custom;
        case DesignVariantName.UnoptimizedString:
          return standard;
      }
    },
    [custom, standard]
  );

  useEffect(() => {
    // Add design variant options
    const options: VariantDesignOption[] = [];
    // these if statements are ordered to match business requirements for which variant should be listed first
    if (defaultVariant) {
      setSelectedDesignVariant(defaultVariant);
    }
    if (variantDesignSets.mlpe) {
      const newOption: VariantDesignOption = {
        designVariant: DesignVariantName.Mlpe,
        label: designLabel(DesignVariantName.Mlpe),
        warnings: deriveDesignWarnings({
          ...state,
          design: variantDesignSets.mlpe.design,
          productionSimulation: variantDesignSets.mlpe.productionSimulation,
        }),
        tooltipDescription: selectVariantDesignDefaultTooltipDescription(
          variantDesignSets.mlpe.design
        ),
      };
      options.push(newOption);
    }
    if (variantDesignSets.unoptimizedString) {
      const newOption: VariantDesignOption = {
        designVariant: DesignVariantName.UnoptimizedString,
        label: designLabel(DesignVariantName.UnoptimizedString),
        warnings: deriveDesignWarnings({
          ...state,
          design: variantDesignSets.unoptimizedString.design,
          productionSimulation:
            variantDesignSets.unoptimizedString.productionSimulation,
        }),
        tooltipDescription: selectVariantDesignDefaultTooltipDescription(
          variantDesignSets.unoptimizedString.design
        ),
      };
      options.push(newOption);
    }
    setDesignVariantOptions(options);
  }, [variantDesignSets, defaultVariant, designLabel]);

  const handleConfirmSelection = () => {
    if (!selectedDesignVariant) {
      setHasError(true);
    } else {
      setHideVariantOptions(true);
      generateSelectVariantDesignFullStoryMetrics(selectedDesignVariant);
      onClose(selectedDesignVariant as DesignVariantName);
    }
  };

  const handleChange = (
    e: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    const target = e.target as HTMLInputElement;
    if (checked) {
      setSelectedDesignVariant(target.value);
    }
  };

  const generateSelectVariantDesignFullStoryMetrics = (
    selectedDesignVariant: string
  ) => {
    try {
      const selectedDesign: Design =
        selectedDesignVariant === DesignVariantName.Mlpe
          ? variantDesignSets?.mlpe?.design!
          : variantDesignSets?.unoptimizedString?.design!;
      const isSolarOnly = selectedDesign.batteryCount === 0;
      const userSelectedDefault = defaultVariant === selectedDesignVariant;
      const inverterId =
        selectedDesign.inverters[0].properties.inverterSpecificationId;
      const inverterManufacturer =
        selectedDesign.getInverterManufacturer(inverterId);

      let batteryModel, batteryManufacturer;
      if (!isSolarOnly) {
        const batteryId =
          selectedDesign.batteries[0].properties.batterySpecificationId;
        batteryModel = selectedDesign.getBatteryModel(batteryId);
        batteryManufacturer = selectedDesign.getBatteryManufacturer(batteryId);
      }
      dispatch(setVariantDesignSelected(selectedDesignVariant));
      workspaceDispatch({
        type: "setVariantDesignSelected",
        payload: selectedDesignVariant,
      });
      FullStory.event("Variant Design Selected", {
        designId: selectedDesign.id,
        defaultVariant,
        selectedDesignVariant,
        isSolarOnly,
        userSelectedDefault,
        inverterManufacturer,
        batteryModel,
        batteryManufacturer,
      });
    } catch {
      console.error(
        "Error Generating Variant Design Selected FullStory Metrics"
      );
    }
  };

  return (
    <ModalLayout
      open={open}
      title={"Multiple Design Options Available"}
      onClose={handleConfirmSelection}
      // when hideVariantOptions is true show Edit and Confirm. When false just show Confirm
      cancelText={hideVariantOptions ? "Edit" : "Confirm"}
      onCancel={
        hideVariantOptions
          ? () => setHideVariantOptions(false)
          : handleConfirmSelection
      }
      continueText={hideVariantOptions ? "Confirm" : undefined}
      onContinue={hideVariantOptions ? handleConfirmSelection : undefined}
      actionButtons
      displayCloseButton={false}
    >
      <Stack spacing={2}>
        <FormControl>
          {hideVariantOptions ? (
            <FormLabel id="inverter-type-label" style={{ marginBottom: "8px" }}>
              Recommended inverter for this project:{" "}
            </FormLabel>
          ) : (
            <FormLabel id="inverter-type-label" style={{ marginBottom: "8px" }}>
              This design allows for multiple design options. Please confirm the
              recommended inverter or edit the selection.
            </FormLabel>
          )}
          <RadioGroup
            aria-labelledby="inverter-type-label"
            value={selectedDesignVariant}
          >
            {designVariantOptions.map((option) => {
              if (
                option.designVariant === defaultVariant &&
                option.tooltipDescription
              ) {
                return (
                  <Tooltip
                    title={option.tooltipDescription}
                    placement="bottom-end"
                    key={option.designVariant}
                  >
                    <Box key={option.designVariant} px={2} mb={1}>
                      <FormControlLabel
                        value={option.designVariant}
                        control={<Radio />}
                        label={option.label}
                        onChange={handleChange}
                      />
                      {option.warnings.map((warning) => (
                        <FormHelperText key={warning.name}>
                          Warning: {warning.name}
                        </FormHelperText>
                      ))}
                      <HelpOutlineOutlinedIcon
                        fontSize="inherit"
                        color="primary"
                      />
                    </Box>
                  </Tooltip>
                );
              }
              return (
                <Box
                  key={option.designVariant}
                  px={2}
                  mb={1}
                  hidden={
                    selectedDesignVariant !== option.designVariant &&
                    hideVariantOptions
                  }
                >
                  <FormControlLabel
                    value={option.designVariant}
                    control={<Radio />}
                    label={option.label}
                    onChange={handleChange}
                  />
                  {option.warnings.map((warning) => (
                    <FormHelperText key={warning.name}>
                      Warning: {warning.name}
                    </FormHelperText>
                  ))}
                </Box>
              );
            })}
          </RadioGroup>
        </FormControl>
        {hasError && (
          <FormHelperText error>
            A selection is required to continue
          </FormHelperText>
        )}
      </Stack>
    </ModalLayout>
  );
};
