import { ModalLayout } from "./components/ModalLayout";
import { Divider, Stack, Typography } from "@mui/material";
import { useWorkspace } from "src/hooks/useWorkspace";
import { LayoutPlaneEdge } from "@sunrun/design-tools-domain-model";
import { uniqWith, isEqual } from "lodash";
type SetbackRulesModalProps = {
  open: boolean;
  onClose: () => void;
};

export type SetbackRule = Readonly<{
  id: string;
  description: string;
}>;

const SetbackRulesModal = ({ open, onClose }: SetbackRulesModalProps) => {
  const { state } = useWorkspace();
  const usableRoofIds: string[] = [];
  state.siteModel?.roofFaces?.forEach((roofFace) => {
    if (roofFace.properties.usable) {
      usableRoofIds.push(roofFace.id);
    }
  });

  const usableLayoutPlaneEdgeRules = state.setbackRules.layoutPlaneEdgeRules?.filter(
    (layoutPlaneEdge) =>
      layoutPlaneEdge &&
      typeof layoutPlaneEdge?.layoutPlaneIdentifier === "string" &&
      usableRoofIds.includes(layoutPlaneEdge?.layoutPlaneIdentifier),
  );

  return (
    <ModalLayout open={open} title="Setback Rules" onClose={onClose}>
      <div style={{ minWidth: "33vw" }}>
        {usableLayoutPlaneEdgeRules &&
          usableLayoutPlaneEdgeRules.map((layoutPlaneEdge, i) => (
            <LayoutPlaneEdges key={`usableLayoutPlaneEdges-${i}`} layoutPlaneEdge={layoutPlaneEdge}></LayoutPlaneEdges>
          ))}
        {state.setbackRules.setbackRules.map((rule) => (
          <SetbackRuleItem rule={rule}></SetbackRuleItem>
        ))}
      </div>
    </ModalLayout>
  );
};

const SetbackRuleItem = ({ rule }: { rule: SetbackRule }) => (
  <Stack mb={2} spacing={1}>
    <Typography variant="subtitle1" color="primary">
      {rule.id}
    </Typography>
    <Typography variant="body2">{rule.description}</Typography>
    <Divider />
  </Stack>
);

const LayoutPlaneEdges = ({ layoutPlaneEdge }: { layoutPlaneEdge: LayoutPlaneEdge | null }) => {
  const uniqLayoutPlaneEdges = uniqWith(layoutPlaneEdge?.edges, isEqual);
  return (
    <>
      {layoutPlaneEdge && (
        <Stack mb={2} spacing={1} key={`layoutPlaneEdge-${layoutPlaneEdge.layoutPlaneIdentifier}`}>
          <Typography variant="subtitle1" color="primary">
            Roof {layoutPlaneEdge.layoutPlaneIdentifier}
          </Typography>
          {uniqLayoutPlaneEdges?.map((edge, i) => (
            <Stack key={`layoutPlaneEdges-${i}`}>
              <Typography variant="subtitle2" color="primary" textTransform={"capitalize"}>
                {edge?.type?.toLocaleLowerCase()}
              </Typography>
              {edge?.pathwayWidthFt && (
                <Typography ml={1} variant="body2">
                  Pathway Width Ft: {edge?.pathwayWidthFt}
                </Typography>
              )}
              {edge?.pathwayWidthOverrideFt && (
                <Typography ml={1} variant="body2">
                  Pathway Width Override Ft:{edge?.pathwayWidthOverrideFt}
                </Typography>
              )}
              <Typography ml={1} variant="body2">
                Rules:
              </Typography>
              {edge?.appliedFireCodeRules?.map((fireCodeRule: string | null) => (
                <>
                  {fireCodeRule && (
                    <Typography ml={2} variant="body2">
                      {fireCodeRule}
                    </Typography>
                  )}
                </>
              ))}
            </Stack>
          ))}
          <Divider />
        </Stack>
      )}
    </>
  );
};

export default SetbackRulesModal;
