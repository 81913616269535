import { Context } from "aws-lambda";
import * as process from "process";
import { Env } from './env'

export enum LongLivedEnv {
  dev = 'dev',
  stg = 'stg',
  prd = 'prd'
}

// This is copied from @sunrun/design-tools-aws-cdk. We could centralize it in a new package if needed.
enum DesignToolsAccountIds {
  dev = '537832996537',
  stg = '289122335739',
  prd = '133416057010'
}

export enum TestType {
  LocalTest = 'LocalTest',    // Orchestrator runs local using mocked/stubbed network clients
  HybridTest = 'HybridTest',  // Orchestrator runs local using live network clients
  CloudTest = 'CloudTest',    // Test driver invokes orchestrator running in the cloud
  None = 'None',
}

export const appSyncApiUrls = {
  dev: "https://tnxbhqap2bhqhgkwnqye6dytei.appsync-api.us-west-2.amazonaws.com/graphql",
  stg: "todo",        // stg is not supported
  prd: "https://bslxm7n6tjhztg7i6vy5mzkm3e.appsync-api.us-west-2.amazonaws.com/graphql"
}

export const onlineDefaults = {
  dev: "true",
  stg: "true",
  prd: "true"
}


const env = Env.browserOrNodeJs()

export class BrowserContext {
  private parseKeyFromWindowLocationWithDefaults = (key: string, defaults: Record<string, string>): string | null | undefined => {
    if (this.areWeRunningInTheBrowser) {
      if (this.searchParams && this.searchParams.has(key)) {
        return this.searchParams.get(key)
      }
      if (env.isLongLived()) {
        return defaults[env.name]
      } else {
        return defaults['dev']
      }
    }
    return null
  }

  private parseEnvOverrideFromSearchParams = (): string | undefined | null => {
    if (this.areWeRunningInTheBrowser && env.name !== LongLivedEnv.prd) {
      if (this.searchParams && this.searchParams.has('envOverride')) {
        console.log(`Found search param for envOverride: "${this.searchParams.get('envOverride')}"`)
        return this.searchParams.get('envOverride')
      }
    }
    return undefined
  }

  public readonly areWeRunningInTheBrowser = typeof window !== 'undefined'
  public readonly searchParams = (this.areWeRunningInTheBrowser) ? new URLSearchParams(window.location.search) : null
  public readonly apiUrl = this.parseKeyFromWindowLocationWithDefaults('apiUrl', appSyncApiUrls)
  public readonly isOnline = this.parseKeyFromWindowLocationWithDefaults('online', onlineDefaults) == 'true'
  public readonly isOffline = !this.isOnline
  public readonly envOverride = this.parseEnvOverrideFromSearchParams()
  public accessToken?: () => Promise<string>
}

export class RuntimeContext {
  public static readonly env = env
  public static readonly browserContext = new BrowserContext()
  public static lambdaContext: Context
  public static testType: TestType = TestType.None
  public static envOverride: string | null | undefined = RuntimeContext.browserContext.envOverride ?? undefined
  public static mockExternalCalls: boolean = false
  public static signInUserEmail?: string | null
  public static prospectId?: string | null

  public static setEnvOverride = (envOverride?: string | null | undefined) => {
    RuntimeContext.envOverride = envOverride
  }

  public static setMockExternalCalls = (mockExternalCalls: boolean | undefined | null) => {
    if (mockExternalCalls === null || mockExternalCalls === undefined) {
      RuntimeContext.mockExternalCalls = false
    } else {
      RuntimeContext.mockExternalCalls = mockExternalCalls
    }
  }

  public static setTestType = (testType: TestType) => {
    RuntimeContext.testType = testType
  }

  public static getAwsAccount = (): string => {
    if (RuntimeContext.lambdaContext) return RuntimeContext.lambdaContext.invokedFunctionArn.split(":")[4]!
    throw Error("Lambda context is not set.")
  }

  public static isDevAccount = (): boolean => {
    try {
      return RuntimeContext.getAwsAccount() === DesignToolsAccountIds.dev
    } catch (e) {
      return false
    }
  }

  public static isPrdAccount = (): boolean => {
    try {
      return RuntimeContext.getAwsAccount() === DesignToolsAccountIds.prd
    } catch (e) {
      return false
    }
  }

  public static setupTest = (testType: TestType) => {
    if (testType === TestType.None) {
      throw new Error('TestType.None is not used for tests.')
    }
    if (testType !== TestType.LocalTest) {
      // using Env.nodejs() because tests always run in nodejs context, even tests for browsers
      RuntimeContext.setEnvOverride(Env.nodejs().name)
    }
    if (testType !== TestType.HybridTest) {
      RuntimeContext.setMockExternalCalls(true)
    }
    RuntimeContext.setTestType(testType)
    console.log(`
    ********RUNTIME CONTEXT TEST SETUP********
    Test Type: ${JSON.stringify(RuntimeContext.testType)}
    Env Name: ${RuntimeContext.env.name}
    envOverride: ${RuntimeContext.envOverride}
    mockExternalCalls: ${RuntimeContext.mockExternalCalls}`)
  }

  public static areWeRunningInLambda = (): boolean => {
    return (typeof process !== 'undefined' && !!process.env["AWS_LAMBDA_FUNCTION_NAME"])
  }

}
if (RuntimeContext.browserContext.areWeRunningInTheBrowser) {
  console.log(`
  ********RUNTIME CONTEXT********
  Browser Context: ${JSON.stringify(RuntimeContext.browserContext)}
  Env Name: ${JSON.stringify(RuntimeContext.env)}
  envOverride: ${RuntimeContext.envOverride}
  mockExternalCalls: ${RuntimeContext.mockExternalCalls}`)
}
