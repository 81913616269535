import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {produce} from "immer";
import {WorkspacePayloadAction} from "../../types/state-management/action";
import {Design, ProductionSimulationType} from "@sunrun/design-tools-domain-model";
import { IDesign } from "@sunrun/design-tools-domain-model";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type HacksState  = {
  value: IDesign | undefined;
}

const initialState: HacksState = {
  value: undefined,
};
 
export const hacksSlice = createSlice({
  name: "hacks",
  initialState,
  reducers: {
    setDesignAtSimulationTime: (state: HacksState, action: PayloadAction<IDesign>) => {
      state.value = action.payload;
    },
  },
});

export const { setDesignAtSimulationTime } = hacksSlice.actions
export default hacksSlice.reducer

// TODO: Remove the following and transition fully from WorkspaceState to RTK store
export type HackWorkspaceAction = WorkspacePayloadAction<Design, 'setDesignAtSimulationTime'>

export const selectDesignAtSimulationTime = (state: WorkspaceState) => state.HACKS.designAtSimulationTime

export const hacksWorkspaceReducer = (state: WorkspaceState, action: WorkspaceAction) => {
  switch (action.type) {
    case 'setDesignAtSimulationTime': {
      return produce(state.HACKS, draft => {
        draft.designAtSimulationTime = action.payload
      })
    }
    case "setProductionSimulation": {
      const {simulation: newProductionSimulation, design: design} = action.payload
      if (newProductionSimulation.type===ProductionSimulationType.HighResolution){
        return produce(state.HACKS, draft => {
          const {design: newDesign} = action.payload
          draft.designAtSimulationTime = newDesign
        })
      }
      return state.HACKS
    }
    default: {
      return state.HACKS
    }
  }
}
