import { useCallback } from "react";

import { useWorkspace } from "./useWorkspace";
import { deriveSiteModelClass } from "src/features/siteModel/siteModelSlice";
import { deriveDesignClass } from "src/features/design/designSlice";
import { deriveUndoDesignClass } from "src/features/undo/undoDesignSlice";
import { useCopyAndSyncDesignAndSiteModel } from "./useCopyAndSyncDesignAndSiteModel";
import * as FullStory from "@fullstory/browser";
import { deriveIsDesignFinalized } from "src/features/designGuidance/deriveIsDesignFinalized";
import { throttle } from "lodash";
import { useLoadingScreen } from "@sunrun/design-tools-loading-screen";

export const useDesignUndo = () => {
  const { state, dispatch: workspaceDispatch } = useWorkspace();  
  const { copyAndSyncDesignAndSiteModel } = useCopyAndSyncDesignAndSiteModel();
  const { isLoading } = useLoadingScreen();
  const siteModel = deriveSiteModelClass(state);
  const design = deriveDesignClass(state);
  const undoDesign = deriveUndoDesignClass(state);

  const designIsFinalized = deriveIsDesignFinalized(state);

  const isUndoDesignDisabled = useCallback((): boolean => {    
    return !undoDesign || designIsFinalized || isLoading;
  }, [undoDesign, designIsFinalized, isLoading]);

  const handleUndoDesign = useCallback(throttle(async () => {
    if (isUndoDesignDisabled()) {
      console.log("undo design is disabled");
      return;
    }

    if (design && undoDesign && siteModel) {
      const siteModelIdFromUndoDesign = undoDesign.siteModelId || siteModel.id;
      const siteModelVersionFromUndoDesign =
        undoDesign.siteModelVersion || siteModel.version;
      // Update design and siteModel on undo
      // copy and sync both design and siteModel to prevent verion issues
      await copyAndSyncDesignAndSiteModel(undoDesign, {
        id: siteModelIdFromUndoDesign,
        version: siteModelVersionFromUndoDesign,
      });

      workspaceDispatch({ type: "clearUndoDesign" });

      FullStory.event("Undo Design", {
        designId: design.id,
        saveVersion: design.latest,
        prospectId: design.prospectId,
      });
    }
  }, 500), [design, undoDesign, siteModel, isUndoDesignDisabled]);

  return {
    handleUndoDesign,
    isUndoDesignDisabled,
  };
};
