import { repository } from "@sunrun/design-tools-graphql-clients";
import { useWorkspace } from "./useWorkspace";
import { Design, SiteModel } from "@sunrun/design-tools-domain-model";
import { deriveDesignClass, setDesign } from "src/features/design/designSlice";
import { deriveSiteModelClass } from "src/features/siteModel/siteModelSlice";
import { useMutation } from "react-query";
import { useAppDispatch } from "src/store";
import { setErrorModal } from "src/features/modal/modalSlice";

type UpdatedDesignAndSiteModelType = {
  updatedDesign: Design;
  updatedSiteModel: SiteModel;
};

type UpdatedDesignAndSiteModelInputType = {
  designToCopy: Design;
  siteModelToCopy: Pick<SiteModel, "id" | "version">;
};

export const useCopyAndSyncDesignAndSiteModel = () => {
  const { state, dispatch: workspaceDispatch } = useWorkspace();

  const dispatch = useAppDispatch();
  const design = deriveDesignClass(state);
  const siteModel = deriveSiteModelClass(state);

  // This hook allows us to copy specific design and siteModel versions to the current
  // design and siteModel in state. It also takes care of incrementing the version properly to
  // the latest. This hook allows us to be DRY, as the logic is used for
  // bookmark design, undo design, and recalculateSetbacks.
  const copyAndSyncDesignAndSiteModelMutation = useMutation(
    async (
      input: UpdatedDesignAndSiteModelInputType
    ): Promise<UpdatedDesignAndSiteModelType> => {
      const { designToCopy, siteModelToCopy } = input;
      let updatedSiteModel: SiteModel;

      // We need version 0 in order to create a new record
      const [fetchedSiteModelv0, fetchSiteModelToCopy] = await Promise.all([
        repository.get(SiteModel, siteModel?.id || siteModelToCopy.id, 0),
        repository.get(SiteModel, siteModelToCopy.id, siteModelToCopy.version),
      ]);
      updatedSiteModel = fetchedSiteModelv0.copySiteModel(fetchSiteModelToCopy);
      const { vLatest } = await repository.save(updatedSiteModel);
      updatedSiteModel = vLatest;

      const copiedDesign = (design || designToCopy)
        .copyModules(designToCopy, updatedSiteModel)
        .updateSiteModelVersion(updatedSiteModel);

      return {
        updatedDesign: copiedDesign,
        updatedSiteModel: updatedSiteModel,
      };
    },
    {
      onSuccess: async (response) => {
        const { updatedDesign, updatedSiteModel } = response;
        console.log(
          `updated design id ${updatedDesign?.id} _version ${updatedDesign?._version}`
        );
        console.log(
          `updated siteModel id ${updatedSiteModel?.id} version ${updatedSiteModel?.version}`
        );
        dispatch(setDesign(updatedDesign.getIDesign()));
        workspaceDispatch({
          type: "setDesign",
          payload:
            updatedDesign.deleteModulesFromDisabledRoofs(updatedSiteModel), // update from https://github.com/SunRun/design-tools/pull/2760 moved here
        });
        // dispatch(setSiteModel(updatedSiteModel.getISiteModel()))   // TODO https://sunrun.jira.com/browse/ET-1617
        // dispatch(setDesign)
        workspaceDispatch({ type: "setSiteModel", payload: updatedSiteModel });
      },
      onError: (error: Error) => {
        dispatch(
          setErrorModal({
            error,
            message: "Failed to update design and siteModel",
          })
        );
        workspaceDispatch({
          type: "setErrorModal",
          payload: { error, message: "Failed to update design and siteModel" },
        });
        console.log(
          `Updating design and siteModel error for Design ID ${design?.id} and version ${design?.version}`,
          error
        );
      },
    }
  );

  const copyAndSyncDesignAndSiteModel = async (
    designToCopy: Design,
    siteModelToCopy: Pick<SiteModel, "id" | "version">
  ): Promise<UpdatedDesignAndSiteModelType> => {
    return await copyAndSyncDesignAndSiteModelMutation.mutateAsync({
      designToCopy,
      siteModelToCopy,
    });
  };

  return {
    copyAndSyncDesignAndSiteModel,
  };
};
