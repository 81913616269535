import React from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {LoadingScreen} from "@sunrun/design-tools-loading-screen";
import {LightmileProjectSource} from "@sunrun/design-tools-domain-model";
import {useImportLightmileProject} from "../../../useImportLightmileProject";
import { IFrameHostType } from "src/types/IFrame";
import {URLSearchParameterKey} from "src/types/URLSearchParameterKey"

export const ImportLightmileProjectRoute = () => {
  const {setImportParams} = useImportLightmileProject();

  const {lightmileProjectId, lightmileDesignIndex} = useParams();
  const [searchParams] = useSearchParams();
  const prospectId = searchParams.get(URLSearchParameterKey.ProspectId) || undefined;
  const signedRootId = searchParams.get(URLSearchParameterKey.SignedRootId) || undefined;
  const signedRootIdDashProduct = searchParams.get(URLSearchParameterKey.SignedRootIdDashProduct) || undefined;
  const interconnectionAppliedProposalId = searchParams.get(URLSearchParameterKey.InterconnectionAppliedProposalId) || undefined;

  console.log("lightmileProjectId:", lightmileProjectId);
  console.log("lightmileDesignIndex:", lightmileDesignIndex);
  if (prospectId) console.log("prospectId:", prospectId);
  if (signedRootId) console.log("signedRootId:", signedRootId);
  if (interconnectionAppliedProposalId) console.log("interconnectionAppliedProposalId:", interconnectionAppliedProposalId);

  React.useEffect(() => {
    if (lightmileProjectId && lightmileDesignIndex) {
      const activeProjectSource: LightmileProjectSource = {
        sourceByDesign: {
          lightmileProjectId,
          lightmileDesignIndex: parseInt(lightmileDesignIndex),
        }
      };
      setImportParams({
        activeProjectSource,
        prospectId,
        signedRootId,
        signedRootIdDashProduct,
        interconnectionAppliedProposalId
      });
    }
  }, [lightmileProjectId, lightmileDesignIndex]);

  return (
    <LoadingScreen />
  )
}
