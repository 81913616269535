import * as React from "react";
import {useQuery} from "react-query";
import {repository} from "@sunrun/design-tools-graphql-clients";
import {SolarResource, TestFixtures} from "@sunrun/design-tools-domain-model";
import {useWorkspace} from "src/hooks/useWorkspace";
import {useErrorHandler} from "react-error-boundary";
import { setSolarResource } from "src/features/solarResource/solarResourceSlice";
import { useAppDispatch } from "src/store";

type UseSolarResourceProps = {
  solarResourceId?: string,
  solarResourceVersion?: number,
}

export const useSolarResource = ({solarResourceId, solarResourceVersion}: UseSolarResourceProps) => {
  const { state, dispatch: workspaceDispatch } = useWorkspace();
  const dispatch = useAppDispatch()
  
  const handleError = useErrorHandler();

  const getSolarResource = async () => {
    if (solarResourceId)
      return repository.get(SolarResource, solarResourceId, solarResourceVersion ?? 0)
  }

  const query = useQuery(['getSolarResource', solarResourceId], getSolarResource, {
    refetchOnWindowFocus: false,                // TODO support these use cases for conflict resolution
    refetchOnReconnect: false,
    enabled: !!solarResourceId,           // https://react-query.tanstack.com/guides/dependent-queries
    onSuccess: (newSolarResource: SolarResource) => {
      dispatch(setSolarResource(newSolarResource.getISolarResource()))
      workspaceDispatch({type: 'setSolarResource', payload:newSolarResource})
    },
    onError: handleError
  })
}
