import { deriveDesignClass, setDesign } from "src/features/design/designSlice";
import { useWorkspace } from "./useWorkspace";
import {
  CommandType,
  Design,
  LayoutPlaneEdge,
  SetbacksRecalculated,
} from "@sunrun/design-tools-domain-model";
import { UseMutationResult, useMutation } from "react-query";
import { useSearchParams } from "react-router-dom";
import { URLSearchParameterKey } from "src/types/URLSearchParameterKey";
import { processManagerClient, repository } from "@sunrun/design-tools-graphql-clients";
import { SiteModel } from "@sunrun/design-tools-domain-model";
import { useEffect } from "react";
import { useLoadingScreen } from "@sunrun/design-tools-loading-screen";
import { LoadingProcessGroups, LoadingProcessNames } from "src/types/LoadingScreenProcess";
import { useAppDispatch } from "src/store";
import { setErrorModal } from "src/features/modal/modalSlice";
import { deriveUndoDesignClass } from "src/features/undo/undoDesignSlice";
import { deriveSiteModelClass } from "src/features/siteModel/siteModelSlice";

type RecalculateSetbacksProps = Readonly<{
  saveDesignMutation: UseMutationResult<Design, unknown, Design, unknown>;
}>;
export const useRecalculateSetbacks = ({ saveDesignMutation }: RecalculateSetbacksProps) => {
  const { state, dispatch: workspaceDispatch } = useWorkspace();
  const dispatch = useAppDispatch();
  const design = deriveDesignClass(state);
  const [searchParams] = useSearchParams();
  const { helpers: loadingScreenHelpers } = useLoadingScreen();
  const lightmileProjectId =
    searchParams.get(URLSearchParameterKey.LightmileProjectId) || undefined;
  const undoDesign = deriveUndoDesignClass(state);
  const siteModel = deriveSiteModelClass(state);

  const recalculateSetbacksMutation = useMutation(
    async (): Promise<SetbacksRecalculated> => {
      if (!design?.siteModelId) {
        throw new Error("No SiteModel linked to design; cannot recalculate setbacks");
      }

      return await processManagerClient.recalculateSetbacks({
        type: CommandType.RecalculateSetbacks,
        designId: design!.id,
        designVersion: design!.version,
        siteModelId: design!.siteModelId,
        lightmileProjectId: lightmileProjectId!,
      });
    },
    {
      onMutate: async () => {
        if (design?.hasUnsavedChanges) {
          await saveDesignMutation.mutateAsync(design!);
        }
      },
      // TODO: response will be a combination of site model and list of rules
      onSuccess: async (response: SetbacksRecalculated) => {
        console.log(
          `Recalculated setbacks success for Design ID ${design?.id} and version ${design?.version}.`,
        );
        if (response.siteModelId && response.siteModelVersion) {
          // Set the site model to the new site model
          const newSiteModel = await repository.get(
            SiteModel,
            response.siteModelId,
            response.siteModelVersion,
          );
          // TODO comment it out because of https://sunrun.jira.com/browse/ET-1617
          // dispatch(setSiteModel(newSiteModel.getISiteModel()));
          workspaceDispatch({ type: "setSiteModel", payload: newSiteModel });
          // Set design to use the new site model latest version
          workspaceDispatch({
            type: "setSiteModelVersion",
            payload: newSiteModel,
          });
          workspaceDispatch({
            type: "setLayoutPlaneEdgeRules",
            payload: response.layoutPlaneEdges as LayoutPlaneEdge[],
          });
        } else {
          throw new Error(
            `Site model ID or version is undefined for Design ID ${design?.id} and version ${design?.version}`,
          );
        }
      },
      onError: async (error: Error) => {
        dispatch(setErrorModal({ error, message: "Failed to recalculate setbacks" }));
        workspaceDispatch({
          type: "setErrorModal",
          payload: { error, message: "Failed to recalculate setbacks" },
        });
        console.log(
          `Recalculated setbacks error for for Design ID ${design?.id} and version ${design?.version}`,
          error,
        );
        if (design && undoDesign && siteModel) {
          dispatch(setDesign(undoDesign.getIDesign()));
          workspaceDispatch({ type: "setDesign", payload: undoDesign });
          workspaceDispatch({ type: "clearUndoDesign" });
        }
      },
    },
  );

  const recalculateSetbacks = async (): Promise<SetbacksRecalculated> => {
    return await recalculateSetbacksMutation.mutateAsync();
  };

  useEffect(
    function addLoadingScreenProcess() {
      if (recalculateSetbacksMutation.isLoading) {
        loadingScreenHelpers.addProcess({
          name: LoadingProcessNames.FETCH_FIRE_SETBACK,
          group: LoadingProcessGroups.UPDATING_SITE_MODEL,
        });
        return function completeLoadingScreenProcess() {
          loadingScreenHelpers.completeProcess(LoadingProcessNames.FETCH_FIRE_SETBACK);
        };
      }
    },
    [recalculateSetbacksMutation.isLoading],
  );

  return {
    recalculateSetbacks,
  };
};
