// note that since TS 3.4 there is better builtin support
// for type inference with readonly arrays and readonly tuples!
// this might be good enough for now:
export type Vector2D = readonly [number,number];
// the above should be impossible to push(newNumbers),
// and even v2d[0]=blah will fail compile!
function map(v: Vector2D, fn: (x: number)=>number): Vector2D {
  const r: Vector2D = [fn(v[0]),fn(v[1])];
  return r;
}
function mapAB(a:Vector2D, b:Vector2D, fn:(n:number,m:number)=>number): Vector2D {
  return [fn(a[0],b[0]),fn(a[1],b[1])];
}
// some basic math on 2D vectors.
// lets go with this for now since its rather short
// if it has type holes or perf issues, we can resolve those later!
export const add = (a:Vector2D,b:Vector2D)=>mapAB(a,b,(a,b)=>a+b);
export const sub = (a:Vector2D,b:Vector2D)=>mapAB(a,b,(a,b)=>a-b);
export const div = (a:Vector2D,b:Vector2D)=>mapAB(a,b,(a,b)=>a/b);
export const mul = (a:Vector2D,b:Vector2D)=>mapAB(a,b,(a,b)=>a*b);
export const min = (a:Vector2D,b:Vector2D)=>mapAB(a,b,(a,b)=>Math.min(a,b));
export const max = (a:Vector2D,b:Vector2D)=>mapAB(a,b,(a,b)=>Math.max(a,b));
export const scale = (a:Vector2D, s: number)=>map(a,(x)=>x*s);
export const square = (a:Vector2D)=>map(a,(x)=>x*x);
export const sum = (a:Vector2D)=>a.reduce((total,element)=>total+element,0);
export const length = (a:Vector2D): number => Math.sqrt(sum(square(a)));
export const direction = (a:Vector2D):Vector2D => scale(a, 1.0/length(a));
export const dot = (a: Vector2D, b:Vector2D):number=>sum(mul(a,b));
// the length of a's shadow in the direction of b
export const scalarProjection = (a: Vector2D, b: Vector2D): number => dot(a, direction(b));
// project a onto b --> the point where a line from a (orthogonal to b) would intersect b
// aka scalarProject(a,b)*b
export const project = (a: Vector2D, b: Vector2D):Vector2D => scale(b, dot(a,b)/dot(b,b));

export const unitVectorFromCardinalDirectionAngleDegrees = (angleDegrees: number): Vector2D => {
  const angleRadians = (90 - angleDegrees) * Math.PI / 180
  return [Math.cos(angleRadians), Math.sin(angleRadians)]
}
export const cardinalDirectionAngleDegrees = (v: Vector2D): number => {
  const radians = Math.atan2(v[1], v[0])
  const degrees = 90 - radians / Math.PI * 180
  return degrees >= 0 ? degrees : degrees + 360
}
