import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { useLoadingScreen } from "@sunrun/design-tools-loading-screen";
import {
  CommandType,
  Design,
  DesignSimulatedWithLightmileCore,
  DesignVariant,
  DesignVariantName,
  ProductionSimulation,
  SiteModel,
  calculateSunHourDiffMetricOutput
} from "@sunrun/design-tools-domain-model";
import { processManagerClient, repository } from "@sunrun/design-tools-graphql-clients";
import { LoadingProcessGroups, LoadingProcessNames } from "src/types/LoadingScreenProcess";
import { useWorkspace } from "src/hooks/useWorkspace";
import { useSearchParams } from "react-router-dom";
import * as FullStory from "@fullstory/browser";
import { URLSearchParameterKey } from "src/types/URLSearchParameterKey";
import { deriveDesignWarnings } from "src/features/designGuidance/deriveDesignWarnings";
import { useState } from "react";
import { useReadOnlyDesignSet } from "./useReadOnlyDesignSet";
import { convertToReadableDateAndTime } from "src/utils/designDateTime";
import { ProductOptionValue } from "@sunrun/design-tools-domain-model/build/offer/constants";
import { deriveDesignClass } from "src/features/design/designSlice";
import { setProductionSimulation } from "src/features/productionSimulation/productionSimulationSlice";
import { useAppDispatch, useAppSelector } from "src/store";
import { deriveDesignConstraintsClass } from "src/features/designConstraints/designConstraintsSlice";
import { deriveProductionSimulationClass } from "src/features/productionSimulation/productionSimulationSlice";
import { useFlags } from "flagsmith/react";
import { FlagsmithFeatureEnums } from "src/config/flagsmithConfig";
import { useRecalculateSetbacks } from "./useRecalculateSetbacks";
import { deriveCollidingModules } from "src/features/designGuidance/deriveCollidingModules";
import { deriveLowResProductionSimulation } from "src/features/lowResProductionSimulation/deriveLowResProductionSimulation";
import { addFinalizeDesignClickCount, designFinalized, dismissVariantDesignSelection, setVariantDesignDefault, simulateDesignComplete, simulateDesignInProgress } from "src/features/workflowState/workflowStateSlice";
import { openDesignWarningsModal, openSelectDesignVariantModal, setCancelContinueModal, setErrorModal, setInfoModal } from "src/features/modal/modalSlice";

type FinalizeDesignProps = Readonly<{
  saveDesignMutation: UseMutationResult<Design, unknown, Design, unknown>,
  designId?: string;
  designVersion?: number;
  didShowSaveError?: boolean;
}>

export const useSimulateDesign = ({saveDesignMutation, designId, designVersion, didShowSaveError}: FinalizeDesignProps) => {
  const {state, dispatch: workspaceDispatch} = useWorkspace();
  const dispatch = useAppDispatch();
  const {customer, workflowState, settings, host} = state;  
  const selectedInverters = useAppSelector(
    (state) => state.selectedEquipment.selectedInverters
  );
  const [searchParams] = useSearchParams();
  const lightmileProjectId = searchParams.get(URLSearchParameterKey.LightmileProjectId) || undefined;
  const interconnectionAppliedDesignId = searchParams.get(URLSearchParameterKey.InterconnectionAppliedDesignId) || undefined;
  const {helpers: loadingScreenHelpers} = useLoadingScreen();
  const queryClient = useQueryClient();
  const design = deriveDesignClass(state);
  const designConstraints = deriveDesignConstraintsClass(state)
  const productionSimulation = deriveProductionSimulationClass(state)
  const { recalculateSetbacks } = useRecalculateSetbacks({ saveDesignMutation });
  const lowResSimulationToUse = deriveLowResProductionSimulation(state);
  const lowResSunHours = lowResSimulationToUse?.getSystemSunHours(design);

  // We are using useState on these id's here to noe violate the rules of hooks when calling useReadOnlyDesignSet. 
  // With this approach we can update these values in simulateMutation.onSuccess and trigger useReadOnlyDesignSet to run again with our ids
  const [mlpeDesignId, setMlpeDesignId] = useState("")
  const [unoptimizedStringDesignId, setUnoptimizedStringDesignId] = useState("")
  const [mlpeProductionSimulationId, setMlpeProductionSimulationId] = useState("")
  const [unoptimizedStringProductionSimulationId, setUnoptimizedStringProductionSimulationId] = useState("")
  useReadOnlyDesignSet({mlpeDesignId, unoptimizedStringDesignId, mlpeProductionSimulationId, unoptimizedStringProductionSimulationId})
  const changeOrderSimulateLogicEnabled = useFlags([
    FlagsmithFeatureEnums.ENABLE_CHANGE_ORDER_DOUBLE_SIMULATE_LOGIC,
  ])[FlagsmithFeatureEnums.ENABLE_CHANGE_ORDER_DOUBLE_SIMULATE_LOGIC].enabled;
  const isChangeOrder = searchParams.has(URLSearchParameterKey.SignedRootId);
  
  /*** ET-1890 removed recalculate before simulate 
  * We are now simulating every time an active roof face changes
  * leaving this commented out instead of removing as we will need something similar 
  * once we switch from assumption based setbacks to design based setbacks 
  ***/

  //const recalculateFireSetbacksEnabled = state.settings.isRecalculateFirecodeSetbacksEnabled;
  // const validateRecalculatedSetbacksInSimulate = async () => {
  //   const recalculateSetbacksResponse = await recalculateSetbacks();
  //   const updatedSiteModel = await repository.get(
  //     SiteModel,
  //     recalculateSetbacksResponse.siteModelId,
  //     recalculateSetbacksResponse.siteModelVersion
  //   );
  //   const collidingModules = deriveCollidingModules({
  //     ...state,
  //     siteModel: updatedSiteModel,
  //   });
  //   if (collidingModules.size > 0) {
  //     dispatch(simulateDesignComplete())
  //     workspaceDispatch({ type: 'simulateDesignComplete' });
  //     dispatch(setErrorModal({ message: "Design failed during setbacks calculation. Please update your module layout to match the updated setbacks." } ))
  //     workspaceDispatch({ type: "setErrorModal", payload: { message: "Design failed during setbacks calculation. Please update your module layout to match the updated setbacks." } });
  //     return false;
  //   } 
  //   return true;
  // }


  /***
   *
   * SIMULATE DESIGN: This is used when iHD is hosted in Offer Experience since we need to separate
   * the simulation and export that are combined into a single operation in useLegacyFinalizeDesign
   *
   * Order of Operations:
   *
   * simulateDesignWithLightmileCore
   *   onError -> display error / reset
   *   onSuccess -> trigger refetch of Design, ProductionSimulation
   * ReFetch Design, ProductionSimulation
   *
   */
  const simulateDesign = async (): Promise<void> => {

    if (lightmileProjectId === undefined) {
      dispatch(setErrorModal({message: "Cannot Simulate Design without LightmileProjectId"}))
      workspaceDispatch({ type: "setErrorModal", payload: {message: "Cannot Simulate Design without LightmileProjectId"}})
      return;
    }
    if (!customer || !designConstraints || !design || !productionSimulation) {
      dispatch(setErrorModal({message: "Cannot Simulate Design without missing aggregates"}))
      workspaceDispatch({ type: "setErrorModal", payload: {message: "Cannot Simulate Design without missing aggregates"}})
      return;
    }
    if (design.version !== 0) {
      dispatch(setErrorModal({message: "Can only Simulate Design when using the current Design version (v0)"}))
      workspaceDispatch({ type: "setErrorModal", payload: {message: "Can only Simulate Design when using the current Design version (v0)"}})
      return;
    }
    if (!designId) {
      dispatch(setErrorModal({message: "Can only Simulate Design with a valid designId"}))
      workspaceDispatch({ type: "setErrorModal", payload: {message: "Can only Simulate Design with a valid designId"}})
    }
    if (state.workflowState.hasDesignInitiatedWithCollidingModules){
      FullStory.event("Design Finalized From Red X Start", {designId: designId, host: host})
    }
    if (didShowSaveError){
      const  serverDesign = await repository.get(Design, designId!, designVersion ?? 0)
      dispatch(setCancelContinueModal({ title: `Design Conflict`,
        message: `The most recent changes were created by ${serverDesign?.createdBy} at ${convertToReadableDateAndTime(serverDesign?.updatedAt, 'h:mma on YYYY-MM-DD')}.
          \nOverwrite will discard all of ${serverDesign?.createdBy}'s changes and publish your changes.
          \nPlease click cancel and close this session if you do not want to overwrite existing changes.
          \nYou may also see this message if you are working on the same session in multiple tabs or windows.`,
        cancelText:'Cancel',
        continueText:'Overwrite',
        onContinue: async () => {
          workspaceDispatch({type: 'resolvePrepareDesignForOverride', payload: serverDesign})
          dispatch(simulateDesignInProgress())
          workspaceDispatch({type: 'simulateDesignInProgress'})
          FullStory.event("Simulating Design with Overwrite", {})
          // if (recalculateFireSetbacksEnabled) {
          //   const isConfigurationStillValid = await validateRecalculatedSetbacksInSimulate();
          //   if (!isConfigurationStillValid) {
          //     return;
          //   }
          // }
          await simulateMutation.mutateAsync();
        },
      }))
      workspaceDispatch({ type: "setCancelContinueModal", payload:
        { title: `Design Conflict`,
          message: `The most recent changes were created by ${serverDesign?.createdBy} at ${convertToReadableDateAndTime(serverDesign?.updatedAt, 'h:mma on YYYY-MM-DD')}.
            \nOverwrite will discard all of ${serverDesign?.createdBy}'s changes and publish your changes.
            \nPlease click cancel and close this session if you do not want to overwrite existing changes.
            \nYou may also see this message if you are working on the same session in multiple tabs or windows.`,
          cancelText:'Cancel',
          continueText:'Overwrite',
          onContinue: async () => {
            workspaceDispatch({type: 'resolvePrepareDesignForOverride', payload: serverDesign})
            dispatch(simulateDesignInProgress())
            workspaceDispatch({type: 'simulateDesignInProgress'})
            FullStory.event("Simulating Design with Overwrite", {})
            // if (recalculateFireSetbacksEnabled) {
            //   const isConfigurationStillValid = await validateRecalculatedSetbacksInSimulate();
            //   if (!isConfigurationStillValid) {
            //     return;
            //   }
            // }
            await simulateMutation.mutateAsync();
          },
        }})
      }
    else{
      dispatch(dismissVariantDesignSelection())
      workspaceDispatch({type: 'dismissVariantDesignSelection'})
      dispatch(simulateDesignInProgress())
      workspaceDispatch({type: 'simulateDesignInProgress'})
      FullStory.event("Simulating Design", {})
      // if (recalculateFireSetbacksEnabled) {
      //   const isConfigurationStillValid = await validateRecalculatedSetbacksInSimulate();
      //   if (!isConfigurationStillValid) {
      //     return;
      //   }
      // }
      await simulateMutation.mutateAsync();
    }
  };

  const runDoubleSimulate = (): boolean => {
    // Do not double simulate with alternate inverter if user has manually selected an inverter
    if(workflowState.hasUserInverterUpdate) return false;      
    // Do not double simulate for change orders  
    if(changeOrderSimulateLogicEnabled){
      return !isChangeOrder
    }        
      return true;
  } 

  const simulateMutation = useMutation(async (): Promise<DesignSimulatedWithLightmileCore> => {
    const signedRootId = state.mostRecentSignedDesign.projectHasSignedDesign ?
      state.mostRecentSignedDesign.signedRootId : null;
    const extractedCustomerData = {
      utility: customer?.utility,
      nemQualification: customer?.nemQualification,
      annualUsagekWh: customer?.annualUsagekWh
    };
    const extractedDesignConstraintsData = {
      acBatteryConstraints: designConstraints?.batteryConstraints.ac,
      sunsimCalculationParameters: designConstraints?.sunsimCalculationParameters
    }
    // We are passing in a version for the setbacks calculator here:
    // v0.0: Legacy setbacks
    // v1.0: Assumption based setbacks
    // v2.0: Full design based setbacks
    const setbacksCalculatorVersion = state.settings.isRecalculateFirecodeSetbacksEnabled ? 1 : 0;

    return await processManagerClient.simulateDesignWithLightmileCoreAsync({
      designId: design!.id,
      lightmileProjectId: lightmileProjectId!,
      productionSimulationId: productionSimulation!.id,
      signedRootId: signedRootId,
      interconnectionAppliedDesignId,
      // Take battery count from design, because we can not tell the count of backupTier - Plus from offer; Plus battery count could be 3 or 4.
      constraintsFromOffer: {batteryCount: design!.batteryCount},
      type: CommandType.SimulateDesignWithLightmileCore,
      effectiveDate: state.mostRecentSignedDesign.effectiveDate,
      alternateInverterDesign: runDoubleSimulate(),
      offerExperience : true,
      isFilter10kLogicEnabled: settings.isFilter10kLogicEnabled,
      utility: extractedCustomerData.utility,
      nemQualification: extractedCustomerData.nemQualification,
      annualUsagekWh: extractedCustomerData.annualUsagekWh,
      acBatteryConstraints: extractedDesignConstraintsData.acBatteryConstraints,
      sunsimCalculationParameters: extractedDesignConstraintsData.sunsimCalculationParameters,
      isRecalculateFirecodeSetbacksEnabled: settings.isRecalculateFirecodeSetbacksEnabled,
      setbacksCalculatorVersion: setbacksCalculatorVersion,
      selectedInverters: selectedInverters,
      lowResSunHours: lowResSunHours,
    });
  }, {
    onMutate: async () => {
      loadingScreenHelpers.addProcess({
        group: LoadingProcessGroups.FINALIZE_DESIGN,
        name: LoadingProcessNames.DESIGN_SIMULATION,
      });
      // ensure design is up-to-date
      if (design!.hasUnsavedChanges) {
        await saveDesignMutation.mutateAsync(design!)
      }
    },
    onSettled: () => {
      loadingScreenHelpers.completeProcess(LoadingProcessNames.DESIGN_SIMULATION);
      dispatch(designFinalized());
      workspaceDispatch({type: "designFinalized"})
    },
    onError: (error: Error) => {
      dispatch(simulateDesignComplete())
      workspaceDispatch({type: 'simulateDesignComplete'});

      // TODO: ET-1899 ticket for improving our error handling in general. 
      // FIXME: We need better ways to handle errors from coming back from lightmile adapter. 
      // Certain errors are expected and are just due to design configuration. The error message should tell the user how to resolve the issue
      const updateBatteryCountSubstring = "Please update products to the required number of batteries"
      const requiresBatteryCountUpdate = error.message.includes(updateBatteryCountSubstring)
      if (requiresBatteryCountUpdate) {
        let parsedErrorMessage = error.message
        try {
          const startJsonIndex = error.message.indexOf("{")
          const errorJsonString = error.message.substring(startJsonIndex)
          parsedErrorMessage = JSON.parse(errorJsonString).message
        } catch (error) {
          console.warn("Failed to parse lightmile-adapter error message")
        }
        const regex = /At least (\d+) batteries are required/;
        const match = error.message.match(regex);
        // Default to 2 as a fallback. It's the most common occurrence
        const newBatteryCount = match && match[1] ? parseInt(match[1]) : 2
        const message = "Failed to finalize the current design. Either reduce the system size by removing modules or update the battery count and try again.\n\n" + parsedErrorMessage
        const cancelContinuePayload = {
          message,
          cancelText: "Do Nothing",
          continueText: `Update Battery Count (${newBatteryCount})`,
          onContinue: () => {
            workspaceDispatch({type: "updateDesignWithBatteryCount", payload: newBatteryCount})
            workspaceDispatch({type: "dismissCancelContinueModal"})
          },
          title: "Design Update Required"
        }
        dispatch(setCancelContinueModal(cancelContinuePayload))
        workspaceDispatch({
          type: "setCancelContinueModal",
          payload: cancelContinuePayload
        })
        // Return early so we don't show the other error modals
        return
      }

      if (!runDoubleSimulate()){

        // Clean errorMessage to show the actionable part of lightmile adapter errors to our users. If we are not showing a Lightmile error display the whole error.message
        const errorMessage = error.message.includes("Lightmile") && error.message.split("message")[2] ?
         `\nAdditional Details from Lightmile : ` + error.message.split("message")[2].replace(/\\n/g,'').replace(/[^a-zA-Z0-9. ]/g,'') : `\nAdditional Details : ` + error.message
        const equipmentIssueMessage = `We could not finalize your design with the selected equipment.\nPlease select additional inverters, modify the design, or modify the equipment and try again.\n`

        dispatch(setInfoModal({
          title: "Selected Equipment Issue",
          message: equipmentIssueMessage + errorMessage }))
        workspaceDispatch({ type: "setInfoModal", payload: {
          title: "Selected Equipment Issue",
          message: equipmentIssueMessage + errorMessage }})
      }
      else {
        dispatch(setErrorModal({
          error, 
          message: `We could not finalize a design with your selected module layout.\n\nLightmile-Core double simulate attempted a design with an ${ProductOptionValue.Custom} inverter and a ${ProductOptionValue.Standard} inverter.`}))
        workspaceDispatch({ type: "setErrorModal", payload: {
          error, 
          message: `We could not finalize a design with your selected module layout.\n\nLightmile-Core double simulate attempted a design with an ${ProductOptionValue.Custom} inverter and a ${ProductOptionValue.Standard} inverter.`}})
      }
    },
    onSuccess: async (designSimulatedEvent: DesignSimulatedWithLightmileCore) => {  
      FullStory.event("Design Simulated With Lightmile Core", {})
      dispatch(addFinalizeDesignClickCount());
      workspaceDispatch({ type: "addFinalizeDesignClickCount" });
      // new workflow when simulateDesignWithLightmileCore returns design variants
      // set variantIds to kick off state update in above useReadOnlyDesignSet call
      if (designSimulatedEvent.variants) {
        await reloadVariantDesignAndProductionSimulation(designSimulatedEvent)
      }
      // old workflow when simulateDesignWithLightmileCore returns single design
      else {
        await reloadDesignAndProductionSimulation(designSimulatedEvent);
      }
    }
  });

  const reloadDesignAndProductionSimulation = async (designSimulatedEvent: DesignSimulatedWithLightmileCore) => {
    loadingScreenHelpers.addProcess({
      group: LoadingProcessGroups.FINALIZE_DESIGN,
      name: LoadingProcessNames.RELOAD_DESIGN,
    });
    const {designId, productionSimulationId, productionSimulationVersion} = designSimulatedEvent;
    const newDesign = await repository.get(Design, designId, 0);
    const newProductionSimulation = await repository.get(ProductionSimulation, productionSimulationId, productionSimulationVersion);
    // We persist the design and production simulation in two ways: in the QueryClient (a global provider in the app
    // that maintains a cache) and in our useContext/useReducer-based workspace state.
    // TODO: this necessity of synchronizing two caches is begging to create bugs. How can we improve? https://sunrun.jira.com/browse/LS-1714
    queryClient.setQueryData(["getDesign", designId], newDesign);
    queryClient.setQueryData(["getProductionSimulation", productionSimulationId], newProductionSimulation);
    console.log('updated inverter', JSON.stringify(newDesign.selectedInverterSpecifications.map(i => `${i.manufacturer} ${i.model}`)))
    workspaceDispatch({type: 'setDesign', payload: newDesign});
    dispatch(setProductionSimulation(newProductionSimulation.getIProductionSimulation()));
    workspaceDispatch({type: 'setProductionSimulation', payload: {simulation:newProductionSimulation, design: newDesign}});
    dispatch(simulateDesignComplete())
    workspaceDispatch({ type: 'simulateDesignComplete' });
    
    // Add 1 to count manually for check, because of dispatch not updating until after
    if (design && workflowState.finalizeDesignClickCount + 1 === 1) {
      const highResSunHours = newProductionSimulation?.getSystemSunHours(design) ?? 0;
      calculateSunHourDiffMetricOutput(lowResSunHours, highResSunHours);
    }

    loadingScreenHelpers.completeProcess(LoadingProcessNames.RELOAD_DESIGN);
    if (deriveDesignWarnings({ ...state, productionSimulation: newProductionSimulation, design: newDesign })?.length > 0) {
      dispatch(openDesignWarningsModal())
      workspaceDispatch({type: 'openDesignWarningsModal'});
    }
  }

  const reloadVariantDesignAndProductionSimulation = async (designSimulatedEvent: any) => {
    try{
      const defaultVariantName = designSimulatedEvent.defaultVariantName
      const {designId : unoptimizedStringDesignId, productionSimulationId : unoptimizedStringProductionSimulationId}  = designSimulatedEvent.variants.find((designVariant:DesignVariant)=>designVariant.designVariantName === DesignVariantName.UnoptimizedString)
      const {designId : mlpeDesignId, productionSimulationId : mlpeProductionSimulationId}  = designSimulatedEvent.variants.find((designVariant:DesignVariant)=>designVariant.designVariantName === DesignVariantName.Mlpe)
      setMlpeDesignId(mlpeDesignId)
      setUnoptimizedStringDesignId(unoptimizedStringDesignId)
      setMlpeProductionSimulationId(mlpeProductionSimulationId)
      setUnoptimizedStringProductionSimulationId(unoptimizedStringProductionSimulationId)
      // this dispatch saves the default inverter selection in state for go to pricing metrics 
      dispatch(setVariantDesignDefault(defaultVariantName))
      workspaceDispatch({type: 'setVariantDesignDefault', payload: defaultVariantName})
      // this dispatch opens the design variant model with generateLightmileDesign's selected defaultVariantName
      dispatch(openSelectDesignVariantModal(defaultVariantName))
      workspaceDispatch({type: 'openSelectDesignVariantModal', payload: defaultVariantName})
      loadingScreenHelpers.completeProcess(LoadingProcessNames.RELOAD_DESIGN);
    }
    catch {
      dispatch(setErrorModal({message: "Error Mapping Design Variants"}))
      workspaceDispatch({ type: "setErrorModal", payload: {message: "Error Mapping Design Variants"}})
    }
  }

  return {
    simulateDesign
  };
};