import {WorkspacePayloadAction, WorkspaceEmptyAction} from "src/types/state-management/action"
import {ModuleOrientation} from "@sunrun/design-tools-domain-model";
import {produce} from "immer";
import {WorkspaceAction, WorkspaceState} from "src/hooks/useWorkspace";
import {createSlice, PayloadAction } from "@reduxjs/toolkit";

// TODO: refactor all the state and actions pertaining to workflow state
//    (this is open/closed, this is displaying/not) to the workflowState slice
//    and keep only user settings here.

type SettingsState = {
  isDesignMenuOpen: boolean
  isInterfaceMinimized: boolean
  isMagneticSnapEnabled: boolean
  isMagneticSlideEnabled: boolean
  isMapImageLayerVisible: boolean
  isModuleSpacingLayerVisible: boolean
  isRoofFacesLayerVisible: boolean
  isModulesLayerVisible: boolean
  isSetbacksLayerVisible: boolean
  isObstructionsLayerVisible: boolean
  isTreesLayerVisible: boolean
  isSunHoursVisible: boolean
  moduleOrientation: ModuleOrientation
  isDesignStatusDialogueVisible: boolean
  enforceDesignRestrictionsForSales: boolean
  temporarilyDisableMagneticSnap: boolean
  isFilter10kLogicEnabled: boolean
  isRecalculateFirecodeSetbacksEnabled: boolean
  parentSetbacksCalculatorVersion: number
};

const initialState: SettingsState = {
  isDesignMenuOpen: false,
  isInterfaceMinimized: false,
  isMagneticSnapEnabled: true,
  isMagneticSlideEnabled: false,
  isMapImageLayerVisible: true,
  isModuleSpacingLayerVisible: false, // FIXME: Remove this hack as part of LS-2321. We should set this back to true 
  isRoofFacesLayerVisible: true,
  isModulesLayerVisible: false, // FIXME: Remove this hack as part of LS-2321. We should set this back to true
  isSetbacksLayerVisible: true,
  isObstructionsLayerVisible: true,
  isTreesLayerVisible: true,
  isSunHoursVisible: true,
  moduleOrientation: ModuleOrientation.Portrait,
  isDesignStatusDialogueVisible: false,
  enforceDesignRestrictionsForSales: false,
  temporarilyDisableMagneticSnap: false,
  isFilter10kLogicEnabled: true,
  isRecalculateFirecodeSetbacksEnabled: false,
  parentSetbacksCalculatorVersion: 0
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    closeDesignMenu: (state: SettingsState) => {
      state.isDesignMenuOpen = false
    },
    openDesignMenu: (state: SettingsState) => {
      state.isDesignMenuOpen = true
    },
    setIsDesignMenuOpen: (state: SettingsState, action: PayloadAction<boolean>) => {
      state.isDesignMenuOpen = action.payload
    },
    setIsDesignStatusDialogueVisible: (state: SettingsState, action: PayloadAction<boolean>) => {
      state.isDesignStatusDialogueVisible = action.payload
    },
    setModuleOrientationSetting: (state: SettingsState, action: PayloadAction<ModuleOrientation>) => {
      state.moduleOrientation = action.payload
    },
    setEnforceDesignRestrictionsForSales: (state: SettingsState, action: PayloadAction<boolean>) => {
      state.enforceDesignRestrictionsForSales = action.payload
    }, 
    setIsModulesLayerVisible: (state: SettingsState, action: PayloadAction<boolean>) => {
      state.isModulesLayerVisible = action.payload
    },
    setIsModuleSpacingLayerVisible: (state: SettingsState, action: PayloadAction<boolean>) => {
      state.isModuleSpacingLayerVisible = false
    },
    toggleIsDesignMenuOpen: (state: SettingsState) => {
      state.isDesignMenuOpen = !state.isDesignMenuOpen
    },
    toggleIsInterfaceMinimized: (state: SettingsState) => {
      state.isInterfaceMinimized = !state.isInterfaceMinimized
    },
    toggleIsMagneticSnapEnabled: (state: SettingsState) => {
      state.isMagneticSnapEnabled = !state.isMagneticSnapEnabled
    },
    toggleIsMagneticSlideEnabled: (state: SettingsState) => {
      state.isMagneticSlideEnabled = !state.isMagneticSlideEnabled
    }, 
    toggleIsSunHoursVisible: (state: SettingsState) => {
      state.isSunHoursVisible = !state.isSunHoursVisible
    },
    toggleIsMapImageLayerVisible: (state: SettingsState) => {
      state.isMapImageLayerVisible = !state.isMapImageLayerVisible
    },
    toggleIsModuleSpacingLayerVisible: (state: SettingsState) => {
      state.isModuleSpacingLayerVisible = !state.isModuleSpacingLayerVisible
    },
    toggleIsRoofFacesLayerVisible: (state: SettingsState) => {
      state.isRoofFacesLayerVisible = !state.isRoofFacesLayerVisible
    },
    toggleIsModulesLayerVisible: (state: SettingsState) => {
      state.isModulesLayerVisible = !state.isModulesLayerVisible
    },
    toggleIsSetbacksLayerVisible: (state: SettingsState) => {
      state.isSetbacksLayerVisible = !state.isSetbacksLayerVisible
    },
    toggleIsObstructionsLayerVisible: (state: SettingsState) => {
      state.isObstructionsLayerVisible = !state.isObstructionsLayerVisible
    },
    toggleIsTreesLayerVisible: (state: SettingsState) => {
      state.isTreesLayerVisible = !state.isTreesLayerVisible
    },
    toggleModuleOrientationSetting: (state: SettingsState) => {
      if (state.moduleOrientation === ModuleOrientation.Portrait) {
        state.moduleOrientation = ModuleOrientation.Landscape
      } else {
        state.moduleOrientation = ModuleOrientation.Portrait
      }
    },
    toggleIsFilter10kLogicEnabled: (state: SettingsState) => {
      state.isFilter10kLogicEnabled = !state.isFilter10kLogicEnabled
    },
    setIsRecalculateFirecodeSetbacksEnabled: (state: SettingsState, action: PayloadAction<boolean>) => {
      state.isRecalculateFirecodeSetbacksEnabled = action.payload
    },
    setParentSetbacksCalculatorVersion: (state: SettingsState, action: PayloadAction<number>) => {
      state.parentSetbacksCalculatorVersion = action.payload
    },
  }
});

export const { 
  closeDesignMenu, 
  openDesignMenu,
  setIsDesignMenuOpen,
  setIsDesignStatusDialogueVisible,
  setModuleOrientationSetting,
  setEnforceDesignRestrictionsForSales,
  setIsModulesLayerVisible,
  setIsModuleSpacingLayerVisible,
  toggleIsDesignMenuOpen,
  toggleIsInterfaceMinimized,
  toggleIsMagneticSnapEnabled,
  toggleIsMagneticSlideEnabled,
  toggleIsSunHoursVisible,
  toggleIsMapImageLayerVisible,
  toggleIsModuleSpacingLayerVisible,
  toggleIsRoofFacesLayerVisible,
  toggleIsModulesLayerVisible,
  toggleIsSetbacksLayerVisible,
  toggleIsObstructionsLayerVisible,
  toggleIsTreesLayerVisible,
  toggleModuleOrientationSetting,
  toggleIsFilter10kLogicEnabled,
  setIsRecalculateFirecodeSetbacksEnabled,
  setParentSetbacksCalculatorVersion,
} = settingsSlice.actions
export default settingsSlice.reducer

// TODO: Remove the following and transition fully from WorkspaceState to RTK store

export type SettingsWorkspaceAction =
  | WorkspaceEmptyAction<'toggleIsSunHoursVisible'>
  | WorkspaceEmptyAction<'toggleIsMapImageLayerVisible'>
  | WorkspaceEmptyAction<'toggleIsModuleSpacingLayerVisible'>
  | WorkspaceEmptyAction<'toggleIsRoofFacesLayerVisible'>
  | WorkspaceEmptyAction<'toggleIsModulesLayerVisible'>
  | WorkspaceEmptyAction<'toggleIsSetbacksLayerVisible'>
  | WorkspaceEmptyAction<'toggleIsObstructionsLayerVisible'>
  | WorkspaceEmptyAction<'toggleIsTreesLayerVisible'>
  | WorkspaceEmptyAction<'toggleIsDesignMenuOpen'>
  | WorkspaceEmptyAction<'closeDesignMenu'>
  | WorkspaceEmptyAction<'openDesignMenu'>
  | WorkspaceEmptyAction<'toggleIsInterfaceMinimized'>
  | WorkspaceEmptyAction<'toggleIsMagneticSnapEnabled'>
  | WorkspaceEmptyAction<'toggleIsMagneticSlideEnabled'>
  | WorkspaceEmptyAction<'toggleModuleOrientationSetting'>
  | WorkspaceEmptyAction<'toggleIsFilter10kLogicEnabled'>
  | WorkspacePayloadAction<ModuleOrientation, 'setModuleOrientationSetting'>
  | WorkspacePayloadAction<boolean, 'setEnforceDesignRestrictionsForSales'>
  | WorkspacePayloadAction<boolean, 'setIsHotkeyDescriptionsOpen'>  // No implementation
  | WorkspacePayloadAction<boolean, 'setIsDesignMenuOpen'>
  | WorkspacePayloadAction<boolean, 'setIsDesignStatusDialogueVisible'>
  | WorkspacePayloadAction<boolean, 'setIsModulesLayerVisible'>
  | WorkspacePayloadAction<boolean, 'setIsModuleSpacingLayerVisible'>
  | WorkspacePayloadAction<boolean, 'setIsRecalculateFirecodeSetbacksEnabled'>


export type Settings = {
  isDesignMenuOpen: boolean
  isInterfaceMinimized: boolean
  isMagneticSnapEnabled: boolean
  isMagneticSlideEnabled: boolean
  isMapImageLayerVisible: boolean
  isModuleSpacingLayerVisible: boolean
  isRoofFacesLayerVisible: boolean
  isModulesLayerVisible: boolean
  isSetbacksLayerVisible: boolean
  isObstructionsLayerVisible: boolean
  isTreesLayerVisible: boolean
  isSunHoursVisible: boolean
  moduleOrientation: ModuleOrientation
  isDesignStatusDialogueVisible: boolean
  enforceDesignRestrictionsForSales: boolean
  temporarilyDisableMagneticSnap: boolean
  isFilter10kLogicEnabled: boolean
  isRecalculateFirecodeSetbacksEnabled: boolean
}

export const settingsInitialState: Settings = {
  isDesignMenuOpen: false,
  isInterfaceMinimized: false,
  isMagneticSnapEnabled: true,
  isMagneticSlideEnabled: false,
  isMapImageLayerVisible: true,
  isModuleSpacingLayerVisible: false, // FIXME: Remove this hack as part of LS-2321. We should set this back to true 
  isRoofFacesLayerVisible: true,
  isModulesLayerVisible: false, // FIXME: Remove this hack as part of LS-2321. We should set this back to true
  isSetbacksLayerVisible: true,
  isObstructionsLayerVisible: true,
  isTreesLayerVisible: true,
  isSunHoursVisible: true,
  moduleOrientation: ModuleOrientation.Portrait,
  isDesignStatusDialogueVisible: false,
  enforceDesignRestrictionsForSales: false,
  temporarilyDisableMagneticSnap: false,
  isFilter10kLogicEnabled: true,
  isRecalculateFirecodeSetbacksEnabled: false
}

export const selectSettingsEnforceDesignRestrictionsForSales = (state: WorkspaceState) => state.settings.enforceDesignRestrictionsForSales

export const settingsWorkspaceReducer = (state: WorkspaceState, action: WorkspaceAction): Settings => {
  switch (action.type) {
    case "closeDesignMenu": {
      return produce(state.settings, draft => {
        draft.isDesignMenuOpen = false
      })
    }
    case "openDesignMenu": {
      return produce(state.settings, draft => {
        draft.isDesignMenuOpen = true
      })
    }
    case "setIsDesignMenuOpen": {
      return produce(state.settings, draft => {
        draft.isDesignMenuOpen = action.payload
      })
    }
    case "setIsDesignStatusDialogueVisible": {
      return produce(state.settings, draft => {
        draft.isDesignStatusDialogueVisible = action.payload
      })
    }
    case 'setModuleOrientationSetting': {
      return produce(state.settings, draft => {
        draft.moduleOrientation = action.payload
      })
    }
    case "setEnforceDesignRestrictionsForSales": {
      return produce(state.settings, draft => {
        draft.enforceDesignRestrictionsForSales = action.payload
      })
    }
    case "setIsModulesLayerVisible": {
      return produce(state.settings, draft => {
        draft.isModulesLayerVisible = action.payload
      })
    }
    case "setIsModuleSpacingLayerVisible": {
      return produce(state.settings, draft => {
        draft.isModuleSpacingLayerVisible = action.payload
      })
    }
    case "setIsRecalculateFirecodeSetbacksEnabled": {
      return produce(state.settings, draft => {
        draft.isRecalculateFirecodeSetbacksEnabled = action.payload
      })
    }
    case "toggleIsDesignMenuOpen": {
      return produce(state.settings, draft => {
        draft.isDesignMenuOpen = !state.settings.isDesignMenuOpen
      })
    }
    case "toggleIsInterfaceMinimized": {
      return produce(state.settings, draft => {
        draft.isInterfaceMinimized = !state.settings.isInterfaceMinimized
      })
    }
    case "toggleIsMagneticSnapEnabled": {
      return produce(state.settings, draft => {
        draft.isMagneticSnapEnabled = !state.settings.isMagneticSnapEnabled
      })
    }
    case "toggleIsMagneticSlideEnabled": {
      return produce(state.settings, draft => {
        draft.isMagneticSlideEnabled = !state.settings.isMagneticSlideEnabled
      })
    }
    case 'toggleIsSunHoursVisible': {
      return produce(state.settings, draft => {
        draft.isSunHoursVisible = !state.settings.isSunHoursVisible
      })
    }
    case 'toggleIsMapImageLayerVisible': {
      return produce(state.settings, draft => {
        draft.isMapImageLayerVisible = !state.settings.isMapImageLayerVisible
      })
    }
    case 'toggleIsModuleSpacingLayerVisible': {
      return produce(state.settings, draft => {
        draft.isModuleSpacingLayerVisible = !state.settings.isModuleSpacingLayerVisible
      })
    }
    case 'toggleIsRoofFacesLayerVisible': {
      return produce(state.settings, draft => {
        draft.isRoofFacesLayerVisible = !state.settings.isRoofFacesLayerVisible
      })
    }
    case 'toggleIsModulesLayerVisible': {
      return produce(state.settings, draft => {
        draft.isModulesLayerVisible = !state.settings.isModulesLayerVisible
      })
    }
    case 'toggleIsSetbacksLayerVisible': {
      return produce(state.settings, draft => {
        draft.isSetbacksLayerVisible = !state.settings.isSetbacksLayerVisible
      })
    }
    case 'toggleIsObstructionsLayerVisible': {
      return produce(state.settings, draft => {
        draft.isObstructionsLayerVisible = !state.settings.isObstructionsLayerVisible
      })
    }
    case 'toggleIsTreesLayerVisible': {
      return produce(state.settings, draft => {
        draft.isTreesLayerVisible = !state.settings.isTreesLayerVisible
      })
    }
    case "toggleModuleOrientationSetting": {
      return produce(state.settings, draft => {
        if (state.settings.moduleOrientation === ModuleOrientation.Portrait) {
          draft.moduleOrientation = ModuleOrientation.Landscape
        } else {
          draft.moduleOrientation = ModuleOrientation.Portrait
        }
      })
    }
    case "toggleIsFilter10kLogicEnabled": {
      return produce(state.settings, draft => {
        draft.isFilter10kLogicEnabled = !state.settings.isFilter10kLogicEnabled
      })
    }
    default: {
      return state.settings
    }
  }
}
