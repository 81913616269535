import { ArrayUtil } from "@sunrun/design-tools-functional";
import { G2Rectangle } from "./rectangle";

//a row of rectangles which are connected together
export class G2RectangleRow {
  readonly rects: G2Rectangle[];

  private constructor(
    rects: G2Rectangle[]
  ) {
    this.rects = rects
  }

  //this detects whether rects are in a row, so we always create a rectangel row this way
  //rects can be passed in unsorted along xAxis, we will sort it for you
  public static fromRectangles(rects: G2Rectangle[], allowMixedOrientation: boolean = false): G2RectangleRow | null {
    if (rects.length == 0) {
      //allow empty rctangleRow, its legit
      return new G2RectangleRow(rects)
    } else {
      const rectSortedAlongXAxis = (a: G2Rectangle, b: G2Rectangle) => {
        return (a.lowerLeft.x < b.lowerLeft.x) ? -Math.sign(a.xAxis().x) : Math.sign(a.xAxis().x)
      }
      const sortedRects = [...rects].sort(rectSortedAlongXAxis)
      const rectPairs = ArrayUtil.zip(sortedRects.slice(0,sortedRects.length-1), sortedRects.slice(1))
      const rectPairsEqual = rectPairs.map(rectPair=>{
        const [leftRect, rightRect] = rectPair
        //this condition will allow mixed orientation in a row
        if (allowMixedOrientation) {
          return leftRect.isPortrait() === rightRect!.isPortrait()?
            leftRect.toPolygon().containsPoint(rightRect!.moveLeft().toPolygon().centroid()) :
            leftRect.rotateCounterClockwise().toPolygon().containsPoint(rightRect!.moveLeft().toPolygon().centroid())
        } else {
          //only allow rect with same orientation in a row
          return leftRect.equals(rightRect!.moveLeft())
        }
      })
      if (rectPairsEqual.every(it=>it)) {
        return new G2RectangleRow(sortedRects);
      } else {
        console.warn("rectangle row must have all rectangles connected to each other at straight left/right relationship")
        return null
      }
    }
  }

  //get the one middle rect if there are odd number of rects in the row
  //or the two middle rects if there are even number of rects in the row
  private middleRects(): G2Rectangle[] {
    if (this.rects.length % 2 == 0) {
      return [
        this.rects[this.rects.length / 2 - 1],
        this.rects[this.rects.length / 2]
      ]
    } else {
      return [
        this.rects[Math.floor(this.rects.length / 2)]
      ]
    }
  }

  private middleRectsIndexes(): number[] {
    return this.middleRects().map(middleRect => {
      return this.rects.findIndex(rect => rect.equals(middleRect))
    })
  }

  //eg: when row has 11 rects, middleRectsIndexes=[5], leftRectsIndexes = [0,1,2,3,4], rightRectsIndexes = [6,7,8,9,10]
  //eg: when row has 12 rects, middleRectsIndexes=[5,6], leftRectsIndexes = [0,1,2,3,4], rightRectsIndexes = [7,8,9,10,11]
  private leftRects(): G2Rectangle[] {
    const middleRectsIndexes = this.middleRectsIndexes()
    return ArrayUtil.consecutiveIntegers(middleRectsIndexes[0]).map(index=>this.rects[index])
  }

  private rightRects(): G2Rectangle[] {
    const middleRectsIndexes = this.middleRectsIndexes()
    return ArrayUtil.consecutiveIntegers(middleRectsIndexes[0],middleRectsIndexes[0] + middleRectsIndexes.length).map(index=>this.rects[index])
  }

  // rotate every rectangle in place and even out or squash back in to form another tight-knit rectangle row
  // you are able to preprocess the rect before rotation and postprocess the rect after rotation, this makes padding
  // different rectangular buffer possible
  public rotateInPlace(
    portraitPreRotateFunc: (rect: G2Rectangle) => G2Rectangle = (rect) => rect,
    portraitPostRotateFunc: (rect: G2Rectangle) => G2Rectangle = (rect) => rect,
    landscapePreRotateFunc: (rect: G2Rectangle) => G2Rectangle = (rect) => rect,
    landscapePostRotateFunc: (rect: G2Rectangle) => G2Rectangle = (rect) => rect
  ): G2RectangleRow {
    const middleRects = this.middleRects()
    const leftRects = this.leftRects()
    const rightRects = this.rightRects()

    const shrinkThenRotateThenEnlarge = (rect: G2Rectangle) => {
      return rect.isPortrait() ?
          portraitPostRotateFunc(portraitPreRotateFunc(rect).rotateCounterClockwise()) :
          landscapePostRotateFunc(landscapePreRotateFunc(rect).rotateCounterClockwise())
    }

    //middleRects merely rotated, later on it also needs to be shifted
    const middleRectsRotated = middleRects.map((rect)=>{
      return shrinkThenRotateThenEnlarge(rect)
    })

    const shiftDistance = Math.abs((middleRects[0].lowerSideLength() - middleRectsRotated[0].lowerSideLength())/ 2.0)

    //here we ignore case where 2 middleRects have different orientation
    const middleRectsRotatedAndShifted = middleRects.length == 1 ?
      [middleRectsRotated[0]] :
      (
        middleRects[0].isPortrait() ?
        //rotate from portrait to landscape
        [
          middleRectsRotated[0].moveLeftBy(shiftDistance/middleRectsRotated[0].lowerSideLength()),
          middleRectsRotated[1].moveRightBy(shiftDistance/middleRectsRotated[0].lowerSideLength())
        ] :
        //rotate from landscape to portrait
        [
          middleRectsRotated[0].moveRightBy(shiftDistance/middleRectsRotated[0].lowerSideLength()),
          middleRectsRotated[1].moveLeftBy(shiftDistance/middleRectsRotated[0].lowerSideLength())
        ]
      )
    const leftRectsRotatedAndShifted = ArrayUtil.foldl(
      //f
      (rect, acc) => [
        shrinkThenRotateThenEnlarge(rect).moveLowerRightTo(
          acc.length === 0 ? middleRectsRotatedAndShifted[0].lowerLeft : acc[0].lowerLeft
        )
      ].concat(acc),
      //acc
      [] as G2Rectangle[],
      //rects
      leftRects.reverse()
    )
    const rightRectsRotatedAndShifted = ArrayUtil.foldl(
      //f
      (rect, acc) => acc.concat([
        shrinkThenRotateThenEnlarge(rect).moveLowerLeftTo(
          acc.length === 0 ? middleRectsRotatedAndShifted[middleRectsRotatedAndShifted.length - 1].lowerRight : acc[acc.length-1].lowerRight
        )
      ]),
      //acc
      [] as G2Rectangle[],
      //rects
      rightRects
    )

    return new G2RectangleRow(
      leftRectsRotatedAndShifted.concat(middleRectsRotatedAndShifted).concat(rightRectsRotatedAndShifted)
    )
  }

  public equals(rectRow: G2RectangleRow): boolean {
    if (this.rects.length === rectRow.rects.length) {
      return this.rects.map((rect, index) => rect.equals(rectRow.rects[index])).every(it=>it)
    } else {
      return false
    }
  }
}
