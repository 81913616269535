import { roundToDecimalPlaces } from "./util"
import { G2Vector2D } from "./vector2D";
import { G2Quaternion } from "./quarternion";
import { G2Rectangle } from "./rectangle";
// only for converting back to vector3D for backward compatibility
import { Vector3D } from "../vectors/vector3D";
import { degreesFromRadians } from "./util";

import type { Point } from "geojson";

// a vector has a magnitude and a direction
// it actually means from origin to (x,y,z)
export class G2Vector3D  {
  readonly x: number;
  readonly y: number;
  readonly z: number;

  constructor(x: number, y: number, z: number) {
    this.x = x;
    this.y = y;
    this.z = z;
  }

  public dot(vector: G2Vector3D): number {
    return this.x * vector.x + this.y * vector.y + this.z * vector.z
  }

  // calculate vector perpendicular to 2 vectors: this and vector, using right hand rule
  public cross(vector: G2Vector3D): G2Vector3D {
    const {x:a1,y:a2,z:a3} = this;
    const {x:b1,y:b2,z:b3} = vector;
    return new G2Vector3D(a2*b3-a3*b2, a3*b1-a1*b3,a1*b2-a2*b1);
  }

  public equals(otherVector3D: G2Vector3D, toDecimalPlaces: number = 2) {
    return roundToDecimalPlaces(this.x, toDecimalPlaces) == roundToDecimalPlaces(otherVector3D.x, toDecimalPlaces) &&
        roundToDecimalPlaces(this.y, toDecimalPlaces) == roundToDecimalPlaces(otherVector3D.y, toDecimalPlaces) &&
        roundToDecimalPlaces(this.z, toDecimalPlaces) == roundToDecimalPlaces(otherVector3D.z, toDecimalPlaces)
  }

  public normalize() {
    const norm = Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z);
    return new G2Vector3D(this.x/norm, this.y/norm, this.z/norm)
  }

  public length():number {
    return Math.sqrt(
        this.dot(this)
    );
  }

  public dist(vector: G2Vector3D): number {
    const xDistance = this.x - vector.x
    const yDistance = this.y - vector.y
    const zDistance = this.z - vector.z
    return Math.sqrt(xDistance * xDistance + yDistance * yDistance + zDistance * zDistance)
  }

  public add(vector3D: G2Vector3D){
    const {x:a1,y:a2,z:a3} = this;
    const {x:b1,y:b2,z:b3} = vector3D;
    return new G2Vector3D(a1+b1,a2+b2,a3+b3);
  }

  public sub(vector3D: G2Vector3D){
    const {x:a1,y:a2,z:a3} = this;
    const {x:b1,y:b2,z:b3} = vector3D;
    return new G2Vector3D(a1-b1,a2-b2,a3-b3);
  }

  public scale(s:number){
    return new G2Vector3D(this.x*s,this.y*s,this.z*s);
  }

  // return the length of a's shadow in the direction of b
  public scalarProjectTo(vector:G2Vector3D): number {
    return this.dot(vector.normalize())
  }

  public projectTo(vector: G2Vector3D): G2Vector3D {
    return vector.normalize().scale(this.scalarProjectTo(vector))
  }

  public midpoint(vector:G2Vector3D): G2Vector3D {
    return new G2Vector3D((this.x + vector.x)/2, (this.y + vector.y)/2, (this.z + vector.z)/2)
  }

  //get azimuth of the vector projected to 2D, the clockwise angle from north
  public getAzimuthInDegrees(): number {
    const north = new G2Vector3D(0, 1, 0);
    const azimuthVector3D = this.toVector2D().toVector3D()   //set z to 0
    const angleInRadians = north.anglesCounterClockwiseInRadiansToVectorAroundAxis(azimuthVector3D, g2ZAxis)
    const azimuthInRadians = angleInRadians > 0? 2*Math.PI - angleInRadians : -angleInRadians
    return degreesFromRadians(azimuthInRadians)
  }

  public facingSouthernHemisphere(): boolean {
    const azimuthDegrees = this.getAzimuthInDegrees()
    return azimuthDegrees > 90 && azimuthDegrees < 270
  }

  //angles between 2 vector always lie between [0, Math.Pi], notice this is different from anglesCounterClockWiseInRadiansToVectorAroundAxis
  public anglesInRadiansToVector(vector: G2Vector3D): number {
    return Math.acos(this.dot(vector)/(this.length() * vector.length()))
  }

  //this is the reverse of rotateCounterClockWiseByRadiansAroundAxis
  //https://math.stackexchange.com/questions/2548811/find-an-angle-to-rotate-a-vector-around-a-ray-so-that-the-vector-gets-as-close-a
  //for 0 to 180 ccw rotation, it returns positive number 0 to pi
  //for 180 to360 ccw rotation, it returns negative number -pi to 0
  public anglesCounterClockwiseInRadiansToVectorAroundAxis(vector: G2Vector3D, aroundAxis: G2Vector3D): number {
    const thisNormalized = this.normalize()
    const vectNormalized = vector.normalize()
    const aroundAxisNormalized = aroundAxis.normalize()
    const c = thisNormalized.sub(aroundAxisNormalized.scale(thisNormalized.dot(aroundAxisNormalized)))
    //e is a unit vector perpendicular to aroundAxisNormalized in aroundAxisNormalized,thisVector-plane
    const e = c.scale(1/c.length())
    const f = aroundAxisNormalized.cross(e)
    return Math.atan2(vectNormalized.dot(f), vectNormalized.dot(e))
  }

  //notice this is the only place Quaternion is used, and this complex math term is only used in Geometry domain by mathematicians
  //use your righthand, thumb pointing to direction of aroundAxis, the other 4 fingers rotate counterClockwise
  public rotateCounterClockwiseByRadiansAroundAxis(byRadians: number, aroundAxis: G2Vector3D, origin: G2Vector3D = new G2Vector3D(0,0,0)): G2Vector3D {
    // prepare a quaternion to rotate around zAxis for radiansCounterclockwise
    const rotClockwise = G2Quaternion.fromAxisAngle(aroundAxis, byRadians);
    // use the quaternion to rotate this vector
    const rotatedVector = rotClockwise.rotateVector(this.sub(origin)).add(origin);
    return rotatedVector
  }

  static fromNumArray(arr:number[]): G2Vector3D {
    return new G2Vector3D(arr[0],arr[1],arr[2])
  }

  public toNumArray(): number[] {
    return [this.x, this.y, this.z]
  }

  public toVector2D(): G2Vector2D {
    return new G2Vector2D(this.x, this.y)
  }

  //create a w*h Rectangle using this vector as middle
  //notice how easy we can turn any centroid into a rectangle
  public toRectangle(
    widthNormalVector: G2Vector3D,
    heightNormalVector: G2Vector3D,
    width: number,
    height: number,
    isLandscape: boolean
  ): G2Rectangle {
    if (isLandscape) {
      const w = width;
      width = height;
      height = w;
    }
    const right = widthNormalVector.scale(width / 2)
    const up = heightNormalVector.scale(height / 2)
    const left = widthNormalVector.scale(-width / 2)
    const down = heightNormalVector.scale(-height / 2)
    // be sure to return a polygon wound counter-clockwise! and always construct a rectangle with vertices
    // in order like: lowerLeft, lowerRight, upperRight, upperLeft
    return new G2Rectangle(
      this.add(left).add(down),
      this.add(right).add(down),
      this.add(right).add(up),
      this.add(left).add(up)
    )
  }

  //right hand rule, if A,B,C is in counter clock wise order like below:
  //                            .(C)
  //
  //              .(A)
  //                         .(B)
  static normalOfPoints(A: G2Vector3D, B: G2Vector3D, C: G2Vector3D): G2Vector3D {
    const BA = A.sub(B);     //vector pointing from B to A
    const BC = C.sub(B);     //vector pointing from B to C
    //use the righthand with 4 fingers wrapping from BC to BA, the thumb will point away from screen toward the programmer
    return BC.normalize().cross(BA.normalize()).normalize();
  }

  public parallelAndSameDirTo(anotherVector: G2Vector3D): boolean {
    const x = (this.length() * anotherVector.length()) / this.dot(anotherVector)
    return roundToDecimalPlaces(x) == 1
  }

  public parallelAndOppositeDirTo(anotherVector: G2Vector3D): boolean {
    const x = (this.length() * anotherVector.length()) / this.dot(anotherVector)
    return roundToDecimalPlaces(x) == -1
  }

  public parallelTo(anotherVector: G2Vector3D): boolean {
    const a = this.parallelAndSameDirTo(anotherVector)
    const b = this.parallelAndOppositeDirTo(anotherVector)
    return a || b
  }

  public isZeroVector(): boolean {
    return this.equals(
      new G2Vector3D(0,0,0)
    )
  }

  //toVector3D in old geometry package
  public toVector3D(): Vector3D {
    return new Vector3D(
      this.x,this.y,this.z
    )
  }

  static fromGeoJson(point: Point): G2Vector3D {
    return new G2Vector3D(
        point.coordinates[0],point.coordinates[1],point.coordinates[2]
    )
  }

  public toString(): string {
    return "(x: "+roundToDecimalPlaces(this.x)+" , y: " +roundToDecimalPlaces(this.y)+ ", z: " +roundToDecimalPlaces(this.z)+ ")"
  }
}

export const g2XAxis = new G2Vector3D(1, 0, 0)
export const g2YAxis = new G2Vector3D(0, 1, 0)
export const g2ZAxis = new G2Vector3D(0, 0, 1)
