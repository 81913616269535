import {
  ISolarResource,
  LightmileProjectImported,
  SolarResource,
} from "@sunrun/design-tools-domain-model";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { WorkspaceAction, WorkspaceState } from "src/hooks/useWorkspace";
import { WorkspacePayloadAction } from "src/types/state-management/action";

// Redux state
export type SolarResourceExpandedState = {
  value?: ISolarResource;
  id?: string;
  version?: number;
  fetchError?: boolean;
};

export const initialState: SolarResourceExpandedState = {
  value: undefined,
  id: undefined,
  version: undefined,
  fetchError: undefined,
};

export const solarResourceExpandedSlice = createSlice({
  name: "solarResourceExpanded",
  initialState,
  reducers: {
    setSolarResourceExpanded: (
      state: SolarResourceExpandedState,
      action: PayloadAction<ISolarResource>
    ) => {
      state.value = action.payload;
    },
    setSolarResourceExpandedIdAndVersion: (
      state: SolarResourceExpandedState,
      action: PayloadAction<LightmileProjectImported>
    ) => {
      state.id = action.payload.solarResourceExpandedId || undefined;
      state.version = action.payload.solarResourceExpandedVersion || undefined
      state.fetchError = false;
    },
    setSolarResourceExpandedFetchError: (state: SolarResourceExpandedState) => {
      state.fetchError = true;
    },
  },
});

// Adding to workspace state to make it easier to derive solarResource using current deriveSolarResourceClass selector
// TODO: Remove the following and transition fully from WorkspaceState to RTK store
export type SolarResourceExpandedWorkspaceAction = WorkspacePayloadAction<SolarResource, 'setSolarResourceExpanded'>

export const selectSolarResource = (state: WorkspaceState) => state.solarResource;

export const solarResourceExpandedWorkspaceReducer = (state: WorkspaceState, action: WorkspaceAction): ISolarResource | undefined => {
  switch (action.type) {
    case 'setSolarResourceExpanded': {
      return action.payload.getISolarResource()
    }
    default: {
      return state.solarResourceExpanded
    }
  }
}

export const {
  setSolarResourceExpanded,
  setSolarResourceExpandedIdAndVersion,
  setSolarResourceExpandedFetchError,
} = solarResourceExpandedSlice.actions;
export default solarResourceExpandedSlice.reducer;

export const selectSolarResourceExpanded = (
  state: WorkspaceState,  
) => {
  return state.solarResourceExpanded;  
};
