import { useAppDispatch, useAppSelector } from "src/store";
import { ModalLayout } from "./components/ModalLayout";
import { closeConfigureManualBatteriesModal } from "src/features/modal/modalSlice";
import {
  Alert,
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { EQUIPMENT_MODAL_SPACING } from ".";
import { useEffect, useState } from "react";
import { useWorkspace } from "src/hooks/useWorkspace";
import { deriveDesignClass } from "src/features/design/designSlice";
import {
  Battery,
  BATTERY_MODEL_POWERWALL_3_BASE_PACK,
  BATTERY_MODEL_POWERWALL_3_BASE_PACK_AC_COUPLED,
  BATTERY_MODEL_POWERWALL_3_EXPANSION,
  BatteryMode,
  BatterySpecification,
  Design,
  getPowerwall3EquipmentDisplayName,
} from "@sunrun/design-tools-domain-model";
import { Add, DeleteOutline, LockOutlined } from "@material-ui/icons";
import { setHasManualBatteryConfig } from "src/features/workflowState/workflowStateSlice";

export type ConfigureManualBatteriesProps = {
  selectedMidIds: string[];
  selectedBatteryIds: string[];
  batteryCount: number;
  maxAllowedBatteriesCount: number;
};

export const ConfigureManualBatteriesModal = (
  props: ConfigureManualBatteriesProps
) => {
  const { selectedMidIds, selectedBatteryIds, maxAllowedBatteriesCount } = props;
  const isConfigureManualBatteriesModalOpen = useAppSelector(
    (state) => state.modal.isConfigureManualBatteriesModalOpen
  );
  const keystoneEquipment = useAppSelector(state => state.availableEquipment.keystoneEquipment);
  const dispatch = useAppDispatch();
  const { state, dispatch: workspaceDispatch } = useWorkspace();
  const design = deriveDesignClass(state);
  const [batterySpecs, setBatterySpecs] = useState<BatterySpecification[]>();
  const [batteries, setBatteries] = useState<Battery[]>();
  const [batteryCount, setBatteryCount] = useState<number>(props.batteryCount);

  const handleClose = () => {
    dispatch(closeConfigureManualBatteriesModal());
  };

  const handleContinue = () => {
    if (batteries) {
      workspaceDispatch({
        type: "updateDesignWithBatteryConfiguration",
        payload: {
          selectedMidIds,
          selectedBatterySpecificationIds: selectedBatteryIds,
          batteries: batteries,
          keystoneEquipment,
          isFilter10kLogicEnabled: state.settings.isFilter10kLogicEnabled
        },
      });
      workspaceDispatch({ type: "setHasManualBatteryConfig", payload: true });
      dispatch(setHasManualBatteryConfig(true));
      workspaceDispatch({ type: "closeSelectStorageModal" });
      dispatch(closeConfigureManualBatteriesModal());
    }
    console.error("Unable to manually configure batteries");
  };

  useEffect(() => {
    if (design) {
      const batterySpecs: BatterySpecification[] =
        (selectedBatteryIds
          ?.map((id) => design.getBatterySpec(id))
          ?.filter((spec) => spec !== undefined) as BatterySpecification[]) ??
        [];
      const designWithDefaultConfig = design.selectAndUpdateBatteries(
        selectedBatteryIds,
        undefined,
        batteryCount
      );
      setBatterySpecs(batterySpecs);
      // Sort the batteries so that the base packs are at the top of the list.
      // This also ensures that when we lock the first battery on the list, that it
      // is a PW3 Base Pack
      const basePackSpecId = batterySpecs.find(
        (spec) =>
          spec.model === BATTERY_MODEL_POWERWALL_3_BASE_PACK ||
          spec.model === BATTERY_MODEL_POWERWALL_3_BASE_PACK_AC_COUPLED
      )?.id;
      const sortedBatteries = [...designWithDefaultConfig.batteries].sort(
        (a, b) => {
          if (a.properties.batterySpecificationId === basePackSpecId) return -1;
          if (b.properties.batterySpecificationId === basePackSpecId) return 1;
          return 0;
        }
      );
      setBatteries(sortedBatteries);
    }
  }, [selectedBatteryIds]);

  const handleBatterySelectionChange = (
    batteryId: string,
    newSpecId: string
  ) => {
    const newBatteryConfig = batteries?.map((battery) =>
      battery.id === batteryId
        ? {
            ...battery,
            properties: {
              ...battery.properties,
              batterySpecificationId: newSpecId,
            },
          }
        : battery
    );
    setBatteries(newBatteryConfig);
  };

  const handleRemoveBattery = (batteryId: string) => {
    const newBatteryConfig = batteries?.filter(
      (battery) => battery.id !== batteryId
    );
    setBatteries(newBatteryConfig);
    setBatteryCount(newBatteryConfig?.length ?? props.batteryCount);
  };

  const handleAddBattery = () => {
    if (batteries && batterySpecs && batterySpecs.length) {
      const expansionBatterySpec = batterySpecs.find(
        (spec) => spec.model === BATTERY_MODEL_POWERWALL_3_EXPANSION
      );
      const addOnBattery = expansionBatterySpec
        ? Design.createBattery(expansionBatterySpec.id, BatteryMode.Backup)
        : Design.createBattery(batterySpecs[0].id, BatteryMode.Backup);
      const newBatteryConfig = [...batteries, addOnBattery];
      setBatteries(newBatteryConfig);
    }
  };

  return (
    <ModalLayout
      open={isConfigureManualBatteriesModalOpen}
      onClose={handleClose}
      onContinue={handleContinue}
      actionButtons
      continueText="Save Manual Configuration"
      cancelText="Go Back"
      title="Manual Battery Configuration"
    >
      <Box>
        <Stack spacing={EQUIPMENT_MODAL_SPACING}>
          <Typography variant="body2">
            Manually configure the batteries on the design. By default, the
            design will use Powerwall 3 Energy Expansion packs to satisfy the
            desired battery count. If needed, those Powerwall 3 Energy Expansion
            Packs can be replaced with Powerwall 3 Base Packs.
          </Typography>
          <Alert severity="info">
            Note that after the design is finalized, the number of Powerwall 3
            Base Packs may be increased to account for the DC load
          </Alert>
          <Box m={2}>
            <Stack spacing={2} sx={{ m: 2 }}>
              {batteries &&
                batteries.map((battery, index) => {
                  return batterySpecs ? (
                    <BatteryOption
                      removeBattery={handleRemoveBattery}
                      locked={!index}
                      onChange={handleBatterySelectionChange}
                      key={battery.id}
                      battery={battery}
                      availableBatterySpecs={batterySpecs}
                    />
                  ) : null;
                })}
              {batteries && batteries.length < maxAllowedBatteriesCount && (
                <Button variant="text" onClick={handleAddBattery}>
                  <Add />
                  Add another battery
                </Button>
              )}
            </Stack>
          </Box>
        </Stack>
      </Box>
    </ModalLayout>
  );
};

const BatteryOption = ({
  battery,
  availableBatterySpecs,
  locked,
  onChange,
  removeBattery,
}: {
  battery: Battery;
  availableBatterySpecs: BatterySpecification[];
  locked: boolean;
  onChange: (batteryId: string, newSpecId: string) => void;
  removeBattery: (batteryId: string) => void;
}) => {
  const selectedBatterySpec = availableBatterySpecs.find(
    (spec) => spec.id === battery.properties.batterySpecificationId
  );
  if (!selectedBatterySpec) {
    console.warn(
      "Could not find selected battery spec in the availableBatterySpecs"
    );
    return null;
  }

  const handleBatteryChange = (e: SelectChangeEvent) => {
    onChange(battery.id, e.target.value);
  };

  const handleRemoveBattery = () => {
    removeBattery(battery.id);
  };

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Stack direction={"row"} alignItems={"center"} spacing={2}>
        {locked ? (
          <Tooltip title="At least 1 battery is required">
            <Box p={1}>
              <LockOutlined />
            </Box>
          </Tooltip>
        ) : (
          <IconButton onClick={handleRemoveBattery}>
            <DeleteOutline color="error" />
          </IconButton>
        )}
        {locked ? (
          <Typography variant="body1">
            {getPowerwall3EquipmentDisplayName(selectedBatterySpec.model)}
          </Typography>
        ) : (
          <FormControl variant="standard">
            <Select
              value={selectedBatterySpec.id}
              onChange={handleBatteryChange}
            >
              {availableBatterySpecs.map((spec) => (
                <MenuItem value={spec.id}>
                  {getPowerwall3EquipmentDisplayName(spec.model)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Stack>
    </Paper>
  );
};
