import { FlagsmithContextType, FlagsmithProvider } from "flagsmith/react";
import { getDefaultFeatures, getFlagsmithConfiguration } from "src/config/flagsmithConfig";
import { useAuth } from "@sunrun/design-tools-react-router-require-auth";
import { AuthContextType } from "@sunrun/design-tools-react-router-require-auth/build/AuthContext";
import { useCallback, useEffect } from "react";

/**
 * This is hack to make it easy for TestDataScenarios to mock out AuthContextType object
 * This is required for testing so that the Vdom renders for acceptance tests
 */
let useAuthMock: AuthContextType | undefined;
export function mockUseAuth(mock: AuthContextType) {
  useAuthMock = mock;
}

export const FlagsmithProviderWrapper = ({ flagsmith, children }: FlagsmithContextType) => {
  const auth = useAuth();
  const email = auth?.email || useAuthMock?.email;

  // Follows the same pattern as Offer Experience https://github.com/SunRun/offer-experience/blob/staging/src/providers/flagsmith.tsx
  const setIdentity = useCallback((identityToSet: string) => {
    if (!flagsmith.initialised || flagsmith.loadingState?.isFetching) {
      window.setTimeout(() => {
        setIdentity(identityToSet);
      }, 100);
    } else {
      flagsmith.identify(identityToSet, { email: identityToSet });
    }
  }, []);

  useEffect(() => {
    if (email) {
      console.log("Setting Flagsmith Identity", email);
      setIdentity(email);
    }
  }, [email, setIdentity]);

  const flagsmithOptions = {
    environmentID: getFlagsmithConfiguration().environmentID,
    defaultFlags: getDefaultFeatures(),
    identity: email || "default",
    traits: {
      email: email || null,
    },
  };

  return (
    <FlagsmithProvider options={flagsmithOptions} flagsmith={flagsmith}>
      {children}
    </FlagsmithProvider>
  );
};
