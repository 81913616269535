import os from 'os'
import * as process from "process";

export enum EnvType {
  LongLived = 'LongLived',
  PrPreview = 'PrPreview',
  DevSandbox = 'DevSandbox'
}

export enum LongLivedEnv {
  dev = 'dev',
  stg = 'stg',
  prd = 'prd'
}

type EnvInputs = Readonly<{
  envName?: string
}>

export interface IEnv {
  readonly name: string
  readonly type: EnvType
}

/**
 * Uses enums because it is easier to serialize enums than class implementations.
 */
export class Env implements IEnv {
  readonly name: string
  readonly type: EnvType

  constructor(name: string, type: EnvType) {
    this.name = name
    this.type = type
  }

  isEphemeral(): boolean {
    return this.type !== EnvType.LongLived
  }

  isLongLived(): boolean {
    return this.type === EnvType.LongLived
  }

  /**
   * For context, Amplify backend environments can only be lowercase letters between 2-10 characters.
   * See https://github.com/aws-amplify/amplify-cli/issues/979
   */

  static fromEnvInputs(inputs: EnvInputs) {
    if (inputs.envName) {
      if (isNumber(inputs.envName)) {
        return new Env(
          `pr${inputs.envName}`,
          EnvType.PrPreview
        )
      } else if (inputs.envName == 'dev' || inputs.envName == 'stg' || inputs.envName == 'prd') {
        return new Env(
          inputs.envName,
          EnvType.LongLived
        )
      } else {
        return new Env(
          inputs.envName,
          EnvType.DevSandbox
        )
      }
    } else {
      return new Env(
        inferDevSandboxEnvName(),
        EnvType.DevSandbox
      )
    }
  }

  static fromResourceName(resourceName: string): Env {
    const envName = resourceName.split("-")[0]!
    return Env.fromEnvInputs({ envName })
  }

  static browser(): Env {
    if (window.location.host.startsWith('localhost')) {
      return new Env("localhost", EnvType.DevSandbox)
    } else if (window.location.host.includes('dev')) {  // e.g. https://ihd.devdesigntools.sunrun.com/ or https://dev.dvo2nnfm57yrv.amplifyapp.com/
      return new Env('dev', EnvType.LongLived)
    } else if (window.location.host.includes('stg')) {  // e.g. stg.ihd.sunrun.com
      return new Env('stg', EnvType.LongLived)
    } else if (window.location.host.includes("prd") || window.location.host === "ihd.designtools.sunrun.com") {
      return new Env('prd', EnvType.LongLived)
    } else {
      return new Env(window.location.host.split('.')[0]!, EnvType.PrPreview)
    }
  }

  /**
   * Can be used in multiple types of nodejs contexts: jest tests or lambda runtimes.
   */
  static nodejs(): Env {
    const env = process.env
    const envName = env['ENV_NAME']
    const lambdaFunctionName = env['AWS_LAMBDA_FUNCTION_NAME']
    if (envName) {
      return Env.fromEnvInputs({ envName })
    } else if (lambdaFunctionName) {
      return Env.fromResourceName(lambdaFunctionName)
    } else {
      return Env.fromEnvInputs({})
    }
  }

  static browserOrNodeJs(): Env {
    // Order is essential, process may be polyfilled and not be undefined
    if (typeof window !== 'undefined') {
      return Env.browser()
    } else if (typeof process !== 'undefined') {
      return Env.nodejs()
    } else {
      throw new Error("Neither window or process is defined.")
    }
  }
}

const isNumber = (val: any): boolean => {
  return !isNaN(val)
}

const inferDevSandboxEnvName = () => {
  let name = os.userInfo().username.replace('.', '').toLowerCase().substring(0, 10);
  name = name.replace(/aws/g, 'zzz'); // Environment names cannot contain "aws", so replace with conspicuous placeholder
  return name
}
