import React from "react";
import { useQuery } from "react-query";
import { useErrorHandler } from "react-error-boundary";
import { useLoadingScreen } from "@sunrun/design-tools-loading-screen";
import { storefrontClient } from "@sunrun/design-tools-graphql-clients";
import { LoadingProcessGroups, LoadingProcessNames } from "src/types/LoadingScreenProcess";
import { useWorkspace } from "src/hooks/useWorkspace";
import { useSearchParams } from "react-router-dom";
import { Offer } from "@sunrun/design-tools-domain-model";
import { URLSearchParameterKey } from "src/types/URLSearchParameterKey";
import { useProductToEquipmentMapping } from "./useProductToEquipmentMapping";
import { deriveProductOptionChangesMessages} from "src/features/designGuidance/deriveOfferProductChanges";
import { useAppDispatch } from "src/store";
import { dismissOfferUpdateConfirmationModal, displayOfferUpdateConfirmationModal } from "src/features/modal/modalSlice";

export const useOffer = () => {
  const { helpers: loadingScreenHelpers } = useLoadingScreen();
  const handleError = useErrorHandler();
  const {state, dispatch: workspaceDispatch} = useWorkspace();
  const dispatch = useAppDispatch();
  const { handleProductToEquipmentMappingAndSetDesign } = useProductToEquipmentMapping();
  const productChangesMessages = deriveProductOptionChangesMessages(state); 
  const [searchParams] = useSearchParams();
  const offerId = searchParams.get(URLSearchParameterKey.OfferId);
  const signedParentProposalId = searchParams.get(URLSearchParameterKey.SignedRootId);

  const getOffer = async (): Promise<Offer> => {
    if (offerId) {
      // This next block of code is overly complicated. Petition Storefront to return parentFinancialProduct as a field on an offer
      if (signedParentProposalId) {
        const offer = await storefrontClient.getOffer(offerId);
        // We are getting the SolarDesignAttributes line from the offer to match it to the offer line in offer history
        let customDesignedProductOfferLineId = "";
        if (offer && offer.lines) {
          offer.lines.forEach((line) => {
            if (
              line.__typename === "CustomDesignedProductOfferLine" &&
              line.designAttributes?.__typename === "SolarDesignAttributes"
            ) {
              customDesignedProductOfferLineId = line.id;
            }
          });
        }

        const offerHistory = await storefrontClient.getOfferHistory(offerId);
        // Use offer status and signedRoot to find last signed customer agreement
        const signedRootOffer = offerHistory.snapshots.find(
          (offer: Offer) =>
            offer.status === "SIGNED" && offer.proposalId?.includes(signedParentProposalId),
        );

        let parentFinancialProduct;

        if (
          signedRootOffer &&
          signedRootOffer.paymentGroups &&
          signedRootOffer.paymentGroups.length > 0
        ) {
          // Match customDesignedProductOfferLineId to the correct offer line in the signed offer
          // there can be multiple paymentGroups in a offer.
          // One example:  a solar system is paid for in "Loan" and an EV charger is paid for in Cash
          signedRootOffer.paymentGroups.forEach((paymentGroup) => {
            paymentGroup?.offerLines?.forEach((offerLine) => {
              if (customDesignedProductOfferLineId === offerLine) {
                parentFinancialProduct = paymentGroup?.financialProduct;
              }
            });
          });
        }
        // return offer with parentFinancialProduct as added property
        return { ...offer, parentFinancialProduct };
      }
      // For non change order we can save a call to getOfferHistory
      else {
        const offer = await storefrontClient.getOffer(offerId);
        return offer;
      }
    }
    throw Error("useQuery should be disabled when offerId is undefined.");
  };

  const query = useQuery(["getOffer", offerId], getOffer, {
    refetchOnWindowFocus: false, // TODO support these use cases for conflict resolution
    refetchOnReconnect: false,
    enabled: !!offerId, // https://react-query.tanstack.com/guides/dependent-queries
    onSuccess: (newOffer: Offer) => {
      workspaceDispatch({type: "setOffer", payload: newOffer});
    },
    onError: handleError,
  });

  React.useEffect(
    function addLoadingScreenProcess() {
      if (query.isFetching) {
        loadingScreenHelpers.addProcess({
          name: LoadingProcessNames.OFFER,
          group: LoadingProcessGroups.INITIALIZE_IHD,
        });
        return function completeLoadingScreenProcess() {
          loadingScreenHelpers.completeProcess(LoadingProcessNames.OFFER);
        };
      }
    },
    [query.isFetching],
  );

  const refetchOffer = async () => {
    console.log("refetching offer");
    const offerQueryResult = await query.refetch();
    if (offerQueryResult.status === "success") {
      // for type strictness; should always be a success
      const newOffer = offerQueryResult.data;
      // only do p2e mapping after a refetch, and not when we mutate Offer
      handleProductToEquipmentMappingAndSetDesign(newOffer);
    }
  };

  React.useEffect(
    function checkToDisplayOfferUpdateConfirmationModal() {
      if (productChangesMessages.length) {
        dispatch(displayOfferUpdateConfirmationModal())
        workspaceDispatch({ type: "displayOfferUpdateConfirmationModal" });
      } else {
        dispatch(dismissOfferUpdateConfirmationModal())
        workspaceDispatch({ type: "dismissOfferUpdateConfirmationModal" });
      }
    },
    [productChangesMessages],
  );

  return {
    refetchOffer,
  };
};
