import { createSelector } from "reselect";
import { deriveDesignClass } from "src/features/design/designSlice";
import { deriveProductionSimulationClass, selectProductionSimulation } from "src/features/productionSimulation/productionSimulationSlice";
import { determineDesignWarnings } from "@sunrun/design-tools-domain-model";
import { selectCustomer } from "../customer/customerSlice";
import { deriveReadOnlyDesignClassSets } from "../readOnlyDesignSet/readOnlyDesignSetSlice";
import { deriveDesignConstraintsClass } from "../designConstraints/designConstraintsSlice";
import { deriveLowResProductionSimulation } from "../lowResProductionSimulation/deriveLowResProductionSimulation";

export const deriveDesignWarnings = createSelector(
  [
    deriveDesignClass,
    deriveLowResProductionSimulation,
    deriveProductionSimulationClass,
    selectCustomer,
    deriveReadOnlyDesignClassSets,
    deriveDesignConstraintsClass,
  ],
  (
    design,
    lowResProductionSimulation,
    productionSimulation,
    customer,
    readOnlyDesignSets,
    designConstraints
  ) => {
    return determineDesignWarnings({
      activeDesign: design,
      activeLowResProductionSimulation: lowResProductionSimulation,
      activeProductionSimulation: productionSimulation,
      signedRootDesignSet: readOnlyDesignSets.signedRootDesignSet,
      interconnectionAppliedDesignSet: readOnlyDesignSets.interconnectionAppliedDesignSet,
      customer,
      designConstraints,
    });
  }
);
