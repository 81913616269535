import { Badge, Box, Fab, Stack, useMediaQuery, useTheme } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import SaveIcon from "@mui/icons-material/Save";
import { useWorkspace } from "src/hooks/useWorkspace";
import { LayerControl } from "../MapControl/LayerControl";
import { ModuleAdjustmentActionBar } from "./components/ModuleAdjustmentActionBar";
import { Footer } from "./components/Footer";
import { DesignToolsTheme } from "@sunrun/design-tools-themes";
import { useFlags } from "flagsmith/react";
import { FlagsmithFeatureEnums } from "src/config/flagsmithConfig";
import { DesignUndo } from "../DesignUndo/DesignUndo";
import { WorkflowButtonProps } from "../Toolbar";
import { useAppDispatch } from "src/store";
import { setIsBookmarkDesignOpen, setIsFinancialProductsOpen } from "src/features/modal/modalSlice";
import { selectIsOfferExperience } from "src/features/host/hostSlice";

export const FloatingActionButtons = (workflowButtonProps: WorkflowButtonProps) => {
  const theme = useTheme<DesignToolsTheme>();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const {state, dispatch: workspaceDispatch} = useWorkspace();
  const dispatch = useAppDispatch();
  const allowBookmarkDesign = useFlags([
    FlagsmithFeatureEnums.ENABLE_BOOKMARK_DESIGN,
  ])[FlagsmithFeatureEnums.ENABLE_BOOKMARK_DESIGN].enabled;
  const isOfferExperience = selectIsOfferExperience(state);

  const bookmarkedDesignCount = state?.bookmarkedDesigns?.length;

  return (
    <Box
      height={0}
      overflow="visible"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          pb: 1,
          px: isXs ? 2 : 0,
        }}
      >        
        <Box width="56px" />
        <ModuleAdjustmentActionBar />
        <Stack spacing={2}>          
          <DesignUndo />
          {allowBookmarkDesign && isOfferExperience && (
            <Fab
              color="secondary"
              aria-label="bookmark"
              onClick={() => {
                dispatch(setIsBookmarkDesignOpen(true))
                workspaceDispatch({ type: "setIsBookmarkDesignOpen", payload: true })
              }}
            >
              <Badge color='primary' badgeContent={bookmarkedDesignCount} max={10}>
                <SaveIcon fontSize="large" />
              </Badge>
            </Fab>
          )}
          <Fab
            color="secondary"
            aria-label="edit"
            onClick={() => {
              dispatch(setIsFinancialProductsOpen(true))
              workspaceDispatch({ type: "setIsFinancialProductsOpen", payload: true })
            }}
          >
            <TaskAltIcon />
          </Fab>
          <LayerControl />
          <Box height="112px"></Box>{" "}
          {/* TODO: Fix this placeholder hack for the ZoomControl component. */}
        </Stack>
      </Box>
      {isXs && <Footer {...workflowButtonProps} />}
    </Box>
  );
};
