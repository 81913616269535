import {useMutation, UseMutationResult, useQueryClient} from "react-query";
import {useLoadingScreen} from "@sunrun/design-tools-loading-screen";
import {
  CommandType,
  Design,
  DesignConstraints,
  DesignExportedToLightmile,
  designRequirementsToValidate,
  DesignSimulatedWithLightmileCore,
  DesignValidator,
  ProductionSimulation
} from "@sunrun/design-tools-domain-model";
import {processManagerClient, repository} from "@sunrun/design-tools-graphql-clients";
import {LoadingProcessGroups, LoadingProcessNames} from "src/types/LoadingScreenProcess";
import {IFrameEventType} from "src/types/IFrame";
import {postIFrameMessage} from "./useIFrameHost";
import {useWorkspace} from "src/hooks/useWorkspace";
import {useSearchParams} from "react-router-dom";
import * as FullStory from "@fullstory/browser";
import {URLSearchParameterKey} from "src/types/URLSearchParameterKey"
import { deriveDesignConstraintsClass } from "src/features/designConstraints/designConstraintsSlice"; 
import { setProductionSimulation } from "src/features/productionSimulation/productionSimulationSlice";
import { deriveDesignClass, setDesign } from "src/features/design/designSlice";
import { useAppDispatch } from "src/store";
import { designFinalized, legacyFinalizeDesignComplete, legacyFinalizeDesignInProgress } from "src/features/workflowState/workflowStateSlice";
import { setIsDesignStatusDialogueVisible } from "src/features/settings/settingsSlice";
import { setErrorModal } from "src/features/modal/modalSlice";

type FinalizeDesignProps = Readonly<{
  saveDesignMutation: UseMutationResult<Design, unknown, Design, unknown>,
}>

export const useLegacyFinalizeDesign = ({saveDesignMutation}: FinalizeDesignProps) => { 
  const {state, dispatch: workspaceDispatch} = useWorkspace();
  const dispatch = useAppDispatch();
  const {customer, productionSimulation, settings, host} = state;
  const [searchParams] = useSearchParams();
  const lightmileProjectId = searchParams.get(URLSearchParameterKey.LightmileProjectId) || undefined;
  const interconnectionAppliedDesignId = searchParams.get(URLSearchParameterKey.InterconnectionAppliedDesignId) || undefined;
  const {helpers: loadingScreenHelpers} = useLoadingScreen();
  const queryClient = useQueryClient();

  const designConstraints = deriveDesignConstraintsClass(state);
  const design = deriveDesignClass(state);

  /***
   *
   * LEGACY FINALIZE DESIGN (used when iHD is hosted by Splat / Lightmile)
   *
   * Order of Operations:
   *
   * simulateDesignWithLightmileCore
   *   onError -> display error / reset
   *   onSuccess -> trigger refetch of Design, ProductionSimulation
   * ReFetch Design, ProductionSimulation
   *   Re-validateDesignRequirementsForLightmileExport
   *     If Errors -> display DesignStatus Modal / reset
   *     If None -> continue to export
   * exportDesignToLightmile
   *   onError -> display error / reset
   *   onSuccess -> Complete process (enable FinishEditing button), display DesignStatus Modal
   *
   */
  const legacyFinalizeDesign = async (): Promise<void> => {
    if (lightmileProjectId == undefined) {
      dispatch(setErrorModal({message: "Cannot Finalize Design without LightmileProjectId"}))
      workspaceDispatch({ type: "setErrorModal", payload: {message: "Cannot Finalize Design without LightmileProjectId"}})
      return;
    }
    if (!customer || !designConstraints || !design || !productionSimulation) {
      dispatch(setErrorModal({message: "Cannot Finalize Design without missing aggregates"}))
      workspaceDispatch({ type: "setErrorModal", payload: {message: "Cannot Finalize Design without missing aggregates"}})
      return;
    }
    if (design.version !== 0) {
      dispatch(setErrorModal({message: "Can only Finalize Design when using the current Design version (v0)"}))
      workspaceDispatch({ type: "setErrorModal", payload: {message: "Can only Finalize Design when using the current Design version (v0)"}})
      return;
    }
    if (state.workflowState.hasDesignInitiatedWithCollidingModules){
      FullStory.event("Design Finalized From Red X Start", {designId: design!.id, host: host})
    }
    dispatch(legacyFinalizeDesignInProgress())
    workspaceDispatch({type: 'legacyFinalizeDesignInProgress'})
    FullStory.event("Finalizing Design", {})
    await simulateMutation.mutateAsync();
  };

  const signedRootId = state.mostRecentSignedDesign.projectHasSignedDesign ?
    state.mostRecentSignedDesign.signedRootId : null;
  const extractedCustomerData = {
    utility: customer?.utility,
    nemQualification: customer?.nemQualification,
    annualUsagekWh: customer?.annualUsagekWh
  };
  const extractedDesignConstraintsData = {
    acBatteryConstraints: designConstraints?.batteryConstraints.ac,
    sunsimCalculationParameters: designConstraints?.sunsimCalculationParameters
  }
  
  const simulateMutation = useMutation(async (): Promise<DesignSimulatedWithLightmileCore> => {
    return await processManagerClient.simulateDesignWithLightmileCoreAsync({
      constraintsFromOffer: null,
      designId: design!.id,
      lightmileProjectId: lightmileProjectId!,
      productionSimulationId: productionSimulation!.id,
      signedRootId: signedRootId,
      interconnectionAppliedDesignId,
      type: CommandType.SimulateDesignWithLightmileCore,
      effectiveDate: state.mostRecentSignedDesign.effectiveDate,
      isFilter10kLogicEnabled : settings.isFilter10kLogicEnabled,
      utility: extractedCustomerData.utility,
      nemQualification: extractedCustomerData.nemQualification,
      annualUsagekWh: extractedCustomerData.annualUsagekWh,
      acBatteryConstraints: extractedDesignConstraintsData.acBatteryConstraints,
      sunsimCalculationParameters: extractedDesignConstraintsData.sunsimCalculationParameters
    });
  }, {
    onMutate: async () => {
      loadingScreenHelpers.addProcess({
        group: LoadingProcessGroups.FINALIZE_DESIGN,
        name: LoadingProcessNames.DESIGN_SIMULATION,
      });
      // ensure design is up-to-date
      if (design!.hasUnsavedChanges) {
        await saveDesignMutation.mutateAsync(design!)
      }
    },
    onSettled: () => {
      loadingScreenHelpers.completeProcess(LoadingProcessNames.DESIGN_SIMULATION);
      dispatch(designFinalized());
      workspaceDispatch({type: "designFinalized"})
    },
    onError: (error: Error) => {
      postIFrameMessage(IFrameEventType.ENABLE_FINALIZE_DESIGN_BUTTON, {designId: design!.id});
      dispatch(legacyFinalizeDesignComplete());
      workspaceDispatch({type: 'legacyFinalizeDesignComplete'});
      dispatch(setErrorModal({error, message: "Failed to simulate design with Lightmile-Core"}))
      workspaceDispatch({ type: "setErrorModal", payload: {error, message: "Failed to simulate design with Lightmile-Core"}})
    },
    onSuccess: async (designSimulatedEvent: DesignSimulatedWithLightmileCore) => {
      FullStory.event("Design Simulated With Lightmile Core", {})
      await reloadDesignAndProductionSimulation(designSimulatedEvent);
    }
  });

  const reloadDesignAndProductionSimulation = async (designSimulatedEvent: DesignSimulatedWithLightmileCore) => {
    loadingScreenHelpers.addProcess({
      group: LoadingProcessGroups.FINALIZE_DESIGN,
      name: LoadingProcessNames.RELOAD_DESIGN,
    });
    const {designId, productionSimulationId, productionSimulationVersion} = designSimulatedEvent;
    const newDesign = await repository.get(Design, designId, 0);
    const newProductionSimulation = await repository.get(ProductionSimulation, productionSimulationId, productionSimulationVersion);
    // We persist the design and production simulation in two ways: in the QueryClient (a global provider in the app
    // that maintains a cache) and in our useContext/useReducer-based workspace state.
    // TODO: this necessity of synchronizing two caches is begging to create bugs. How can we improve? https://sunrun.jira.com/browse/LS-1714
    queryClient.setQueryData(["getDesign", designId], newDesign);
    queryClient.setQueryData(["getProductionSimulation", productionSimulationId], newProductionSimulation);
    dispatch(setDesign(newDesign.getIDesign()));
    workspaceDispatch({type: 'setDesign', payload: newDesign});
    dispatch(setProductionSimulation(newProductionSimulation.getIProductionSimulation()));
    workspaceDispatch({type: 'setProductionSimulation', payload: {simulation:newProductionSimulation, design: newDesign}});

     /***
     * TODO This code still use an imperative, forward chained approach to deriving data.
     *   We need to migrate it to the new derivations / reselect / backwards chained approach, which will require this
     *   whole hook to be refactored.
     */
    const unMetDesignRequirements = DesignValidator.determineUnMetDesignRequirements(
      designRequirementsToValidate.lightmileExport,
      newDesign,
      undefined,
      newProductionSimulation,
      customer,
      designConstraints,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
    if (unMetDesignRequirements.length > 0) {
      postIFrameMessage(IFrameEventType.ENABLE_FINALIZE_DESIGN_BUTTON, {designId: design!.id});
      dispatch(legacyFinalizeDesignComplete());
      workspaceDispatch({type: 'legacyFinalizeDesignComplete'});
      dispatch(setIsDesignStatusDialogueVisible(true))
      workspaceDispatch({type: "setIsDesignStatusDialogueVisible", payload: true})

      // Hack to keep the design status UI from opening before the derivation completes
      await new Promise(t => setTimeout(t, 1000))
      loadingScreenHelpers.completeProcess(LoadingProcessNames.RELOAD_DESIGN);
      return;
    }

    loadingScreenHelpers.completeProcess(LoadingProcessNames.RELOAD_DESIGN);
    return exportMutation.mutateAsync();
  }

  const exportMutation = useMutation((): Promise<DesignExportedToLightmile> => {
    loadingScreenHelpers.addProcess({
      group: LoadingProcessGroups.FINALIZE_DESIGN,
      name: LoadingProcessNames.EXPORT,
    });

    return processManagerClient.exportDesignToLightmile({
      designId: design!.id,
      lightmileProjectId: lightmileProjectId!,
      type: CommandType.ExportDesignToLightmile
    });
  }, {
    onSettled: () => {
      loadingScreenHelpers.completeProcess(LoadingProcessNames.EXPORT);
      dispatch(legacyFinalizeDesignComplete());
      workspaceDispatch({type: 'legacyFinalizeDesignComplete'});
    },
    onError: (error: Error) => {
      postIFrameMessage(IFrameEventType.ENABLE_FINALIZE_DESIGN_BUTTON, {designId: design!.id});
      dispatch(setErrorModal({error, message: "Failed to export design to Lightmile"}))
      workspaceDispatch({ type: "setErrorModal", payload: {error, message: "Failed to export design to Lightmile"}})
    },
    onSuccess: () => {
      FullStory.event("Exported Design To Lightmile", {})
      postIFrameMessage(IFrameEventType.DESIGN_EDIT_COMPLETE, {designId: design!.id});
      dispatch(setIsDesignStatusDialogueVisible(true));
      workspaceDispatch({type: "setIsDesignStatusDialogueVisible", payload: true});
    }
  });

  return {
    legacyFinalizeDesign,
  };
};