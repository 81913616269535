import { makeStyles } from "@material-ui/core";

//  Inspiration for this component and its animation is from the CircularProgress component from MUI
//  https://mui.com/material-ui/react-progress/
//  https://github.com/mui/material-ui/tree/bc35128302b5bd61fa35f89d371aeed91e6a5748/packages/mui-material/src/CircularProgress

export const Spinner = () => {
  const useStyles = makeStyles({
    "@keyframes spin": {
      '0%': {
        transformOrigin: "50% 50%",
      },
      '100%': {
        transform: "rotate(360deg)",
      },
    },
    "@keyframes dash": {
      '0%': {
        strokeDasharray: "1px 200px",
        strokeDashoffset: "0px"
      },
      '50%': {
        strokeDasharray: "100px 200px",
        strokeDashoffset: "-15px"

      },
      '100%': {
        strokeDasharray: "100px 200px",
        strokeDashoffset: "-125px"
      },
    },
    spinner: {
      animation: "$spin 1.4s linear infinite",
      height: "70px",
      width: "70px",
    },
    circle: {
      animation: "$dash 1.4s ease-in-out infinite",
      strokeDasharray: "80px, 200px",
      strokeDashoffset: "0px"
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.spinner}>
      <svg viewBox="22 22 44 44">
        <defs>
          <linearGradient id="gradient" x1="0%" y1="100%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#FFFBF0" />
            <stop offset="100%" stopColor="#A5C9FF" />
          </linearGradient>
        </defs>
        <circle className={classes.circle} cx="44" cy="44" r="20.2" fill="none" stroke="url(#gradient)" strokeLinecap="round" strokeWidth="3.8"></circle>
      </svg>
    </div>
  );
};
