import { useEffect, useState } from "react";
import { useWorkspace } from "./useWorkspace";
import { Design, ProductionSimulation } from "@sunrun/design-tools-domain-model";
import { repository } from "@sunrun/design-tools-graphql-clients";
import { useErrorHandler } from "react-error-boundary";
import { useQuery } from "react-query";
import { useLoadingScreen } from "@sunrun/design-tools-loading-screen";
import { LoadingProcessGroups, LoadingProcessNames } from "src/types/LoadingScreenProcess";

type UseReadOnlyDesignSetProps = {
  signedRootDesignId?: string;
  interconnectionAppliedDesignId?: string;
  copyFromDesignId?: string;
  mlpeDesignId?: string;
  unoptimizedStringDesignId?: string;
  mlpeProductionSimulationId?: string;
  unoptimizedStringProductionSimulationId?: string;
};

export const useReadOnlyDesignSet = ({
    signedRootDesignId,
    interconnectionAppliedDesignId,
    copyFromDesignId,
    mlpeDesignId,
    unoptimizedStringDesignId,
    mlpeProductionSimulationId,
    unoptimizedStringProductionSimulationId,
  }: UseReadOnlyDesignSetProps
) => {   
  
  const { dispatch } = useWorkspace();
  const {helpers: loadingScreenHelpers} = useLoadingScreen();
  const handleError = useErrorHandler();
  const [signedRootDesign, setSignedRootDesign] = useState<Design>();
  const [signedRootProductionSimulation, setSignedRootProductionSimulation] = useState<ProductionSimulation>();
  const [interconnectionAppliedDesign, setInterconnectionAppliedDesign] = useState<Design>();
  const [interconnectionAppliedProductionSimulation, setInterconnectionAppliedProductionSimulation] = useState<ProductionSimulation>();
  const [mlpeVariantDesign, setMlpeVariantDesign] = useState<Design>();
  const [mlpeVariantProductionSimulation, setMlpeVariantProductionSimulation] = useState<ProductionSimulation>();
  const [unoptimizedStringVariantDesign, setUnoptimizedStringVariantDesign] = useState<Design>();
  const [unoptimizedStringVariantProductionSimulation, setUnoptimizedStringVariantProductionSimulation] = useState<ProductionSimulation>();

  const getDesign = async (designId?: string | null | undefined) => {
    if (designId) return repository.get(Design, designId, 0);
    return undefined;
  };

  const getProductionSimulation = async (productionSimulationId?: string | null | undefined) => {
    if (productionSimulationId) return repository.get(ProductionSimulation, productionSimulationId, 0);
    return undefined;
  };

  const mlpeVariantDesignQuery = useQuery(["getDesign", mlpeDesignId], () => getDesign(mlpeDesignId), {
    refetchOnWindowFocus: false, 
    refetchOnReconnect: false,
    enabled: !!mlpeDesignId, 
    onSuccess: (design: Design) => {
      setMlpeVariantDesign(design)
    },
    onError: handleError,
  });

  const mlpeVariantProductionSimulationQuery = useQuery(["getProductionSimulation", mlpeVariantDesign], () => getProductionSimulation(mlpeProductionSimulationId), {
    refetchOnWindowFocus: false, 
    refetchOnReconnect: false,
    enabled: !!mlpeProductionSimulationId, 
    onSuccess: (productionSimulation: ProductionSimulation) => {
      setMlpeVariantProductionSimulation(productionSimulation)
    },
    onError: handleError,
  });

  const unoptimizedStringVariantDesignQuery = useQuery(["getDesign", unoptimizedStringDesignId], () => getDesign(unoptimizedStringDesignId), {
    refetchOnWindowFocus: false, 
    refetchOnReconnect: false,
    enabled: !!unoptimizedStringDesignId, 
    onSuccess: (design: Design) => {
      setUnoptimizedStringVariantDesign(design)
    },
    onError: handleError,
  });

  const unoptimizedStringVariantProductionSimulationQuery = useQuery(["getProductionSimulation", unoptimizedStringVariantDesign], () => getProductionSimulation(unoptimizedStringProductionSimulationId), {
    refetchOnWindowFocus: false, 
    refetchOnReconnect: false,
    enabled: !!unoptimizedStringProductionSimulationId, 
    onSuccess: (productionSimulation: ProductionSimulation) => { 
      setUnoptimizedStringVariantProductionSimulation(productionSimulation)
    },
    onError: handleError,
  });

  const signedRootDesignQuery = useQuery(["getDesign", signedRootDesignId], () => getDesign(signedRootDesignId), {
    refetchOnWindowFocus: false, 
    refetchOnReconnect: false,
    enabled: !!signedRootDesignId, 
    onSuccess: (design: Design) => {
      setSignedRootDesign(design)
    },
    onError: handleError,
  });

  const signedRootProductionSimulationQuery = useQuery(["getProductionSimulation", signedRootDesign], () => getProductionSimulation(signedRootDesign?.productionSimulationId), {
    refetchOnWindowFocus: false, 
    refetchOnReconnect: false,
    enabled: !!signedRootDesign, 
    onSuccess: (productionSimulation: ProductionSimulation) => {
      setSignedRootProductionSimulation(productionSimulation)
    },
    onError: handleError,
  });

  const interconnectionAppliedDesignQuery = useQuery(["getDesign", interconnectionAppliedDesignId], () => getDesign(interconnectionAppliedDesignId), {
    refetchOnWindowFocus: false, 
    refetchOnReconnect: false,
    enabled: !!interconnectionAppliedDesignId, 
    onSuccess: (design: Design) => {
      setInterconnectionAppliedDesign(design)
    },
    onError: handleError,
  });

  const interconnectionAppliedProductionSimulationQuery = useQuery(["getProductionSimulation", interconnectionAppliedDesign], () => getProductionSimulation(interconnectionAppliedDesign?.productionSimulationId), {
    refetchOnWindowFocus: false, 
    refetchOnReconnect: false,
    enabled: !!interconnectionAppliedDesign, 
    onSuccess: (productionSimulation: ProductionSimulation) => {
      setInterconnectionAppliedProductionSimulation(productionSimulation)
    },
    onError: handleError,
  });

  // When we copy from a design, we only care to copy the Design itself; no need to fetch the ProductionSimulation
  const copyFromDesignQuery = useQuery(["getDesign", copyFromDesignId], () => getDesign(copyFromDesignId), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!copyFromDesignId,
    onSuccess: (design: Design) => {
      dispatch({ type: "setCopyFromDesign", payload: design });
    },
    onError: handleError,
  });

  useEffect(() => {
    if(signedRootDesign && signedRootProductionSimulation){
      dispatch({ 
        type: "setSignedRootDesignSet", 
        payload: { design: signedRootDesign, productionSimulation: signedRootProductionSimulation } 
      });
    }
  },[signedRootDesign, signedRootProductionSimulation])

  useEffect(() => {
    if(mlpeVariantDesign && mlpeVariantProductionSimulation){
      dispatch({ 
        type: "setMlpeVariantDesignSet", 
        payload: { design: mlpeVariantDesign, productionSimulation: mlpeVariantProductionSimulation } 
      });
    }
  },[mlpeVariantDesign, mlpeVariantProductionSimulation])

  useEffect(() => {
    if(unoptimizedStringVariantDesign && unoptimizedStringVariantProductionSimulation){
      dispatch({ 
        type: "setUnoptimizedVariantDesignSet", 
        payload: { design: unoptimizedStringVariantDesign, productionSimulation: unoptimizedStringVariantProductionSimulation } 
      });
    }
  },[unoptimizedStringVariantDesign, unoptimizedStringVariantProductionSimulation])

  useEffect(() => {
    if(interconnectionAppliedDesign && interconnectionAppliedProductionSimulation){
      dispatch({ 
        type: "setInterconnectionAppliedDesignSet", 
        payload: { design: interconnectionAppliedDesign, productionSimulation: interconnectionAppliedProductionSimulation } 
      });
    }
  },[interconnectionAppliedDesign, interconnectionAppliedProductionSimulation])

  useEffect(function addLoadingScreenProcess() {
    if (
      signedRootDesignQuery.isFetching ||
      interconnectionAppliedDesignQuery.isFetching ||
      copyFromDesignQuery.isFetching ||
      unoptimizedStringVariantDesignQuery.isFetching ||
      mlpeVariantDesignQuery.isFetching

    ) {
      loadingScreenHelpers.addProcess({
        name: LoadingProcessNames.DESIGN,
        group: LoadingProcessGroups.INITIALIZE_IHD,
      });
      return function completeLoadingScreenProcess() {
        loadingScreenHelpers.completeProcess(LoadingProcessNames.DESIGN);
      };
    }
  }, 
  [
    signedRootDesignQuery.isFetching, 
    interconnectionAppliedDesignQuery.isFetching,
    copyFromDesignQuery.isFetching,
    mlpeVariantDesignQuery.isFetching,
    unoptimizedStringVariantDesignQuery.isFetching 
  ]);
  
  useEffect(function addLoadingScreenProcess() {
    if (signedRootProductionSimulationQuery.isFetching || interconnectionAppliedProductionSimulationQuery.isFetching || unoptimizedStringVariantProductionSimulationQuery.isFetching || mlpeVariantProductionSimulationQuery.isFetching) {
      loadingScreenHelpers.addProcess({
        name: LoadingProcessNames.DESIGN_SIMULATION,
        group: LoadingProcessGroups.INITIALIZE_IHD,
      });
      return function completeLoadingScreenProcess() {
        loadingScreenHelpers.completeProcess(LoadingProcessNames.DESIGN_SIMULATION);
      };
    }
  }, 
  [
    signedRootProductionSimulationQuery.isFetching,
    interconnectionAppliedProductionSimulationQuery.isFetching,
    unoptimizedStringVariantProductionSimulationQuery.isFetching,
    mlpeVariantProductionSimulationQuery.isFetching
  ]);
};

