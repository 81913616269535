const TWO_PLACE_SHIFT = 100
const THREE_PLACE_SHIFT = 1000

export const round = (num: number, decimalPlaces: number = 2):number => {
  let shift;
  switch (decimalPlaces) {
    case 2:
      shift = TWO_PLACE_SHIFT;
      break;
    case 3:
      shift = THREE_PLACE_SHIFT;
      break;
    default:
      shift = Math.pow(10, decimalPlaces)
  }
  return Math.round((num + Number.EPSILON) * shift) / shift
}
