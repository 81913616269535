import {WorkspaceAction, WorkspaceState} from "src/hooks/useWorkspace";
import {WorkspaceEmptyAction, WorkspacePayloadAction} from "../../types/state-management/action";
import {produce} from "immer";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type Notification = {
  snackbarMessage: string | undefined
}

export const notificationInitialState = {
  snackbarMessage: undefined
}

type NotificationState = {
  snackbarMessage: string | undefined
}

const initialState = {
  snackbarMessage: undefined
}

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    showSnackbarMessage: (state: NotificationState, action: PayloadAction<string>) => {
      state.snackbarMessage = action.payload
    },
    dismissSnackbarMessage: (state: NotificationState) => {
      state.snackbarMessage = undefined
    },
  }
});

export const { showSnackbarMessage, dismissSnackbarMessage } = notificationSlice.actions
export default notificationSlice.reducer

// TODO: Remove the following and transition fully from WorkspaceState to RTK store
export type NotificationWorkspaceAction =
  | WorkspacePayloadAction<string, 'showSnackbarMessage'>
  | WorkspaceEmptyAction<'dismissSnackbarMessage'>

export const notificationWorkspaceReducer = (state: WorkspaceState, action: WorkspaceAction): Notification => {
  switch (action.type) {
    case "showSnackbarMessage": {
      return produce(state.notification, draft => {
        draft.snackbarMessage = action.payload
      })
    }

    case "dismissSnackbarMessage": {
      return produce(state.notification, draft => {
        draft.snackbarMessage = undefined
      })
    }

    default:
      return state.notification
  }
}
