import {
  Design,
  BatteryMode,
  BATTERY_MODEL_POWERWALL_3_BASE_PACK,
  BATTERY_MODEL_POWERWALL_3_BASE_PACK_AC_COUPLED,
  PvProduct,
} from "@sunrun/design-tools-domain-model";
import { useLoadingScreen } from "@sunrun/design-tools-loading-screen";
import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { LoadingProcessGroups, LoadingProcessNames } from "src/types/LoadingScreenProcess";
import { URLSearchParameterKey } from "src/types/URLSearchParameterKey";
import { useMaxFill } from "./useMaxFill";
import { useProductToEquipmentMapping } from "./useProductToEquipmentMapping";
import { useWorkspace } from "./useWorkspace";
import * as FullStory from "@fullstory/browser";
import { deriveLowResProductionSimulation } from "src/features/lowResProductionSimulation/deriveLowResProductionSimulation";
import { useFlags } from "flagsmith/react";
import { FlagsmithFeatureEnums } from "src/config/flagsmithConfig";
import { useHandleDesignInitMetrics } from "./useInitDesignMetrics";
import { deriveSiteModelClass } from "src/features/siteModel/siteModelSlice";
import { deriveDesignClass, setDesign } from "src/features/design/designSlice";
import { deriveReadOnlyDesignClassSets } from "src/features/readOnlyDesignSet/readOnlyDesignSetSlice";
import { useAppSelector, useAppDispatch } from "src/store";
import { KeystoneEquipment } from "@sunrun/design-tools-keystone-client";
import { setCancelContinueModal, setErrorModal, setInfoModal } from "src/features/modal/modalSlice";
import {
  designInitiated,
  setRequiresRecalculateSetbacks,
} from "src/features/workflowState/workflowStateSlice";
import {
  setIsModulesLayerVisible,
  setIsModuleSpacingLayerVisible,
} from "src/features/settings/settingsSlice";
import { selectIsAdditionalSystem, selectIsAffiliate } from "src/features/offer/offerSlice";

export type UseInitDesignProps = {
  recalculateSetbacks: () => void;
};

export const useInitDesign = ({ recalculateSetbacks }: UseInitDesignProps) => {
  const { state, dispatch: workspaceDispatch } = useWorkspace();
  const dispatch = useAppDispatch();
  const {
    solarResource,
    customer,
    designConstraints,
    offer,
    availableEquipment,
    availableEquipmentIfNewOffer,
    workflowState,
    host,
  } = state;
  const { parentSetbacksCalculatorVersion } = useAppSelector((state) => state.settings);
  const { copyFromDesign, signedRootDesignSet } = deriveReadOnlyDesignClassSets(state);
  const { hasAvailableEquipmentLoaded, hasDesignInitiated } = workflowState;
  const { helpers: loadingScreenHelpers } = useLoadingScreen();
  const { handleMaxFill } = useMaxFill();
  const { handleProductToEquipmentMapping } = useProductToEquipmentMapping();
  const [searchParams] = useSearchParams();
  const requiresCopyFromDesign = searchParams.has(URLSearchParameterKey.CopyFromDesignId);
  const isChangeOrder = searchParams.has(URLSearchParameterKey.SignedRootId);
  const isChangeOrderWithParentSetbacksOptIn =
    isChangeOrder && parentSetbacksCalculatorVersion === 1;
  const featureFlags = useFlags([
    FlagsmithFeatureEnums.INIT_MAXFILL,
    FlagsmithFeatureEnums.MIN_IMPORT_PROPOSAL_DATE_PW3,
    FlagsmithFeatureEnums.OPT_USERS_INTO_DYNAMIC_FIRE_SETBACK,
    FlagsmithFeatureEnums.ENABLE_FIRE_SETBACK,
  ]);
  const initMaxfillFlag = featureFlags[FlagsmithFeatureEnums.INIT_MAXFILL];
  const minImportProposalDatePw3Flag =
    featureFlags[FlagsmithFeatureEnums.MIN_IMPORT_PROPOSAL_DATE_PW3];
  const optInToFirecodeSetbacks =
    featureFlags[FlagsmithFeatureEnums.OPT_USERS_INTO_DYNAMIC_FIRE_SETBACK];
  const showFireSetbackRefresh = featureFlags[FlagsmithFeatureEnums.ENABLE_FIRE_SETBACK];

  const { handleDesignInitMetrics } = useHandleDesignInitMetrics();
  const siteModel = deriveSiteModelClass(state);
  const design = deriveDesignClass(state);
  const keystoneEquipment = useAppSelector((state) => state.availableEquipment.keystoneEquipment);
  const isAdditionalSystem = selectIsAdditionalSystem(state);

  const handleCopyDesign = useCallback(
    (design: Design, keystoneEquipment: KeystoneEquipment[]) => {
      if (!siteModel) {
        console.warn("Attempting to CopyDesign before SiteModel is loaded!");
        return design;
      }

      if (!keystoneEquipment) {
        console.warn("Attempting to CopyDesign before KeystoneEquipment is loaded!");
        return design;
      }

      if (!copyFromDesign) {
        dispatch(
          setErrorModal({
            title: "Failed to Copy Design",
            message: "No valid design was found to copy from, starting with a fresh design",
          }),
        );
        workspaceDispatch({
          type: "setErrorModal",
          payload: {
            title: "Failed to Copy Design",
            message: "No valid design was found to copy from, starting with a fresh design",
          },
        });
        return design;
      }

      const copiedDesign = design.copyDesign(
        copyFromDesign,
        siteModel,
        keystoneEquipment,
        isAdditionalSystem,
      );
      if (copyFromDesign.moduleCount !== copiedDesign.moduleCount) {
        dispatch(
          setInfoModal({
            title: "Copied Design Adjusted",
            message:
              "We were unable to exactly match the copied design. We have made some adjustments to the module layout.",
          }),
        );
        workspaceDispatch({
          type: "setInfoModal",
          payload: {
            title: "Copied Design Adjusted",
            message:
              "We were unable to exactly match the copied design. We have made some adjustments to the module layout.",
          },
        });
      }
      return copiedDesign;
    },
    [siteModel, copyFromDesign],
  );

  // Copy modules and module layout from signed root design. This function is currently used for logging only.
  const handleCopySignedRootDesign = useCallback(
    (design: Design) => {
      if (!siteModel || !signedRootDesignSet?.design) {
        return undefined;
      }
      const copiedSignedRootDesign = design.copyModules(signedRootDesignSet.design, siteModel);
      return copiedSignedRootDesign;
    },
    [siteModel, signedRootDesignSet],
  );

  // We are passing the need to recalculate setbacks as property on workflowState
  // We need to do this because featureActionResolver is not a react component but needs access to recalculateSetbacks hook
  useEffect(() => {
    const callRecalculateSetbacks = async () => {
      await recalculateSetbacks();
    };
    if (
      siteModel &&
      design &&
      state.workflowState?.requiresRecalculateSetbacks &&
      state.settings.isRecalculateFirecodeSetbacksEnabled
    ) {
      workspaceDispatch({ type: "setRequiresRecalculateSetbacks", payload: false });
      dispatch(setRequiresRecalculateSetbacks(false));
      callRecalculateSetbacks();
    }
  }, [state.workflowState?.requiresRecalculateSetbacks]);

  // Initiate Design once everything has loaded.
  useEffect(() => {
    const isReadyForInit =
      !hasDesignInitiated &&
      design &&
      solarResource &&
      siteModel &&
      customer &&
      designConstraints &&
      offer &&
      availableEquipment &&
      (copyFromDesign || !requiresCopyFromDesign) &&
      hasAvailableEquipmentLoaded &&
      keystoneEquipment;
    if (isReadyForInit) {
      loadingScreenHelpers.addProcess({
        name: LoadingProcessNames.DESIGN_INIT,
        group: LoadingProcessGroups.INITIALIZE_IHD,
      });
      // TODO LS-1919 refactor productToEquipment mapping to keep availableEquipment as a Value Object, without having to save to Design
      const designWithAvailableEquipment = design.setEquipmentSpecifications(
        availableEquipment,
        siteModel,
      );

      let designToUpdate = designWithAvailableEquipment;
      // Copy Design
      if (copyFromDesign) {
        designToUpdate = handleCopyDesign(designWithAvailableEquipment, keystoneEquipment ?? []);
      }

      // Copy Signed Root Design Modules for metrics tracking
      let signedRootDesignForMetrics;
      if (signedRootDesignSet?.design) {
        signedRootDesignForMetrics = handleCopySignedRootDesign(designWithAvailableEquipment);
      }

      // Map products to equipment
      let designWithUpdatedEquipment = handleProductToEquipmentMapping(designToUpdate);

      /*
       * Filtering the equipment specs to only include the selected modules. This will help avoid issues where
       * the EquipmentSpecifications on the design cause the design to exceed the 400kb DynamoDB item size limit.
       * This filter is only required in Offer Experience. Legacy iHD's list of equipment will be much smaller since
       * we only use the equipment from the imported lightmile design map
       */
      designWithUpdatedEquipment =
        designWithUpdatedEquipment.filterEquipmentSpecsToSelectedEquipment();

      if (
        !isChangeOrder &&
        designWithUpdatedEquipment?.getBatteryMode() === BatteryMode.SelfConsumption
      ) {
        /*
         * If we are not doing a change order select all compatible inverter models from the previously selected model's manufacturer.
         * We need this additional update as updateDesignToMatchOffer will skip an update when doesDesignSatisfyProductSelection is true
         * Without this step 10k inverters can be uncessicarly omitted from the list of of selectedInverterSpecificationIds
         * this cause downstream issues with our 10k retry for 2 batteries logic in generateLightmileDesign
         */
        designWithUpdatedEquipment = designWithUpdatedEquipment.autoUpdateWithCompatibleInverters(
          keystoneEquipment ?? [],
          state.settings.isFilter10kLogicEnabled,
        );
      }

      // Avoid an edge case in firecode setbacks pilot where roofs can load with disabled modules.
      // We don't run this on change orders whose parents used the new workflow
      if (!isChangeOrderWithParentSetbacksOptIn) {
        designWithUpdatedEquipment =
          designWithUpdatedEquipment.deleteModulesFromDisabledRoofs(siteModel);
      }

      // Log Red X Metrics after applying handleProductToEquipmentMapping to each design Version
      handleDesignInitMetrics({
        initialDesign: handleProductToEquipmentMapping(designWithAvailableEquipment),
        copyDesign: copyFromDesign ? handleProductToEquipmentMapping(designToUpdate) : undefined,
        signedRootDesign: signedRootDesignForMetrics
          ? handleProductToEquipmentMapping(signedRootDesignForMetrics)
          : undefined,
      });

      let initiatedDesign = designWithUpdatedEquipment;
      // MaxFill
      console.log(
        "Flagsmith feature flag design_tools_init_maxfill enabled:",
        initMaxfillFlag.enabled,
      );
      if (initMaxfillFlag.enabled && design.latest === 1 && !copyFromDesign && !isChangeOrder) {
        initiatedDesign = handleMaxFill(designWithUpdatedEquipment);
      }
      dispatch(setDesign(initiatedDesign.getIDesign()));
      workspaceDispatch({ type: "setDesign", payload: initiatedDesign });
      dispatch(designInitiated());
      workspaceDispatch({ type: "designInitiated" });

      // Track design initial offset for new designs
      const customerUsage = state.customer?.annualUsagekWh ?? 0;
      const annualProductionKwh = deriveLowResProductionSimulation({
        ...state,
        design: initiatedDesign,
      })?.annualProductionKwh;
      if (customerUsage && annualProductionKwh && !copyFromDesign && !isChangeOrder) {
        const offset = annualProductionKwh / customerUsage;
        FullStory.event("Design Initiated", { designId: design.id, offset: offset, host: host });
      }

      // FIXME: Remove this hack as part of LS-2321. This hack was put in place to hide the LM-Design while loading in OE.
      dispatch(setIsModulesLayerVisible(true));
      workspaceDispatch({ type: "setIsModulesLayerVisible", payload: true });
      dispatch(setIsModuleSpacingLayerVisible(true));
      workspaceDispatch({ type: "setIsModuleSpacingLayerVisible", payload: true });

      // A subset of firecode setbacks users will be forced into the pilot. Delete this code after completion of the pilot.
      if (
        optInToFirecodeSetbacks.enabled &&
        !state.settings.isRecalculateFirecodeSetbacksEnabled &&
        !isChangeOrder // we call this function later if design is a change order
      ) {
        console.log("User Opted in to firecode setbacks");
        const callRecalculateSetbacks = async () => {
          await recalculateSetbacks();
        };
        workspaceDispatch({ type: "setIsRecalculateFirecodeSetbacksEnabled", payload: true });
        workspaceDispatch({ type: "setRequiresRecalculateSetbacks", payload: false });
        dispatch(setRequiresRecalculateSetbacks(false));
        callRecalculateSetbacks();
      }
      // ET-1777: Deactivate undo on ihd load - clears undo on inital load once modules are placed on to design.
      workspaceDispatch({ type: "clearUndoDesign" });
      loadingScreenHelpers.completeProcess(LoadingProcessNames.DESIGN_INIT);
    }
  }, [
    hasDesignInitiated,
    design,
    siteModel,
    solarResource,
    customer,
    designConstraints,
    offer,
    availableEquipment,
    copyFromDesign,
    hasAvailableEquipmentLoaded,
    keystoneEquipment,
    handleCopyDesign,
    handleMaxFill,
    handleProductToEquipmentMapping,
  ]);

  // WHEN hasDesignInitiated flips true, prompt user to update PW2 -> PW3
  useEffect(
    function initiateDesignWithUserSelections() {
      // Prompts the user to auto-update from PW2 -> PW3
      // TODO: generalize this (ie not just pw3) using getEquipmentPriority?
      if (hasDesignInitiated) {
        if (!design || !availableEquipmentIfNewOffer) return;

        // A subset of firecode setbacks users will be forced into the pilot. Delete this code after completion of the pilot.
        if (
          optInToFirecodeSetbacks.enabled ||
          (isChangeOrderWithParentSetbacksOptIn && showFireSetbackRefresh.enabled)
        ) {
          console.log("User Opted in to firecode setbacks during initiateDesignWithUserSelections");
          const callRecalculateSetbacks = async () => {
            await recalculateSetbacks();
          };
          workspaceDispatch({ type: "setIsRecalculateFirecodeSetbacksEnabled", payload: true });
          workspaceDispatch({ type: "setRequiresRecalculateSetbacks", payload: false });
          dispatch(setRequiresRecalculateSetbacks(false));
          callRecalculateSetbacks();
        }

        // #1: Determine if PW2 -> PW3 auto-conversion is applicable. See ET-1816
        // This is essentially an evaluation of a "SellingRule" in Keystone.
        // This feature needs to go out asap; in the future we can determine if/how Keystone
        // could be leveraged to evaluate these conditions.
        const parentDesignHasPw2 = design.isOnlyPowerwall2Design;
        const isPw3Available = design.equipmentSpecifications.batterySpecifications.some(
          (batterySpec) =>
            batterySpec.model === BATTERY_MODEL_POWERWALL_3_BASE_PACK ||
            batterySpec.model === BATTERY_MODEL_POWERWALL_3_BASE_PACK_AC_COUPLED,
        );
        const isPw3AvailableIfNewOffer = availableEquipmentIfNewOffer.batterySpecifications.some(
          (batterySpec) =>
            batterySpec.model === BATTERY_MODEL_POWERWALL_3_BASE_PACK ||
            batterySpec.model === BATTERY_MODEL_POWERWALL_3_BASE_PACK_AC_COUPLED,
        );

        const effectiveDate = new Date(state.mostRecentSignedDesign.effectiveDate ?? 0);
        const dateEnabled = minImportProposalDatePw3Flag.enabled;
        const minImportProposalDateValue = dateEnabled
          ? (minImportProposalDatePw3Flag.value as string)
          : undefined;
        const minImportProposalDate = minImportProposalDateValue
          ? new Date(minImportProposalDateValue)
          : null; // Date at time of coding: "2024-07-10T14:10:00-0700" (2:10 pm MT)
        const isEffectiveDateWithinDateRange =
          minImportProposalDate && effectiveDate > minImportProposalDate;

        // This isn't the "correct" way of determining affiliates, but reusing the same logic that goes into allowing Equipment Edit.
        const isAffiliate = selectIsAffiliate(state);
        const isWithinSelectSalesDivision = !isAffiliate;

        const parentFinancialProduct = state.offer?.parentFinancialProduct;
        const selectFinancialProducts: PvProduct[] = [
          PvProduct.Prepaid,
          PvProduct.Monthly,
          PvProduct.FlexMonthly,
        ];
        const isWithinSelectFinancialProduct =
          parentFinancialProduct && selectFinancialProducts.includes(parentFinancialProduct);

        const shouldSuggestPw3AutoConversion =
          isChangeOrder &&
          parentDesignHasPw2 &&
          isPw3Available &&
          isPw3AvailableIfNewOffer &&
          isEffectiveDateWithinDateRange &&
          isWithinSelectSalesDivision &&
          isWithinSelectFinancialProduct;

        // #2: If applicable, prompt user to update to PW3. See ET-1857
        if (shouldSuggestPw3AutoConversion) {
          const title = "PW3 update is available";
          const message =
            "This project should be updated to PW3. Only use PW2 if required to avoid DQ.";
          const cancelText = "Keep PW2";
          const continueText = "Update to PW3";
          const onContinue = () => {
            workspaceDispatch({
              type: "autoUpdateDesignWithPw3",
              payload: {
                keystoneEquipment,
                isFilter10kLogicEnabled: state.settings.isFilter10kLogicEnabled,
              },
            });
          };

          dispatch(
            setCancelContinueModal({
              title,
              message,
              cancelText,
              continueText,
              onContinue,
            }),
          );
          workspaceDispatch({
            type: "setCancelContinueModal",
            payload: {
              title,
              message,
              cancelText,
              continueText,
              onContinue,
            },
          });
        }
      }
    },
    [hasDesignInitiated],
  );
};
