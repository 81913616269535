import {Setback} from "@sunrun/design-tools-domain-model";
import {setbackStripes} from "src/styles/stylingPatterns";
import {GeoJsonLayer} from "src/components/GeoJsonLayer";
import {PathOptions} from "leaflet";
import React from "react";

export type SetbackProps = {
  setback: Setback
}

export const SetbackLayer = (props: SetbackProps) => {
  const [pathOptions,] = React.useState<PathOptions>({
    color: "#EA3434",
    opacity: 0.5,
    fillOpacity: 0.7,
    fillPattern: setbackStripes
  })
  return (
    <GeoJsonLayer
      data={props.setback}
      pathOptions={pathOptions}
      key={props.setback.id}
    />
  )
}