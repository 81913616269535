import { CommandType, DesignVariantName, LightmileDesignSelected, Design, link, ReadOnlyDesignSet, calculateSunHourDiffMetricOutput } from "@sunrun/design-tools-domain-model";
import { useWorkspace } from "./useWorkspace";
import { processManagerClient, repository } from "@sunrun/design-tools-graphql-clients";
import { useMutation, useQueryClient } from "react-query";
import { useLoadingScreen } from "@sunrun/design-tools-loading-screen";
import {LoadingProcessGroups, LoadingProcessNames} from "../types/LoadingScreenProcess";
import * as FullStory from "@fullstory/browser";
import { setProductionSimulation } from "src/features/productionSimulation/productionSimulationSlice";
import { deriveDesignClass, setDesign } from "src/features/design/designSlice";
import { deriveReadOnlyDesignClassSets } from "src/features/readOnlyDesignSet/readOnlyDesignSetSlice";
import { useAppDispatch } from "src/store";
import { deriveLowResProductionSimulation } from "src/features/lowResProductionSimulation/deriveLowResProductionSimulation";
import { setVariantDesignSelection, simulateDesignComplete } from "src/features/workflowState/workflowStateSlice";
import { closeSelectDesignVariantModal, setErrorModal } from "src/features/modal/modalSlice";

export const useSelectLightmileDesign = () => {
  const {state, dispatch: workspaceDispatch} = useWorkspace();
  const dispatch = useAppDispatch();
  const { productionSimulation } = state;
  const {helpers: loadingScreenHelpers} = useLoadingScreen();
  const queryClient = useQueryClient();
  const design = deriveDesignClass(state);
  const { workflowState } = state;
  const lowResSimulationToUse = deriveLowResProductionSimulation(state);
  const lowResSunHours = lowResSimulationToUse?.getSystemSunHours(design);

  const setLocalStateAndSelectLightmileDesign = async (designVariantName : DesignVariantName)=> {
    dispatch(setVariantDesignSelection());
    workspaceDispatch({type: 'setVariantDesignSelection'})
    // update S3 \\
    await selectLightmileDesignMutation.mutateAsync(designVariantName)

    // update local state \\
    // get variant 
    const readOnlyDesignSet = deriveReadOnlyDesignClassSets(state)
    const variantSelected : ReadOnlyDesignSet = designVariantName === DesignVariantName.UnoptimizedString ? readOnlyDesignSet.variantDesignSets.unoptimizedString! : readOnlyDesignSet.variantDesignSets.mlpe!

    // merge selectedDesign content with initial design Id and versioning
      let mergedVariantDesign = new Design(
      {...variantSelected.design, 
      id:design!.id, 
      _version:design!._version,
      version:0, 
      latest:design!.latest ? design!.latest + 1 : design!.latest 
    })
    //  merge selectedProductionSimulation content with initial Prod Sim Id and versioning
    const mergedVariantProductionSimulation = variantSelected.productionSimulation.updateWithNewDesign(mergedVariantDesign, variantSelected.productionSimulation.annualProductionKwh)

    // Save newly created merged design and merged productionSimulation
    const {vLatest:prodSimLatest} = await repository.save(mergedVariantProductionSimulation)
    mergedVariantDesign = link(mergedVariantDesign, prodSimLatest)
    const {v0:linkedDesign} = await repository.save(mergedVariantDesign)

    // update query client state
    queryClient.setQueryData(["getDesign", design!.id], linkedDesign);
    queryClient.setQueryData(["getProductionSimulation", productionSimulation!.id], prodSimLatest);

    // update context state
    dispatch(setDesign(linkedDesign.getIDesign()))
    workspaceDispatch({type: 'setDesign', payload: linkedDesign});
    dispatch(setProductionSimulation(prodSimLatest.getIProductionSimulation()));
    workspaceDispatch({type: 'setProductionSimulation', payload: {simulation:prodSimLatest, design: linkedDesign}});
    dispatch(simulateDesignComplete())
    workspaceDispatch({ type: 'simulateDesignComplete' });
    // At this state don't need to add one to the count because it would have been dispatched by this point
    if (design && workflowState.finalizeDesignClickCount === 1) {
      const highResSunHours = prodSimLatest?.getSystemSunHours(design) ?? 0;
      calculateSunHourDiffMetricOutput(lowResSunHours, highResSunHours);
    }
    FullStory.event("Inverter Selected After Generate Designs", {inverterSelectedAfterGenerateDesigns: designVariantName})
  }

  const selectLightmileDesignMutation = useMutation((designVariant: DesignVariantName): Promise<LightmileDesignSelected> => {
    loadingScreenHelpers.addProcess({
      group: LoadingProcessGroups.SELECTING_LIGHTMILE_DESIGN,
      name: LoadingProcessNames.SELECTING_VARIANT_DESIGN,
    });

    const command = {
      designId: design!.id,
      designVariant,
      type: CommandType.SelectLightmileDesign
    }

    return processManagerClient.selectLightmileDesign(command)
  }, {
    onSettled: () => {
      loadingScreenHelpers.completeProcess(LoadingProcessNames.SELECTING_VARIANT_DESIGN);
    },
    onError: (error: Error) => {
      dispatch(setErrorModal({error, message: "Failed to select variant lightmile design"}))
      workspaceDispatch({ type: "setErrorModal", payload: {error, message: "Failed to select variant lightmile design"}})
    },
    onSuccess: () => {
      dispatch(closeSelectDesignVariantModal())
      workspaceDispatch({ type: "closeSelectDesignVariantModal"})
    }
  });
  return {
    setLocalStateAndSelectLightmileDesign,
  };
};
