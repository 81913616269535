import React from "react";
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Paper,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DesignToolsTheme } from "@sunrun/design-tools-themes";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ToolbarStats from "./components/ToolbarStats";
import { useWorkspace } from "src/hooks/useWorkspace";
import { deriveIsDesignFinalized } from "src/features/designGuidance/deriveIsDesignFinalized";
import { deriveLowResProductionSimulation } from "src/features/lowResProductionSimulation/deriveLowResProductionSimulation";
import MoreDetails from "./components/MoreDetails";
import { WorkflowButton } from "./components/WorkflowButton";
import DesignStatus from "../DesignStatus";
import DesignMenu from "../DesignMenu";
import { SelectModuleModal, SelectInverterModal, SelectBatteryModal } from "src/components/Modal";
import {
  DesignConstraintType,
  DEFAULT_MAXIMUM_AC_BATTERY_COUNT,
  DEFAULT_MAXIMUM_DC_BATTERY_COUNT,
} from "@sunrun/design-tools-domain-model";
import { IFrameHostType } from "src/types/IFrame";
import { selectHost } from "src/features/host/hostSlice";
import { deriveDesignConstraintsClass } from "src/features/designConstraints/designConstraintsSlice";
import { deriveProductionSimulationClass } from "src/features/productionSimulation/productionSimulationSlice";
import { deriveSolarResourceClass } from "src/features/solarResource/solarResourceSlice";
import { deriveDesignClass, selectAllowEditBatteryCount } from "src/features/design/designSlice";
import { useAppSelector, useAppDispatch } from "src/store";
import { setHasUserInverterUpdate } from "src/features/workflowState/workflowStateSlice";
import { selectIsAdditionalSystem, selectIsAffiliate } from "src/features/offer/offerSlice";
import {
  closeSelectInverterModal,
  closeSelectModuleModal,
  closeSelectStorageModal,
} from "src/features/modal/modalSlice";

export interface ToolbarProps {
  disableFinalizeDesignButton: boolean;
  simulateDesign: () => void;
  exportDesign: () => void;
  createDesignImages: () => void;
  legacyFinalizeDesign: () => void;
  recalculateSetbacks: () => void;
}

const Toolbar: React.FC<ToolbarProps> = (props) => {
  const { state, dispatch: workspaceDispatch } = useWorkspace();
  const dispatch = useAppDispatch();
  const keystoneEquipment = useAppSelector((state) => state.availableEquipment.keystoneEquipment);
  const { customer, offer, display } = state;
  const solarResource = deriveSolarResourceClass(state);
  const toggleExpand = () => workspaceDispatch({ type: "toggleIsToolbarExpanded" });
  const theme = useTheme<DesignToolsTheme>();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const designFinalized = deriveIsDesignFinalized(state);
  const designConstraints = deriveDesignConstraintsClass(state);
  const productionSimulation = deriveProductionSimulationClass(state);
  const design = deriveDesignClass(state);
  const simulationToUse = designFinalized
    ? productionSimulation
    : deriveLowResProductionSimulation(state);
  const sunHours = isNaN(simulationToUse?.getSystemSunHours(design) ?? 0)
    ? 0
    : simulationToUse?.getSystemSunHours(design) ?? 0;
  const isAffiliate = selectIsAffiliate(state);
  const isAdditionalSystem = selectIsAdditionalSystem(state)

  const allowEditEquipment =
    selectHost(state) === IFrameHostType.LIGHTMILE_OFFER_BUILDER || isAffiliate;

  const selectedBatteryId =
    design?.selectedEquipmentSpecificationIds?.selectedBatterySpecificationIds[0];

  let isAcBattery = false;
  // Powerwall 3 Batteries can be either AC or DC coupled, but will always use the AC batteryConstraints.
  let requireAcBatteryCountForDCBatteryException = design?.isPowerwall3;

  if (selectedBatteryId) {
    const spec = design?.getBatterySpec(selectedBatteryId);
    isAcBattery = spec?.manufacturerSpecifications?.acStorage || false;
  }

  const maxAllowedBatteriesCount =
    isAcBattery || requireAcBatteryCountForDCBatteryException
      ? designConstraints?.batteryConstraints?.ac?.maxAcBatteries ??
        DEFAULT_MAXIMUM_AC_BATTERY_COUNT
      : DEFAULT_MAXIMUM_DC_BATTERY_COUNT;
  const allowEditBatteryCount = selectAllowEditBatteryCount(state);

  const handleSelectNewInverters = (
    newInverterSpecIds: string[],
    newPowerOptimizerSpecIds: string[] = []
  ) => {
    dispatch(closeSelectInverterModal());
    workspaceDispatch({ type: "closeSelectInverterModal" });
    workspaceDispatch({
      type: "updateDesignWithInverterSpecIds",
      payload: {
        newInverterSpecIds,
        keystoneEquipment,
        isFilter10kLogicEnabled: state.settings.isFilter10kLogicEnabled,
      },
    });
    workspaceDispatch({
      type: "updateDesignWithPowerOptimizerSpecIds",
      payload: {
        newPowerOptimizerSpecIds,
        keystoneEquipment,
        isFilter10kLogicEnabled: state.settings.isFilter10kLogicEnabled,
      },
    });

    dispatch(setHasUserInverterUpdate());
    workspaceDispatch({ type: "setHasUserInverterUpdate" });
  };

  const handleSelectNewBatteries = (
    newBatterySpecIds: string[],
    batteryCount: number,
    newMidIds?: string[]
  ) => {
    dispatch(closeSelectStorageModal());
    workspaceDispatch({ type: "closeSelectStorageModal" });
    workspaceDispatch({
      type: "updateDesignWithBatteryCount",
      payload: batteryCount,
    });
    workspaceDispatch({
      type: "updateDesignWithBatterySpecIds",
      payload: {
        newBatterySpecIds,
        newMidIds,
        keystoneEquipment,
        isFilter10kLogicEnabled: state.settings.isFilter10kLogicEnabled,
      },
    });
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Paper
          elevation={2}
          sx={{
            zIndex: theme.zIndex.appBar,
            position: "relative",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <DesignMenu />
            <Divider orientation="vertical" flexItem />
            <ToolbarStats
              customerUsage={customer?.annualUsagekWh ?? 0}
              annualProductionKwh={simulationToUse?.annualProductionKwh ?? 0}
              targetOffset={design?.designParameters?.targetUsageOffsetPercent ?? 0}
              maxOffset={
                designConstraints?.getConstraintValue(DesignConstraintType.MaxOffsetPercent) ?? 0
              }
              dcSystemSizeKw={design?.dcSystemSizeKw ?? 0}
              sunHours={sunHours}
              minSunHours={
                designConstraints?.getConstraintValue(DesignConstraintType.MinSunHours) ?? 0
              }
              maxSunHours={solarResource?.siteMaxSunHours ?? 0}
            />
            <Divider orientation="vertical" flexItem />
            <DesignStatus />
            <Divider orientation="vertical" flexItem />
            {!isXs && (
              <Box
                sx={{
                  paddingX: 2,
                }}
              >
                <WorkflowButton {...props} buttonProps={{ size: "sm", width: "100%" }} />
              </Box>
            )}
            <Divider orientation="vertical" flexItem />
            <IconButton
              color="primary"
              size="large"
              onClick={toggleExpand}
              sx={{ borderRadius: "inherit" }}
            >
              {display.isToolbarExpanded ? (
                <ExpandLessIcon fontSize="inherit" />
              ) : (
                <ExpandMoreIcon fontSize="inherit" />
              )}
            </IconButton>
          </Stack>
          <Box overflow="auto">
            <Divider />
            <Collapse in={display.isToolbarExpanded}>
              <MoreDetails />
            </Collapse>
          </Box>
        </Paper>
      </div>
      <SelectModuleModal
        open={state.modal.isSelectModuleOpen}
        onClose={() => {
          dispatch(closeSelectModuleModal());
          workspaceDispatch({ type: "closeSelectModuleModal" });
        }}
      />
      <SelectInverterModal
        availableInverters={
          offer && keystoneEquipment
            ? design?.getCompatibleInverters(
                keystoneEquipment ?? [],
                state.settings.isFilter10kLogicEnabled
              ) || []
            : []
        } // TODO: this is calculating on every single rerender. bad.
        designId={design?.id}
        offerId={offer?.id}
        open={state.modal.isSelectInverterOpen}
        onClose={() => {
          dispatch(closeSelectInverterModal());
          workspaceDispatch({ type: "closeSelectInverterModal" });
        }}
        onInverterChange={handleSelectNewInverters}
        selectedInverterIds={
          design?.selectedEquipmentSpecificationIds?.selectedInverterSpecificationIds
        }
      />
      <SelectBatteryModal
        availableMids={
        offer && design?.selectedBatterySpecifications && keystoneEquipment
          ? design.getCompatibleMids(
              keystoneEquipment ?? [],
              isAdditionalSystem
            )
          : []
        }
        designId={design?.id}
        offerId={offer?.id}
        open={state.modal.isSelectStorageOpen}
        onClose={() => {
          dispatch(closeSelectStorageModal());
          workspaceDispatch({ type: "closeSelectStorageModal" });
        }}
        onBatteryChange={handleSelectNewBatteries}
        selectedBatteryIds={
          design?.selectedEquipmentSpecificationIds?.selectedBatterySpecificationIds
        }
        batteryCount={design?.batteryCount}
        allowEditEquipment={allowEditEquipment}
        allowEditBatteryCount={allowEditBatteryCount}
        maxAllowedBatteriesCount={maxAllowedBatteriesCount}
      />
    </>
  );
};

export default Toolbar;
