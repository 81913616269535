import {SiteModel, SiteModelFeatureType} from "@sunrun/design-tools-domain-model";

const siteModelWithRoof = new SiteModel({
  prospectId: 'testFixture',
  pin: {
    type: 'Point',
    coordinates: [523719.862, 4315908.125, 4.5] //[523733, 4315904] // [523683, 4315854]
  },
  version: 0,
  selectedOrthogonalGeoRasterId: "testFixture",
  trees: [
    {
      id: 'tree1',
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [523709.862, 4315908.125, 4.5]
      },
      properties: {
        type: SiteModelFeatureType.Tree,
        radiusMeters: 6.4,
        heightMeters: 7,
      }
    },
    {
      id: 'tree2',
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [523740.200, 4315896.225, 4.5]
      },
      properties: {
        type: SiteModelFeatureType.Tree,
        radiusMeters: 4.0,
        heightMeters: 7,
      }
    },
    {
      id: 'tree3',
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [523728.973, 4315885.871, 4.5]
      },
      properties: {
        type: SiteModelFeatureType.Tree,
        radiusMeters: 3.9,
        heightMeters: 7,
      }
    },
    {
      id: 'tree4',
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [523731.998, 4315887.878, 4.5]
      },
      properties: {
        type: SiteModelFeatureType.Tree,
        radiusMeters: 2.8,
        heightMeters: 7,
      }
    },
    {
      id: 'tree5',
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [523721.321, 4315916.281, 4.5]
      },
      properties: {
        type: SiteModelFeatureType.Tree,
        radiusMeters: 5,
        heightMeters: 7,
      }
    }
  ],
  roofFaces: [
    {
      id: '0',
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[
          [523720.083, 4315912.666, 4.5],
          [523734.456, 4315902.761, 4.5],
          [523731.536, 4315898.419, 3],
          [523717.658, 4315907.800, 3],
          [523720.083, 4315912.666, 4.5]
        ]]
      },
      properties: {
        type: SiteModelFeatureType.RoofFace,
        usable: true,
        azimuthDegrees: 215.0,
        pitchDegrees: 20,
      }
    },
    {
      id: '1',
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[
          [523720.083, 4315912.666, 4.5],
          [523723.613, 4315916.667, 3],
          [523737.782, 4315906.998, 3],
          [523734.456, 4315902.761, 4.5],
          [523720.083, 4315912.666, 4.5]
        ]]
      },
      properties: {
        type: SiteModelFeatureType.RoofFace,
        usable: true,
        azimuthDegrees: 35.0,
        pitchDegrees: 20,
      }
    },
    {
      id: '2',
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[
          [523722.430, 4315904.518, 2],
          [523726.358, 4315901.936, 2.8],
          [523721.587, 4315895.088, 2.8],
          [523717.863, 4315897.679, 2],
          [523722.430, 4315904.518, 2]
        ]]
      },
      properties: {
        type: SiteModelFeatureType.RoofFace,
        usable: true,
        azimuthDegrees: 305.0,
        pitchDegrees: 20,
      }
    },
    {
      id: '3',
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[
          [523726.358, 4315901.936, 2.8],
          [523730.256, 4315899.331, 2],
          [523725.660, 4315892.440, 2],
          [523721.587, 4315895.088, 2.8],
          [523726.358, 4315901.936, 2.8]
        ]]
      },
      properties: {
        type: SiteModelFeatureType.RoofFace,
        usable: false,
        azimuthDegrees: 125.0,
        pitchDegrees: 20,
      }
    }
  ],
  obstructions: [
    {
      id: "skylight_0",
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[
          [523728.556, 4315904.862, 3.75],
          [523729.799, 4315904.150, 3.75],
          [523729.125, 4315903.107, 3.75],
          [523727.862, 4315903.863, 3.75],
          [523728.556, 4315904.862, 3.75]
        ]]
      },
      properties: {
        type: SiteModelFeatureType.Obstruction,
        heightMeters: 0.1524
      }
    },
    {
      id: "vent_1",
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[
          [523726.609, 4315905.318, 3.75],
          [523727.016, 4315905.030, 3.75],
          [523726.663, 4315904.624, 3.75],
          [523726.255, 4315904.912, 3.75],
          [523726.609, 4315905.318, 3.75]
        ]]
      },
      properties: {
        type: SiteModelFeatureType.Obstruction,
        heightMeters: 0.6096
      }
    },
    {
      id: "chimney_2",
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[
          [523723.513, 4315911.208, 5],
          [523724.395, 4315910.628, 5],
          [523723.981, 4315910.111, 5],
          [523723.070, 4315910.686, 5],
          [523723.513, 4315911.208, 5]
        ]]
      },
      properties: {
        type: SiteModelFeatureType.Obstruction,
        heightMeters: 0.9144
      }
    },
  ],
  setbacks: [
    {
      id: '100',
      type: "Feature",
      geometry: {
        type: "Polygon",
        // 3Sided Setback
        coordinates: [[
          [523720.083, 4315912.666, 4.5],
          [523734.456, 4315902.761, 4.5],
          [523731.536, 4315898.419, 3],
          [523730.753, 4315898.947, 3],
          [523733.244, 4315902.656, 3],
          [523720.436, 4315911.266, 3],
          [523718.439, 4315907.273, 3],
          [523717.658, 4315907.800, 3],
          [523720.083, 4315912.666, 4.5]
        ]]
      },
      properties: {
        type: SiteModelFeatureType.Setback,
      }
    },
    {
      id: '101',
      type: "Feature",
      geometry: {
        type: "Polygon",
        // Polygon with Interior
        coordinates: [[
          [523720.083, 4315912.666, 4.5],
          [523723.613, 4315916.667, 3],
          [523737.782, 4315906.998, 3],
          [523734.456, 4315902.761, 4.5],
          [523720.083, 4315912.666, 4.5]
        ], [
          [523721.298, 4315912.617, 3],
          [523734.107, 4315903.848, 3],
          [523736.565, 4315906.982, 3],
          [523723.965, 4315915.644, 3],
          [523721.298, 4315912.617, 3]
        ]]
      },
      properties: {
        type: SiteModelFeatureType.Setback,
      }
    }
  ],
  fireCodePathways: [],
  crs: {
    type: "name",
    properties: {
      name: "urn:ogc:def:crs:EPSG::26913"
    }
  }
})


export default siteModelWithRoof
