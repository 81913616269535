import { OpportunityInput } from "@sunrun/design-tools-generated-graphql";
import { KeystoneEquipment, KeystoneEquipmentSellingRule } from "./keystoneClient";

enum AggregateAction {
  Add = "Add",
  Subtract = "Subtract",
}

export namespace SellingRulesUtils {
  export const KEYSTONE_ALL = "all";

  export const filterEquipmentSellingRules = (
    opportunity: OpportunityInput,
    sellingRules: KeystoneEquipmentSellingRule[]
  ): KeystoneEquipmentSellingRule[] => {
    const {
      purchaseThrough,
      salesDivision,
      acquisitionChannel,
      state,
      utility,
    } = opportunity;

    // TODO: Maybe add filter on date?
    // We need to filter off of all selling rules due to contentful limitation
    const filteredSellingRules = sellingRules?.filter((sellingRule) => {
      // We only want Equipment selling rules here
      const sellingRuleType = sellingRule?.type?.name === "Equipment";
      const statePostalAbbreviations = sellingRule?.stateCollection?.items?.map(
        (state) => state?.postalAbbreviation?.toLowerCase()
      );
      // We use aliases and a flatmap here to account for discrepancies in utility names
      const utilityAliases = sellingRule?.utilityCollection?.items?.flatMap(
        (utility) => utility?.aliases?.map((alias) => alias?.toLowerCase())
      );
      const acquisitionChannelNames =
        sellingRule?.acquisitionChannelCollection?.items?.map(
          (acquisitionChannel) => acquisitionChannel?.name?.toLowerCase()
        );
      const salesDivisionNames =
        sellingRule?.salesDivisionCollection?.items?.map((salesDivision) =>
          salesDivision?.name?.toLowerCase()
        );
      const purchaseThroughNames =
        sellingRule?.purchaseThroughCollection?.items?.map((purchaseThrough) =>
          purchaseThrough?.name?.toLowerCase()
        );
      
      const satisfiesStatePostalAbbreviations =
        statePostalAbbreviations?.includes(state?.toLowerCase()) ||
        statePostalAbbreviations?.includes(KEYSTONE_ALL);
      const satisfiesUtilityAliases =
        utilityAliases?.includes(utility?.toLowerCase()) ||
        utilityAliases?.includes(KEYSTONE_ALL);
      const satisfiesAcquisitionChannelNames =
        acquisitionChannelNames?.includes(acquisitionChannel?.toLowerCase()) ||
        acquisitionChannelNames?.includes(KEYSTONE_ALL);
      const satisfiesSalesDivisionNames =
        salesDivisionNames?.includes(salesDivision?.toLowerCase()) ||
        salesDivisionNames?.includes(KEYSTONE_ALL);
      const satisfiesPurchaseThroughNames =
        purchaseThroughNames?.includes(purchaseThrough?.toLowerCase()) ||
        purchaseThroughNames?.includes(KEYSTONE_ALL);
      
      return (
        sellingRuleType &&
        satisfiesStatePostalAbbreviations &&
        satisfiesUtilityAliases &&
        satisfiesAcquisitionChannelNames &&
        satisfiesSalesDivisionNames &&
        satisfiesPurchaseThroughNames
      );
    });

    return filteredSellingRules;
  };

  export const getAvailableEquipmentIdsFromSellingRules = (
    sellingRules: KeystoneEquipmentSellingRule[]
  ): string[] => {
    const equipmentToSubtract = new Set<string>();
    const availableEquipment = new Set<string>();

    for (const sellingRule of sellingRules) {
      let isValidAggregateAction;
      if (sellingRule?.aggregationAction?.name) {
        isValidAggregateAction = (
          Object.values(AggregateAction) as string[]
        ).includes(sellingRule.aggregationAction.name);
      } else {
        console.warn(
          `Did not receive AggregationAction value "${sellingRule.aggregationAction}", will ignore this equipment selling rule`
        );
        continue;
      }

      if (!isValidAggregateAction) {
        console.warn(
          `Received unrecognized AggregationAction value "${sellingRule.aggregationAction}", will ignore this equipment selling rule`
        );
        continue;
      }
      sellingRule.equipmentCollection?.items?.forEach((equipment) => {
        if (!equipment) {
          return;
        }

        if (sellingRule.aggregationAction?.name === AggregateAction.Add) {
          availableEquipment.add(equipment.sys?.id);
        }
        if (sellingRule.aggregationAction?.name === AggregateAction.Subtract) {
          equipmentToSubtract.add(equipment.sys?.id);
        }
      });
    }
    // Remove equipment to subtract afterward since rule ordering is not guaranteed
    for (const equipmentId of equipmentToSubtract) {
      availableEquipment.delete(equipmentId);
    }
    return Array.from(availableEquipment.keys());
  };

  export const mapEquipmentIdsToEquipmentSpecs = (
    opportunity: OpportunityInput,
    availableEquipment: KeystoneEquipment[],
    sellingRules: KeystoneEquipmentSellingRule[]
  ): KeystoneEquipment[] => {
    const filteredSellingRules = filterEquipmentSellingRules(opportunity, sellingRules);
    const equipmentIdsBySellingRules =
      getAvailableEquipmentIdsFromSellingRules(filteredSellingRules);

    const availableEquipmentBySellingRules: KeystoneEquipment[] =
      availableEquipment?.filter((spec) => {
        return equipmentIdsBySellingRules.includes(spec?.sys?.id);
      });

    return availableEquipmentBySellingRules;
  };
}
