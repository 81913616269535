import { createSelector } from "reselect";
import { selectDesign } from "src/features/design/designSlice";
import { selectWorkflowState } from "../workflowState/workflowStateSlice";
import { deriveUnMetFinalizeDesignRequirements } from "./deriveDesignRequirements";

export const deriveIsDesignReadyForFinalize = createSelector(
  [selectDesign, selectWorkflowState, deriveUnMetFinalizeDesignRequirements],
  (design, workflowState, unMetDesignRequirements) => {


    const isDesignReadyForFinalize =
      design &&
      !workflowState.isSimulateDesignInProgress &&
      !workflowState.isLegacyFinalizeDesignInProgress &&
      unMetDesignRequirements.length === 0
    return isDesignReadyForFinalize;
  },
);
