import { Box, styled, useTheme } from "@mui/material";
import { DesignToolsTheme } from "@sunrun/design-tools-themes";

type ProgressBarProps = {
  value: number;
  maxValue: number;
  minValue?: number;
  targetValue?: number;
  variant: "sunhour" | "offset";
}

const ProgressBar = ({ value, maxValue, minValue = 0, targetValue = 1, variant }: ProgressBarProps) => {
  const theme = useTheme<DesignToolsTheme>();
  const adjustedMaxValue = maxValue - minValue;
  const adjustedValue = value - minValue;
  const progressPercent = adjustedValue / adjustedMaxValue;
  const targetPercent = targetValue / maxValue;
  const isSunHour = variant === "sunhour";

  const progressColor = value > maxValue ? theme.palette.error.light : "#599cff";

  const ProgressBarContainer = styled(Box)({
    width: "100%",
    maxWidth: "74px",
    minWidth: "30px",
    height: "36px",
    position: "relative",
  });
  const ProgressBar = styled(Box)({
    width: "100%",
    height: "90%",
    background: isSunHour
      ? "linear-gradient(90deg, #599cff 0%, #e7effe 100%)"
      : "#D4D5DA",
    borderRadius: "4px",
    position: "absolute",
    overflow: "hidden",
    margin: "auto",
    top: 0,
    bottom: 0,
  });
  const Progress = styled(Box)({
    background: progressColor,
    width: `${progressPercent * 100}%`,
    maxWidth: "100%",
    height: "100%",
    position: "absolute",
  });
  const ProgressDashedLine = styled(Box)({
    width: `${isSunHour ? progressPercent * 100 : targetPercent * 100}%`,
    maxWidth: "100%",
    height: "100%",
    borderRight: "1.6px dashed #49517D",
    position: "absolute",
  });
  return (
    <ProgressBarContainer>
      <ProgressBar>
        {!isSunHour && <Progress />}
      </ProgressBar>
      <ProgressDashedLine />
    </ProgressBarContainer>
  )
}

export default ProgressBar;