"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.delegatingHubBucket = exports.localHubBucket = exports.s3HubBucket = exports.EXTERNAL_BUCKET = exports.AWS_REGION = void 0;
const client_s3_1 = require("@aws-sdk/client-s3");
const s3_request_presigner_1 = require("@aws-sdk/s3-request-presigner");
const serviceRegistry_1 = require("./serviceRegistry");
const design_tools_resource_names_1 = require("@sunrun/design-tools-resource-names");
const design_tools_runtime_context_1 = require("@sunrun/design-tools-runtime-context");
exports.AWS_REGION = "us-west-2";
var EXTERNAL_BUCKET;
(function (EXTERNAL_BUCKET) {
    EXTERNAL_BUCKET["LIGHTMILE"] = "lightmile";
})(EXTERNAL_BUCKET = exports.EXTERNAL_BUCKET || (exports.EXTERNAL_BUCKET = {}));
const LIGHTMILE_BUCKET_PROD = "lightmile-bb";
const LIGHTMILE_BUCKET_STAGING = "majstg-lightmile-bb";
class DelegatingHubBucket {
    put(input) {
        if (design_tools_runtime_context_1.RuntimeContext.testType === design_tools_runtime_context_1.TestType.LocalTest) {
            return exports.localHubBucket.put(input);
        }
        else {
            return exports.s3HubBucket.put(input);
        }
    }
    putJSON(input) {
        if (design_tools_runtime_context_1.RuntimeContext.testType === design_tools_runtime_context_1.TestType.LocalTest) {
            return exports.localHubBucket.putJSON(input);
        }
        else {
            return exports.s3HubBucket.putJSON(input);
        }
    }
    get(input) {
        if (design_tools_runtime_context_1.RuntimeContext.testType === design_tools_runtime_context_1.TestType.LocalTest) {
            return exports.localHubBucket.get(input);
        }
        else {
            return exports.s3HubBucket.get(input);
        }
    }
    getJSON(input) {
        if (design_tools_runtime_context_1.RuntimeContext.testType === design_tools_runtime_context_1.TestType.LocalTest) {
            return exports.localHubBucket.getJSON(input);
        }
        else {
            return exports.s3HubBucket.getJSON(input);
        }
    }
    exists(input) {
        if (design_tools_runtime_context_1.RuntimeContext.testType === design_tools_runtime_context_1.TestType.LocalTest) {
            return exports.localHubBucket.exists(input);
        }
        else {
            return exports.s3HubBucket.exists(input);
        }
    }
    delete(input) {
        if (design_tools_runtime_context_1.RuntimeContext.testType === design_tools_runtime_context_1.TestType.LocalTest) {
            return exports.localHubBucket.delete(input);
        }
        else {
            return exports.s3HubBucket.delete(input);
        }
    }
    getPresignedUrlToGet(input, expiration = 3600) {
        if (design_tools_runtime_context_1.RuntimeContext.testType === design_tools_runtime_context_1.TestType.LocalTest) {
            return exports.localHubBucket.getPresignedUrlToGet(input, expiration);
        }
        else {
            return exports.s3HubBucket.getPresignedUrlToGet(input, expiration);
        }
    }
    resolveBucketName() {
        return exports.s3HubBucket.resolveBucketName();
    }
}
class S3HubBucket {
    constructor() {
        this.s3Client = new client_s3_1.S3Client({ region: exports.AWS_REGION });
        this.resolveBucketName = () => __awaiter(this, void 0, void 0, function* () {
            return serviceRegistry_1.ServiceRegistry.resolveS3BucketName(design_tools_resource_names_1.Deployments.Hub, 'hub', design_tools_runtime_context_1.RuntimeContext.envOverride);
        });
        this.resolveLightmileBucketName = () => __awaiter(this, void 0, void 0, function* () {
            return design_tools_runtime_context_1.RuntimeContext.isPrdAccount() ? LIGHTMILE_BUCKET_PROD : LIGHTMILE_BUCKET_STAGING;
        });
        // Reference: https://transang.me/modern-fetch-and-how-to-get-buffer-output-from-aws-sdk-v3-getobjectcommand/
        this.streamToBuffer = (stream) => new Promise((resolve, reject) => {
            const chunks = [];
            stream.on('data', chunk => chunks.push(chunk));
            stream.once('end', () => resolve(Buffer.concat(chunks)));
            stream.once('error', reject);
        });
    }
    put(input) {
        return __awaiter(this, void 0, void 0, function* () {
            const bucketName = yield this.resolveBucketName();
            yield this.s3Client.send(new client_s3_1.PutObjectCommand({
                Bucket: bucketName,
                Key: input.key,
                Body: input.body,
            }));
            console.log(`Saved object at location "${bucketName}/${input.key}"`);
        });
    }
    putJSON(input) {
        return __awaiter(this, void 0, void 0, function* () {
            const { obj } = input, jsonInput = __rest(input, ["obj"]);
            const modifiedInput = Object.assign(Object.assign({}, jsonInput), { body: JSON.stringify(obj) });
            return this.put(modifiedInput);
        });
    }
    get(input) {
        return __awaiter(this, void 0, void 0, function* () {
            const bucketName = input.bucket === EXTERNAL_BUCKET.LIGHTMILE ? yield this.resolveLightmileBucketName() : yield this.resolveBucketName();
            const resp = yield this.s3Client.send(new client_s3_1.GetObjectCommand({
                Bucket: bucketName,
                Key: input.key
            }));
            const stream = resp.Body;
            const buffer = yield this.streamToBuffer(stream);
            return buffer;
        });
    }
    getJSON(input) {
        return __awaiter(this, void 0, void 0, function* () {
            const buffer = yield this.get(input);
            return JSON.parse(buffer.toString());
        });
    }
    delete(input) {
        return __awaiter(this, void 0, void 0, function* () {
            const bucketName = yield this.resolveBucketName();
            yield this.s3Client.send(new client_s3_1.DeleteObjectCommand({
                Bucket: bucketName,
                Key: input.key
            }));
            console.log(`Deleted object at location "${bucketName}/${input.key}"`);
        });
    }
    exists(input) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const bucketName = yield this.resolveBucketName();
                yield this.s3Client.send(new client_s3_1.HeadObjectCommand({
                    Bucket: bucketName,
                    Key: input.key
                }));
                // If the command was successful, return true
                return true;
            }
            catch (error) {
                // If there's an error (e.g., the object doesn't exist), return false
                return false;
            }
        });
    }
    getPresignedUrlToGet(input, expiration = 3600) {
        return __awaiter(this, void 0, void 0, function* () {
            const bucketName = yield this.resolveBucketName();
            const command = new client_s3_1.GetObjectCommand({
                Bucket: bucketName,
                Key: input.key
            });
            this.s3Client;
            return yield (0, s3_request_presigner_1.getSignedUrl)(this.s3Client, command, { expiresIn: expiration });
        });
    }
}
class LocalHubBucket {
    constructor() {
        this.localBucket = {};
    }
    put(input) {
        return __awaiter(this, void 0, void 0, function* () {
            this.localBucket[input.key] = input.body;
        });
    }
    putJSON(input) {
        return __awaiter(this, void 0, void 0, function* () {
            const { obj } = input, jsonInput = __rest(input, ["obj"]);
            const modifiedInput = Object.assign(Object.assign({}, jsonInput), { body: JSON.stringify(obj) });
            return this.put(modifiedInput);
        });
    }
    get(input) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.localBucket[input.key];
        });
    }
    getJSON(input) {
        return __awaiter(this, void 0, void 0, function* () {
            const obj = yield this.get(input);
            return JSON.parse(obj);
        });
    }
    delete(input) {
        return __awaiter(this, void 0, void 0, function* () {
            delete this.localBucket[input.key];
        });
    }
    exists(input) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.localBucket[input.key])
                return true;
            return false;
        });
    }
    getPresignedUrlToGet(input, expiration = 3600) {
        return __awaiter(this, void 0, void 0, function* () {
            // Return fake URL for testing
            return `https://www.example.com/${input.key}`;
        });
    }
}
exports.s3HubBucket = new S3HubBucket();
exports.localHubBucket = new LocalHubBucket();
exports.delegatingHubBucket = new DelegatingHubBucket();
