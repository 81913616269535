
// given a map of entries, applies a function to group the values by a different set of keys
export const groupEntriesBy = <K, V>(mapToGroup: Map<K, Set<V>>, fn: (key: K, value: Set<V>) => Map<K, Set<V>>): Map<K, Set<V>> => {
  let resultMap = new Map<K, Set<V>>()
  for (let [key, values] of mapToGroup.entries()) {
    const grouped = fn(key, values)
    for (const [groupedKey, groupedValue] of grouped.entries()) {
      // @ts-ignore for some reason the compiler doesn't recognize that we're checking for undefined value case <shrug/>
      const existingGroupSet: Set<V> = resultMap.get(groupedKey) !== undefined ? resultMap.get(groupedKey) : new Set<V>()
      const newGroupSet = new Set([...existingGroupSet, ...groupedValue])
      resultMap.set(groupedKey, newGroupSet)
    }
  }
  return resultMap
}
