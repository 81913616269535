import type { Promotion } from "@sunrun/design-tools-domain-model";
import type { WorkspacePayloadAction } from "src/types/state-management/action";
import type { WorkspaceAction, WorkspaceState } from "src/hooks/useWorkspace";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type PromotionsState = {
  value: Promotion[] | undefined;
}

const initialState: PromotionsState = {
  value: undefined,
}; 

export const promotionsSlice = createSlice({
  name: "promotions",
  initialState,
  reducers: {
    setPromotions: (state: PromotionsState, action: PayloadAction<Promotion[]>) => {
      state.value = action.payload
    },
  }
});

export const { setPromotions } = promotionsSlice.actions
export default promotionsSlice.reducer

// TODO: Remove the following and transition fully from WorkspaceState to RTK store
export type PromotionWorkspaceAction = WorkspacePayloadAction<Promotion[], "setPromotions">;

export interface PromotionDisplayComponentProps {
  isEligible: boolean
  isApplied: boolean
  onCallToActionClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const promotionsWorkspaceReducer = (state: WorkspaceState, action: WorkspaceAction): Array<Promotion> | undefined => {
  switch (action.type) {
    case "setPromotions": {
      return action.payload;
    }
    default: {
      return state.promotions;
    }
  }
};
