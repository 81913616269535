import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { WorkspaceAction, WorkspaceState } from "../../hooks/useWorkspace";
import { WorkspaceEmptyAction, WorkspacePayloadAction } from "src/types/state-management/action";
import { createSelector } from "reselect";
import { LayoutPlaneEdge } from "@sunrun/design-tools-domain-model";

// Redux
export type SetbackRule = {
  id: string;
  description: string;
};

// setbackRules refers to design wide Rules for current Design
// layoutPlaneEdgeRules refers to per roof design codes coming directly from LM calculateSetbacks endpoint
export type SetbackRulesState = {
  setbackRules: SetbackRule[];
  layoutPlaneEdgeRules: (LayoutPlaneEdge | null)[] | null | undefined;
};

export const setbackRulesInitialState: SetbackRulesState = {
  setbackRules: [],
  layoutPlaneEdgeRules: [],
};

export const setbackRulesSlice = createSlice({
  name: "setbackRules",
  initialState: setbackRulesInitialState,
  reducers: {
    setSetbackRules: (state: SetbackRulesState, action: PayloadAction<SetbackRule[]>) => {
      state.setbackRules = action.payload;
    },
    setLayoutPlaneEdgeRules: (
      state: SetbackRulesState,
      action: PayloadAction<LayoutPlaneEdge[]>,
    ) => {
      state.layoutPlaneEdgeRules = action.payload;
    },
  },
});

export const { setSetbackRules, setLayoutPlaneEdgeRules } = setbackRulesSlice.actions;
export default setbackRulesSlice.reducer;

// Workspace State
export type SetbackRulesAction =
  | WorkspacePayloadAction<SetbackRule[], "setSetbackRules">
  | WorkspacePayloadAction<LayoutPlaneEdge[], "setLayoutPlaneEdgeRules">
  | WorkspaceEmptyAction<"clearSetbackRules">;

const selectSetbackRules = (state: WorkspaceState) => state.setbackRules;

export const setbackRulesReducer = (
  state: WorkspaceState,
  action: WorkspaceAction,
): SetbackRulesState => {
  switch (action.type) {
    // Set setback rules
    case "setSetbackRules": {
      const updatedSetbackRules = action.payload.map((rule) => rule);
      return {
        setbackRules: updatedSetbackRules,
        layoutPlaneEdgeRules: state.setbackRules.layoutPlaneEdgeRules,
      };
    }
    case "setLayoutPlaneEdgeRules": {
      const updatedLayoutPlaneEdgeRules = action.payload.map((rule) => rule);
      return {
        setbackRules: state.setbackRules.setbackRules,
        layoutPlaneEdgeRules: updatedLayoutPlaneEdgeRules,
      };
    }
    // Clear setback rules
    case "clearSetbackRules": {
      return setbackRulesInitialState;
    }
    default: {
      return state.setbackRules;
    }
  }
};

export const deriveSetbackRules = createSelector(
  [selectSetbackRules],
  (rules: SetbackRulesState) => {
    return rules;
  },
);
