import { Stack, Typography, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { formatNumber } from "@sunrun/design-tools-themes";

export type StatDisplayProps = {
  title: string;
  stat: string | number | undefined;
  unit?: string | undefined;
  toDecimal?: number;
  noWrap?: boolean;
  onEditClick?: () => void;
};

const StatDisplay = ({
  title,
  stat = "N/A",
  unit,
  toDecimal = 0,
  noWrap = false,
  onEditClick,
}: StatDisplayProps) => {
  stat = isNaN(Number(stat))
    ? stat
    : parseFloat(Number(stat).toFixed(toDecimal));
  const formattedStat = isNaN(Number(stat))
    ? stat
    : formatNumber({
        value: Number(stat),
        maximumFractionDigits: toDecimal,
        replaceZeroWithDefault: true,
      });
  return (
    <Stack>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography variant="body2" color="primary" noWrap={noWrap}>
          {title}
        </Typography>
        {onEditClick && (
          <IconButton size="small" onClick={onEditClick}>
            <EditIcon fontSize="inherit"/>
          </IconButton>
        )}
      </Stack>
      <Typography variant="caption" whiteSpace="pre-line">
        {formattedStat} {unit}
      </Typography>
    </Stack>
  );
};

export default StatDisplay;
