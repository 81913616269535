import * as React from "react";
import { useQuery } from "react-query";
import { repository } from "@sunrun/design-tools-graphql-clients";
import { SolarResource, TestFixtures } from "@sunrun/design-tools-domain-model";
import { useWorkspace } from "src/hooks/useWorkspace";
import { useErrorHandler } from "react-error-boundary";
import { useAppDispatch, useAppSelector } from "src/store";
import { setSolarResourceExpanded } from "src/features/solarResourceExpanded/solarResourceExpandedSlice";
import { useLoadingScreen } from "@sunrun/design-tools-loading-screen";
import {
  LoadingProcessGroups,
  LoadingProcessNames,
} from "src/types/LoadingScreenProcess";
import { deriveDesignClass, setDesign } from "src/features/design/designSlice";

type useSolarResourceExpandedProps = {
  solarResourceExpandedId?: string;
  solarResourceExpandedVersion?: number;
};

export const useSolarResourceExpanded = ({
  solarResourceExpandedId,
  solarResourceExpandedVersion,
}: useSolarResourceExpandedProps) => {
  const { state, dispatch: workspaceDispatch } = useWorkspace();
  const dispatch = useAppDispatch();
  const { helpers: loadingScreenHelpers } = useLoadingScreen();
  const isRecalculateFirecodeSetbacksEnabled = useAppSelector(
    ({ settings }) => settings.isRecalculateFirecodeSetbacksEnabled
  );
  const { id: expandedSolarResourceId, fetchError } = useAppSelector(
    (store) => store.solarResourceExpanded
  );

  const handleError = useErrorHandler();
  const design = deriveDesignClass(state);

  // Gets the expanded solarResource data from S3 after Id is passed back from second importLightmileProject call
  const getSolarResourceExpanded = async () => {
    if (solarResourceExpandedId) {
      console.log(
        "Retrieving expanded solar resource from s3 using id: ",
        solarResourceExpandedId
      );
      return repository.get(
        SolarResource,
        solarResourceExpandedId,
        solarResourceExpandedVersion || 1
      );
    }
  };

  const query = useQuery(
    ["getSolarResourceExpanded", solarResourceExpandedId],
    getSolarResourceExpanded,
    {
      refetchOnWindowFocus: false, // TODO support these use cases for conflict resolution
      refetchOnReconnect: false,
      enabled: !!solarResourceExpandedId, // https://react-query.tanstack.com/guides/dependent-queries
      onSuccess: (newSolarResource: SolarResource) => {
        dispatch(
          setSolarResourceExpanded(newSolarResource.getISolarResource())
        );
        workspaceDispatch({
          type: "setSolarResourceExpanded",
          payload: newSolarResource,
        });
        // update design with solarResourceExpanded id and version
        const updatedDesign =
          design?.updateDesignWithSolarResourceExpandedIdandVersion(
            newSolarResource
          );
        if (updatedDesign) {
          workspaceDispatch({ type: "setDesign", payload: updatedDesign });
          dispatch(setDesign(updatedDesign.getIDesign()));
        }
      },
      onError: handleError,
    }
  );

  // Loading UI Logic for expanded solar resource
  // Show loading screen if user is opted in but solar resource id has not been retrieved.
  React.useEffect(
    function addLoadingScreenProcess() {
      if (
        isRecalculateFirecodeSetbacksEnabled &&
        !fetchError &&
        !expandedSolarResourceId
      ) {
        loadingScreenHelpers.addProcess({
          group: LoadingProcessGroups.IHD_UPDATING,
          name: LoadingProcessNames.FETCH_EXPANDED_IRRADIANCE,
        });
        return function completeLoadingScreenProcess() {
          loadingScreenHelpers.completeProcess(
            LoadingProcessNames.FETCH_EXPANDED_IRRADIANCE
          );
        };
      }
    },
    [isRecalculateFirecodeSetbacksEnabled, fetchError, expandedSolarResourceId]
  );
};
