import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {produce} from "immer";
import {WorkspaceEmptyAction, WorkspacePayloadAction} from "../../types/state-management/action";
import {LatLngBounds} from "leaflet";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type HeatMap = {
  isVisible: boolean
  bounds?: LatLngBounds  // Causing warning: A non-serializable value was detected in the state https://sunrun.jira.com/browse/ET-1617 
}

export type HeatMapState = {
  isVisible: boolean
  bounds?: LatLngBounds
};

export const initialState: HeatMapState = {
  isVisible: false,
  bounds: undefined
}

export const heatMapSlice = createSlice({
  name: "heatMap",
  initialState,
  reducers: {
    setHeatMapBounds: (state: HeatMapState, action: PayloadAction<LatLngBounds>) => {
      state.bounds = action.payload;
    },
    setHeatMapVisibility: (state: HeatMapState, action: PayloadAction<boolean>) => {
      state.isVisible = action.payload;
    },
    toggleHeatMapVisibility: (state: HeatMapState) => {
      state.isVisible = !state.isVisible;
    },
  },
});

export const { setHeatMapBounds, setHeatMapVisibility, toggleHeatMapVisibility } = heatMapSlice.actions
export default heatMapSlice.reducer

// TODO: Remove the following and transition fully from WorkspaceState to RTK store
export type HeatMapWorkspaceAction =
  | WorkspacePayloadAction<LatLngBounds, 'setHeatMapBounds'>
  | WorkspacePayloadAction<boolean, 'setHeatMapVisibility'>
  | WorkspaceEmptyAction<'toggleHeatMapVisibility'>

export const heatMapWorkspaceReducer = (state: WorkspaceState, action: WorkspaceAction): HeatMap => {
  switch (action.type) {
    case 'setHeatMapBounds': {
      return produce(state.heatMap, draft => {
        draft.bounds = action.payload
      })
    }
    case 'setHeatMapVisibility': {
      return produce(state.heatMap, draft => {
        draft.isVisible = action.payload
      })
    }
    case 'toggleHeatMapVisibility': {
      return produce(state.heatMap, draft => {
        draft.isVisible = !state.heatMap.isVisible
      })
    }
    default : {
      return state.heatMap
    }
  }
}
