import { InfoModal } from "./InfoModal";
import { ErrorModal } from "./ErrorModal";
import FinancialProductsModal from "./FinancialProductsModal";
import WhatsNewModal from "./WhatsNewModal";
import { HotkeyDescriptionsModal } from "./HotkeyDescriptionsModal";
import { ModuleIssuesModal } from "./ModuleIssuesModal";
import { OfferUpdateConfirmationModal} from "./OfferUpdateConfirmationModal";
import { ProductEquipmentMappingErrorModal } from "./ProductEquipmentMappingErrorModal";
import { CancelContinueModal } from "./CancelContinueModal";
import { SelectModuleModal } from "./SelectModuleModal";
import { SelectInverterModal } from "./SelectInverterModal";
import { SelectBatteryModal } from "./SelectBatteryModal";
import { DesignWarningsModal } from "./DesignWarningsModal";
import { SelectDesignVariantModal } from "./SelectDesignVariantModal";
import { ConfigureManualBatteriesModal } from "./ConfigureManualBatteriesModal";

export const EQUIPMENT_MODAL_BOX_SIZING = 1.5
export const EQUIPMENT_MODAL_SPACING = 2
export const EQUIPMENT_MODAL_FORM_SELECT_MAX_HEIGHT = "212px"

export {
  InfoModal,
  ErrorModal,
  FinancialProductsModal,
  WhatsNewModal,
  HotkeyDescriptionsModal,
  ModuleIssuesModal,
  OfferUpdateConfirmationModal,
  ProductEquipmentMappingErrorModal,
  CancelContinueModal,
  SelectModuleModal,
  SelectInverterModal,
  SelectBatteryModal,
  DesignWarningsModal,
  SelectDesignVariantModal,
  ConfigureManualBatteriesModal,
}
