import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {WorkspaceEmptyAction, WorkspacePayloadAction, WorkspaceEvent} from "../../types/state-management/action";
import {Module} from "@sunrun/design-tools-domain-model";
import { deriveDesignClass } from "../design/designSlice";
import { deriveSiteModelClass } from "../siteModel/siteModelSlice";
import { deriveMarquee } from "../marquee/marqueeSlice";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type ModuleSelectionState = {
  selectedModuleIds: Set<string> | undefined
}

const initialState: ModuleSelectionState = {
  selectedModuleIds : undefined,
}

export const moduleSelectionSlice = createSlice({
  name: "moduleSelection",
  initialState,
  reducers: {
    clearSelectedModules: (state: ModuleSelectionState) => {
      state.selectedModuleIds = undefined;
    },
    //TODO: move more actions https://sunrun.jira.com/browse/ET-1664
  }
});

export const { clearSelectedModules } = moduleSelectionSlice.actions
export default moduleSelectionSlice.reducer

// TODO: Remove the following and transition fully from WorkspaceState to RTK store
export type ModuleSelection = {
  selectedModuleIds: Set<string>
}

export type ModuleSelectionWorkspaceAction =
  | WorkspaceEmptyAction<'clearSelectedModules'>
  | WorkspacePayloadAction<WorkspaceEvent, 'selectOneModule'>
  | WorkspacePayloadAction<WorkspaceEvent, 'selectAllModulesOnRoofFace'>
  | WorkspaceEmptyAction<'selectModulesByMarquee'>

export const moduleSelectionWorkspaceReducer = (state: WorkspaceState, action: WorkspaceAction): ModuleSelection => {
  const design = deriveDesignClass(state);
  const siteModel = deriveSiteModelClass(state);
  const marquee = deriveMarquee(state);
  if (!(design && siteModel)) {
    return state.moduleSelection
  }
  switch (action.type) {
    case "clearSelectedModules": {
      return {
        selectedModuleIds: new Set()
      }
    }
    case "selectOneModule": {
      const layer = action.payload.propagatedFrom.layer as Module
      const moduleId = layer.id
      if (state.moduleSelection.selectedModuleIds.has(moduleId)) {
        return state.moduleSelection // bail out
      } else {
        const newSet = new Set<string>([moduleId])
        const newlySelectedModule = design.getModuleById(moduleId)
        // only leave modules selected if they are on the same roof face as the newly selected module
        if (newlySelectedModule){
          state.moduleSelection.selectedModuleIds.forEach((it: string) => {
              const module = design?.getModuleById(it)
              if (module?.properties.roofFaceId === newlySelectedModule.properties.roofFaceId) {
                newSet.add(module.id as string)
              }
            }
          )
        }
        return {
          selectedModuleIds: newSet
        }
      }
    }
    case "selectAllModulesOnRoofFace": {
      if (design && design.modules) {
        const module = action.payload.propagatedFrom.layer as Module
        const roofFaceId = module.properties.roofFaceId
        return {
          selectedModuleIds: new Set(
            design.modules.filter(it => it.properties.roofFaceId === roofFaceId).map(it => it.id)
          )
        }
      }
      return state.moduleSelection // bail out
    }
    case "selectModulesByMarquee": {
      const roofFaceId = marquee.roofFaceId
      const selectionPolygon = marquee.selectionPolygon
      const roofFaceModules = design!.modules.filter(module => module.properties.roofFaceId === roofFaceId)
      const modulesToSelect = roofFaceModules.filter(module => selectionPolygon!.intrudesOn(module))
      return {
        selectedModuleIds: new Set(
          modulesToSelect.map(module => module.id)
        )
      }
    }
    default: {
      return state.moduleSelection
    }
  }
}
