import {createSelector} from "reselect";
import {selectCustomer} from "src/features/customer/customerSlice"
import {deriveDesignClass, selectDesign, selectDesignIsUsingMicroInverter} from "src/features/design/designSlice"
import {deriveDesignConstraintsClass} from "src/features/designConstraints/designConstraintsSlice"
import {deriveProductionSimulationClass, selectProductionSimulation} from "src/features/productionSimulation/productionSimulationSlice"
import {selectSettingsEnforceDesignRestrictionsForSales} from "src/features/settings/settingsSlice";
import {DesignRequirement, designRequirementsToValidate, DesignValidator, EquipmentSpecifications} from "@sunrun/design-tools-domain-model";
import {deriveLowResProductionSimulation} from "../lowResProductionSimulation/deriveLowResProductionSimulation";
import {deriveCollidingModules} from "./deriveCollidingModules";
import {deriveIslandedModules} from "./deriveIslandedModules";
import {deriveShortStringModules} from "./deriveShortStringModules";
import { selectHasUserInverterUpdate } from "../workflowState/workflowStateSlice";
import { selectOffer } from "../offer/offerSlice";
import { WorkspaceState } from "src/hooks/useWorkspace";
import { selectSiteModel } from "../siteModel/siteModelSlice";

// Using a pass through input selector here so that we can pass in experimentalEquipmentSpecs from the redux store
const selectExperimentalEquipmentSpecs = (state: WorkspaceState, experimentalEquipmentSpecs?: Partial<EquipmentSpecifications>) => experimentalEquipmentSpecs;

// Derives a list of test equipment currently used on the design
// We use this to block users from being able to proceed to pricing and proposal generation with experimental equipment
export const deriveExperimentalEquipmentOnDesign = createSelector(
  [selectDesign, selectExperimentalEquipmentSpecs],
  (design, experimentalEquipmentSpecs) => {
    if (experimentalEquipmentSpecs && design && design.selectedEquipmentSpecificationIds) {
      const {selectedEquipmentSpecificationIds} = design
      // We only use this test equipment override for modules currently
      const experimentalEquipmentOnDesign = experimentalEquipmentSpecs.moduleSpecifications?.filter(spec => selectedEquipmentSpecificationIds.selectedModuleSpecificationIds.includes(spec.id))
      if (experimentalEquipmentOnDesign?.length) {
        return experimentalEquipmentOnDesign;
      }
    }
    return false;
  }
)

export const deriveDesignRequirementsForDesignStatus = createSelector(
  [selectSettingsEnforceDesignRestrictionsForSales],
  (enforceDesignRestrictionsForSales) => {
    return enforceDesignRestrictionsForSales ?
      designRequirementsToValidate.iHD.designStatus : designRequirementsToValidate.iHD.lightmileHost.designStatus
  }
)

export const deriveDesignRequirementsForFinalizeDesign = createSelector(
  [selectSettingsEnforceDesignRestrictionsForSales, selectDesignIsUsingMicroInverter],
  (enforceDesignRestrictionsForSales, designIsUsingMicroInverter) => {
    if (enforceDesignRestrictionsForSales) {
      return designIsUsingMicroInverter ?
        designRequirementsToValidate.iHD.finalizeDesign.microInverter :
        designRequirementsToValidate.iHD.finalizeDesign.stringInverter
    } else {
      return designIsUsingMicroInverter ?
        designRequirementsToValidate.iHD.lightmileHost.finalizeDesign.microInverter :
        designRequirementsToValidate.iHD.lightmileHost.finalizeDesign.stringInverter
    }
  }
)

export const deriveUnMetDesignStatusRequirements = createSelector(
  [
    deriveDesignRequirementsForDesignStatus,
    deriveDesignClass,
    deriveLowResProductionSimulation,
    deriveProductionSimulationClass,
    selectCustomer,
    deriveDesignConstraintsClass,
    deriveCollidingModules,
    deriveIslandedModules,
    deriveShortStringModules,
    selectHasUserInverterUpdate,
    selectOffer,
    deriveExperimentalEquipmentOnDesign,
    selectSiteModel
  ],
  (
    designRequirementsToValidate,
    design,
    lowRes,
    productionSimulation,
    customer,
    designConstraints,
    collidingModules,
    islandedModules,
    shortStringModules,
    hasUserInverterUpdate,
    offer,
    experimentalEquipmentOnDesign,
    siteModel
  ) => {
    const designRequirements = DesignValidator.determineUnMetDesignRequirements(
      designRequirementsToValidate,
      design,
      lowRes,
      productionSimulation,
      customer,
      designConstraints,
      collidingModules,
      islandedModules,
      shortStringModules,
      hasUserInverterUpdate,
      offer,
      siteModel
    );
    if (experimentalEquipmentOnDesign) {
      designRequirements.push(DesignRequirement.DESIGN_HAS_TEST_EQUIPMENT)
      const equipmentStrings = experimentalEquipmentOnDesign.map(spec => `${spec.manufacturer} ${spec.model}`).join(', ')
      designRequirements.push(`If you need to proceed to pricing, please remove the following equipment from the design: ${equipmentStrings}` as DesignRequirement)
    }
    return designRequirements;
  }
);

export const deriveUnMetFinalizeDesignRequirements = createSelector(
  [
    deriveDesignRequirementsForFinalizeDesign,
    deriveDesignClass,
    deriveLowResProductionSimulation,
    deriveProductionSimulationClass,
    selectCustomer,
    deriveDesignConstraintsClass,
    deriveCollidingModules,
    deriveIslandedModules,
    deriveShortStringModules,
    selectHasUserInverterUpdate,
    selectOffer,
    selectSiteModel
  ],
  (
    designRequirementsToValidate,
    design,
    lowRes,
    productionSimulation,
    customer,
    designConstraints,
    collidingModules,
    islandedModules,
    shortStringModules,
    hasUserInverterUpdate,
    offer,
    siteModel
  ) => {
    return DesignValidator.determineUnMetDesignRequirements(
      designRequirementsToValidate,
      design,
      lowRes,
      productionSimulation,
      customer,
      designConstraints,
      collidingModules,
      islandedModules,
      shortStringModules,
      hasUserInverterUpdate,
      offer,
      siteModel
    );
  }
);
