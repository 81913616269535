export const ArrayUtil = {
  foldr: <A, B>(f: (x: A, acc: B) => B, acc: B, [h, ...t]: A[]): B => h === undefined ? acc : f(h, ArrayUtil.foldr(f, acc, t)),
  foldl: <A, B>(f: (x: A, acc: B) => B, acc: B, [h, ...t]: A[]): B => h === undefined ? acc : ArrayUtil.foldl(f, f(h, acc), t),

  range: function range(start: number, toInclude: number): Array<number> {
    const fromZero = Array.from(Array(toInclude - start + 1).keys())
    return fromZero.map(i => i + start)
  },

  consecutiveIntegers: function consecutiveIntegers(count: number, startingWith = 0): number[] {
    return ArrayUtil.range(startingWith, startingWith + count - 1)
  },

  rotate: function rotate<T extends {}>(arr: Array<T>, clockWise: Boolean = true): Array<T> {
    if( arr.length === 0) {
      return arr;
    }

    if (clockWise) {
      return [arr[arr.length - 1]!].concat(arr.slice(0, arr.length - 1))
    } else {
      return arr.slice(1, arr.length).concat(arr[0]!)
    }
  },

  getLongest: function getLongest<T>(arrOfArr: Array<Array<T>>): Array<T> {
    if (arrOfArr.filter((arr) => arr.length != 0).length == 0) {
      return Array<T>()
    } else {
      const maxSize = Math.max(...arrOfArr.map((arr) => arr.length))
      return arrOfArr.find((arr) => arr.length == maxSize)!!
    }
  },

  //get the item in array with smallest f(item)
  getSmallest: function getSmallest<T>(arr: Array<T>, f: (item: T) => number) {
    const arrWithFApplied = arr.map(item => f(item))
    const minX = Math.min(...arrWithFApplied)
    return arr[arrWithFApplied.findIndex(x => x === minX)]
  },

  unique: function unique<T>(arr: Array<T>, compareFunc: (a: T, b: T) => boolean): Array<T> {
    return arr.filter((item1, index) => {
      const indexOfItem1 = arr.findIndex(item2 => compareFunc(item1, item2))
      return indexOfItem1 === index
    })
  },

  zip: function zip<A, B>(a: A[], b: B[]): Array<[A, B | undefined]> {
    return a.map((k, i) => [k, b[i]]);
  },

  frequency: function frequency<T>(arr: T[], compareFunc: (a: T, b: T) => boolean): Map<T, number> {
    var map = new Map();
		for (var i = 0; i < arr.length; i++) {
			//if (map.has(arr[i])) {
      const keysMet = [...map.keys()].filter(key => compareFunc(key, arr[i]!))
      if (keysMet.length > 0) {
				map.set(keysMet[0], map.get(keysMet[0]) + 1);
			} else {
				map.set(arr[i], 1);
			}
		}
    return map
  }
}