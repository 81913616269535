import {useMutation} from "react-query";
import {CommandType, DesignImagesCreated} from "@sunrun/design-tools-domain-model";
import {processManagerClient} from "@sunrun/design-tools-graphql-clients";
import {useWorkspace} from "./useWorkspace";

export const useTriggerCreateDesignImages = () => {
  const {state} = useWorkspace();
  const {design} = state;

  const createDesignImages = async () => {
    return createDesignImagesMutation.mutateAsync()
  }

  const createDesignImagesMutation = useMutation((): Promise<DesignImagesCreated> => {
    return processManagerClient.createDesignImagesAsync({
      designId: design!.id,
      designVersion: design!.version,
      type: CommandType.CreateDesignImages
    });
  }, {
    onError: (error: Error) => {
      console.error("Error creating design image");
      console.error(error);
    },
    onSuccess: () => {
      console.log("Design image created");
    }
  });

  return {
    createDesignImages
  }
}
