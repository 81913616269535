import {round} from "../geometry/rounding"
import {Position} from "../geometry/point";

export class Vector3D  {
  readonly x: number;
  readonly y: number;
  readonly z: number;

  constructor(x: number, y: number, z: number) {

    this.x = x;
    this.y = y;
    this.z = z;
  }

  equals(otherVector3D: Vector3D, toDecimalPlaces: number = 2) {
    return round(this.x, toDecimalPlaces) == round(otherVector3D.x, toDecimalPlaces) &&
      round(this.y, toDecimalPlaces) == round(otherVector3D.y, toDecimalPlaces) &&
      round(this.z, toDecimalPlaces) == round(otherVector3D.z, toDecimalPlaces)
  }

  normalize() {
    const norm = Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z);
    return new Vector3D(this.x/norm, this.y/norm, this.z/norm)
  }

  toPosition(): Position {
    return [this.x, this.y, this.z]
  }

  static dot(A: Vector3D, B: Vector3D): number {
    return (A.x*B.x)+(A.y*B.y)+(A.z*B.z);
  }
  public length():number {
    return Math.sqrt(Vector3D.dot(this,this));
  }
  static cross(a: Vector3D, b: Vector3D): Vector3D {
    const {x:a1,y:a2,z:a3} = a;
    const {x:b1,y:b2,z:b3} = b;
  return new Vector3D(a2*b3-a3*b2, a3*b1-a1*b3,a1*b2-a2*b1);
  }
  static add(a: Vector3D, b: Vector3D){
    const {x:a1,y:a2,z:a3} = a;
    const {x:b1,y:b2,z:b3} = b;
    return new Vector3D(a1+b1,a2+b2,a3+b3);
  }
  static sub(a: Vector3D, b: Vector3D){
    const {x:a1,y:a2,z:a3} = a;
    const {x:b1,y:b2,z:b3} = b;
    return new Vector3D(a1-b1,a2-b2,a3-b3);
  }
  static scale(a: Vector3D, s:number){
    return new Vector3D(a.x*s,a.y*s,a.z*s);
  }
  // return the length of a's shadow in the direction of b
  static scalarProject(a:Vector3D, b:Vector3D): number {
    return this.dot(a,b.normalize())
  }
  static midpoint(a:Vector3D, b:Vector3D): Vector3D {
    return new Vector3D((a.x + b.x)/2, (a.y + b.y)/2, (a.z + b.z)/2)
  }
}

export const xAxis = new Vector3D(1, 0, 0)
export const yAxis = new Vector3D(0, 1, 0)
export const zAxis = new Vector3D(0, 0, 1)
