import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SiteSurvey } from "@sunrun/site-survey-graphql";

export type SiteSurveyState  = {
  value: SiteSurvey | undefined;
}

export const initialState: SiteSurveyState = {
  value: undefined,
};

export const siteSurveySlice = createSlice({
  name: "siteSurvey",
  initialState,
  reducers: {
    setSiteSurvey: (state: SiteSurveyState, action: PayloadAction<SiteSurvey>) => {
      state.value = action.payload;
    },
  }
});

export const { setSiteSurvey } = siteSurveySlice.actions;
export default siteSurveySlice.reducer;
