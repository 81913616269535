import { createSelector } from "reselect";
import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {WorkspacePayloadAction} from "../../types/state-management/action";
import {ISolarResource, SolarResource} from "@sunrun/design-tools-domain-model";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { selectSolarResourceExpanded } from "../solarResourceExpanded/solarResourceExpandedSlice";

export type SolarResourceState = {
  value: ISolarResource | undefined;
}

export const initialState: SolarResourceState = {
  value: undefined,
};

export const solarResourceSlice = createSlice({
  name: "solarResource",
  initialState,
  reducers: {
    setSolarResource: (state: SolarResourceState, action: PayloadAction<ISolarResource>) => {
      state.value = action.payload
    },
  },
});

export const { setSolarResource } = solarResourceSlice.actions
export default solarResourceSlice.reducer

// TODO: Remove the following and transition fully from WorkspaceState to RTK store
export type SolarResourceWorkspaceAction = WorkspacePayloadAction<SolarResource, 'setSolarResource'>

export const selectSolarResource = (state: WorkspaceState) => state.solarResource;

export const solarResourceWorkspaceReducer = (state: WorkspaceState, action: WorkspaceAction): ISolarResource | undefined => {
  switch (action.type) {
    case 'setSolarResource': {
      return action.payload.getISolarResource()
    }
    default: {
      return state.solarResource
    }
  }
}

const selectIsRecalculateFirecodeSetbacksEnabled = (state:WorkspaceState) => state.settings.isRecalculateFirecodeSetbacksEnabled;

// select normal solar resource or expanded solar resource based on opt in
export const deriveSolarResourceClass = createSelector(
  [
    selectIsRecalculateFirecodeSetbacksEnabled,
    selectSolarResource,
    selectSolarResourceExpanded,
  ],
  (
    isRecalculateFirecodeSetbacksEnabled,
    solarResource,
    solarResourceExapanded
  ): SolarResource | undefined => {
    if(isRecalculateFirecodeSetbacksEnabled && solarResourceExapanded){
      console.log('deriveSolarResourceClass using expanded solarResource id: ', solarResourceExapanded.id)
      return new SolarResource(solarResourceExapanded);
    }
    console.log('deriveSolarResourceClass using normal solarResource id: ', solarResource?.id)
    return solarResource ? new SolarResource(solarResource) : undefined;
  }
);
