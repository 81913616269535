import { WorkspaceAction, WorkspaceState } from "src/hooks/useWorkspace";
import {
  WorkspaceEmptyAction,
  WorkspacePayloadAction,
} from "../../types/state-management/action";
import { DesignVariantName } from "@sunrun/design-tools-domain-model";
import { produce } from "immer";
import { createSelector } from "reselect";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type InfoModalConfig = {
  title?: string;
  message: string;
};

export type CancelContinueModalConfig = {
  title?: string;
  message: string;
  onContinue?: () => void;
  cancelText?: string;
  continueText?: string;
};

export type ErrorModalConfig = {
  title?: string;
  message?: string;
  error?: Error;
};

export type Modal = {
  infoModal: InfoModalConfig | undefined;
  errorModal: ErrorModalConfig | undefined;
  financialProductsOpen: boolean;
  isBookmarkDesignOpen: boolean;
  isOfferUpdateConfirmationModalOpen: boolean;
  isProductEquipmentMappingErrorModalOpen: boolean;
  productEquipmentMappingErrorModal: ErrorModalConfig | undefined;
  isWhatsNewOpen: boolean;
  isHotkeyDescriptionsOpen: boolean;
  isModuleIssuesOpen: boolean;
  cancelContinueModal: CancelContinueModalConfig | undefined;
  isSelectModuleOpen: boolean;
  isSelectInverterOpen: boolean;
  isSelectStorageOpen: boolean;
  isDesignWarningsModalOpen: boolean;
  isSelectDesignVariantModalOpen: boolean;
  selectDesignVariantDefault: DesignVariantName | undefined;
  isSetbackRulesOpen: boolean;
};

export const modalInitialState: Modal = {
  infoModal: undefined,
  errorModal: undefined,
  financialProductsOpen: false,
  isBookmarkDesignOpen: false,
  isOfferUpdateConfirmationModalOpen: false,
  isProductEquipmentMappingErrorModalOpen: false,
  productEquipmentMappingErrorModal: undefined,
  isWhatsNewOpen: false,
  isHotkeyDescriptionsOpen: false,
  isModuleIssuesOpen: false,
  cancelContinueModal: undefined,
  isSelectModuleOpen: false,
  isSelectInverterOpen: false,
  isSelectStorageOpen: false,
  isDesignWarningsModalOpen: false,
  isSelectDesignVariantModalOpen: false,
  selectDesignVariantDefault: undefined,
  isSetbackRulesOpen: false,
};

type ModalState = {
  infoModal: InfoModalConfig | undefined;
  errorModal: ErrorModalConfig | undefined;
  financialProductsOpen: boolean;
  isBookmarkDesignOpen: boolean;
  isOfferUpdateConfirmationModalOpen: boolean;
  isProductEquipmentMappingErrorModalOpen: boolean;
  productEquipmentMappingErrorModal: ErrorModalConfig | undefined;
  isWhatsNewOpen: boolean;
  isHotkeyDescriptionsOpen: boolean;
  isModuleIssuesOpen: boolean;
  cancelContinueModal: CancelContinueModalConfig | undefined;
  isSelectModuleOpen: boolean;
  isSelectInverterOpen: boolean;
  isConfigureManualInverterOpen: boolean;
  isSelectStorageOpen: boolean;
  isDesignWarningsModalOpen: boolean;
  isSelectDesignVariantModalOpen: boolean;
  selectDesignVariantDefault: DesignVariantName | undefined;
  isSetbackRulesOpen: boolean;
  isConfigureManualBatteriesModalOpen: boolean;
};

const initialState: ModalState = {
  infoModal: undefined,
  errorModal: undefined,
  financialProductsOpen: false,
  isBookmarkDesignOpen: false,
  isOfferUpdateConfirmationModalOpen: false,
  isProductEquipmentMappingErrorModalOpen: false,
  productEquipmentMappingErrorModal: undefined,
  isWhatsNewOpen: false,
  isHotkeyDescriptionsOpen: false,
  isModuleIssuesOpen: false,
  cancelContinueModal: undefined,
  isSelectModuleOpen: false,
  isSelectInverterOpen: false,
  isConfigureManualInverterOpen: false,
  isSelectStorageOpen: false,
  isDesignWarningsModalOpen: false,
  isSelectDesignVariantModalOpen: false,
  selectDesignVariantDefault: undefined,
  isSetbackRulesOpen: false,
  isConfigureManualBatteriesModalOpen: false,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setInfoModal: (
      state: ModalState,
      action: PayloadAction<InfoModalConfig>
    ) => {
      state.infoModal = action.payload;
    },
    dismissInfoModal: (state: ModalState) => {
      state.infoModal = undefined;
    },
    setErrorModal: (
      state: ModalState,
      action: PayloadAction<ErrorModalConfig>
    ) => {
      state.errorModal = action.payload;
    },
    dismissErrorModal: (state: ModalState) => {
      state.errorModal = undefined;
    },
    setIsFinancialProductsOpen: (
      state: ModalState,
      action: PayloadAction<boolean>
    ) => {
      state.financialProductsOpen = action.payload;
    },
    dismissFinancialProducts: (state: ModalState) => {
      state.financialProductsOpen = false;
    },
    setIsBookmarkDesignOpen: (
      state: ModalState,
      action: PayloadAction<boolean>
    ) => {
      state.isBookmarkDesignOpen = action.payload;
    },
    dismissBookmarkDesigns: (state: ModalState) => {
      state.isBookmarkDesignOpen = false;
    },
    setIsModuleIssuesOpen: (
      state: ModalState,
      action: PayloadAction<boolean>
    ) => {
      state.isModuleIssuesOpen = action.payload;
    },
    dismissModuleIssues: (state: ModalState) => {
      state.isModuleIssuesOpen = false;
    },
    displayOfferUpdateConfirmationModal: (state: ModalState) => {
      state.isOfferUpdateConfirmationModalOpen = true;
    },
    dismissOfferUpdateConfirmationModal: (state: ModalState) => {
      state.isOfferUpdateConfirmationModalOpen = false;
    },
    displayProductEquipmentMappingErrorModal: (
      state: ModalState,
      action: PayloadAction<ErrorModalConfig>
    ) => {
      state.isProductEquipmentMappingErrorModalOpen = true;
      state.productEquipmentMappingErrorModal = action.payload;
    },
    dismissProductEquipmentMappingErrorModal: (state: ModalState) => {
      state.isProductEquipmentMappingErrorModalOpen = false;
    },
    openWhatsNew: (state: ModalState) => {
      state.isWhatsNewOpen = true;
    },
    closeWhatsNew: (state: ModalState) => {
      state.isWhatsNewOpen = false;
    },
    setIsWhatsNewOpen: (state: ModalState, action: PayloadAction<boolean>) => {
      state.isWhatsNewOpen = action.payload;
    },
    closeHotKeyDescriptions: (state: ModalState) => {
      state.isHotkeyDescriptionsOpen = false;
    },
    openHotKeyDescriptions: (state: ModalState) => {
      state.isHotkeyDescriptionsOpen = true;
    },
    setIsHotkeyDescriptionsOpen: (
      state: ModalState,
      action: PayloadAction<boolean>
    ) => {
      state.isHotkeyDescriptionsOpen = action.payload;
    },
    toggleIsHotkeyDescriptionsOpen: (state: ModalState) => {
      state.isHotkeyDescriptionsOpen = !state.isHotkeyDescriptionsOpen;
    },
    setCancelContinueModal: (
      state: ModalState,
      action: PayloadAction<CancelContinueModalConfig>
    ) => {
      state.cancelContinueModal = action.payload;
    },
    dismissCancelContinueModal: (state: ModalState) => {
      state.cancelContinueModal = undefined;
    },
    openSelectModuleModal: (state: ModalState) => {
      state.isSelectModuleOpen = true;
    },
    closeSelectModuleModal: (state: ModalState) => {
      state.isSelectModuleOpen = false;
    },
    openSelectInverterModal: (state: ModalState) => {
      state.isSelectInverterOpen = true;
    },
    closeSelectInverterModal: (state: ModalState) => {
      state.isSelectInverterOpen = false;
    },
    openSelectStorageModal: (state: ModalState) => {
      state.isSelectStorageOpen = true;
    },
    closeSelectStorageModal: (state: ModalState) => {
      state.isSelectStorageOpen = false;
    },
    openDesignWarningsModal: (state: ModalState) => {
      state.isDesignWarningsModalOpen = true;
    },
    closeDesignWarningsModal: (state: ModalState) => {
      state.isDesignWarningsModalOpen = false;
    },
    openSelectDesignVariantModal: (
      state: ModalState,
      action: PayloadAction<DesignVariantName | undefined>
    ) => {
      state.isDesignWarningsModalOpen = true;
      state.selectDesignVariantDefault = action.payload;
    },
    closeSelectDesignVariantModal: (state: ModalState) => {
      state.isDesignWarningsModalOpen = false;
    },
    openSetbackRules: (state: ModalState) => {
      state.isSetbackRulesOpen = true;
    },
    closeSetbackRules: (state: ModalState) => {
      state.isSetbackRulesOpen = false;
    },
    setIsSetbackRulesOpen: (
      state: ModalState,
      action: PayloadAction<boolean>
    ) => {
      state.isSetbackRulesOpen = action.payload;
    },
    openConfigureManualBatteriesModal: (state: ModalState) => {
      state.isConfigureManualBatteriesModalOpen = true
    },
    closeConfigureManualBatteriesModal: (state: ModalState) => {
      state.isConfigureManualBatteriesModalOpen = false
    },
    openSelectManualInverterModal: (
      state: ModalState,
    ) => {
      state.isConfigureManualInverterOpen = true;
    },
    closeSelectManualInverterModal: (
      state: ModalState,
    ) => {
      state.isConfigureManualInverterOpen = false;
    },
  },
});

export const {
  setInfoModal,
  dismissInfoModal,
  setErrorModal,
  dismissErrorModal,
  setIsFinancialProductsOpen,
  dismissFinancialProducts,
  setIsBookmarkDesignOpen,
  dismissBookmarkDesigns,
  setIsModuleIssuesOpen,
  dismissModuleIssues,
  displayOfferUpdateConfirmationModal,
  dismissOfferUpdateConfirmationModal,
  displayProductEquipmentMappingErrorModal,
  dismissProductEquipmentMappingErrorModal,
  openWhatsNew,
  closeWhatsNew,
  setIsWhatsNewOpen,
  closeHotKeyDescriptions,
  openHotKeyDescriptions,
  setIsHotkeyDescriptionsOpen,
  toggleIsHotkeyDescriptionsOpen,
  setCancelContinueModal,
  dismissCancelContinueModal,
  openSelectModuleModal,
  closeSelectModuleModal,
  openSelectInverterModal,
  closeSelectInverterModal,
  openSelectStorageModal,
  closeSelectStorageModal,
  openDesignWarningsModal,
  closeDesignWarningsModal,
  openSelectDesignVariantModal,
  closeSelectDesignVariantModal,
  openSetbackRules,
  closeSetbackRules,
  setIsSetbackRulesOpen,
  openConfigureManualBatteriesModal,
  closeConfigureManualBatteriesModal,
  openSelectManualInverterModal,
  closeSelectManualInverterModal,
} = modalSlice.actions;
export default modalSlice.reducer;

// TODO: Remove the following and transition fully from WorkspaceState to RTK store
export type ModalWorkspaceAction =
  | WorkspacePayloadAction<InfoModalConfig, "setInfoModal">
  | WorkspaceEmptyAction<"dismissInfoModal">
  | WorkspacePayloadAction<ErrorModalConfig, "setErrorModal">
  | WorkspaceEmptyAction<"dismissErrorModal">
  | WorkspacePayloadAction<boolean, "setIsFinancialProductsOpen">
  | WorkspaceEmptyAction<"dismissFinancialProducts">
  | WorkspacePayloadAction<boolean, "setIsBookmarkDesignOpen">
  | WorkspaceEmptyAction<"dismissBookmarkDesigns">
  | WorkspacePayloadAction<boolean, "setIsModuleIssuesOpen">
  | WorkspaceEmptyAction<"dismissModuleIssues">
  | WorkspaceEmptyAction<"displayOfferUpdateConfirmationModal">
  | WorkspaceEmptyAction<"dismissOfferUpdateConfirmationModal">
  | WorkspacePayloadAction<
      ErrorModalConfig,
      "displayProductEquipmentMappingErrorModal"
    >
  | WorkspaceEmptyAction<"dismissProductEquipmentMappingErrorModal">
  | WorkspaceEmptyAction<"openWhatsNew">
  | WorkspaceEmptyAction<"closeWhatsNew">
  | WorkspacePayloadAction<boolean, "setIsWhatsNewOpen">
  | WorkspaceEmptyAction<"openSetbackRules">
  | WorkspaceEmptyAction<"closeSetbackRules">
  | WorkspacePayloadAction<boolean, "setIsSetbackRulesOpen">
  | WorkspaceEmptyAction<"toggleIsHotkeyDescriptionsOpen">
  | WorkspaceEmptyAction<"closeHotKeyDescriptions">
  | WorkspaceEmptyAction<"openHotKeyDescriptions">
  | WorkspacePayloadAction<boolean, "setIsHotkeyDescriptionsOpen">
  | WorkspacePayloadAction<CancelContinueModalConfig, "setCancelContinueModal">
  | WorkspaceEmptyAction<"dismissCancelContinueModal">
  | WorkspaceEmptyAction<"openSelectModuleModal">
  | WorkspaceEmptyAction<"closeSelectModuleModal">
  | WorkspaceEmptyAction<"openSelectInverterModal">
  | WorkspaceEmptyAction<"closeSelectInverterModal">
  | WorkspaceEmptyAction<"openSelectStorageModal">
  | WorkspaceEmptyAction<"closeSelectStorageModal">
  | WorkspaceEmptyAction<"openDesignWarningsModal">
  | WorkspaceEmptyAction<"closeDesignWarningsModal">
  | WorkspacePayloadAction<
      DesignVariantName | undefined,
      "openSelectDesignVariantModal"
    >
  | WorkspaceEmptyAction<"closeSelectDesignVariantModal">;

export const modalWorkspaceReducer = (
  state: WorkspaceState,
  action: WorkspaceAction
): Modal => {
  switch (action.type) {
    case "setInfoModal": {
      return produce(state.modal, (draft) => {
        draft.infoModal = action.payload;
      });
    }

    case "dismissInfoModal": {
      return produce(state.modal, (draft) => {
        draft.infoModal = undefined;
      });
    }

    case "setErrorModal": {
      return produce(state.modal, (draft) => {
        draft.errorModal = action.payload;
      });
    }

    case "dismissErrorModal": {
      return produce(state.modal, (draft) => {
        draft.errorModal = undefined;
      });
    }

    case "setIsFinancialProductsOpen": {
      return produce(state.modal, (draft) => {
        draft.financialProductsOpen = action.payload;
      });
    }

    case "dismissFinancialProducts": {
      return produce(state.modal, (draft) => {
        draft.financialProductsOpen = false;
      });
    }

    case "setIsBookmarkDesignOpen": {
      return produce(state.modal, (draft) => {
        draft.isBookmarkDesignOpen = action.payload;
      });
    }

    case "dismissBookmarkDesigns": {
      return produce(state.modal, (draft) => {
        draft.isBookmarkDesignOpen = false;
      });
    }

    case "setIsModuleIssuesOpen": {
      return produce(state.modal, (draft) => {
        draft.isModuleIssuesOpen = action.payload;
      });
    }

    case "dismissModuleIssues": {
      return produce(state.modal, (draft) => {
        draft.isModuleIssuesOpen = false;
      });
    }

    case "displayOfferUpdateConfirmationModal": {
      return produce(state.modal, (draft) => {
        draft.isOfferUpdateConfirmationModalOpen = true;
      });
    }

    case "dismissOfferUpdateConfirmationModal": {
      return produce(state.modal, (draft) => {
        draft.isOfferUpdateConfirmationModalOpen = false;
      });
    }

    case "displayProductEquipmentMappingErrorModal": {
      return produce(state.modal, (draft) => {
        draft.isProductEquipmentMappingErrorModalOpen = true;
        draft.productEquipmentMappingErrorModal = action.payload;
      });
    }

    case "dismissProductEquipmentMappingErrorModal": {
      return produce(state.modal, (draft) => {
        draft.isProductEquipmentMappingErrorModalOpen = false;
      });
    }

    case "openWhatsNew": {
      return produce(state.modal, (draft) => {
        draft.isWhatsNewOpen = true;
      });
    }

    case "closeWhatsNew": {
      return produce(state.modal, (draft) => {
        draft.isWhatsNewOpen = false;
      });
    }

    case "setIsWhatsNewOpen": {
      return produce(state.modal, (draft) => {
        draft.isWhatsNewOpen = action.payload;
      });
    }

    case "closeHotKeyDescriptions": {
      return produce(state.modal, (draft) => {
        draft.isHotkeyDescriptionsOpen = false;
      });
    }

    case "openHotKeyDescriptions": {
      return produce(state.modal, (draft) => {
        draft.isHotkeyDescriptionsOpen = true;
      });
    }

    case "setIsHotkeyDescriptionsOpen": {
      return produce(state.modal, (draft) => {
        draft.isHotkeyDescriptionsOpen = action.payload;
      });
    }

    case "toggleIsHotkeyDescriptionsOpen": {
      return produce(state.modal, (draft) => {
        draft.isHotkeyDescriptionsOpen = !state.modal.isHotkeyDescriptionsOpen;
      });
    }

    case "setCancelContinueModal": {
      return produce(state.modal, (draft) => {
        draft.cancelContinueModal = action.payload;
      });
    }

    case "dismissCancelContinueModal": {
      return produce(state.modal, (draft) => {
        draft.cancelContinueModal = undefined;
      });
    }

    case "openSelectModuleModal": {
      return produce(state.modal, (draft) => {
        draft.isSelectModuleOpen = true;
      });
    }

    case "closeSelectModuleModal": {
      return produce(state.modal, (draft) => {
        draft.isSelectModuleOpen = false;
      });
    }

    case "openSelectInverterModal": {
      return produce(state.modal, (draft) => {
        draft.isSelectInverterOpen = true;
      });
    }

    case "closeSelectInverterModal": {
      return produce(state.modal, (draft) => {
        draft.isSelectInverterOpen = false;
      });
    }

    case "openSelectStorageModal": {
      return produce(state.modal, (draft) => {
        draft.isSelectStorageOpen = true;
      });
    }

    case "closeSelectStorageModal": {
      return produce(state.modal, (draft) => {
        draft.isSelectStorageOpen = false;
      });
    }

    case "openDesignWarningsModal": {
      return produce(state.modal, (draft) => {
        draft.isDesignWarningsModalOpen = true;
      });
    }

    case "closeDesignWarningsModal": {
      return produce(state.modal, (draft) => {
        draft.isDesignWarningsModalOpen = false;
      });
    }

    case "openSelectDesignVariantModal": {
      return produce(state.modal, (draft) => {
        draft.isSelectDesignVariantModalOpen = true;
        draft.selectDesignVariantDefault = action.payload;
      });
    }

    case "closeSelectDesignVariantModal": {
      return produce(state.modal, (draft) => {
        draft.isSelectDesignVariantModalOpen = false;
      });
    }

    case "openSetbackRules": {
      return produce(state.modal, (draft) => {
        draft.isSetbackRulesOpen = true;
      });
    }

    case "closeSetbackRules": {
      return produce(state.modal, (draft) => {
        draft.isSetbackRulesOpen = false;
      });
    }

    case "setIsSetbackRulesOpen": {
      return produce(state.modal, (draft) => {
        draft.isSetbackRulesOpen = action.payload;
      });
    }

    default:
      return state.modal;
  }
};

const selectModalState = (state: WorkspaceState) => state.modal;

// Using a pass through input selector for redux state
const selectIsConfigureManualInverterOpen = (
  state: WorkspaceState,
  isConfigureManualInverterOpen: boolean
) => {
  return isConfigureManualInverterOpen;
};

export const selectIsAnyModalOpen = createSelector(
  [selectModalState, selectIsConfigureManualInverterOpen],
  (modal: Modal, isConfigureManualInverterOpen) => {
    const {
      infoModal,
      errorModal,
      financialProductsOpen,
      isOfferUpdateConfirmationModalOpen,
      isProductEquipmentMappingErrorModalOpen,
      productEquipmentMappingErrorModal,
      isWhatsNewOpen,
      isHotkeyDescriptionsOpen,
      isModuleIssuesOpen,
      cancelContinueModal,
      isSelectModuleOpen,
      isSelectInverterOpen,
      isSelectStorageOpen,
      isDesignWarningsModalOpen,
      isSelectDesignVariantModalOpen,
    } = modal;

    return (
      !!infoModal ||
      !!errorModal ||
      financialProductsOpen ||
      isOfferUpdateConfirmationModalOpen ||
      isProductEquipmentMappingErrorModalOpen ||
      !!productEquipmentMappingErrorModal ||
      isWhatsNewOpen ||
      isHotkeyDescriptionsOpen ||
      isModuleIssuesOpen ||
      !!cancelContinueModal ||
      isSelectModuleOpen ||
      isSelectInverterOpen ||
      isSelectStorageOpen ||
      isDesignWarningsModalOpen ||
      isSelectDesignVariantModalOpen ||
      // isConfigureManualInverterOpen is in redux but current states are a mix of redux + workspace state, ideally the rest is moved to redux.
      isConfigureManualInverterOpen
    );
  }
);
