import { WorkspaceEmptyAction } from "src/types/state-management/action";
import { produce } from "immer";
import { WorkspaceAction, WorkspaceState } from "src/hooks/useWorkspace";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type Display = {
  isToolbarExpanded: boolean;
  isDesignMenuExpanded: boolean;
};
export type DisplayState = { value: Display };

export const initialState: DisplayState = {
  value: {
    isToolbarExpanded: false,
    isDesignMenuExpanded: false,
  },
};

export const displaySlice = createSlice({
  name: "display",
  initialState,
  reducers: {
    setDisplay: (state: DisplayState, action: PayloadAction<Display>) => {
      state.value = action.payload;
    },
    setIsDesignMenuExpanded: (state: DisplayState, action: PayloadAction<boolean>) => {
      state.value.isDesignMenuExpanded = action.payload;
    },
  },
});

export const { setDisplay, setIsDesignMenuExpanded } = displaySlice.actions;
export default displaySlice.reducer;

// TODO: Remove the following and transition fully from WorkspaceState to RTK store
export type DisplayAction = WorkspaceEmptyAction<"toggleIsToolbarExpanded">;

export const displayInitialState: Display = {
  isToolbarExpanded: false,
  isDesignMenuExpanded: false,
};

export const displayReducer = (
  state: WorkspaceState,
  action: WorkspaceAction
): Display => {
  switch (action.type) {
    case "toggleIsToolbarExpanded": {
      return produce(state.display, (draft) => {
        draft.isToolbarExpanded = !state.display.isToolbarExpanded;
      });
    }
    default: {
      return state.display;
    }
  }
};
