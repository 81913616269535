import {Position, positionToVector3D} from "./point";
import {Vector3D} from "../vectors/vector3D";

export const buildRectangularBuffer = (rectangle: Position[], bufferSizes: number[]): Position[] => {
  const newPositions: Position[] = []
  rectangle.forEach((_currPos, index) => {
    if (index > 3) return
    const collinearDistance = bufferSizes[(index + 1) % 4]
    const perpendicularDistance = bufferSizes[index]
    newPositions.push(calcBufferVertex(rectangle, index, collinearDistance, perpendicularDistance))
  })
  newPositions.push(newPositions[0]) // close the polygon
  return newPositions
}

/*  Graphical illustration of calcBufferVertex() being applied to each edge of the rectangle in succession.
    Each leg of the extension is `distance` in length. The calculation is 3D and the resulting points (shown by
    x marks) are coplanar to original rectangle. The rectangle is assumed to have a counterclockwise winding order.
    The algo works regardless of the angle or orientation of the provided rectangle.

                                   ╔═══x     x                                           2---------------1
                                   ║         ║                                           |               |
       3-------2           3-------2         ╚═══3═══════2           3-------2           |   3-------2   |
       |   r   |           |   r   ║             |   r   |           ║   r   |           |   |   r   |   |
       |   e   |           |   e   ║             |   e   |           ║   e   |           |   |   e   |   |
       |   c   |    -->    |   c   ║     -->     |   c   |    -->    ║   c   |    -->    |   |   c   |   |
       |   t   |           |   t   ║             |   t   |           ║   t   |           |   |   t   |   |
       0═══════1═══╗       0-------1             0-------1           0-------1           |   0-------1   |
                   ║                                                 ║                   |               |
                   x                                             x═══╝                   3---------------0
                                                                                              buffer!
 */

const calcBufferVertex = (rectangle: Position[], edgeIndex: number, collinearDistance: number, perpendicularDistance: number): Position => {
  // The first two positions constitute the current edge. The third looks ahead to the next edge
  const firstPos = rectangle[edgeIndex]
  const secondPos = edgeIndex < 3 ? rectangle[edgeIndex + 1] : rectangle[0]
  const thirdPos = edgeIndex < 2 ? rectangle[edgeIndex + 2] : rectangle[edgeIndex - 2]
  const firstVec = positionToVector3D(firstPos)
  const secondVec = positionToVector3D(secondPos)
  const thirdVec = positionToVector3D(thirdPos)
  // first we calculate a vector that extends the current edge by distance (i.e. collinear to the current edge
  // and extending in the direction by which successive polygon vertices proceed around the ring)
  const currEdgeVecNormalized = Vector3D.sub(secondVec, firstVec).normalize()
  const collinearExtension = Vector3D.scale(currEdgeVecNormalized, collinearDistance)
  // Next calculate a vector that extends perpendicular to the right (away from the interior of the polygon).
  // To do this we look at the next edge vector in the ring and extend it as we did the last, with the exception
  // that the direction is reversed (i.e. opposite to the direction by which the successive vertices proceed around
  // the ring)
  const nextEdgeVecNormalized = Vector3D.sub(secondVec, thirdVec).normalize()
  const perpendicularExtension = Vector3D.scale(nextEdgeVecNormalized, perpendicularDistance)
  const vectorToAdd = Vector3D.add(collinearExtension, perpendicularExtension)
  const resultVector = Vector3D.add(secondVec, vectorToAdd)
  return [resultVector.x, resultVector.y, resultVector.z]
}