import {WorkspaceAction, WorkspaceState} from "src/hooks/useWorkspace";
import {Design, IDesign, IProductionSimulation, ProductionSimulation, ReadOnlyDesignSet} from "@sunrun/design-tools-domain-model";
import {WorkspacePayloadAction} from "../../types/state-management/action";
import produce from "immer";
import { createSelector } from "reselect";

// ET-948: Keep ReadOnlyDesignSet as classes
export type SerializableReadOnlyDesignSet = {
  readonly design: IDesign;
  readonly productionSimulation: IProductionSimulation;
}

export type SerializableReadOnlyDesignSets = {
  signedRootDesignSet?: SerializableReadOnlyDesignSet
  interconnectionAppliedDesignSet?: SerializableReadOnlyDesignSet
  copyFromDesign?: IDesign
  variantDesignSets: {
    mlpe?: SerializableReadOnlyDesignSet
    unoptimizedString?: SerializableReadOnlyDesignSet
  }
}

export type ReadOnlyDesignSets = {
  signedRootDesignSet?: ReadOnlyDesignSet
  interconnectionAppliedDesignSet?: ReadOnlyDesignSet
  copyFromDesign?: Design
  variantDesignSets: {
    mlpe?: ReadOnlyDesignSet
    unoptimizedString?: ReadOnlyDesignSet
  }
}

export const initialReadOnlyDesignState = {
  signedRootDesignSet: undefined,
  interconnectionAppliedDesignSet: undefined,
  copyFromDesign: undefined,
  variantDesignSets: {
    mlpe: undefined,
    unoptimizedString: undefined
  },
}

export type ReadOnlyDesignSetsAction = 
  | WorkspacePayloadAction<ReadOnlyDesignSet, 'setSignedRootDesignSet'>
  | WorkspacePayloadAction<ReadOnlyDesignSet, 'setInterconnectionAppliedDesignSet'>
  | WorkspacePayloadAction<Design, 'setCopyFromDesign'>
  | WorkspacePayloadAction<ReadOnlyDesignSet, 'setMlpeVariantDesignSet'>
  | WorkspacePayloadAction<ReadOnlyDesignSet, 'setUnoptimizedVariantDesignSet'>

export const selectReadOnlyDesignSets = (state: WorkspaceState) => state.readOnlyDesignSets

export const readOnlyDesignSetsReducer = (state: WorkspaceState, action: WorkspaceAction): SerializableReadOnlyDesignSets => {
  switch (action.type) {
    case "setSignedRootDesignSet": {
      return produce(state.readOnlyDesignSets, draft => {
        draft.signedRootDesignSet = createSearalizableReadOnlyDesignSet(action.payload)
      })
    }
    case "setInterconnectionAppliedDesignSet": {
      return produce(state.readOnlyDesignSets, draft => {
        draft.interconnectionAppliedDesignSet = createSearalizableReadOnlyDesignSet(action.payload)
      })
    }
    case "setCopyFromDesign": {
      return produce(state.readOnlyDesignSets, draft => {         
        draft.copyFromDesign = action.payload.getIDesign()
      })
    }
    case "setMlpeVariantDesignSet": {
      return produce(state.readOnlyDesignSets, draft => {
        draft.variantDesignSets.mlpe = createSearalizableReadOnlyDesignSet(action.payload)
      })
    }
    case "setUnoptimizedVariantDesignSet": {
      return produce(state.readOnlyDesignSets, draft => {
        draft.variantDesignSets.unoptimizedString = createSearalizableReadOnlyDesignSet(action.payload)
      })
    }
    default: {
      return state.readOnlyDesignSets
    }
  }
}

const createSearalizableReadOnlyDesignSet = (readOnlyDesignSet: ReadOnlyDesignSet): SerializableReadOnlyDesignSet => {
  const {design, productionSimulation} = readOnlyDesignSet
  return {
    design: design.getIDesign(),
    productionSimulation: productionSimulation.getIProductionSimulation() 
  }
}

const createReadOnlyDesignSet = (serializedReadOnlyDesignSet?: SerializableReadOnlyDesignSet): ReadOnlyDesignSet | undefined => {
  if (!serializedReadOnlyDesignSet) return undefined;
  const {design, productionSimulation} = serializedReadOnlyDesignSet
  return {
    design: new Design(design),
    productionSimulation: new ProductionSimulation(productionSimulation)

  }
}

export const deriveReadOnlyDesignClassSets = createSelector(
  [selectReadOnlyDesignSets],
  (readOnlyDesignSets) => {
    const {signedRootDesignSet, interconnectionAppliedDesignSet, copyFromDesign, variantDesignSets} = readOnlyDesignSets     
    return {
      signedRootDesignSet: createReadOnlyDesignSet(signedRootDesignSet),
      interconnectionAppliedDesignSet: createReadOnlyDesignSet(interconnectionAppliedDesignSet),
      copyFromDesign: copyFromDesign ? new Design(copyFromDesign) : undefined,
      variantDesignSets: {
        mlpe: createReadOnlyDesignSet(variantDesignSets?.mlpe),
        unoptimizedString: createReadOnlyDesignSet(variantDesignSets?.unoptimizedString)
      }
    }
  }
)
