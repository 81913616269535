import { ReactNode, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Design,
  isFlatRoof,
  ModuleOrientation,
  ModulesAgreeWithRoofs,
  ModulesAre3DClosedRectanglesWithFiniteValues,
  SiteModel,
  SiteModelRoofsAre3DAndMatchAngles,
} from "@sunrun/design-tools-domain-model";
import { useWorkspace } from "src/hooks/useWorkspace";
import { useDomainModelWithSearchParams } from "src/hooks/useDomainModelWithSearchParams";
import { useIFrameHost } from "src/hooks/useIFrameHost";
import useStyles from "../styles/styles";
import { useOffer } from "src/hooks/useOffer";
import { usePromotions } from "src/hooks/usePromotions";
import { useProductAvailability } from "src/hooks/useProductAvailability";
import Workspace from "src/components/Workspace";
import { IFrameHostType } from "src/types/IFrame";
import { URLSearchParameterKey } from "src/types/URLSearchParameterKey";
import { useAvailableEquipment } from "../hooks/useAvailableEquipment";
import { useInitDesign } from "src/hooks/useInitDesign";
import { setHost } from "src/features/host/hostSlice";
import {
  setEffectiveDate,
  setSignedRootId,
  setSignedRootProductType,
} from "src/features/mostRecentSignedDesign/mostRecentSignedDesign";
import { useListProducts } from "src/hooks/useListProducts";
import { deriveDesignConstraintsClass } from "src/features/designConstraints/designConstraintsSlice";
import { deriveProductionSimulationClass } from "src/features/productionSimulation/productionSimulationSlice";
import { deriveSolarResourceClass } from "src/features/solarResource/solarResourceSlice";
import { deriveSiteModelClass } from "src/features/siteModel/siteModelSlice";
import { deriveDesignClass } from "src/features/design/designSlice";
import { useAppDispatch } from "src/store";
import { setEnforceDesignRestrictionsForSales, setIsModulesLayerVisible, setIsModuleSpacingLayerVisible, setModuleOrientationSetting } from "src/features/settings/settingsSlice";

function reportDesignErrors(design: Design, siteModel: SiteModel) {
  const errs: { msg: string }[] = [];
  for (const m of design.modules) {
    errs.push(...ModulesAre3DClosedRectanglesWithFiniteValues(m));
  }
  errs.push(...ModulesAgreeWithRoofs({ design, siteModel }));
  if (errs.length > 0) {
    console.warn(
      `this design contains ${errs.length} inconsistencies which could cause poor behavior or crashes: `,
      errs
    );
  }
}

function reportSiteModelErrors(siteModel: SiteModel) {
  const errs: { msg: string }[] = [];
  errs.push(...SiteModelRoofsAre3DAndMatchAngles(siteModel));
  if (errs.length > 0) {
    console.warn(
      `this site contains ${errs.length} inconsistencies which could cause poor behavior or crashes: `,
      errs
    );
  }
}

export type WorkspaceRouteProps = {
  children: ReactNode;
};

export const WorkspaceRoute = ({ children }: WorkspaceRouteProps) => {
  const { state, dispatch: workspaceDispatch } = useWorkspace();
  const dispatch = useAppDispatch();
  const { refetchOffer } = useOffer();
  const { geoRaster, customer, moduleSelection } = state;
  const {
    legacyFinalizeDesign,
    simulateDesign,
    exportDesign,
    createDesignImages,
    recalculateSetbacks
  } = useDomainModelWithSearchParams();
  const [disableRotateButton, setDisableRotateButton] =
    useState<boolean>(false);
  const [
    disableFillRoofFaceAroundModuleButton,
    setDisableFillRoofFaceAroundModuleButton,
  ] = useState<boolean>(false);
  const [rotateSelectedModulesMsg, setRotateSelectedModulesMsg] =
    useState<string>("rotate selected modules");
  useAvailableEquipment();
  useStyles();
  usePromotions();
  useProductAvailability();
  useListProducts();
  useInitDesign({recalculateSetbacks});
  const solarResource = deriveSolarResourceClass(state);

  const [searchParams] = useSearchParams();

  const designConstraints = deriveDesignConstraintsClass(state);
  const productionSimulation = deriveProductionSimulationClass(state);
  const siteModel = deriveSiteModelClass(state);
  const design = deriveDesignClass(state);

  useEffect(() => {
    const signedRootId =
      searchParams.get(URLSearchParameterKey.SignedRootId) || undefined;
    const effectiveDate =
      searchParams.get(URLSearchParameterKey.EffectiveDate) || undefined;
    const signedRootIdDashProduct =
      searchParams.get(URLSearchParameterKey.SignedRootIdDashProduct) ||
      undefined;
    if (signedRootId) {
      dispatch(setSignedRootId(signedRootId));
      workspaceDispatch({ type: "setSignedRootId", payload: signedRootId });
    }
    if (effectiveDate) {
      dispatch(setEffectiveDate(effectiveDate));
      workspaceDispatch({ type: "setEffectiveDate", payload: effectiveDate });
    }
    if (signedRootIdDashProduct) {
      const productType = signedRootIdDashProduct.slice(-1)[0];
      if (productType) {
        dispatch(setSignedRootProductType(productType));
        workspaceDispatch({
          type: "setSignedRootProductType",
          payload: productType,
        });
      }
    }
  }, [searchParams]);

  const iFrameHost = useIFrameHost({
    legacyFinalizeDesign,
    refetchOffer,
    design,
    siteModel,
    productionSimulation,
    customer,
    designConstraints,
    geoRaster,
    solarResource,
  });


  // We are using iFrame host to determine user type. Set Sales User Restrictions for non LM users
  const isLightmileUser = iFrameHost === IFrameHostType.LIGHTMILE || iFrameHost === IFrameHostType.LIGHTMILE_OFFER_BUILDER

  useEffect(() => {
    // TODO: Remove this dispatch after verifying that we can read this from the redux store.
    workspaceDispatch({ type: "setHost", payload: iFrameHost });
    dispatch(setHost(iFrameHost));
    dispatch(setEnforceDesignRestrictionsForSales(!isLightmileUser))
    workspaceDispatch({
      type: "setEnforceDesignRestrictionsForSales",
      payload: !isLightmileUser,
    });
    // FIXME: Remove this hack as part of LS-2321. This hack was put in place to hide the LM-Design while loading in OE.
    if (
      iFrameHost !== IFrameHostType.OFFER_BUILDER &&
      iFrameHost !== IFrameHostType.LIGHTMILE_OFFER_BUILDER
    ) {
      dispatch(setIsModulesLayerVisible(true));
      workspaceDispatch({ type: "setIsModulesLayerVisible", payload: true });
      dispatch(setIsModuleSpacingLayerVisible(true))
      workspaceDispatch({
        type: "setIsModuleSpacingLayerVisible",
        payload: true,
      });
    }
  }, [iFrameHost]);

  const updateRotateButton = () => {
    if (design && siteModel) {
      const disableRotate = design.isRotateDisabled(
        moduleSelection.selectedModuleIds,
        siteModel
      );
      setDisableRotateButton(disableRotate);
      if (disableRotate && moduleSelection.selectedModuleIds.size > 0) {
        const modulesToRotate = Array.from(
          moduleSelection.selectedModuleIds
        ).map((id) => design!.getModuleById(id));
        const firstModuleToRotate = modulesToRotate[0]!;
        const roofFaceId = firstModuleToRotate.properties.roofFaceId;
        const roofFace = siteModel.getRoofFaceById(roofFaceId);
        if (roofFace && isFlatRoof(roofFace)) {
          setRotateSelectedModulesMsg(
            "To rotate modules on a flat roof, select one module or a single row of modules with same orientation"
          );
        } else {
          setRotateSelectedModulesMsg("please select modules on a roof face");
        }
      } else {
        setRotateSelectedModulesMsg("");
      }
    }
  };

  const updateFillRoofFaceButton = () => {
    if (design && siteModel) {
      const disableFillRoofFaceFromModuleButton =
        design.isFillRoofFaceAroundModuleDisabled(
          moduleSelection.selectedModuleIds,
          siteModel
        );
      setDisableFillRoofFaceAroundModuleButton(
        disableFillRoofFaceFromModuleButton
      );
    }
  };

  useEffect(() => {
    updateRotateButton();
    updateFillRoofFaceButton();
  }, [moduleSelection.selectedModuleIds]);

  useEffect(
    function runDiagnosticsToHelpFindBugs() {
      if (design && siteModel) reportDesignErrors(design, siteModel);
    },
    [siteModel, design]
  );
  useEffect(
    function runDiagnosticsToHelpFindBugs() {
      if (siteModel) reportSiteModelErrors(siteModel);
    },
    [siteModel]
  );

  useEffect(() => {
    // set module orientation
    // (mixed defaults to portrait)
    dispatch(setModuleOrientationSetting(design?.designParameters?.moduleOrientation ===
      ModuleOrientation.Landscape
        ? ModuleOrientation.Landscape
        : ModuleOrientation.Portrait))
    workspaceDispatch({
      type: "setModuleOrientationSetting",
      payload:
        design?.designParameters?.moduleOrientation ===
        ModuleOrientation.Landscape
          ? ModuleOrientation.Landscape
          : ModuleOrientation.Portrait,
    });
  }, [design?.designParameters?.moduleOrientation]);

  return (
    <Workspace
      children={children}
      // TODO: Refactor our state management to eliminate the need to prop drill here.
      // Calling useDomainModelWithSearchParams in multiple components currently causes issues with react-query.
      simulateDesign={simulateDesign}
      exportDesign={exportDesign}
      createDesignImages={createDesignImages}
      legacyFinalizeDesign={legacyFinalizeDesign}
      recalculateSetbacks={recalculateSetbacks}
    />
  );
};
