import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {Polygon, Position, rotatedBoundingBox} from "@sunrun/design-tools-geometry";
import {CollidableGeoJsonFeature, FeatureInit} from "@sunrun/design-tools-domain-model";
import {WorkspaceEmptyAction, WorkspacePayloadAction, WorkspaceEvent} from "../../types/state-management/action";
import { createSelector } from "reselect";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type MarqueeState = {
  isPointerDown: boolean
  isDragging: boolean
  startPosition?: Position
  dragPosition?: Position
  azimuthDegrees: number | undefined
  roofFaceId: string | undefined
}

const initialState: MarqueeState = {
  isPointerDown: false,
  isDragging: false,
  startPosition: undefined,
  dragPosition: undefined,
  azimuthDegrees: undefined,
  roofFaceId: undefined
}

export const marqueeSlice = createSlice({
  name: "marquee",
  initialState,
  reducers: {
    initMarquee: (state: MarqueeState) => { 
      state = initialState;
    },
    drawMarquee: (state: MarqueeState, action: PayloadAction<WorkspaceEvent>) => {
      const startPosition = state.startPosition!
      const endPosition = action.payload.position
      const azimuthDegrees = state.azimuthDegrees!
      const newFormsState: MarqueeState = {
        isPointerDown : true,
        isDragging : true,
        startPosition : state.startPosition,
        dragPosition : action.payload.position,
        azimuthDegrees : state.azimuthDegrees,
        roofFaceId : state.roofFaceId
      }
      return newFormsState;
  },
    resetMarquee: (state: MarqueeState) => {
      state = initialState;
    },
  }
});

export const { initMarquee, drawMarquee, resetMarquee } = marqueeSlice.actions
export default marqueeSlice.reducer

// TODO: Remove the following and transition fully from WorkspaceState to RTK store
export type MarqueeWorkspaceAction =
  | WorkspacePayloadAction<InitMarqueePayload, 'initMarquee'>
  | WorkspacePayloadAction<WorkspaceEvent, 'drawMarquee'>
  | WorkspaceEmptyAction<'resetMarquee'>

export type InitMarqueePayload = {
  roofFaceId: string
  pointerPosition: Position
  azimuthDegrees: number
}

export type Marquee = {
  isPointerDown: boolean
  isDragging: boolean
  startPosition?: Position
  dragPosition?: Position
  azimuthDegrees: number | undefined
  selectionPolygon: CollidableMarqueePolygon | undefined
  roofFaceId: string | undefined
}

//  redefining selectionPolygon for serialization
type OmitSelectionPolygonMarquee = Omit<Marquee, 'selectionPolygon'>

export type serializableMarquee = OmitSelectionPolygonMarquee & {
  selectionPolygon: FeatureInit<Polygon, any> | undefined
}

export const marqueeInitialState = {
  isPointerDown: false,
  isDragging: false,
  startPosition: undefined,
  dragPosition: undefined,
  azimuthDegrees: undefined,
  selectionPolygon: undefined,
  roofFaceId: undefined
}

export const marqueeWorkspaceReducer = (state: WorkspaceState, action: WorkspaceAction): serializableMarquee => {
  switch (action.type) {
    case "initMarquee": {
      return {
        isPointerDown: true,
        isDragging: false,
        startPosition: action.payload.pointerPosition,
        dragPosition: undefined,
        azimuthDegrees: action.payload.azimuthDegrees,
        selectionPolygon: undefined,
        roofFaceId: action.payload.roofFaceId
      }
    }
    case "drawMarquee": {
      const startPosition = state.marquee.startPosition!
      const endPosition = action.payload.position
      const azimuthDegrees = state.marquee.azimuthDegrees!
      const selectionPolygonFeature = selectionAsSerializableFeature(startPosition, endPosition, azimuthDegrees)
      return {
        isPointerDown: true,
        isDragging: true,
        startPosition: state.marquee.startPosition,
        dragPosition: action.payload.position,
        azimuthDegrees: state.marquee.azimuthDegrees,
        selectionPolygon: selectionPolygonFeature,
        roofFaceId: state.marquee.roofFaceId
      }
    }
    case "resetMarquee": {
      return marqueeInitialState
    }
    default:
      return state.marquee
  }
}

// feature is the serealiable data that is used to construct CollidableMarqueePolygon
const selectionAsSerializableFeature = (startPosition: Position, endPosition: Position, azimuthDegrees: number): FeatureInit<Polygon, any> => {
  const bboxCoords = rotatedBoundingBox(startPosition, endPosition, azimuthDegrees)
  return {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [bboxCoords]
    },
    properties: [],
  }  
}

// 
const selectionAsPolygon = (startPosition: Position, endPosition: Position, azimuthDegrees: number): CollidableMarqueePolygon => {
  const feature = selectionAsSerializableFeature(startPosition, endPosition, azimuthDegrees)
  return new CollidableMarqueePolygon(feature)
}

export class CollidableMarqueePolygon extends CollidableGeoJsonFeature<Polygon, any> {
  constructor(init: FeatureInit<Polygon, any>) {
    super(init);
  }
}

export const selectMarquee = (state: WorkspaceState) => state.marquee;

// derive Marquee
export const deriveMarquee = createSelector(
  [selectMarquee],
  (marquee) => { 
    const {selectionPolygon} = marquee
    return {
      ...marquee,
      selectionPolygon: selectionPolygon ? new CollidableMarqueePolygon(selectionPolygon) : undefined
    }
  }
)


