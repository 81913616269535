"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RuntimeContext = exports.BrowserContext = exports.onlineDefaults = exports.appSyncApiUrls = exports.TestType = void 0;
const design_tools_resource_names_1 = require("@sunrun/design-tools-resource-names");
const process = __importStar(require("process"));
// This is copied from @sunrun/design-tools-aws-cdk. We could centralize it in a new package if needed.
var DesignToolsAccountIds;
(function (DesignToolsAccountIds) {
    DesignToolsAccountIds["dev"] = "537832996537";
    DesignToolsAccountIds["relcert"] = "537832996537";
    DesignToolsAccountIds["stg"] = "289122335739";
    DesignToolsAccountIds["prd"] = "133416057010";
})(DesignToolsAccountIds || (DesignToolsAccountIds = {}));
var TestType;
(function (TestType) {
    TestType["LocalTest"] = "LocalTest";
    TestType["HybridTest"] = "HybridTest";
    TestType["CloudTest"] = "CloudTest";
    TestType["None"] = "None";
})(TestType || (exports.TestType = TestType = {}));
exports.appSyncApiUrls = {
    dev: "https://tnxbhqap2bhqhgkwnqye6dytei.appsync-api.us-west-2.amazonaws.com/graphql",
    relcert: "https://eh322fn4d5b57mlxbit7j2ub4y.appsync-api.us-west-2.amazonaws.com/graphql",
    stg: "todo",
    prd: "https://bslxm7n6tjhztg7i6vy5mzkm3e.appsync-api.us-west-2.amazonaws.com/graphql"
};
exports.onlineDefaults = {
    dev: "true",
    relcert: "true",
    stg: "true",
    prd: "true"
};
class BrowserContext {
    constructor() {
        this.parseKeyFromWindowLocationWithDefaults = (key, defaults) => {
            if (this.areWeRunningInTheBrowser) {
                if (this.searchParams && this.searchParams.has(key)) {
                    return this.searchParams.get(key);
                }
                if (RuntimeContext.env.isLongLived()) {
                    return defaults[RuntimeContext.env.name];
                }
                else {
                    return defaults['dev'];
                }
            }
            return null;
        };
        this.parseEnvOverrideFromSearchParams = () => {
            if (this.areWeRunningInTheBrowser && RuntimeContext.env.name !== design_tools_resource_names_1.LongLivedEnv.prd) {
                if (this.searchParams && this.searchParams.has('envOverride')) {
                    console.log(`Found search param for envOverride: "${this.searchParams.get('envOverride')}"`);
                    return this.searchParams.get('envOverride');
                }
            }
            return undefined;
        };
        this.areWeRunningInTheBrowser = typeof window !== 'undefined';
        this.searchParams = (this.areWeRunningInTheBrowser) ? new URLSearchParams(window.location.search) : null;
        this.apiUrl = this.parseKeyFromWindowLocationWithDefaults('apiUrl', exports.appSyncApiUrls);
        this.isOnline = this.parseKeyFromWindowLocationWithDefaults('online', exports.onlineDefaults) == 'true';
        this.isOffline = !this.isOnline;
        this.envOverride = this.parseEnvOverrideFromSearchParams();
    }
}
exports.BrowserContext = BrowserContext;
class RuntimeContext {
}
exports.RuntimeContext = RuntimeContext;
RuntimeContext.env = design_tools_resource_names_1.Env.browserOrNodeJs();
RuntimeContext.browserContext = new BrowserContext();
RuntimeContext.testType = TestType.None;
RuntimeContext.envOverride = RuntimeContext.browserContext.envOverride ?? undefined;
RuntimeContext.mockExternalCalls = false;
RuntimeContext.setEnvOverride = (envOverride) => {
    RuntimeContext.envOverride = envOverride;
};
RuntimeContext.setMockExternalCalls = (mockExternalCalls) => {
    if (mockExternalCalls === null || mockExternalCalls === undefined) {
        RuntimeContext.mockExternalCalls = false;
    }
    else {
        RuntimeContext.mockExternalCalls = mockExternalCalls;
    }
};
RuntimeContext.setTestType = (testType) => {
    RuntimeContext.testType = testType;
};
RuntimeContext.getAwsAccount = () => {
    if (RuntimeContext.lambdaContext)
        return RuntimeContext.lambdaContext.invokedFunctionArn.split(":")[4];
    throw Error("Lambda context is not set.");
};
RuntimeContext.isDevAccount = () => {
    try {
        return RuntimeContext.getAwsAccount() === DesignToolsAccountIds.dev;
    }
    catch (e) {
        return false;
    }
};
RuntimeContext.isPrdAccount = () => {
    try {
        return RuntimeContext.getAwsAccount() === DesignToolsAccountIds.prd;
    }
    catch (e) {
        return false;
    }
};
RuntimeContext.setupTest = (testType) => {
    if (testType === TestType.None) {
        throw new Error('TestType.None is not used for tests.');
    }
    if (testType !== TestType.LocalTest) {
        // using Env.nodejs() because tests always run in nodejs context, even tests for browsers
        RuntimeContext.setEnvOverride(design_tools_resource_names_1.Env.nodejs().name);
    }
    if (testType !== TestType.HybridTest) {
        RuntimeContext.setMockExternalCalls(true);
    }
    RuntimeContext.setTestType(testType);
    console.log(`
    ********RUNTIME CONTEXT TEST SETUP********
    Test Type: ${JSON.stringify(RuntimeContext.testType)}
    Env Name: ${RuntimeContext.env.name}
    envOverride: ${RuntimeContext.envOverride}
    mockExternalCalls: ${RuntimeContext.mockExternalCalls}`);
};
RuntimeContext.areWeRunningInLambda = () => {
    return (typeof process !== 'undefined' && !!process.env["AWS_LAMBDA_FUNCTION_NAME"]);
};
if (RuntimeContext.browserContext.areWeRunningInTheBrowser) {
    console.log(`
  ********RUNTIME CONTEXT********
  Browser Context: ${JSON.stringify(RuntimeContext.browserContext)}
  Env Name: ${JSON.stringify(RuntimeContext.env)}
  envOverride: ${RuntimeContext.envOverride}
  mockExternalCalls: ${RuntimeContext.mockExternalCalls}`);
}
