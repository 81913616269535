import { Box, MenuItem, Typography } from "@mui/material";
import { DesignMenuItem } from "./DesignMenu";

type DesignMenuItemProps = {
  menuItem: DesignMenuItem;
  isLast: boolean;
};

export const DesignMenuItemComponent = ({
  menuItem,
  isLast,
}: DesignMenuItemProps) => (
  <MenuItem
    divider={!isLast}
    onClick={menuItem.action}
    sx={{
      py: 2,
      minWidth: "300px",
      gap: 1,
    }}
  >
    <Typography>{menuItem.title}</Typography>
    {menuItem?.value && (
      <Typography color="primary">
        {menuItem.value}
      </Typography>
    )}
    {menuItem?.hotkey && (
      <Box ml="auto">
        {menuItem.hotkey}
      </Box>
    )}
  </MenuItem>
);
