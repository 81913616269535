import { useEffect, useState } from "react";
import {
  Box,
  Link,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import {
  BATTERY_MODEL_POWERWALLPLUS,
  BATTERY_MODEL_POWERWALL_2,
  BATTERY_MODEL_POWERWALL_3_BASE_PACK,
  DesignEquipmentType,
  DesignEquipmentUtils,
  BATTERY_MODEL_POWERWALL_3_BASE_PACK_AC_COUPLED,
  sortEquipmentSpecificationsByModel,
} from "@sunrun/design-tools-domain-model";
import { ModalLayout } from "./components/ModalLayout";
import { postIFrameMessage } from "src//hooks/useIFrameHost";
import { IFrameEventType } from "src/types/IFrame";
import {
  ConfigureManualBatteriesModal,
  EQUIPMENT_MODAL_BOX_SIZING,
  EQUIPMENT_MODAL_FORM_SELECT_MAX_HEIGHT,
  EQUIPMENT_MODAL_SPACING,
} from ".";

import type {
  BatterySpecification,
} from "@sunrun/design-tools-domain-model";
import type { SelectChangeEvent } from "@mui/material";
import type { ChangeEvent } from "react";
import { useWorkspace } from "src/hooks/useWorkspace";
import { BATTERY_MODEL_POWERWALL_3_EXPANSION } from "@sunrun/design-tools-domain-model";
import { selectBackupProductVariantId, selectIsAdditionalSystem } from "src/features/offer/offerSlice";
import { deriveDesignClass } from "src/features/design/designSlice";
import { useAppDispatch, useAppSelector } from "src/store";
import { openConfigureManualBatteriesModal } from "src/features/modal/modalSlice";
import { KeystoneEquipment } from "@sunrun/design-tools-keystone-client";

type SelectBatteryModalProps = {
  availableMids: KeystoneEquipment[];
  designId?: string;
  offerId?: string;
  open: boolean;
  selectedBatteryIds?: string[];
  batteryCount?: number;
  allowEditEquipment?: boolean;
  allowEditBatteryCount?: boolean;
  maxAllowedBatteriesCount: number;
  onBatteryChange: (
    newBatteryIds: string[],
    batteryCount: number,
    newMidIds?: string[],
  ) => void;
  onClose: () => void;
};

type SelectedBatteryIdsByManufacturer = Record<string, string[]>;

export const SelectBatteryModal = (props: SelectBatteryModalProps) => {
  const { state } = useWorkspace();
  const keystoneEquipment = useAppSelector(state => state.availableEquipment.keystoneEquipment)
  const batterySelectionOptions = useAppSelector(
    (state) => state.availableEquipment.batterySelectionOptions
  );
  const isConfigureManualBatteriesModalOpen = useAppSelector(
    (state) => state.modal.isConfigureManualBatteriesModalOpen
  );
  const dispatch = useAppDispatch();
  const [selectedManufacturer, setSelectedManufacturer] = useState("");
  const [selectedBatteryCount, setSelectedBatteryCount] = useState<
    number | undefined
  >(0);
  const [selectedBatteriesByManufacturer, setSelectedBatteriesByManufacturer] =
    useState<SelectedBatteryIdsByManufacturer>({});
  const [selectedMid, setSelectedMid] = useState("");
  const [availableBatteries, setAvailableBatteries] = useState<
    BatterySpecification[]
  >([]);
  const [availableMids, setAvailableMids] = useState<
    KeystoneEquipment[]
  >([]);
  const [addedPowerwall2Batteries, setAddedPowerwall2Batteries] =
    useState(false);
  const [addedPowerwall3Expansion, setAddedPowerwall3Expansion] =
    useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [hasError, setHasError] = useState(false);
  const design = deriveDesignClass(state);
  const backupProductVariantId = selectBackupProductVariantId(state);

  useEffect(() => {
    // When update products is triggered, local state is not unmounted. This prevents the correct
    // selected manufacturer/model from being selected. Use selector for backupProductVariantId to update the dirty state
    setIsDirty(false);
  }, [backupProductVariantId]);

  useEffect(() => {
    // Powerwall 3 Extensions are not viable as a standalone battery selection. These will be selected automatically when a user selects Powerwall 3 Base Pack.
    setAvailableBatteries(
      batterySelectionOptions.filter(
        (battery) => battery.model !== BATTERY_MODEL_POWERWALL_3_EXPANSION
      )
    );
    if (!isDirty) {
      setSelectedBatteryCount(props.batteryCount);
      setAvailableMids(props.availableMids);
    }

    const initialSelectedBatteryIds = props.selectedBatteryIds;
    const initialSelectedManufacturer = batterySelectionOptions.find(
      (spec) => spec.id === initialSelectedBatteryIds?.[0]
    )?.manufacturer;
    const initialSelectedMid =
      props.availableMids.find((spec) => {
        const designEquipmentMid = DesignEquipmentUtils.filterByType(
          design?.designEquipment ?? [],
          DesignEquipmentType.MICROGRID_INTERCONNECT_DEVICE
        );
        return spec.sys.id === designEquipmentMid[0]?.specification?.id;
      })?.sys.id || "";

    if (!isDirty && initialSelectedMid !== undefined) {
      setSelectedMid(initialSelectedMid);
    }
    // If the form has not been edited then we set the initial manufacturer and battery selection
    if (
      !isDirty &&
      initialSelectedManufacturer !== undefined &&
      initialSelectedBatteryIds !== undefined
    ) {
      setSelectedManufacturer(initialSelectedManufacturer);

      let initialSelectedBatteriesByManufacturer =
        availableManufacturers.reduce(
          (acc: SelectedBatteryIdsByManufacturer, manufacturer) => {
            acc[manufacturer] = [];
            return acc;
          },
          {}
        );
      initialSelectedBatteriesByManufacturer[initialSelectedManufacturer] =
        initialSelectedBatteryIds;
      setSelectedBatteriesByManufacturer(
        initialSelectedBatteriesByManufacturer
      );
    }
  }, [batterySelectionOptions, props.selectedBatteryIds, props.open]);

  const availableManufacturers = [
    ...new Set(availableBatteries.map((spec) => spec.manufacturer)),
  ].sort();
  const manufacturerOptions = availableManufacturers.map((manufacturer) => {
    return (
      <MenuItem key={manufacturer} value={manufacturer}>
        {manufacturer}
      </MenuItem>
    );
  });

  let batteryCountArray: Array<number> = [];
  for (let i = 1; i <= props.maxAllowedBatteriesCount; i++) {
    batteryCountArray.push(i);
  }
  const batteryCountOptions = batteryCountArray.map((item) => {
    return (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    );
  });

  const filterBatteriesByManufacturer = (manufacturer: string) => {
    return availableBatteries
      .filter((spec) => spec.manufacturer === manufacturer)
      .sort(sortEquipmentSpecificationsByModel);
  };
  const storageForSelectedManufacturer =
    filterBatteriesByManufacturer(selectedManufacturer);

  const handleModelCheckboxToggle = (event: ChangeEvent<HTMLInputElement>) => {
    const id = event.target.name;
    setIsDirty(true);
    const updatedSelectedBatteriesByManufacturer = {
      ...selectedBatteriesByManufacturer,
    };
    const updatedSelectedIds = [
      ...(selectedBatteriesByManufacturer[selectedManufacturer] ?? []),
    ];
    const index = updatedSelectedIds.indexOf(id);
    if (index === -1) {
      updatedSelectedIds.push(id);
    } else {
      updatedSelectedIds.splice(index, 1);
    }

    if (updatedSelectedIds.length === 0) {
      setHasError(true);
    } else {
      setHasError(false);
    }

    updatedSelectedBatteriesByManufacturer[selectedManufacturer] =
      updatedSelectedIds;

    setSelectedBatteriesByManufacturer(updatedSelectedBatteriesByManufacturer);
  };

  const getBatterySpecByModel = (model: string) => {
    const batterySpec = batterySelectionOptions.find((battery) => {
      return battery.model === model;
    });
    return batterySpec;
  };

  const handleModelChange = (event: SelectChangeEvent) => {
    const selectedBatteries = { [selectedManufacturer]: [event.target.value] };
    setIsDirty(true);
    // If the user selects a Powerwall Plus battery, we should also add the Powerwall 2 battery if it is available.
    const powerwallPlusBatterySpec = getBatterySpecByModel(
      BATTERY_MODEL_POWERWALLPLUS
    );
    const powerwall2BatterySpec = getBatterySpecByModel(
      BATTERY_MODEL_POWERWALL_2
    );
    const powerwall3BatterySpec = getBatterySpecByModel(
      BATTERY_MODEL_POWERWALL_3_BASE_PACK
    );
    const powerwall3BatteryExtensionSpec = getBatterySpecByModel(
      BATTERY_MODEL_POWERWALL_3_EXPANSION
    );

    const isPowerwallPlus = event.target.value === powerwallPlusBatterySpec?.id;
    const isPowerwall3 = event.target.value === powerwall3BatterySpec?.id;

    if (isPowerwallPlus && powerwall2BatterySpec) {
      selectedBatteries[selectedManufacturer] = [
        powerwallPlusBatterySpec.id,
        powerwall2BatterySpec.id,
      ];
    }

    if (isPowerwall3 && powerwall3BatteryExtensionSpec) {
      selectedBatteries[selectedManufacturer] = [
        powerwall3BatterySpec.id,
        powerwall3BatteryExtensionSpec.id,
      ];
    }

    const selectedBatterySpecs = selectedBatteries[selectedManufacturer].map(
      (id) => design?.getBatterySpec(id)!
    );
    
    const additionalSystem = selectIsAdditionalSystem(state)
    const currentMids = design?.getCompatibleMids(keystoneEquipment ?? [], additionalSystem, selectedBatterySpecs)

    setAvailableMids(currentMids ?? []);
    setSelectedMid(currentMids?.[0]?.sys?.id ?? "");

    setSelectedBatteriesByManufacturer(selectedBatteries);
  };

  const handleMidChange = (event: SelectChangeEvent) => {
    setIsDirty(true);
    setSelectedMid(event.target.value);
  };

  // If the selected batteries have PW+ and PW2, show a note to the user that PW2 batteries will also be on the design
  useEffect(() => {
    const powerwallPlusBatterySpec = getBatterySpecByModel(
      BATTERY_MODEL_POWERWALLPLUS
    );
    const powerwall2BatterySpec = getBatterySpecByModel(
      BATTERY_MODEL_POWERWALL_2
    );

    if (
      powerwallPlusBatterySpec &&
      powerwall2BatterySpec &&
      selectedBatteriesByManufacturer[selectedManufacturer]?.includes(
        powerwallPlusBatterySpec.id
      ) &&
      selectedBatteriesByManufacturer[selectedManufacturer]?.includes(
        powerwall2BatterySpec.id
      )
    ) {
      setAddedPowerwall2Batteries(true);
    } else {
      setAddedPowerwall2Batteries(false);
    }

    const powerwall3ExpansionSpec = getBatterySpecByModel(
      BATTERY_MODEL_POWERWALL_3_EXPANSION
    );
    if (
      powerwall3ExpansionSpec &&
      selectedBatteriesByManufacturer[selectedManufacturer]?.includes(
        powerwall3ExpansionSpec.id
      )
    ) {
      setAddedPowerwall3Expansion(true);
    } else setAddedPowerwall3Expansion(false);
  }, [selectedBatteriesByManufacturer]);

  const multiSelectionManufacturers: string[] = [];
  const multiSelectEnabled =
    multiSelectionManufacturers.includes(selectedManufacturer);

  const multiSelectModelControls = (
    <FormControl
      fullWidth
      component="fieldset"
      sx={{
        maxHeight: EQUIPMENT_MODAL_FORM_SELECT_MAX_HEIGHT,
        overflow: "auto",
      }}
    >
      <FormLabel component="legend">Model</FormLabel>
      <Box px={EQUIPMENT_MODAL_BOX_SIZING}>
        <FormGroup>
          {storageForSelectedManufacturer.map((spec) => {
            const selectedBatteryIds =
              selectedBatteriesByManufacturer[selectedManufacturer] ?? [];
            return (
              <FormControlLabel
                key={spec.id}
                control={
                  <Checkbox
                    checked={selectedBatteryIds.includes(spec.id)}
                    onChange={handleModelCheckboxToggle}
                    name={spec.id}
                  />
                }
                label={spec.model}
              />
            );
          })}
        </FormGroup>
      </Box>
    </FormControl>
  );

  const singleSelectModelControls = (
    <FormControl fullWidth>
      <InputLabel id="battery-model-label">Model</InputLabel>
      <Select
        label="Model"
        labelId="battery-model-label"
        onChange={handleModelChange}
        value={selectedBatteriesByManufacturer[selectedManufacturer]?.[0] || ""}
      >
        {storageForSelectedManufacturer.map((spec) => (
          <MenuItem key={spec.id} value={spec.id}>
            {spec.model}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const singleSelectMidControls = (
    <FormControl fullWidth>
      <InputLabel id="storage-microgrid-interconnect-device-label">
        Microgrid Interconnect Device
      </InputLabel>
      <Select
        label="Microgrid Interconnect Device"
        labelId="storage-microgrid-interconnect-device-label"
        onChange={handleMidChange}
        value={selectedMid}
      >
        {availableMids.map((spec) => (
          <MenuItem key={spec.sys.id} value={spec.sys.id}>
            {spec.model}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const handleManufacturerChange = (event: SelectChangeEvent) => {
    setHasError(false);
    setIsDirty(true);
    setSelectedManufacturer(event.target.value);
  };

  const handleCountChange = (event: SelectChangeEvent) => {
    setHasError(false);
    setIsDirty(true);
    setSelectedBatteryCount(parseInt(event.target.value));
  };

  const handleContinue = () => {
    const selectedBatteryIds =
      selectedBatteriesByManufacturer[selectedManufacturer];

    if (selectedBatteryIds.length > 0) {
      if (selectedMid) {
        props.onBatteryChange(
          selectedBatteryIds,
          selectedBatteryCount || 0,
          [selectedMid],
        );
      } else {
        props.onBatteryChange(selectedBatteryIds, selectedBatteryCount || 0);
      }
    } else {
      setHasError(true);
    }
  };

  const handleClose = () => {
    setIsDirty(false);
    setHasError(false);
    setSelectedBatteriesByManufacturer({});
    setSelectedManufacturer("");
    setSelectedMid("");
    setSelectedBatteryCount(1);
    props.onClose();
  };

  const updateProducts = (event: React.MouseEvent) => {
    event.preventDefault();
    handleClose();
    postIFrameMessage(IFrameEventType.DESIGN_EDIT_CANCEL, {
      designId: props.designId,
      offerId: props.offerId,
    });
  };

  const showPW3ConfigButton = () => {
    const selectedBatteryIds =
      selectedBatteriesByManufacturer[selectedManufacturer] ?? [];
    const selectedBatterySpecs: BatterySpecification[] = selectedBatteryIds
      .map((id) => design?.getBatterySpec(id))
      .filter((spec) => !!spec) as BatterySpecification[];
    const powerwall3Base = selectedBatterySpecs.find(
      (spec) =>
        spec.model === BATTERY_MODEL_POWERWALL_3_BASE_PACK ||
        spec.model === BATTERY_MODEL_POWERWALL_3_BASE_PACK_AC_COUPLED
    );
    const powerwall3Extension = selectedBatterySpecs.find(
      (spec) => spec.model === BATTERY_MODEL_POWERWALL_3_EXPANSION
    );
    return (
      props.allowEditEquipment && !!powerwall3Base && !!powerwall3Extension
    );
  };

  return isConfigureManualBatteriesModalOpen ? (
    <ConfigureManualBatteriesModal
      selectedMidIds={selectedMid ? [selectedMid] : []}
      batteryCount={selectedBatteryCount ?? 1}
      selectedBatteryIds={selectedBatteriesByManufacturer[selectedManufacturer]}
      maxAllowedBatteriesCount={props.maxAllowedBatteriesCount}
    />
  ) : (
    <ModalLayout
      open={props.open}
      title={
        props.allowEditEquipment
          ? "Select Available Batteries"
          : "Select Battery Count"
      }
      onClose={handleClose}
      onContinue={handleContinue}
      actionButtons={true}
      cancelText="Cancel"
      continueText={
        props.allowEditEquipment
          ? "Select Available Batteries"
          : "Select Battery Count"
      }
      secondaryActionButton={showPW3ConfigButton()}
      secondaryActionText="Manual Configuration"
      onSecondaryAction={() => {
        dispatch(openConfigureManualBatteriesModal());
      }}
    >
      {availableBatteries.length === 0 ? (
        <Typography variant="body2">
          No batteries available. Something went wrong.
        </Typography>
      ) : (
        <Box>
          <Stack spacing={EQUIPMENT_MODAL_SPACING}>
            {props.allowEditEquipment && (
              <>
                <Typography variant="body2">
                  Select which models may be used for this design. You may only
                  select models from a single manufacturer.
                </Typography>
                <Typography variant="body2">
                  Only showing battery options that are compatible with selected
                  products,
                  <br />
                  <Link href="#" onClick={updateProducts}>
                    update products
                  </Link>{" "}
                  to select other batteries.
                </Typography>
                <Box pt={EQUIPMENT_MODAL_BOX_SIZING}>
                  <FormControl fullWidth>
                    <InputLabel id="storage-manufacturer-label">
                      Manufacturer
                    </InputLabel>
                    <Select
                      label="Manufacturer"
                      labelId="storage-manufacturer-label"
                      onChange={handleManufacturerChange}
                      value={selectedManufacturer}
                    >
                      {manufacturerOptions}
                    </Select>
                  </FormControl>
                </Box>
                {selectedManufacturer && (
                  <>
                    {multiSelectEnabled
                      ? multiSelectModelControls
                      : singleSelectModelControls}
                  </>
                )}
                {availableMids.length > 0 && <>{singleSelectMidControls}</>}
                {selectedManufacturer && (
                  <>
                    {addedPowerwall2Batteries && (
                      <FormHelperText>
                        Powerwall 2 batteries will be added for designs
                        requiring more batteries than inverters.
                      </FormHelperText>
                    )}
                    {addedPowerwall3Expansion && (
                      <FormHelperText>
                        Powerwall 3 expansion batteries will be added for
                        designs requiring additional batteries.
                      </FormHelperText>
                    )}
                    {hasError && (
                      <FormHelperText error>
                        At least one battery is required
                      </FormHelperText>
                    )}
                  </>
                )}
                {availableMids.length > 1 && (
                  <>
                    <FormHelperText>
                      This battery is compatible with multiple MID options.
                      Please select correct MID for the design.
                    </FormHelperText>
                  </>
                )}
              </>
            )}
            {props.allowEditBatteryCount && (
              <Box pt={EQUIPMENT_MODAL_BOX_SIZING}>
                <FormControl fullWidth>
                  <InputLabel id="storage-count-label">Count</InputLabel>
                  <Select
                    label="Count"
                    labelId="storage-count-label"
                    onChange={handleCountChange}
                    value={selectedBatteryCount?.toString()}
                  >
                    {batteryCountOptions}
                  </Select>
                </FormControl>
              </Box>
            )}
          </Stack>
        </Box>
      )}
    </ModalLayout>
  );
};
