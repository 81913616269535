"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.findSnsTopicArnByNamePrefix = void 0;
const client_sns_1 = require("@aws-sdk/client-sns");
const snsClient = new client_sns_1.SNSClient({ region: 'us-west-2' });
function findSnsTopicArnByNamePrefix(snsTopicName) {
    return __awaiter(this, void 0, void 0, function* () {
        console.log(`Attempting to find SNS Topic Arn with name ${snsTopicName}`);
        let nextToken = undefined;
        do {
            const listOutput = yield snsClient.send(new client_sns_1.ListTopicsCommand({ NextToken: nextToken }));
            // SNS Topic Arn Format: arn:aws:sns:{region}:{account-id}:{topicName}
            const foundIt = listOutput.Topics.find(it => {
                const stringAfterFifthColon = it.TopicArn.split(':').slice(5).join(':');
                return stringAfterFifthColon.startsWith(snsTopicName);
            });
            if (foundIt) {
                return foundIt.TopicArn;
            }
            nextToken = listOutput === null || listOutput === void 0 ? void 0 : listOutput.NextToken;
        } while (nextToken);
    });
}
exports.findSnsTopicArnByNamePrefix = findSnsTopicArnByNamePrefix;
