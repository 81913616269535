import { G2CoordinateSystem2D } from "./crs2D";
import {roundToDecimalPlaces} from "./util"
import { G2Vector3D } from "./vector3D";

export class G2Vector2D  {
  readonly x: number;
  readonly y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  public dot(vector: G2Vector2D): number {
    return this.x * vector.x + this.y * vector.y
  }

  //determinant
  public det(vector: G2Vector2D): number {
    return this.x * vector.y - this.y * vector.x
  }

  public equals(otherVector2D: G2Vector2D, toDecimalPlaces: number = 2) {
    return roundToDecimalPlaces(this.x, toDecimalPlaces) == roundToDecimalPlaces(otherVector2D.x, toDecimalPlaces) &&
    roundToDecimalPlaces(this.y, toDecimalPlaces) == roundToDecimalPlaces(otherVector2D.y, toDecimalPlaces)
  }

  public normalize() {
    const norm = Math.sqrt(this.x * this.x + this.y * this.y);
    return new G2Vector2D(this.x/norm, this.y/norm)
  }

  public length():number {
    return Math.sqrt(
        this.dot(this)
    );
  }

  public dist(vector: G2Vector2D): number {
    const xDistance = this.x - vector.x
    const yDistance = this.y - vector.y
    return Math.sqrt(xDistance * xDistance + yDistance * yDistance)
  }

  public add(vector2D: G2Vector2D): G2Vector2D {
    return this.toVector3D().add(vector2D.toVector3D()).toVector2D()
  }

  public sub(vector2D: G2Vector2D): G2Vector2D {
    return this.toVector3D().sub(vector2D.toVector3D()).toVector2D()
  }

  public scale(s:number): G2Vector2D {
    return this.toVector3D().scale(s).toVector2D()
  }

  //scalarProjectTo is dot product, it can be:
  //positive: this and vector has angle < 90 degrees
  //negative: this and vector has angle > 90 degrees
  //zero: this and vector is perpendicular
  public scalarProjectTo(vector:G2Vector2D): number {
    return this.toVector3D().scalarProjectTo(vector.toVector3D())
  }

  public projectTo(vector:G2Vector2D): G2Vector2D {
    return this.toVector3D().projectTo(vector.toVector3D()).toVector2D()
  }

  public midpoint(vector:G2Vector2D): G2Vector2D {
    return this.toVector3D().midpoint(vector.toVector3D()).toVector2D()
  }

  public toNumArray(): number[] {
    return [this.x, this.y]
  }

  public toVector3D(): G2Vector3D {
    return new G2Vector3D(this.x, this.y, 0)
  }

  //no need to specify around axis in 2D, its always around Z axix
  public anglesCounterClockWiseInRadiansToVector(vector: G2Vector2D): number {
    const dot = this.dot(vector)
    const det = this.det(vector)
    return Math.atan2(det, dot)
  }

  //no need to specify around axis in 2D, its always around Z axix
  public rotateCounterClockWiseByRadians(byRadians: number, origin: G2Vector2D = new G2Vector2D(0,0)): G2Vector2D {
    //leverage the function in 3D
    const zAxis = new G2Vector3D(0,0,1)
    return this.toVector3D().rotateCounterClockwiseByRadiansAroundAxis(byRadians, zAxis, origin.toVector3D()).toVector2D()
  }

  //project vector2D from its local coordinate system (always origin=(0,0), xAxis=(1,0), yAxis=(0,1)) to newCoordinateSystem
  public projectToCRS(toCRS: G2CoordinateSystem2D): G2Vector2D {
    //any vector is described from the local coordinate perspective
    const localCRS = G2CoordinateSystem2D.baseCRS()
    const originDelta = localCRS.originDeltaToCRS(toCRS)

    const originDeltaProjected = new G2Vector2D(
      originDelta.scalarProjectTo(toCRS.xAxis),
      originDelta.scalarProjectTo(toCRS.yAxis)
    )
    const thisProjected = new G2Vector2D(
      this.scalarProjectTo(toCRS.xAxis),
      this.scalarProjectTo(toCRS.yAxis)
    )
    //returned vector is on the projected crs, so this and originDelta are projected to toCRS first
    return thisProjected.sub(originDeltaProjected)
  }
}
