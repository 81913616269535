import {
  Design,
  Offer,
  createProductToEquipmentSpecsMap,
  createProductToKeystoneEquipmentMap,
  updateDesignToMatchOffer,
} from "@sunrun/design-tools-domain-model";
import { useCallback, useEffect, useState } from "react";
import {
  dismissOfferUpdateConfirmationModal,
  dismissProductEquipmentMappingErrorModal,
  displayProductEquipmentMappingErrorModal,
  ErrorModalConfig,
} from "src/features/modal/modalSlice";
import { useWorkspace } from "./useWorkspace";
import { deriveSiteModelClass } from "src/features/siteModel/siteModelSlice";
import { deriveDesignClass, setDesign } from "src/features/design/designSlice";
import { useAppDispatch, useAppSelector } from "src/store";
import { setBatterSelectionOptions } from "src/features/equipment/availableEquipmentSlice";

export const useProductToEquipmentMapping = () => {
  const { state, dispatch: workspaceDispatch } = useWorkspace();
  const dispatch = useAppDispatch();
  const { offer, workflowState, modal, settings } = state;
  const equipmentSpecsForProductMapping =
    state.availableEquipmentIfNewOffer || state.availableEquipment;
  const { hasAvailableEquipmentLoaded } = workflowState;
  const {
    isOfferUpdateConfirmationModalOpen,
    isProductEquipmentMappingErrorModalOpen,
  } = modal;
  const siteModel = deriveSiteModelClass(state);
  const design = deriveDesignClass(state);
  const keystoneEquipment = useAppSelector(
    (state) => state.availableEquipment.keystoneEquipment
  );
  // New piece of logic currently for sales reps who want to sell 1x PW2 without being forced into 10k inverter then forced into 2x PW2 batteries.
  // This logic needs to live the client so it can be toggled on and off.
  // call handleProductToEquipmentMapping whenever isFilter10kLogicEnabled is toggled
  useEffect(() => {
    if (design && offer && workflowState.hasDesignInitiated) {
      const updatedDesign = handleProductToEquipmentMapping(design, offer);
      dispatch(setDesign(updatedDesign.getIDesign()));
      workspaceDispatch({ type: "setDesign", payload: updatedDesign });
    }
  }, [settings.isFilter10kLogicEnabled]);

  // Do Product To Equipment Mapping and pop up any necessary modals
  // For use when the design will be updated multiple times in a given operation.
  const handleProductToEquipmentMapping = useCallback(
    (design: Design, offer?: Offer): Design => {
      if (!offer) {
        offer = state.offer;
      }
      if (
        !offer ||
        !siteModel ||
        !hasAvailableEquipmentLoaded ||
        !keystoneEquipment ||
        !equipmentSpecsForProductMapping ||
        !state.availableEquipment
      ) {
        console.error(
          "Attempting to update Design to match Offer when aggregates are not yet loaded"
        );
        return design;
      }
      // close previously open modals
      if (isOfferUpdateConfirmationModalOpen) {
        dispatch(dismissOfferUpdateConfirmationModal());
        workspaceDispatch({ type: "dismissOfferUpdateConfirmationModal" });
      }
      if (isProductEquipmentMappingErrorModalOpen)
        dispatch(dismissProductEquipmentMappingErrorModal());
      workspaceDispatch({ type: "dismissProductEquipmentMappingErrorModal" });

      // Create product to equipment mappings
      console.log("Mapping products from offer to equipment specifications.");
      const productToEquipmentSpecsMap = createProductToEquipmentSpecsMap(
        offer,
        equipmentSpecsForProductMapping
      );
      const productToKeystoneEquipmentMap = createProductToKeystoneEquipmentMap(
        offer,
        keystoneEquipment
      );
      console.log(
        "productToEquipmentSpecsMap: ProductId -> EquipmentSpecifications",
        productToEquipmentSpecsMap
      );
      console.log(
        "productToKeystoneEquipmentMap: ProductId -> KeystoneEquipment",
        productToKeystoneEquipmentMap
      );

      // Powerwall+ batteries are not in equipmentSpecsForProductMapping because they are limited to change-orders
      // Using createProductToEquipmentSpecsMap with all availableEquipment for the battery equipment dropdown
      const productToEquipmentSpecsMapWithAvailableEquipment =
        createProductToEquipmentSpecsMap(offer, state.availableEquipment);
      // We currently only filter batteries selection availability based on the offer. Setting batteries to state
      // so we can use this subset of batteries for the equipment dropdown.
      const batterySpecsForOffer = [
        ...new Set(
          [
            ...productToEquipmentSpecsMapWithAvailableEquipment.values(),
          ].flatMap((equipmentSpecs) => equipmentSpecs.batterySpecifications)
        ),
      ];
      dispatch(setBatterSelectionOptions(batterySpecsForOffer));

      let updatedDesign = design;
      const enforceSingleBattery = settings.isFilter10kLogicEnabled;
      try {
        updatedDesign = updateDesignToMatchOffer(
          updatedDesign,
          offer,
          siteModel,
          productToEquipmentSpecsMap,
          productToKeystoneEquipmentMap,
          equipmentSpecsForProductMapping,
          keystoneEquipment,
          enforceSingleBattery
        );
      } catch (e) {
        console.error("Product To Equipment Error", e);
        if (e instanceof Error) {
          const config: ErrorModalConfig = { error: e };
          dispatch(displayProductEquipmentMappingErrorModal(config));
          workspaceDispatch({
            type: "displayProductEquipmentMappingErrorModal",
            payload: config,
          });
        }
      }

      return updatedDesign;
    },
    [
      design,
      offer,
      siteModel,
      hasAvailableEquipmentLoaded,
      keystoneEquipment,
      isOfferUpdateConfirmationModalOpen,
      isProductEquipmentMappingErrorModalOpen,
      settings.isFilter10kLogicEnabled,
    ]
  );

  // Does all of above handleProductToEquipmentMapping, while also setting Design to state
  // For use when this is the standalone action being taken
  const handleProductToEquipmentMappingAndSetDesign = useCallback(
    (offer: Offer) => {
      if (!design) {
        console.warn(
          "Attempting to update Design to match Offer when Design is not yet loaded"
        );
        return;
      }
      const updatedDesign = handleProductToEquipmentMapping(design, offer);
      dispatch(setDesign(updatedDesign.getIDesign()));
      workspaceDispatch({ type: "setDesign", payload: updatedDesign });
    },
    [design, offer, handleProductToEquipmentMapping]
  );

  return {
    handleProductToEquipmentMapping,
    handleProductToEquipmentMappingAndSetDesign,
  };
};
