import { PayloadAction, createSlice } from "@reduxjs/toolkit";

// We name it as selectedInverter here but LC suggests we change this to more LC friendly naming
// such as selectedRequiredInverters or requiredInverters to maintain nomenclature.
// ET - 1985: To update iHD naming as well if warranted but not necessary.
export type SelectedInverter = {
  id: string;
  count: number | undefined;
};
export type SelectedEquipmentState = {
  selectedInverters: SelectedInverter[] | undefined;
};

export const initialState: SelectedEquipmentState = {
  selectedInverters: undefined,
};

export const selectedEquipmentSlice = createSlice({
  name: "selectedEquipment",
  initialState,
  reducers: {
    // selectedInverterSlice currently used to manage only in the case of manual configuration
    // This may need to get updated once keystone migrations and we need this state globally
    // Example: Microinverters won't ever be in this state currently
    setSelectedInverters: (
      state: SelectedEquipmentState,
      action: PayloadAction<SelectedInverter[] | undefined>
    ) => {
      state.selectedInverters = action.payload;
    },
  },
});

export const { setSelectedInverters } = selectedEquipmentSlice.actions;
export default selectedEquipmentSlice.reducer;
