import {createSelector} from "reselect";
import {deriveDesignClass} from "src/features/design/designSlice"
import {deriveSiteModelClass} from "src/features/siteModel/siteModelSlice"
import {selectIsLeafletDragging} from "src/features/moduleDrag/moduleDragSlice";
import {ModuleCollisionChecker} from "@sunrun/design-tools-domain-model";
import {StaticReferences} from "@sunrun/design-tools-domain-model";
import {selectIsModuleNudging} from "../moduleNudge/moduleNudgeSlice";

export const deriveCollidingModules = createSelector(
  [deriveDesignClass, deriveSiteModelClass, selectIsLeafletDragging, selectIsModuleNudging],
  (design, siteModel, isLeafletDragging, isModuleNudging): Set<string> => {
    if (isLeafletDragging || isModuleNudging) {
      // Only derive when drag/nudge completes
      return deriveCollidingModules.lastResult()
    }
    if (design && siteModel) {
      return ModuleCollisionChecker.findCollidingModules(design.modules, siteModel);
    }
    return StaticReferences.EMPTY_SET;
  }
);

export const deriveCollidingModuleDetailCounts = createSelector(
  [deriveDesignClass, deriveSiteModelClass],
  (design, siteModel): {collisionsCount?:number, siteModelCollisionsCount?: number, obstructionCollisionsCount?: number, modulesOffRoofFaceCount?: number, interModuleCollisionsCount?: number}  => {
    if (design && siteModel) {      
      const collisionsCount = ModuleCollisionChecker.findCollidingModules(design.modules, siteModel).size
      const siteModelCollisionsCount = ModuleCollisionChecker.checkForModuleSiteModelCollisions(design.modules, siteModel, true).size
      const obstructionCollisionsCount =  ModuleCollisionChecker.checkForModuleSiteModelCollisions(design.modules, siteModel, false).size - siteModelCollisionsCount
      const modulesOffRoofFaceCount= ModuleCollisionChecker.checkForModulesOffRoofFace(design.modules, siteModel).size
      const interModuleCollisionsCount= ModuleCollisionChecker.checkForInterModuleCollisions(design.modules).size
      return {collisionsCount, siteModelCollisionsCount, obstructionCollisionsCount, modulesOffRoofFaceCount, interModuleCollisionsCount}
    }
    return {}
  }
);
