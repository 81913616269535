import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {WorkspacePayloadAction} from "../../types/state-management/action";
import {BatterySpecification, EquipmentSpecifications} from "@sunrun/design-tools-domain-model";
import { KeystoneEquipment } from "@sunrun/design-tools-keystone-client";

export type AvailableEquipmentState = {
  value?: EquipmentSpecifications
  valueIfNewOffer?: EquipmentSpecifications
  keystoneEquipment?: KeystoneEquipment[]
  batterySelectionOptions: BatterySpecification[]
  experimentalEquipmentSpecs?: Partial<EquipmentSpecifications>
};

export const initialState: AvailableEquipmentState = {
  value: undefined,
  valueIfNewOffer: undefined,
  keystoneEquipment: undefined,
  batterySelectionOptions: [],
  experimentalEquipmentSpecs: undefined,
};

export const availableEquipmentSlice = createSlice({
  name: "availableEquipment",
  initialState,
  reducers: {
    setAvailableEquipment: (state: AvailableEquipmentState, action: PayloadAction<EquipmentSpecifications>) => {
      console.log('setting available equipment inside redux')
      state.value = action.payload;
    },
    setAvailableEquipmentIfNewOffer: (state: AvailableEquipmentState, action: PayloadAction<EquipmentSpecifications>) => {
      state.valueIfNewOffer = action.payload;
    },
    setKeystoneEquipment: (state: AvailableEquipmentState, action: PayloadAction<KeystoneEquipment[]>) => {
      state.keystoneEquipment = action.payload;
    },
    setBatterSelectionOptions: (state: AvailableEquipmentState, action: PayloadAction<BatterySpecification[]>) => {
      state.batterySelectionOptions = action.payload
    },
    setExperimentalEquipmentSpecs: (state: AvailableEquipmentState, action: PayloadAction<Partial<EquipmentSpecifications> | undefined>) => {
      state.experimentalEquipmentSpecs = action.payload
    }
  },
});

export const { setAvailableEquipment, setAvailableEquipmentIfNewOffer, setKeystoneEquipment, setBatterSelectionOptions, setExperimentalEquipmentSpecs } = availableEquipmentSlice.actions
export default availableEquipmentSlice.reducer

// TODO: Remove the following and transition fully from WorkspaceState to RTK store
export type AvailableEquipmentAction = WorkspacePayloadAction<EquipmentSpecifications, 'setAvailableEquipment'>;

export const selectAvailableEquipment = (state: WorkspaceState) => state.availableEquipment;

export const availableEquipmentWorkspaceReducer = (state: WorkspaceState, action: WorkspaceAction): EquipmentSpecifications | undefined => {
  switch (action.type) {
    case 'setAvailableEquipment': {
      return action.payload;
    }

    default: {
      return state.availableEquipment;
    }
  }
};
