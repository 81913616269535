import React from "react";
import { useWorkspace } from "../../../hooks/useWorkspace";
import { useOfferLineUpdates } from "../../../hooks/useOfferLineUpdates";
import { postIFrameMessage } from "../../../hooks/useIFrameHost";
import { IFrameEventType, IFrameHostType } from "../../../types/IFrame";
import { SrButton } from "@sunrun/experience-ui-components";
import { ButtonProps } from "@sunrun/experience-ui-components/lib/typescript/components/Button/Button";
import { deriveDesignReadyForExport } from "../../../features/designGuidance/deriveDesignReadyForExport";
import {
  DesignVariantName,
  getDesiredBatteryCountFromOffer,
  isStringInverter,
} from "@sunrun/design-tools-domain-model";
import { useProductToEquipmentMapping } from "src/hooks/useProductToEquipmentMapping";
import { useFlags } from "flagsmith/react";
import { FlagsmithFeatureEnums } from "src/config/flagsmithConfig";
import * as FullStory from "@fullstory/browser";
import { deriveDesignClass } from "src/features/design/designSlice";
import { useAppSelector } from "src/store";
import { useBookmarkDesign } from "src/hooks/useBookmarkDesign";

export type WorkflowButtonProps = {
  disableFinalizeDesignButton: boolean;
  simulateDesign: () => void;
  exportDesign: () => void;
  createDesignImages: () => void;
  legacyFinalizeDesign: () => void;
  recalculateSetbacks: () => void;
  buttonProps?: ButtonProps;
};

export const WorkflowButton: React.FC<WorkflowButtonProps> = (props) => {
  const { state } = useWorkspace();
  switch (state.host) {
    case IFrameHostType.LIGHTMILE_OFFER_BUILDER:
    case IFrameHostType.OFFER_BUILDER:
      return <OfferExperienceButton {...props} />;
    case IFrameHostType.LIGHTMILE:
      return <></>;
    case IFrameHostType.SPLAT:
    case IFrameHostType.UNDEFINED:
    default:
      return <LegacyButton {...props} />;
  }
};

const OfferExperienceButton: React.FC<WorkflowButtonProps> = (props) => {
  const { state, dispatch } = useWorkspace();
  const { offer } = state;
  const {
    disableFinalizeDesignButton,
    simulateDesign,
    exportDesign,
    createDesignImages,
    buttonProps,
  } = props;
  const { updateOfferLines } = useOfferLineUpdates();
  const experimentalEquipmentSpecs = useAppSelector(
    (state) => state.availableEquipment.experimentalEquipmentSpecs
  );
  const { addBookmarkToLatestDesignMutation } = useBookmarkDesign();
  const readyToGoToPricing = deriveDesignReadyForExport(
    state,
    experimentalEquipmentSpecs
  );
  const design = deriveDesignClass(state);
  const { handleProductToEquipmentMappingAndSetDesign } =
    useProductToEquipmentMapping();
  const shouldCreateDesignImages = useFlags([
    FlagsmithFeatureEnums.CREATE_DESIGN_IMAGES,
  ])[FlagsmithFeatureEnums.CREATE_DESIGN_IMAGES].enabled;

  const handleFinalizeDesign = async () => {
    // This resolveSave confirms that all user actions are captured in a design before simulateDesign
    // LS-2266 Retry productToEquipmentMapping if an offer requires batteries but our design is missing batteries
    if (
      offer &&
      design &&
      getDesiredBatteryCountFromOffer(offer, design) > 0 &&
      design.batteries.length === 0
    ) {
      // capturing this as an error log so we can track this in Fullstory
      console.error(
        "Mismatch between design and offer batteries. Re-running handleProductToEquipmentMappingAndSetDesign"
      );
      await handleProductToEquipmentMappingAndSetDesign(offer); // Awaiting ensures handleProductToEquipmentMappingAndSetDesign is not called before design.latest is updated
      dispatch({ type: "resolveSave", payload: design });
    } else {
      await dispatch({ type: "resolveSave", payload: design! }); // Awaiting ensures resolveSave is not called before design.latest is updated
    }
    simulateDesign();
  };

  const exportDesignAndUpdateOffer = React.useCallback(async () => {
    // Bookmark design before going to pricing
    try {
      console.info("Bookmarking design before going to pricing");
      if (design) {
        await addBookmarkToLatestDesignMutation.mutateAsync(design);
      }
    } catch (err) {
      console.error("Error Bookmarking design before going to pricing: ", err);
    }

    // Log when a Offer Experience Design clicks go to pricing to compare percentage to Variant Designs go to pricing
    try {
      FullStory.event("Offer Experience Go To Pricing", {
        designId: design!.id,
        selectedDesignVariant: state.workflowState.variantDesignSelected
          ? state.workflowState.variantDesignSelected
          : isStringInverter(design!.selectedInverterSpecifications[0])
          ? DesignVariantName.UnoptimizedString
          : DesignVariantName.Mlpe,
      });
      if (
        state.workflowState.variantDesignDefault &&
        state.workflowState.variantDesignSelected
      ) {
        FullStory.event("Variant Design Go To Pricing", {
          designId: design?.id,
          defaultVariant: state.workflowState.variantDesignDefault,
          selectedDesignVariant: state.workflowState.variantDesignSelected,
        });
      }
    } catch {
      console.error("Error collecting Fullstory Metrics on Go to Pricing");
    }

    await exportDesign();
    const { success } = await updateOfferLines();
    if (success) {
      if (shouldCreateDesignImages) {
        createDesignImages();
      }
      postIFrameMessage(IFrameEventType.DESIGN_EDIT_COMPLETE, {
        designId: design!.id,
      });
    }
  }, [updateOfferLines, addBookmarkToLatestDesignMutation]);

  if (readyToGoToPricing) {
    return (
      <SrButton
        onPress={exportDesignAndUpdateOffer}
        onLongPress={exportDesignAndUpdateOffer}
        {...buttonProps}
      >
        Go To Pricing
      </SrButton>
    );
  }
  return (
    <SrButton
      isDisabled={disableFinalizeDesignButton}
      onPress={handleFinalizeDesign}
      onLongPress={handleFinalizeDesign}
      {...buttonProps}
    >
      Finalize Design
    </SrButton>
  );
};

const LegacyButton: React.FC<WorkflowButtonProps> = (props) => {
  const { disableFinalizeDesignButton, legacyFinalizeDesign, buttonProps } =
    props;

  return (
    <SrButton
      isDisabled={disableFinalizeDesignButton}
      onPress={legacyFinalizeDesign}
      onLongPress={legacyFinalizeDesign}
      {...buttonProps}
    >
      Finalize Design
    </SrButton>
  );
};
